<template>
  <span :class="$style.tag" data-qa="tag-closed">
    {{ $trlMessage('Closed') }}
  </span>
</template>

<script>
export default {
  name: 'TagClosed'
};
</script>

<style lang="less" module>
.tag {
  position: relative;
  box-sizing: border-box;
  display: inline-block;

  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-transform: uppercase;
  color: #999;
  background-color: transparent;

  margin: 0;
  padding: 4px 7px 3px 7px;

  border: 1px solid;
  border-radius: 3px;
  outline: 0 none;

  cursor: default;
  vertical-align: bottom;
  user-select: none;
}
</style>

<i18n lang="json">{}</i18n>
