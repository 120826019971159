/**
 * Высота одной строки в сетке расписания (в пикселях).
 * Это значение должно быть согласовано с соответствующим значением в стилях компонента. (TODO заменить на v-bind())
 */
export const ROW_HEIGHT = 50;
/**
 * Ширина ячейки времени в сетке расписания (в пикселях).
 * Это значение должно быть согласовано с соответствующим значением в стилях компонента. (TODO заменить на v-bind())
 */
export const TIMECELL_WIDTH = 45;
/**
 * Высота строки для отображения событий на весь день (в пикселях).
 * Учитывается при расчете общей высоты компонента.
 * Это значение должно быть согласовано с соответствующим значением в стилях компонента. (TODO заменить на v-bind())
 */
export const ALL_DAY_ROW_HEIGHT = 27;
