<template>
  <div :class="progressClassName" :title="deadlineTitle">
    <icon type="clock" :class="$style.icon" width="12px" />
    <span :class="$style.title">
      {{ title }}
    </span>
  </div>
</template>

<script>
import Icon from '../icon.vue';

export default {
  name: 'DeadlineProgressPure',
  components: {
    Icon
  },
  props: {
    progress: {
      type: Number,
      required: true
    },
    daysRemaining: {
      type: Number,
      required: true
    },
    deadlineTitle: {
      type: String,
      default: null
    }
  },
  computed: {
    progressClassName() {
      return {
        [this.$style.progress]: true,
        [this.$style.warning]: this.progress >= 0.8 && this.progress <= 1,
        [this.$style.overdue]: this.progress > 1
      };
    },
    progressStyle() {
      return {
        width: `${Math.min(this.progress * 100, 100)}%`
      };
    },
    title() {
      const days = Math.abs(this.daysRemaining);
      return this.daysRemaining >= 0
        ? this.$trlMessage('dashboard.progress.remaining', {
            days
          })
        : this.$trlMessage('dashboard.progress.overdue', {
            days
          });
    }
  }
};
</script>

<style lang="less" module>
@import '../../../less/common/mixins/font';
@import '../../../less/common/variables.less';

.progress {
  color: rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.warning {
  color: #db8330;
}

.overdue {
  color: @red-500;
}

.title {
  color: inherit;
}

.icon {
  display: flex;
  color: inherit;
}
</style>

<i18n lang="json">{}</i18n>
