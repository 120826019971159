import { TelephonyIntegration } from '@/types/telephony-type';

export function createIntegration(telephonyIntegration: TelephonyIntegration) {
  switch (telephonyIntegration.integration) {
    case 'VOXIMPLANT':
      return import('@/services/telephony/integration/voximplant-integration').then(
        ({ VoximplantIntegration }) => new VoximplantIntegration(telephonyIntegration.settings)
      );
    case 'MANGO':
      return import('@/services/telephony/integration/mango-integration').then(
        ({ MangoIntegration }) => new MangoIntegration()
      );
    case 'ASTERISK':
      return import('@/services/telephony/integration/asterisk-integration').then(
        ({ AsteriskIntegration }) => new AsteriskIntegration()
      );
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = telephonyIntegration;
      throw new Error(`integration ${JSON.stringify(telephonyIntegration)} not implemented`);
    }
  }
}
