/*
 * Builds index-based ancestors info
 */
function buildAncestorsInfo(fields, name) {
  const info = Array.from({ length: fields.length }).map(() => []);
  let i = 0;
  while (i < fields.length) {
    i = handleItemAtIndex(i, fields, info);
  }
  return info;

  function handleItemAtIndex(index, items, info) {
    const parent = items[index];
    const parentPath = info[index] ?? [];
    info[index] = parentPath;
    let lookupIndex = index + 1;
    while (lookupIndex < items.length && items[lookupIndex].deep > parent.deep) {
      const diff = items[lookupIndex].deep - parent.deep;
      if (diff > 1) {
        throw new Error('Malformed dictionary: ', name);
      }

      if (diff === 1) {
        info[lookupIndex] = [...parentPath, index];
        lookupIndex = handleItemAtIndex(lookupIndex, items, info);
      }
    }
    return lookupIndex;
  }
}

export const DictionaryHelpers = {
  buildAncestorsInfo
};
