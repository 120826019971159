import Draggabilly from 'draggabilly';

const LOCAL_STORAGE_KEY = 'left-menu-width';
const minWidth = 250;
const maxWidth = 400;

export function initMenuResize({ container, control }) {
  const oldValue = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (oldValue) {
    control.style.left = `${oldValue}px`;
    document.documentElement.style.setProperty('--sidebar-width', oldValue);
  }

  const draggie = new Draggabilly(control, {
    axis: 'x',
    containment: container
  });

  function setWidth(width) {
    document.documentElement.style.setProperty('--sidebar-width', width);
  }

  function handleResize() {
    const width = Math.min(Math.max(draggie.position.x, minWidth), maxWidth);
    localStorage.setItem(LOCAL_STORAGE_KEY, width);
    setWidth(width);
    control.style.left = `${width}px`;
    draggie.setPosition(width);
  }

  draggie.on('dragMove', () => {
    handleResize();
  });

  draggie.on('dragEnd', () => {
    handleResize();
  });

  return draggie;
}
