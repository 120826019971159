<template>
  <base-item>
    {{ $trlMessage('autocomplete.not_found') }}
  </base-item>
</template>

<script>
import BaseItem from './base-item';

export default {
  name: 'EmptyItem',
  components: { BaseItem }
};
</script>

<i18n lang="json">{}</i18n>
