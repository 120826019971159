/**
 * Проверяет, является ли событие событием на весь день.
 *
 * @param {Object} busySlot - Объект, представляющий занятость.
 * @param {Date} busySlot.start - Дата и время начала события.
 * @param {Date} busySlot.end - Дата и время окончания события.
 * @returns {boolean} - Возвращает true, если событие занимает весь день, иначе false.
 */
export const isAllDayEvent = (busySlot) => {
  const startTime = busySlot.start;
  const endTime = busySlot.end;

  const isSameDay = startTime.getDate() === endTime.getDate();
  const isStartOfDay = startTime.getHours() === 0 && startTime.getMinutes() === 0;
  const isEndOfDay = endTime.getHours() === 23 && endTime.getMinutes() === 59;

  return isSameDay && isStartOfDay && isEndOfDay;
};
