<template>
  <div :class="$style.baseLoader">
    <slot name="loader" v-if="isLoaderVisible" />
    <slot name="default" v-else />
  </div>
</template>

<script>
import { watch, ref } from 'vue';

const MIN_LOADING_TIME_MS = 400;

export default {
  name: 'BaseLoader',
  emits: ['loading', 'loaded'],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isLoaderVisible = ref(props.loading);
    let startTime = props.loading ? Date.now() : undefined;
    let timeout;

    watch(
      () => props.loading,
      (currValue) => {
        clearTimeout(timeout);
        if (currValue) {
          isLoaderVisible.value = true;
          startTime = Date.now();
        } else {
          const deltaTime = Date.now() - startTime;
          if (deltaTime < MIN_LOADING_TIME_MS) {
            timeout = setTimeout(() => {
              isLoaderVisible.value = false;
            }, MIN_LOADING_TIME_MS - deltaTime);
          } else {
            isLoaderVisible.value = false;
          }
        }
      }
    );

    watch(isLoaderVisible, (loading) => {
      emit(loading ? 'loading' : 'loaded');
    });

    return {
      isLoaderVisible
    };
  }
};
</script>

<style module>
.baseLoader {
  display: contents;
}
</style>

<i18n lang="json">{}</i18n>
