<template>
  <div data-qa="quota" :class="$style.quota" v-on="$listeners">
    <slot name="before" />
    <div data-qa="content" :class="$style.content">
      <request-list-item-caption
        :hired="quota.already_hired"
        :to-hire="quota.applicants_to_hire"
        :created-at="quota.created"
        :as-button="captionAsButton"
        @click="$emit('show-quota', quota)"
      />
      <request-list-item-body
        v-if="schema"
        :schema="schema"
        :ui-schema="uiSchema"
        :form-data="vacancyRequest.values"
        :additional-fields="additionalFields"
      />
      <div v-else data-qa="no-request" :class="$style.noRequest">
        {{ $trlMessage('vacancy.quota.select.no-request') }}
      </div>
      <request-list-item-deadline-progress
        v-if="
          quota.work_days_before_deadline !== null && quota.work_days_before_deadline !== undefined
        "
        :in-work="quota.work_days_in_work"
        :before-deadline="quota.work_days_before_deadline"
        :after-deadline="quota.work_days_after_deadline"
        :class="$style.deadline"
      />
    </div>
    <slot name="after" />
  </div>
</template>

<script>
import { DateTimeHelper } from '@/util/date-time-helper';
import { VacancyRequestHelpers } from '@/components/vjsf/helpers/vacancy-request-helpers';
import { AdditionalSchemaKeys } from '@/components/hf/request-list-item/additional-schema-keys';
import RequestListItemBody from '@/components/hf/request-list-item/body';
import RequestListItemDeadlineProgress from '@/components/hf/request-list-item/deadline-progress';
import RequestListItemCaption from '@/components/hf/request-list-item/caption';
import { includesAdditionalKey } from '@/components/hf/request-list-item/helpers/includes-additional-key';

export default {
  name: 'QuotaItem',
  components: {
    RequestListItemDeadlineProgress,
    RequestListItemBody,
    RequestListItemCaption
  },
  props: {
    quota: {
      type: Object,
      required: true
    },
    captionAsButton: {
      type: Boolean,
      default: true
    },
    schemaMap: {
      type: Object,
      required: true
    },
    schemasFields: {
      type: Object,
      required: true
    }
  },
  emits: ['show-quota'],
  computed: {
    schema() {
      return (
        this.vacancyRequestSchema && VacancyRequestHelpers.removeDeadline(this.vacancyRequestSchema)
      );
    },
    uiSchema() {
      return (
        this.vacancyRequestSchema &&
        VacancyRequestHelpers.removeDelimiters(this.vacancyRequestSchema)
      );
    },
    vacancyRequest() {
      // Есть формат данных у заявок, где нужная информация содержится в 'vacancy_request',
      // а есть такой формат, где данные содержатся в самой заявке
      return this.quota.vacancy_request ?? this.quota;
    },
    vacancyRequestSchema() {
      return this.vacancyRequest
        ? this.schemaMap[this.vacancyRequest.account_vacancy_request]
        : null;
    },
    schemaFields() {
      return this.vacancyRequest
        ? this.schemasFields[this.vacancyRequest.account_vacancy_request]
        : null;
    },
    additionalFields() {
      const additionalFields = [];
      if (includesAdditionalKey(AdditionalSchemaKeys.watcher, this.schemaFields)) {
        const value = this.quota.account_info.name;
        additionalFields.push({
          key: AdditionalSchemaKeys.watcher,
          value
        });
      }
      if (includesAdditionalKey(AdditionalSchemaKeys.takenBy, this.schemaFields)) {
        let value = this.vacancyRequest.taken_by;
        // taken_by есть только у взятых в работу заявок
        value =
          value &&
          `${value.name || value.email}, ${DateTimeHelper.toShortFormat({
            date: this.vacancyRequest.changed
          })}`;
        additionalFields.push({
          key: AdditionalSchemaKeys.takenBy,
          value
        });
      }
      return additionalFields;
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables.less';

.quota {
  display: flex;
  padding: 20px 0;
}

.quota + .quota {
  border-top: 1px solid @borderColor;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  flex: 1 0 0;
}
.noRequest {
  margin-bottom: 4px;
}

.deadline {
  margin-top: -2px;
}
</style>

<i18n lang="json">{}</i18n>
