export const features = {
  some_feature: {
    name: 'feature_name',
    urls: ['https://hr.huntflow.ru', 'https://master.huntflow.dev']
  },
  additional_info_constructor: {
    name: 'additional info constructor',
    urls: ['https://hr.huntflow.ru']
  },
  view_dictionary_detail: {
    name: 'Просмотр справочника',
    urls: []
  }
};
