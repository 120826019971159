<template>
  <div :class="$style.item">
    {{ $trlMessage('autocomplete.not_found') }}
  </div>
</template>

<style lang="less" module>
.item {
  font-size: 16px;
  line-height: 42px;
  padding-left: 34px;
}
</style>

<i18n lang="json">{}</i18n>
