import cloneDeep from 'lodash/cloneDeep';
import BaseTemplate from '../templates/base-template';
import CompensationTemplate from '../templates/compensation-template';
import ContractTemplate from '../templates/contract-template';
import IncomeTemplate from '../templates/income-template';
import { traverseUISchema } from './traverse-ui-schema';

const DEFAULT_COMPONENTS_MAP = {
  [CompensationTemplate.name]: CompensationTemplate,
  [ContractTemplate.name]: ContractTemplate,
  [IncomeTemplate.name]: IncomeTemplate,
  [BaseTemplate.name]: BaseTemplate
};

// ресолв текстового значения 'ui:ObjectFieldTemplate' на Vue-компонент
export function setObjectTemplateComponents(uiSchema, componentsMap = {}) {
  let result = cloneDeep(uiSchema);
  traverseUISchema(result, (f) => {
    const template = f['ui:ObjectFieldTemplate']?.name || f['ui:ObjectFieldTemplate'];
    if (template) {
      f['ui:ObjectFieldTemplate'] =
        componentsMap[template] ?? DEFAULT_COMPONENTS_MAP[template] ?? template;
    }
  });
  return result;
}
