<template>
  <div>
    <base-dropdown>
      <template #default="{ toggle }">
        <slot name="default" v-bind="{ toggle }"></slot>
      </template>
      <template #content="{ hide }">
        <form data-qa="rejection" @submit.prevent.stop="handleSubmit(hide)">
          <dropdown-menu-layout size="larger" :title="title">
            <template v-if="isDecline" #main>
              <span :class="$style.description">
                {{ $trlMessage('vacancy_request.popup.decline.text') }}
              </span>
              <label>
                <!-- запилить что-то вроде form-textarea (label + base-textarea) -->
                <span :class="$style.label">{{
                  $trlMessage('vacancy_request.popup.decline.reason')
                }}</span>
                <base-textarea v-model="reason" name="reason" data-qa="reason" />
              </label>
            </template>
            <template #footer>
              <base-button
                color="red"
                type="submit"
                :disabled="!reason && isDecline"
                :loading="isPending"
                data-qa="save"
              >
                {{ isDecline ? $trlMessage('button.decline') : $trlMessage('button.remove') }}
              </base-button>
              <base-button @click="hide">
                {{ $trlMessage('button.cancel') }}
              </base-button>
            </template>
          </dropdown-menu-layout>
        </form>
      </template>
    </base-dropdown>
  </div>
</template>

<script>
import { ref } from 'vue';

import { useGlobal } from '@/composables/use-global';
import vacancyRequestsAPI from '@/api/vacancy-request';
import BaseButton from '@/components/button/button';
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown.vue';
import BaseTextarea from '@/components/textarea/textarea';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout';

export default {
  name: 'VacancyRequestRejectionModal',
  components: {
    BaseButton,
    BaseDropdown,
    DropdownMenuLayout,
    BaseTextarea
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    isDecline: {
      type: Boolean,
      default: true
    }
  },
  emits: ['decline', 'remove'],
  setup(props, { emit }) {
    const { $trlMessage } = useGlobal();

    const reason = ref('');
    const isPending = ref(false);
    return {
      title: props.isDecline
        ? $trlMessage('vacancy_request.popup.decline')
        : $trlMessage('vacancy_request.popup.remove'),
      reason,
      isPending,
      handleSubmit: (hideDropdown) => {
        const promise = props.isDecline
          ? vacancyRequestsAPI.decline(props.id, reason.value)
          : vacancyRequestsAPI.remove(props.id);
        isPending.value = true;
        promise
          .then(() => {
            hideDropdown();
            props.isDecline ? emit('decline', reason.value) : emit('remove');
          })
          .finally(() => {
            isPending.value = true;
          });
      }
    };
  }
};
</script>

<style lang="less" module>
.description {
  display: block;
  margin-bottom: 10px;
}

.label {
  display: block;
  margin-bottom: 3px;
}
</style>

<i18n lang="json">{}</i18n>
