<template>
  <div :class="$style.wrapper">
    <base-dropdown :disabled="disabled" :distance="distance" :placement="placement">
      <template #default="{ toggle, shown }">
        <div :class="$style.buttonWrapper">
          <span
            role="button"
            :disabled="disabled"
            :class="shown ? $style.toggledButton : $style.button"
            @click.stop.prevent="toggle"
          >
            <svg width="6" height="8" fill="none" viewBox="0 0 9 13">
              <path
                d="M4.38383.276764c.8415 0 1.54275.12925 2.10375.387751.561.253.98175.591245 1.26225 1.014745.286.418.429.87175.429 1.36125 0 .5335-.1045.97075-.3135 1.31175-.2035.3355-.4565.61875-.759.84975-.3025.2255-.60775.43725-.91575.63525-.3025.198-.55825.42075-.76725.66825-.2035.2475-.30525.56375-.30525.94875v.33825H2.73383v-.42075c0-.5665.09075-1.0285.27225-1.386.187-.363.41525-.66.68475-.891.275-.231.54725-.43175.81675-.60225.2695-.176.495-.35475.6765-.53625.187-.187.2805-.418.2805-.693 0-.3025-.12375-.5335-.37125-.69299-.2475-.15951-.56375-.23926-.94875-.23926-.374 0-.73975.0825-1.09725.2475-.352.165-.6765.418-.9735.759l-1.650002-1.287c.473-.5555 1.034002-.99 1.683002-1.303495.6545-.313501 1.4135-.470251 2.277-.470251zm-.429 9.017256c.4125 0 .7645.1485 1.056.4455.2915.29148.43725.64078.43725 1.04778 0 .407-.14575.759-.43725 1.056-.2915.2915-.6435.4372-1.056.4372-.418 0-.77-.1457-1.056-.4372-.286-.297-.429-.649-.429-1.056 0-.407.143-.7563.429-1.04778.286-.297.638-.4455 1.056-.4455z"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
      </template>
      <template #content="{ hide }">
        <dropdown-menu-layout :size="size">
          <template #main>
            <div :class="$style.content">
              <slot :on-toggle="hide" />
            </div>
          </template>
        </dropdown-menu-layout>
      </template>
    </base-dropdown>
  </div>
</template>

<script>
import BaseDropdown from '../base-dropdown/base-dropdown.vue';
import DropdownMenuLayout from '../../dropdown-menu-layout/dropdown-menu-layout.vue';

export default {
  name: 'BaseHint',
  components: {
    DropdownMenuLayout,
    BaseDropdown
  },
  props: {
    size: {
      type: String,
      default: 'larger'
    },
    distance: BaseDropdown.props.distance,
    placement: BaseDropdown.props.placement,
    disabled: Boolean
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font.less';
@import '~@less/common/variables.less';

.wrapper {
  display: inline-flex;
}

.buttonWrapper {
  width: 16px;
  height: 16px;
}

.button {
  position: absolute;
  display: inline-flex;
  width: 16px;
  height: 16px;
  color: #999999;
  background: #eaeaea;
  border-radius: 50%;
  cursor: pointer;
  vertical-align: middle;
  transition: transform 0.1s cubic-bezier(0.45, 0, 0.55, 1);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:not(:disabled):hover {
    transform: scale(1.5);
  }
}

.toggledButton {
  composes: button;
  transform: scale(1.5);
}

.content {
  .font_regular();
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  white-space: normal;

  p {
    font-size: @baseFontSize;
    line-height: @baseLineHeight;

    margin: 0;
  }

  p + p {
    margin-top: 10px;
  }

  a {
    text-decoration: none;
    color: @linkColor;

    &:hover {
      color: @linkHoverColor;
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
