import axios, { AxiosClient } from '@/api/utils/client/axios';
import { ApiLayer } from '@/api/utils/api-layer';
import { PaginatedList } from '@/api/utils/types';
import { QuotaState } from '@/types/quota-state';
import { VacancyQuota } from '@/types/vacancy-quota';

interface QuotaOptions {
  status?: QuotaState;
  page?: number;
  include_all_frames?: boolean;
  with_hired?: boolean;
  count_hired_events?: boolean;
  skip_pagination?: boolean;
  count?: number;
}

class VacancyQuotaLayer extends ApiLayer<AxiosClient> {
  getListByVacancy(vacancyId: number, params: QuotaOptions = {}) {
    return this.methods.get<Record<number, PaginatedList<VacancyQuota>>>(
      `/vacancy/${vacancyId}/quotas`,
      {
        params
      }
    );
  }

  getList(vacancyId: number, params: QuotaOptions = {}) {
    return this.getListByVacancy(vacancyId, params).then((map) => map[vacancyId]);
  }
}

export const VacancyQuotaAPI = new VacancyQuotaLayer(axios);
