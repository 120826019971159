<template>
  <a :href="href" :class="className" :title="title" data-qa="sidebar-vacancy-title">
    <div v-if="hasIcon" :class="$style.icons">
      <template v-if="vacancy.multiple || vacancy.parent">
        <base-icon
          v-if="isOnHold"
          data-qa="icon-pause"
          type="pause-2"
          :class="[$style.statusIcon, $style.pauseIcon]"
        />
        <base-icon
          v-if="isHidden"
          data-qa="icon-eye-slash"
          type="visibility"
          :class="$style.statusIcon"
        />
      </template>
      <base-icon v-if="isHighPriority && !isOnHold" data-qa="icon-flag" type="small-priority" />
      <base-icon v-if="vacancy.multiple" type="multivacancy" />
    </div>
    <div :class="$style.text">
      <div :class="$style.title">
        <span v-if="vacancy.multiple" :class="$style.titleText">
          {{ $trlMessage('title.multivacancy') }}
        </span>
        <span v-else-if="vacancy.parent && shortCompanyName" :class="$style.titleText">
          {{ shortCompanyName }}
        </span>
        <span v-else-if="vacancy.parent && !shortCompanyName" :class="$style.titleText">
          &nbsp;
        </span>
        <span v-else-if="!vacancy.multiple && !vacancy.parent" :class="$style.titleText">
          {{ vacancy.position }}
        </span>
      </div>
      <div v-if="!vacancy.multiple && !vacancy.parent && vacancy.company" :class="$style.subtitle">
        <span :class="$style.subtitleText">
          <base-icon
            v-if="isOnHold"
            data-qa="icon-pause"
            type="pause-2"
            :class="[$style.statusIcon, $style.pauseIcon]"
          />
          <base-icon
            v-if="isHidden"
            data-qa="icon-eye-slash"
            type="visibility"
            :class="$style.statusIcon"
          />
          <base-icon
            v-if="showDeadlineState"
            type="clock"
            :height="14"
            :class="$style.statusIcon"
          />
          {{ vacancy.company }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
import { DateTimeHelper } from '@/util/date-time-helper';
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'VacancyItem',
  components: {
    BaseIcon
  },
  props: {
    vacancy: {
      type: Object,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    companyName: {
      type: String,
      default: ''
    },
    shortCompanyName: {
      type: String,
      default: ''
    },
    isParent: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasIcon() {
      return this.isHidden || this.isHighPriority || this.isOnHold || this.vacancy.multiple;
    },
    showDeadlineState() {
      return this.isDead && !this.isOnHold;
    },
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.active]: this.isActive,
        [this.$style.deadline]: this.showDeadlineState,
        [this.$style.parent]: this.isParent,
        [this.$style.child]: !this.isParent,
        [this.$style.spacer]: !this.hasIcon
      };
    },
    title() {
      const { position, parent, company } = this.vacancy;
      return [position, parent ? this.companyName : company].filter(Boolean).join(' - ');
    },
    isDead() {
      if (!this.vacancy.earliest_quota_deadline) {
        return false;
      }
      const deadline = DateTimeHelper.parse({ date: this.vacancy.earliest_quota_deadline });
      return !this.isClosed && DateTimeHelper.now().isAfter(deadline, { unit: 'day' });
    },
    isClosed() {
      return this.vacancy.state === 'CLOSED';
    },
    isOnHold() {
      return this.vacancy.state === 'HOLD';
    },
    isHidden() {
      return this.vacancy.hidden;
    },
    isHighPriority() {
      return this.vacancy.priority;
    },
    hasIcons() {
      return this.isOnHold || this.isHidden || this.isHighPriority || this.vacancy.multiple;
    }
  }
};
</script>

<style module>
.root {
  white-space: nowrap;
  font-size: $secondaryFontSize;
  line-height: 20px;
  text-decoration: none;
  transition: color 0.1s linear;
  display: flex;
  margin: 0;
  cursor: pointer;
  color: $sidebarTextColor;
}

.active {
  background-color: $sidebarTextBackgroundActive;
  color: $sidebarTextColorActive;
}

.deadline {
  color: #d44450;
}

.root:not(.active):not(.deadline):hover {
  color: $sidebarTextColorHover;
  background-color: $sidebarTextBackgroundHover;
}

.root:not(.active):hover {
  background-color: $sidebarTextBackgroundHover;
}

.text {
  display: flex;
  flex-direction: column;

  @mixin ellipsis;
}

.subtitle {
  opacity: 0.55;
  display: block;
}

.deadline .subtitle {
  opacity: 1;
}

.title,
.subtitle {
  display: flex;
}

.icons {
  min-width: 12px;
  height: 12px;
  margin-right: 8px;
  color: $sidebarTextColor;
  flex-shrink: 0;
}

.titleText,
.subtitleText {
  @mixin ellipsis;
}

.opacity {
  opacity: 0.7;
}

.parent {
  border-radius: 10px;
  margin: 0;
  padding: 8px 12px;
}

.child {
  border-radius: 8px;
  padding: 4px 8px;
}

.spacer {
  padding-left: 32px;
}

.child.spacer {
  padding-left: 28px;
}

.statusIcon {
  opacity: 0.7;
}

.subtitle .statusIcon {
  opacity: 1;
}

.pauseIcon {
  position: relative;
  top: -1px;
}
</style>

<i18n lang="json">{}</i18n>
