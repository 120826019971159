<template>
  <base-icon :type="icon" :class="$style.icon" :color="iconColor" />
</template>

<script>
import BaseIcon from '@/components/icon.vue';
import { COLORS } from './constants.js';

export default {
  name: 'Icon',
  components: { BaseIcon },
  props: {
    icon: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconColor() {
      return this.disabled ? COLORS['blocked'] : COLORS[this.icon];
    }
  }
};
</script>

<style lang="less" module>
.icon {
  min-height: 20px;
  min-width: 20px;
}
</style>

<i18n lang="json">{}</i18n>
