import { trlMessage, supportLink } from '@/services/i18n';
import { config } from '@/services/config/config';

/**
 * Хелпер для формирования переведенных mailto-ссылок
 * @param {string} trlKey - префикс ключа перевода, напр. settings.sms_templates
 * @param {string} email - кому мы должны слать письмо
 * @returns {string}
 */
const mailto = (trlKey, email = 'office@huntflow.ru', trlArgs = {}) => {
  return toMailto(email, {
    // eslint-disable-next-line no-restricted-syntax
    subject: trlMessage(`${trlKey}.mail.subject`, trlArgs),
    // eslint-disable-next-line no-restricted-syntax
    body: trlMessage(`${trlKey}.mail.body`, trlArgs)
  });
};

export function supportLinkWithContent(trlKey) {
  return (str) => supportLink(str, mailto(trlKey, config.appConfig.get('supportEmail')));
}

export function toMailto(email, params = {}) {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return `mailto:${email}?${query}`;
}

export default mailto;
