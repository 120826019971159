<template>
  <base-error v-if="firstErrors">
    {{ firstErrors.message }}
  </base-error>
</template>

<script>
import { defineComponent } from 'vue';

import BaseError from '@/components/error/error.vue';

export default defineComponent({
  name: 'FormErrors',
  components: { BaseError },
  props: {
    errors: {
      type: Array,
      required: true
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    actualErrors() {
      return this.errors.filter(({ type }) => !this.exclude.includes(type));
    },
    firstErrors() {
      return this.actualErrors[0];
    }
  }
});
</script>

<i18n lang="json">{}</i18n>
