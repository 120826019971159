import { AppHelper } from './app-helper';
import { XSRFHelper } from '@/util/xsrf-helper';
import { trlMessage } from '@/services/i18n';

export const RULES = Object.entries(window.PASSWORD_POLICY?.characters_requirements ?? {}).map(
  ([id, { min_count: minCount, ...other }]) => ({
    ...other,
    id,
    minCount
  })
);
export const MAX_REPEAT_PASSWORD =
  window.PASSWORD_POLICY?.time_features?.passwords_count_that_cannot_be_repeated ?? 0;

export function initLoginPage(selector, component, props) {
  const el = document.querySelector(selector);

  const errors = Object.fromEntries(
    Object.entries(window.FORM_INFO.errors || {}).map(([key, errors]) => [
      key,
      errors.map((error) => ({
        // Вроде как попадают переводы только с `error.*`
        // eslint-disable-next-line no-restricted-syntax
        message: trlMessage(error)
      }))
    ])
  );
  const initialErrors = {};
  const additionalProps = props || (() => ({}));

  if (errors.common) {
    initialErrors.common = errors.common;
  }

  if (errors.email) {
    initialErrors.login = errors.email;
  }

  if (errors.password) {
    initialErrors.password = errors.password;
  }

  const fullProps = {
    passwordRules: RULES,
    initialErrors,
    initialValues: {
      ...window.FORM_INFO.initialValues,
      _xsrf: XSRFHelper.getToken()
    },
    ...additionalProps()
  };

  return AppHelper.createApp(component, {
    props: fullProps,
    route: {
      name: 'login-page',
      params: fullProps
    },
    selector: el
  });
}
