
    import { makeI18n } from '@/services/i18n';
    const baseTranslation = undefined
    
    
    if(module.hot) {
      const bucket = require("/app/build/i18n/browser-bucket.js");
      bucket.add('src/components/email-verification/main/email-verification-modal.vue', {"verification.title":{"ru_RU":"Подтверждение эл. почты","en_US":"Email Confirmation"},"verification.accept":{"ru_RU":"Подтвердить","en_US":"Confirm"},"verification.resend":{"ru_RU":"Отправить повторно","en_US":"Resend"},"verification.retry":{"ru_RU":"Отправить повторно через '<span>'{time}'</span>'","en_US":"Resend in '<span>'{time}'</span>'"},"verification.expired":{"ru_RU":"Срок действия кода истек","en_US":"The code has expired"},"verification.text":{"ru_RU":"Введите код из письма, которое мы отправили на&nbsp;{email}","en_US":"Enter the code from the mail we sent to&nbsp;{email}"},"close_confirmation":{"ru_RU":"Ожидается ввод кода подтверждения. Все равно закрыть?","en_US":"The confirmation code has not been entered. Close it anyway?"}}) 
    }
   
    
    export default function (Component) {
      function beforeCreateI18n() {
        this.$i18n = this.$options._i18n;
      }

      function addHook(current) {
        if (Array.isArray(current)) {
          return [beforeCreateI18n].concat(current);
        } 
        if (current) {
          return [beforeCreateI18n, current];
        }
        return [beforeCreateI18n];
      }
    
      Component._i18n = makeI18n("src/components/email-verification/main/email-verification-modal.vue", baseTranslation);
      Component.beforeCreate = addHook(Component.beforeCreate);
      delete Component._Ctor;
    }
  