import { ref } from 'vue';

export const useLocalAutocomplete = ({ sources = [], options: { matchFn } }) => {
  const result = ref(undefined);

  const search = (query) => {
    // в случае запроса на бэк
    // должна быть реализована отмена предыдущего запроса
    const searchResult = query
      ? sources.map((source) => ({ ...source, items: source.items.filter(matchFn(query, source)) }))
      : sources;

    const promise = Promise.resolve(searchResult);
    promise.then((matches) => {
      result.value = matches;
    });
    return promise;
  };

  return {
    search,
    result
  };
};
