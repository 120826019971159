<template>
  <div :class="$style.errorWrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ErrorWrapper'
};
</script>

<style module>
.errorWrapper:empty {
  display: none;
}
.errorWrapper:not(:empty) {
  margin-bottom: 10px;
  width: 100%;
  flex-shrink: 0;
}
</style>

<i18n lang="json">{}</i18n>
