<template>
  <div>
    <slot name="prepend">
      <span v-if="title" :class="$style.title">{{ title }}</span>
    </slot>
    <slot v-for="(item, index) in valueState" :key="index" name="item" :item="item">
      <reminder-item
        v-model="valueState[index]"
        :focus="lastAdded === valueState[index]"
        :class="$style.item"
        @remove="handleRemove(index)"
      />
    </slot>

    <button type="button" :class="$style.button" @click="handleAdd">
      <base-icon type="plus-new" />
      {{ $trlMessage('button.add') }}
    </button>
  </div>
</template>

<script>
import { watch, ref } from 'vue';

import BaseIcon from '@/components/icon.vue';
import ReminderItem from './reminder-item.vue';
import { DEFAULT_UNIT } from './units';

export default {
  name: 'ReminderList',
  components: {
    BaseIcon,
    ReminderItem
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const valueState = ref(props.value ?? []);
    watch(
      () => props.value,
      (value) => {
        valueState.value = value;
      }
    );
    watch(valueState, (value) => emit('change', value));

    const lastAdded = ref(undefined);

    return {
      valueState,
      lastAdded,
      handleAdd: () => {
        const item = { id: DEFAULT_UNIT };
        lastAdded.value = item;
        valueState.value.push(item);
      },
      handleRemove: (index) => {
        valueState.value.splice(index, 1);
      }
    };
  }
};
</script>

<style module>
.title {
  composes: form-group__label from global;
}

.item {
  margin-bottom: 8px;
}

.button {
  @mixin reset-button-styles;
  padding: 4px 0;
  color: var(--Blue-600, #0ab3ce);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
</style>

<i18n lang="json">{}</i18n>
