<template>
  <div
    :class="{
      [$style.item]: true,
      [$style.gap]: useGap,
      [$style.highlight]: highlight,
      [$style.hoverable]: hoverable
    }"
    :style="{ minHeight: minHeightComputed }"
    data-qa="list-item"
    @click="handleClick"
  >
    <div :class="$style.inner">
      <div v-if="icon && !isHeader" :class="$style.icon">
        <slot name="icon" />
      </div>
      <div :class="classes">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseItem',
  props: {
    disabled: Boolean,
    active: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: true
    },
    deep: {
      type: Number,
      default: 0
    },
    useGap: {
      type: Boolean,
      default: true
    },
    textGapY: {
      type: String,
      default: '8px'
    },
    iconGapY: {
      type: String,
      default: '0px'
    },
    minHeight: {
      type: Number,
      default: 42
    },
    isHeader: {
      type: Boolean,
      default: false
    },
    highlight: Boolean,
    hoverable: Boolean
  },
  emits: ['click'],
  computed: {
    minHeightComputed() {
      return `${this.minHeight}px`;
    },
    classes() {
      return {
        [this.$style.wrapper]: true,
        [this.$style.disabled]: this.disabled,
        [this.$style.border]: this.bordered,
        [this.$style.archive]: this.active === false
      };
    }
  },
  methods: {
    handleClick(e) {
      if (this.disabled) {
        e.stopPropagation();
        return;
      }
      this.$emit('click');
    }
  }
};
</script>

<style module>
.item {
  color: $textColor;
  width: 100%;
  padding: 0;
}
.gap {
  padding-inline: 8px;
}

.inner {
  align-items: center;
  display: flex;
  width: 100%;
  padding-left: calc(24px * v-bind(deep));
}

.highlight,
.hoverable:hover {
  .inner {
    border-radius: 8px;
    background-color: $black-7;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 32px;
  color: #38343f;
  transform: translateY(v-bind(iconGapY));
}

.wrapper {
  flex: 1 0 0;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  line-height: 24px;
  padding: v-bind(textGapY) 8px v-bind(textGapY) 0;

  b {
    background: #fff6da;
    border-radius: 4px;
    font-weight: normal;
  }
}

.disabled {
  color: $black;
  opacity: 0.5;
}

.border {
  border-bottom: $borderColor 1px solid;
}

.archive {
  composes: disabled;
  text-decoration: line-through;
}
</style>

<i18n lang="json">{}</i18n>
