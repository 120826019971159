import cloneDeep from 'lodash/cloneDeep';
import { WIDGET_TYPE } from '../vjsf';
import { traverseUISchema } from './traverse-ui-schema';

export function hideApiFields(uiSchema) {
  let result = cloneDeep(uiSchema);
  traverseUISchema(result, (f) => {
    if (f['ui:options']?.editViaApiOnly) {
      f['ui:widget'] = WIDGET_TYPE.HIDDEN;
    }
  });
  return result;
}
