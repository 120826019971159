<template>
  <div :class="$style.wrapper">
    <component :is="component" v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  name: 'VerticalClamp',
  inheritAttrs: false,
  props: {
    component: {
      type: Object,
      required: true
    }
  }
};
</script>

<style module>
.wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

<i18n lang="json">{}</i18n>
