<script>
import { SkeletonHelper, SkeletonZones } from '@/util/skeleton-helper';
import tariffs, { ServiceKindCodes } from '@/services/tariffs';
import { userPermissions } from '@/services/config/user-permissions';
import { ColleagueAPI } from '@/api/colleague';
import { InviteAPI } from '@/api/invite';
import { MemberType } from '@/types/member-type';
import { normalizeChildren } from '@/util/component-helpers';

export default {
  name: 'WatchersLimitDataProvider',
  data() {
    return {
      ready: userPermissions.isWatcher,
      count: 0
    };
  },
  computed: {
    limit() {
      return tariffs.getTariffLimit(ServiceKindCodes.watchers);
    },
    unlimitedTariffGroup() {
      return tariffs.getUnlimitedGroup(ServiceKindCodes.watchers);
    },
    isLimitExceed() {
      return this.overLimit <= 0;
    },
    isOverLimitExceed() {
      return this.overLimit < 0;
    },
    shouldWarn() {
      if (this.limit === Infinity) {
        return false;
      }

      const countToLimit = this.limit - this.count;
      return countToLimit > 0 && countToLimit / this.limit <= 0.02;
    },
    overLimit() {
      return this.limit ? this.limit - this.count : 0;
    }
  },
  created() {
    if (userPermissions.isWatcher) {
      SkeletonHelper.patchPromise(SkeletonZones.HEADER, Promise.resolve());
      return;
    }
    const requestArray = [
      ColleagueAPI.count({
        member_type: MemberType.WATCHER,
        count_invites: false
      })
    ];

    if (!(window.Settings?.DISABLE_INVITE_USERS /* 🖕 */ ?? false)) {
      requestArray.push(
        InviteAPI.fetchCount({
          member_type: MemberType.WATCHER
        })
      );
    }

    SkeletonHelper.patchPromise(
      SkeletonZones.HEADER,
      Promise.all(requestArray)
        .then(([colleagueCountResponse, watcherInviteTotal]) => {
          const watcherTotal = colleagueCountResponse.watcher.members;
          this.count = watcherTotal + watcherInviteTotal;
        })
        .catch(() => {
          this.count = 0;
        })
        .finally(() => {
          this.ready = true;
        })
    );
  },
  render() {
    const children = normalizeChildren(this, {
      ready: this.ready,
      count: this.count,
      limit: this.limit,
      isLimitExceed: this.isLimitExceed,
      overLimit: Math.abs(this.overLimit),
      shouldWarn: this.shouldWarn,
      isOverLimitExceed: this.isOverLimitExceed,
      unlimitedTariffGroup: this.unlimitedTariffGroup
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
