export const always = (value) => () => value;

export const cond = (mappings) => {
  return (...args) => {
    const [, getter] = mappings.find(([predicate]) => predicate(...args));
    return getter;
  };
};

// TODO: неуверен что это должно лежать в конвертере,
// т.к. кажется неспецифичным именно для форм со старой схемой на в vjsf исполнении
export const getSubmitButtonName = () => '__submit';
