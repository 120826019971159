import '@/services/feature-toggle/feature-toggle';
import { configureCompat } from 'vue';

import registerNpsElement from '@/modules/nps/register-nps-element';
import { RegisterGlobalComponents } from './register-global-components';

configureCompat({
  MODE: 3,

  COMPONENT_V_MODEL: true,
  INSTANCE_ATTRS_CLASS_STYLE: true,
  INSTANCE_EVENT_EMITTER: true,
  INSTANCE_LISTENERS: true,
  OPTIONS_BEFORE_DESTROY: true,
  PRIVATE_APIS: true,
  OPTIONS_DATA_MERGE: true,
  PROPS_DEFAULT_THIS: true,
  TRANSITION_CLASSES: true,
  WATCH_ARRAY: true,
  TRANSITION_GROUP_ROOT: true
});

registerNpsElement();
RegisterGlobalComponents.register();
