<template>
  <button
    v-if="asButton"
    :class="[$style.caption, $style.button]"
    type="button"
    data-qa="caption"
    v-on="$listeners"
    v-html="caption"
  />
  <span v-else :class="$style.caption" data-qa="caption" v-on="$listeners" v-html="caption" />
</template>

<script>
import { DateTimeHelper } from '@/util/date-time-helper';

export default {
  name: 'RequestListItemCaption',
  props: {
    hired: {
      type: Number,
      default: undefined
    },
    toHire: {
      type: Number,
      default: undefined
    },
    createdAt: {
      type: String,
      required: true
    },
    asButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    caption() {
      const withQuotaData = typeof this.toHire === 'number';
      const requestDate = DateTimeHelper.toShortFormat({ date: this.createdAt });

      if (withQuotaData) {
        return this.$trlMessage('vacancy.quota.select.title', {
          requestDate,
          alreadyHired: this.hired ?? 0,
          applicantsToHire: this.toHire
        });
      }
      return this.$trlMessage('vacancy.quota.title', { requestDate });
    }
  }
};
</script>

<style module>
.caption {
  display: block;
  width: fit-content;
  text-aling: left;
  font-weight: 500;
  font-size: $baseFontSize;
  line-height: 20px;
}

.button {
  @mixin reset-button-styles;
  cursor: pointer;

  color: $blueColor;
  &:hover {
    color: $red-400;
  }
}
</style>

<i18n lang="json">{}</i18n>
