<template>
  <div :class="$style.delimiter" :style="style" />
</template>

<script>
export default {
  name: 'BaseDelimiter',
  props: {
    top: {
      type: [Number, String],
      default: 15
    },
    bottom: {
      type: [Number, String],
      default: 15
    }
  },
  computed: {
    style() {
      return {
        marginTop: this.top + 'px',
        marginBottom: this.bottom + 'px'
      };
    }
  }
};
</script>

<style lang="less" module>
@import '@less/common/variables.less';

.delimiter {
  border-bottom: 1px solid @borderColor;
}
</style>

<i18n lang="json">{}</i18n>
