<template>
  <div>
    <base-autocomplete
      :id="id"
      ref="autocomplete"
      :search-engine-instance="searchEngineInstance"
      :include-group-item="false"
      :dropdown-props="{ block: true, size: 'medium' }"
      :menu-props="{ maxHeight: '231px' }"
      :placeholder="$trlMessage('email.placeholder')"
      :invalid="isInvalid"
      :value="inputValue"
      :name="name"
      :show-reset-button="false"
      @toggle="handleToggle"
      @input-value="handleInputValue"
    >
      <template #emptyItem>
        <!-- на кнопке нет события - сабмит будет уже по факту потери фокуса с поля при нажатии на кнопку -->
        <button type="button" tabindex="0" :class="$style.addNewEmail">
          <svg
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.70792 3.14322L1 7.85117M1 7.85117L5.70792 12.5591M1 7.85117C1 7.85117 10.4225 7.85117 13.2009 7.85117C15.9793 7.85117 16.099 7.30687 16.099 4.94597C16.099 2.58507 16.099 1.44092 16.099 1.44092"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            /></svg
          ><span>{{ $trlMessage('add_email') }}</span>
        </button>
      </template>
      <template #item="{ item, isActive, setHoverActiveItem, toggleCheck }">
        <email-autocomplete-item
          :item="item"
          :is-active="isActive"
          @mousemove="setHoverActiveItem(item)"
          @click="toggleCheck(item)"
        />
      </template>
    </base-autocomplete>
    <span v-if="isInvalid && errorMessage" :class="$style.errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { watch, ref, computed } from 'vue';

import { useGlobal } from '@/composables/use-global';
import { isEmailValid } from '@/util/validators/email';
import BaseAutocomplete from '../autocomplete-next/autocomplete.vue';
import { isNewItem } from '../autocomplete-next/helpers';
import EmailAutocompleteItem from './email-autocomplete-item.vue';
import { useEmailAutocomplete } from './use-email-autocomplete';

const ErrorCode = {
  DOUBLE: 'DOUBLE',
  MAILFORMED: 'MAILFORMED'
};

export default {
  name: 'EmailAutocomplete',
  components: {
    BaseAutocomplete,
    EmailAutocompleteItem
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    inputValue: {
      type: String,
      default: ''
    },
    coworkers: {
      type: Array,
      default: () => []
    },
    excludedEmails: {
      type: Array,
      default: () => []
    },
    includeResources: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  emits: ['select', 'input'],
  setup(props, { emit }) {
    const { $trlMessage } = useGlobal();

    const autocomplete = ref(null);

    const searchEngineInstance = useEmailAutocomplete({
      coworkers: props.coworkers,
      options: { includeResources: props.includeResources }
    });

    watch(
      () => props.excludedEmails,
      (excludedEmails) => {
        searchEngineInstance.setExcludeEmailsList(excludedEmails);
      },
      { immediate: true }
    );

    const error = ref(undefined);
    const isInvalid = computed(() => Boolean(error.value) || props.invalid);
    const errorMessage = computed(() => {
      if (error.value === ErrorCode.MAILFORMED) {
        return $trlMessage('error.not_email');
      } else if (error.value === ErrorCode.DOUBLE) {
        return $trlMessage('error.already_email');
      }
      return undefined;
    });

    const emitSelect = (value) => {
      error.value = false;
      emit('select', value);
    };

    const handleInputValue = (value) => {
      error.value = false;
      const payload = value
        ? { email: value, error: getEmailError(value, { existingEmails: props.excludedEmails }) }
        : undefined;
      emit('input', payload);
    };

    return {
      autocomplete,
      isInvalid,
      errorMessage,
      searchEngineInstance,
      commit: () => autocomplete.value.onCommit(),
      focus: () => autocomplete.value.focus(),
      handleInputValue,
      handleToggle: (item) => {
        // если выбрали существующий элемент из списка - эммитим как есть
        if (isNewItem(item) === false) {
          emitSelect(item);
          return;
        }
        // если написали текстом, но есть в списке элемент с таким же email - эммитим его
        const relatedExistingItem = getRelatedItem(item.value, searchEngineInstance.result.value);
        if (relatedExistingItem) {
          emitSelect(relatedExistingItem);
          return;
        }

        // в противном случае проверяем ввод на ошибки
        const emailError = getEmailError(item.value, { existingEmails: props.excludedEmails });
        if (emailError) {
          error.value = emailError;
          return;
        }

        // и если всё ок - эммитим ввод
        emitSelect({ ...item, email: item.value });
      }
    };
  }
};

function getEmailError(email, { existingEmails = [] } = {}) {
  if (!isEmailValid(email)) {
    return ErrorCode.MAILFORMED;
  }
  if (existingEmails.includes(email)) {
    return ErrorCode.DOUBLE;
  }
  return undefined;
}

function getRelatedItem(str, result) {
  for (let groupIndex = 0; groupIndex < result.length; groupIndex++) {
    for (let itemIndex = 0; itemIndex < result[groupIndex].items.length; itemIndex++) {
      if (
        result[groupIndex].items[itemIndex].email.toLocaleLowerCase() === str.toLocaleLowerCase()
      ) {
        return result[groupIndex].items[itemIndex];
      }
    }
  }
}
</script>

<style module>
.addNewEmail {
  @mixin reset-button-styles;
  display: block;
  width: 100%;
  height: 42px;
  padding-left: 35px;
  text-align: left;
  line-height: 42px;
  background-color: #ffffff;
  &:hover {
    background-color: #f6f6f6;
  }

  & > svg {
    vertical-align: text-bottom;
  }

  & > svg + span {
    margin-left: 10px;
  }
}

.errorMessage {
  composes: form-group__error from global;
  display: block;
}
</style>

<i18n lang="json">{}</i18n>
