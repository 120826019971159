<template>
  <base-item
    :disabled="disabled"
    :bordered="bordered"
    :deep="deep"
    :active="active"
    :icon="icon"
    :text-gap-y="textGapY"
    :min-height="minHeight"
    :highlight="highlight"
    :use-gap="useGap"
    @click="$emit('click')"
  >
    <template #icon>
      <slot name="icon" />
    </template>
    <div :class="$style.wrapper">
      <div :class="$style.beforeContent">
        <slot name="before-content" />
      </div>
      <div :class="$style.content">
        <div :class="$style.textWrapper">
          <div :class="$style.beforeCaption">
            <slot name="before-caption" />
          </div>
          <div :class="$style.text">
            <slot />
            <span :class="$style.hint">
              <slot name="hint" />
            </span>
          </div>
          <div :class="$style.afterCaption">
            <slot name="after-caption" />
          </div>
        </div>
        <div :class="$style.subtitle">
          <slot name="subtitle" />
        </div>
      </div>
      <div :class="$style.afterContent">
        <slot name="after-content" />
      </div>
    </div>
  </base-item>
</template>

<script>
import BaseItem from './base-item';

export default {
  name: 'BaseItemLayout',
  components: { BaseItem },
  props: {
    disabled: BaseItem.props.disabled,
    active: BaseItem.props.active,
    bordered: BaseItem.props.bordered,
    icon: BaseItem.props.icon,
    deep: BaseItem.props.deep,
    textGapY: BaseItem.props.textGapY,
    minHeight: BaseItem.props.minHeight,
    highlight: BaseItem.props.highlight,
    useGap: BaseItem.props.useGap
  },
  emits: ['click']
};
</script>

<style module lang="less">
@import '~@less/common/variables';

.wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.content {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0 0;
}
.textWrapper {
  align-items: center;
  display: flex;
  width: 100%;
}
.text {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  color: @black;
}
.hint {
  font-size: 16px;
  color: @black-60;
}
.subtitle {
  color: @black;
  font-size: 15px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.6;
}
.beforeCaption,
.afterCaption,
.beforeContent,
.afterContent {
  flex: 0 0 auto;
  display: inline-flex;
}

.beforeContent {
  align-self: stretch;
  align-items: center;
}
</style>

<i18n lang="json">{}</i18n>
