<template>
  <div :class="$style.periodPresets">
    <button v-for="preset in presets" :key="preset" type="button" @click="handleClick(preset)">
      <!-- eslint-disable-next-line vue/no-restricted-syntax -->
      {{ $trlMessage(`period.${preset}`) }}
    </button>
  </div>
</template>

<script>
import { presets } from './presets';

export default {
  name: 'PeriodPresets',
  props: {
    presets: {
      type: Array,
      required: true
    }
  },
  emits: ['input'],
  methods: {
    handleClick(preset) {
      this.$emit('input', presets[preset]);
    }
  }
};
</script>

<style module>
.periodPresets {
  margin-top: 5px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px 12px;

  button {
    @mixin reset-button-styles;
    font: inherit;
    font-size: 14px;
    line-height: 18px;

    color: #999;
    $gradient: color-mix(in srgb, #999, transparent 60%);
    background-image: linear-gradient($gradient, $gradient);
    background-size: 100% 1px;
    background-position: left bottom;
    background-repeat: repeat-x;

    &:hover {
      cursor: pointer;
      color: $red-400;
      $gradient: color-mix(in srgb, $red-400, transparent 60%);
      background-image: linear-gradient($gradient, $gradient);
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
