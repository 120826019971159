<template>
  <svg v-if="lang === 'ru'" width="151" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.128 0h.001c10.012 0 18.128 7.897 18.128 17.64v.72c0 9.743-8.116 17.64-18.128 17.64C8.115 36 0 28.103 0 18.36v-.72C0 7.896 8.116 0 18.128 0Zm4.967 8.866h4.076l-6.284 8.951 6.626 9.317h-4.365l-4.839-6.707h-.473l-4.891 6.707H8.764l6.627-9.239-6.127-9.03h4.233l4.444 6.682h.474l4.68-6.681Z"
      :fill="color"
    />
    <path
      d="M58.758 10.266h-3.345L51.57 15.75h-.389l-3.647-5.484H44.06l5.029 7.412-5.44 7.583h3.432l4.015-5.505h.389l3.97 5.505h3.584l-5.44-7.648 5.16-7.347Zm22.233 8.447h-4.9V14.6h-2.697v10.71h2.697v-4.134h4.9v4.135h2.698V14.6h-2.698v4.112Zm-16.133 4.299c-1.726 0-2.935-1.35-2.935-3.085 0-1.885 1.209-3.128 2.935-3.128 1.813 0 3.022 1.243 3.022 3.128 0 1.735-1.209 3.085-3.022 3.085Zm3.108-7.327h-.107c-.562-.728-1.706-1.392-3.324-1.392-3.044 0-5.396 2.506-5.396 5.655 0 3.085 2.352 5.57 5.396 5.57 1.618 0 2.762-.664 3.324-1.393h.108l.15 1.136H70.6V14.55h-2.482l-.151 1.134Zm79.003-1.085-3.129 6.94h-.281l-3.453-6.94h-3.238l5.288 10.24-2.245 4.755h3.238l7.058-14.995h-3.238Zm-16.074 8.418c-1.792 0-3.043-1.285-3.043-3.041 0-1.821 1.251-3.085 3.043-3.085 1.813 0 3.086 1.264 3.086 3.085 0 1.756-1.273 3.041-3.086 3.041Zm0-8.675c-3.324 0-5.785 2.506-5.785 5.655 0 3.085 2.461 5.57 5.785 5.57 3.389 0 5.871-2.485 5.871-5.57 0-3.149-2.482-5.655-5.871-5.655Zm-17.292 5.741c-.043 2.013-.69 2.635-1.921 2.635h-.194v2.592c3.195.214 4.727-1.371 4.835-5.056l.086-3.191h4.015v8.247h2.698V14.6h-9.389l-.13 5.483Zm-9.064 2.785v-5.827c1.878 0 3.216 1.114 3.216 2.935 0 1.756-1.338 2.892-3.216 2.892Zm-2.698 0c-1.878 0-3.216-1.136-3.216-2.892 0-1.821 1.338-2.935 3.216-2.935v5.826Zm2.698-8.312v-4.241h-2.698v4.241c-3.389 0-5.936 2.292-5.936 5.42 0 3.106 2.547 5.376 5.936 5.376v4.285h2.698v-4.285c3.388 0 5.935-2.27 5.935-5.376 0-3.128-2.547-5.42-5.935-5.42Zm-18.844 2.506h3.346v8.248h2.698v-8.248h3.367V14.6h-9.41v2.463Z"
      :fill="color"
    />
  </svg>
  <svg v-else width="141" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.128 0h.001c10.012 0 18.128 7.897 18.128 17.64v.72c0 9.743-8.116 17.64-18.128 17.64C8.115 36 0 28.103 0 18.36v-.72C0 7.896 8.116 0 18.128 0Zm4.912 8.863h4.06l-6.26 8.997 6.602 9.365h-4.35l-4.82-6.741H17.8l-4.874 6.741H8.761l6.602-9.286-6.105-9.076h4.219l4.427 6.715h.472l4.663-6.715Z"
      :fill="color"
    />
    <path
      d="M79.291 14.418c-1.356 0-2.625.624-3.098 1.4h-.108l-.15-1.142H73.48v10.771h2.69V20.04c0-1.917.947-3.08 2.56-3.08 1.528 0 2.453 1.077 2.453 3.08v5.407h2.69v-5.536c0-3.576-1.915-5.494-4.583-5.494Zm-11.264 5.946c0 1.853-.968 2.78-2.496 2.78s-2.496-.927-2.496-2.78v-5.687h-2.69v5.709c0 3.705 2.561 5.32 5.186 5.32s5.186-1.615 5.186-5.32v-5.709h-2.69m22.491 6.827v-4.437h3.184v-2.392h-3.184v-3.23H87.85v3.23h-2.517v2.392h2.517v4.631c0 2.52 1.205 3.964 3.572 3.964a4.58 4.58 0 0 0 2.56-.754l-.968-2.262c-1.205.797-2.496.474-2.496-1.142Zm-35.848-5.13h-6.842v-6.053H44.88V25.4h2.948v-6.205h6.842V25.4h2.947V10.32H54.67m62.032 12.822c-1.786 0-3.034-1.292-3.034-3.059 0-1.83 1.248-3.102 3.034-3.102 1.807 0 3.076 1.271 3.076 3.102 0 1.767-1.269 3.06-3.076 3.06Zm0-8.724c-3.314 0-5.766 2.52-5.766 5.687 0 3.102 2.452 5.6 5.766 5.6 3.378 0 5.852-2.498 5.852-5.6 0-3.167-2.474-5.688-5.852-5.688Zm21.136.259-2.259 6.269h-.237l-2.646-6.269h-1.893l-2.604 6.269h-.215l-2.259-6.269h-2.819l4.045 10.771h1.936l2.755-6.355h.215l2.754 6.355h1.915l4.045-10.771m-34.424 10.771h2.69v-15.08h-2.69v15.08Zm-4.446-12.775c.581 0 .925.129 1.377.474l.99-2.047c-.689-.581-1.528-.904-2.582-.904-2.087 0-3.636 1.249-3.636 3.791v.69h-2.518v2.39h2.518v8.38h2.689v-8.38h3.184v-2.39h-3.184v-.733c0-.905.431-1.271 1.162-1.271Z"
      :fill="color"
    />
  </svg>
</template>

<script>
import { appConfig } from '@/services/config/app-config';

export default {
  name: 'NewsLogo',
  props: {
    color: {
      type: String,
      default: '#FFF'
    }
  },
  computed: {
    lang: () => appConfig.get('lang')
  }
};
</script>

<i18n lang="json">{}</i18n>
