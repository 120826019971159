<template>
  <div :class="$style.wrapper">
    {{ $trlMessage('autocomplete.not_found') }}
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style module>
.wrapper {
  text-align: center;
  color: #999999;
  margin-top: 50px;
  font-size: 18px;
  line-height: 26px;
}
</style>

<i18n lang="json">{}</i18n>
