<template>
  <div :class="$style.wrapper">
    {{ message }}
    <base-button @click="$emit('update')">
      {{ $trlMessage('dictionaries.reload') }}
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/components/button/button';

export default {
  name: 'StructureUpdated',
  components: { BaseButton },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  computed: {
    message() {
      switch (this.info.type) {
        case 'user':
          return this.$trlMessage('dictionaries.was.updated.user', {
            name: this.info.name,
            dictionaryName: this.info.meta.name,
            dictionaryType: this.info.meta.type
          });
        case 'api':
          return this.$trlMessage('dictionaries.was.updated.api', {
            name: this.info.name,
            dictionaryName: this.info.meta.name,
            dictionaryType: this.info.meta.type
          });
        case 'script':
          return this.$trlMessage('dictionaries.was.updated.script', {
            dictionaryName: this.info.meta.name,
            dictionaryType: this.info.meta.type
          });
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font.less';

.wrapper {
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  text-align: center;
  max-width: 360px;
  padding: 80px 20px;
  margin: 0 auto;

  .font_regular();
  font-size: 16px;
  line-height: 22px;
}
</style>

<i18n lang="json">{}</i18n>
