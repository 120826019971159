<template>
  <div v-if="editor" :class="editorMenuStyle">
    <item-button
      type="bold"
      :active="editor.isActive('bold')"
      @click="editor.chain().focus().toggleBold().run()"
    >
      {{ $i18n('action.bold') }}
    </item-button>
    <item-button
      type="italic"
      :active="editor.isActive('italic')"
      @click="editor.chain().focus().toggleItalic().run()"
    >
      {{ $i18n('action.italic') }}
    </item-button>
    <item-button
      type="bullet-list"
      :active="editor.isActive('bulletList')"
      @click="editor.chain().focus().toggleBulletList().run()"
    >
      {{ $i18n('action.ul') }}
    </item-button>
    <item-button
      type="numbered-list"
      :active="editor.isActive('orderedList')"
      @click="editor.chain().focus().toggleOrderedList().run()"
    >
      {{ $i18n('action.ol') }}
    </item-button>
    <link-menubar-item :initial-href="getHrefAtCursor()" @change="handleInsertLink">
      <template #button="{ onToggle, show }">
        <item-button type="link" :active="editor.isActive('link') || show" @click="onToggle">
          {{ $i18n('action.link') }}
        </item-button>
      </template>
    </link-menubar-item>
  </div>
</template>

<script>
import { Link as LinkMenubarItem, ItemButton } from '../components/menubar-items';

export default {
  name: 'VMenubar',
  components: {
    LinkMenubarItem,
    ItemButton
  },
  props: {
    editor: {
      type: Object,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editorMenuStyle() {
      return {
        [this.$style.menubar]: true,
        [this.$style.menubarDisabled]: this.disabled,
        [this.$style.menubarInvalid]: this.invalid
      };
    }
  },
  methods: {
    getHrefAtCursor() {
      // этот метод нужен, для того, чтобы если у нас фокус на ссылке
      // при открытии дропдауна "Ссылка" был интерфейс редактирования, а не добавления
      if (!this.editor.isActive('link')) {
        return undefined;
      }
      return this.editor.getAttributes('link').href;
    },
    handleInsertLink(href) {
      if (href) {
        this.editor.chain().focus().extendMarkRange('link').setLinkCustom({ href }).run();
      } else {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      }
    }
  }
};
</script>

<style module>
.menubar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  gap: 4px;
  color: $black;
}

.menubar.menubarInvalid {
}

.menubar.menubarDisabled {
  pointer-events: none;
}
</style>

<i18n lang="json" base="../wysiwyg.lang.json">
{}
</i18n>
