<template>
  <svg :class="computedClass" class="loader-next" viewBox="-30 -30 60 60">
    <circle cx="0" cy="0" r="29" :class="$style.circle" />
  </svg>
</template>

<script>
export default {
  name: 'VLoader',
  props: {
    size: {
      type: String,
      default: 'l',
      validator(value) {
        return ['xs', 's', 'm', 'l'].includes(value);
      }
    },
    color: {
      type: String,
      validator(value) {
        return ['white', 'black'].includes(value);
      },
      default: 'black'
    }
  },
  computed: {
    computedClass() {
      return {
        [this.$style.loader]: true,
        [this.$style[this.size]]: true,
        [this.$style[this.color]]: true
      };
    }
  }
};
</script>

<style lang="less">
// TODO: избавиться от использования less и перевести на module
// но сначала нужно будет в тестах использовать data-qa вместо селектора
.loader-next {
}
</style>

<style module>
@keyframes loader-next {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 200px;
  }
  60% {
    stroke-dashoffset: 300px;
  }
  100% {
    transform: rotate(360deg);
    stroke-dashoffset: 200px;
  }
}

.loader {
  display: block;
  --loader-color: $black;
  flex: 0 0 auto;
}
.circle {
  fill: none;
  stroke: var(--loader-color);
  stroke-width: 1px;
  stroke-dasharray: 170px;
  animation-name: loader-next;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
}

.white {
  --loader-color: $white;
}
.black {
  --loader-color: $black;
}

.xs {
  width: 16px;
  height: 16px;
}
.s {
  width: 20px;
  height: 20px;
}
.m {
  width: 32px;
  height: 32px;
}
.l {
  width: 56px;
  height: 56px;
}
</style>

<i18n lang="json">{}</i18n>
