import { Temporal } from '@js-temporal/polyfill';
import { DurationHelper } from '@/util/duration-helper';
import { userTimezone } from '@/util/constant';

type TimeObject = { time: string; timeZone?: string };
// eslint-disable-next-line no-use-before-define
type TimeVariant = TimeHelper | TimeObject;

export type CompareOptions = {
  unit?: Temporal.TimeUnit;
  roundingIncrement?: number;
};

export class TimeHelper {
  private readonly time: Temporal.PlainTime;

  constructor(time: Temporal.PlainTime) {
    this.time = time;
  }

  get temporal(): Temporal.PlainTime {
    return this.time;
  }

  get hour(): number {
    return this.time.hour;
  }

  get minute(): number {
    return this.time.minute;
  }

  get second(): number {
    return this.time.second;
  }

  toString(options?: Temporal.ToStringPrecisionOptions): string {
    return this.time.toString(options);
  }

  since(date: TimeHelper, options?: Temporal.DifferenceOptions<Temporal.TimeUnit>): DurationHelper {
    return new DurationHelper(this.time.since(date.time, options));
  }

  with(timeLike: TimeHelper | Temporal.PlainTimeLike): TimeHelper {
    return new TimeHelper(this.time.with(timeLike));
  }

  add(durationLike: DurationHelper | Temporal.DurationLike): TimeHelper {
    return new TimeHelper(this.time.add(durationLike));
  }

  hasSame(timeToCompare: TimeVariant, options: CompareOptions = {}): boolean {
    return TimeHelper.compare(this, timeToCompare, options) === 0;
  }

  isBefore(timeToCompare: TimeVariant, options: CompareOptions = {}): boolean {
    return TimeHelper.compare(this, timeToCompare, options) === -1;
  }

  isAfter(timeToCompare: TimeVariant, options: CompareOptions = {}): boolean {
    return TimeHelper.compare(this, timeToCompare, options) === 1;
  }

  round({
    unit,
    roundingIncrement = 1,
    roundingMode
  }: {
    unit: Temporal.TimeUnit;
    roundingIncrement?: number;
    roundingMode?: Temporal.RoundingMode;
  }): TimeHelper {
    return new TimeHelper(
      this.time.round({
        smallestUnit: unit,
        roundingIncrement,
        roundingMode
      })
    );
  }

  static compare(
    time: TimeVariant,
    timeToCompare: TimeVariant,
    { unit, roundingIncrement }: CompareOptions = {}
  ): Temporal.ComparisonResult {
    let timeParsed = normalizeTime(time);
    let timeToCompareParsed = normalizeTime(timeToCompare);
    if (unit) {
      timeParsed = timeParsed.round({ unit, roundingIncrement, roundingMode: 'floor' });
      timeToCompareParsed = timeToCompareParsed.round({
        unit,
        roundingIncrement,
        roundingMode: 'floor'
      });
    }
    return Temporal.PlainTime.compare(timeParsed.temporal, timeToCompareParsed.temporal);
  }

  static parse(data: Temporal.PlainTimeLike | TimeObject): TimeHelper {
    let normalizedTime: string | Temporal.PlainTimeLike;
    if ('time' in data) {
      normalizedTime = data.time;
      if (data.time.includes(' ')) {
        console.warn(`legacy format ${data.time}, use ISO8601 format instead`);
        normalizedTime = data.time.replace(' ', 'T');
      }
    } else {
      normalizedTime = data;
    }
    return new TimeHelper(Temporal.PlainTime.from(normalizedTime));
  }

  static now(timeZone = userTimezone): TimeHelper {
    return new TimeHelper(Temporal.Now.plainTimeISO(timeZone));
  }
}

function normalizeTime(date: TimeVariant): TimeHelper {
  return date instanceof TimeHelper ? date : TimeHelper.parse(date);
}
