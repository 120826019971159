import { computed, watch, ref, Ref } from 'vue';

import { isLabelField } from './constants';

interface Pointer {
  next: () => void;
  prev: () => void;
  reset: () => void;
  select: (index: number) => void;
  pointer: Ref<number>;
}

/*
 * - управление выделением элемента в списке
 */
export function useListPointer<
  Item extends Record<string, string | number> & { [isLabelField]: symbol }
>(items: Ref<Array<Item>>, { loop = ref(true) } = {}): Pointer {
  // с дизайном было решено что нужно оставить подсветку даже дизейбленых элементов, там что не учитываем только заголовки
  const availablePositions = computed(() =>
    items.value.reduce((positions: number[], item, index) => {
      if (item[isLabelField]) {
        return positions;
      }
      positions.push(index);
      return positions;
    }, [])
  );
  const pointer = ref(availablePositions.value[0]);

  function select(index: number) {
    if (availablePositions.value.includes(index)) {
      pointer.value = index;
    }
  }
  function next() {
    const index = availablePositions.value.indexOf(pointer.value);
    const lastIndex = availablePositions.value.length - 1;
    pointer.value =
      index === lastIndex
        ? loop.value
          ? availablePositions.value[0]
          : availablePositions.value[index]
        : availablePositions.value[index + 1];
  }
  function prev() {
    const index = availablePositions.value.indexOf(pointer.value);
    const lastIndex = availablePositions.value.length - 1;
    pointer.value =
      index === 0
        ? loop.value
          ? availablePositions.value[lastIndex]
          : availablePositions.value[index]
        : availablePositions.value[index - 1];
  }

  function reset() {
    pointer.value = availablePositions.value[0];
  }

  watch(items, () => {
    if (!availablePositions.value.includes(pointer.value)) {
      prev();
    }
  });

  return {
    next,
    prev,
    reset,
    select,
    pointer
  };
}
