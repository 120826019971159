<template>
  <div :class="$style.container">
    <div :class="$style.headingContainer">
      <img v-if="logoPath" :src="logoPath" :class="$style.logo" />
      <span :class="$style.title" v-html="title" />
      <slot name="heading"></slot>
    </div>
    <div>
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvaluationPageLayout',
  props: {
    logoPath: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="less" module>
@import '../../../../less/common/variables.less';
@import '../../../../less/common/mixins/font.less';

.container {
  padding-top: 100px;
  padding-bottom: 250px;
  overflow: auto;
  @media screen and (max-width: @screen-sm-max) {
    padding-top: 40px;
  }
}

.logo {
  display: block;
  margin-bottom: 30px;
  max-width: 250px;
  max-height: 75px;
}

.title {
  .font_bold();
  display: block;
  font-size: 24px;
  line-height: 30px;
  @media screen and (max-width: @screen-sm-max) {
    font-size: 20px;
    line-height: 22px;
  }
}

.headingContainer {
  composes: col-xs-12 col-md-offset-3 col-md-6 from global;
}
</style>

<i18n lang="json">{}</i18n>
