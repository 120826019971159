<template>
  <layer-portal
    ref="portal"
    v-bind="$attrs"
    :wrapper-class="wrapperClass"
    :content-class="contentClass"
    :content-style="contentStyle"
    :z-index-increment="zIndexIncrement"
    v-on="$listeners"
  >
    <slot />
  </layer-portal>
</template>

<script>
import { nextTick } from 'vue';
import { nanoid } from 'nanoid';

import layers from '@/util/layers';
import LayerPortal from '@/components/ui/layer-portal/layer-portal.vue';
import { removeLayerSymbol } from './constants';

export default {
  name: 'BaseLayer',
  components: { LayerPortal },
  provide() {
    return {
      [removeLayerSymbol]: () => {
        this.$emit('remove');
      }
    };
  },
  inheritAttrs: false,
  props: {
    wrapperClass: LayerPortal.props.wrapperClass,
    contentClass: LayerPortal.props.contentClass,
    contentStyle: LayerPortal.props.contentStyle,
    zIndexIncrement: LayerPortal.props.zIndexIncrement,
    id: {
      type: String,
      default: () => nanoid()
    },
    trigger: {
      type: Function,
      default: () => null
    },
    onHide: {
      type: Function,
      default: () => true
    }
  },
  emits: ['remove'],
  data() {
    return {
      dragged: false,
      layer: null
    };
  },
  computed: {
    computedTrigger() {
      return this.trigger();
    }
  },
  watch: {
    computedTrigger(trigger) {
      if (!this.layer) {
        return;
      }
      this.layer.trigger = trigger;
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClick, {
      capture: true
    });
    window.addEventListener('mousedown', this.handleMouseDown);
    window.addEventListener('mouseup', this.handleMouseUp);
    nextTick(() => {
      this.layer = layers.addLayer({
        trigger: this.computedTrigger,
        element: this.$refs.portal.getContent(),
        id: this.id,
        hide: () => {
          const shouldHide = this.onHide();
          // для случая, если вдруг слой удалили напрямую
          if (shouldHide !== false) {
            this.$emit('remove');
          }
          return shouldHide;
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick, {
      capture: true
    });
    window.removeEventListener('mousedown', this.handleMouseDown);
    window.removeEventListener('mouseup', this.handleMouseUp);
    layers.removeById(this.id);
  },
  methods: {
    getContent() {
      return this.$refs.portal.getContent();
    },
    handleClick(event) {
      const layer = layers.lastLayer;
      if (!layer || layer.id !== this.id) {
        return;
      }
      if (
        this.dragged ||
        event.target.closest('[data-ignore-outside-click]') ||
        layer.trigger?.contains(event.target) ||
        layer.element.contains(event.target)
      ) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      this.$emit('remove');
    },
    handleMouseDown(event) {
      const layer = layers.lastLayer;
      if (!layer || layer.id !== this.id) {
        return;
      }
      this.dragged = false;
      if (event.target instanceof window.Element) {
        this.tempTarget = event.target;
      }
      if (
        layer.element.contains(event.target) ||
        layer.trigger?.contains(event.target) ||
        event.target.closest('[data-ignore-outside-click]')
      ) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
    },
    handleMouseUp(event) {
      const element = layers.lastLayer.element;
      if (!element.contains(event.target) && !element.contains(this.tempTarget)) {
        return;
      }
      if (this.tempTarget !== event.target) {
        this.dragged = true;
      }
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
