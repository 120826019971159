export type Value = string | number;
export type ValueVariant = Value | null | undefined | Array<Value>;

export function normalizeValue(value: ValueVariant, { multiple = false }: { multiple?: boolean }) {
  if (!multiple) {
    return Array.isArray(value) ? value[0] : value;
  }
  if (Array.isArray(value)) {
    return Array.from(new Set(value));
  }
  if (value === undefined || value === null) {
    return [];
  }
  return [value];
}
