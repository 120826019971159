export function isOther(reason) {
  return reason.id === null;
}

export function otherReasonLast(a, b) {
  if (isOther(a)) {
    return 1;
  }
  if (isOther(b)) {
    return -1;
  }
}

export const SourceTypes = {
  user: 'user',
  system: 'system'
};

export function isUser({ type }) {
  return type === SourceTypes.user;
}

// В нескольких местах есть сортировка по имени,
// возможно стоит вынести в утилиту
export function sortByName(a, b) {
  return a.name.localeCompare(b.name);
}

export function userFirst(a, b) {
  if (isUser(a) && isUser(b)) {
    return;
  }

  if (isUser(a)) {
    return -1;
  }
  if (isUser(b)) {
    return 1;
  }
}
