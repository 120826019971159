import { poller } from '@/poller-instance';

const TTL = 100000;

const listeners = new Map();
const waitingLine = new Map(); // для обработки кейсов, когда нотифай о джобе приходит раньше ответа с jobId

type NotifierMessage = {
  id?: string;
  event: string;
  data: Record<string, unknown>;
};

poller.onMessage((message: NotifierMessage) => {
  if (!message.id) {
    return;
  }
  const key = `${message.event}:${message.id}`;
  if (listeners.has(key)) {
    // got the job
    listeners.get(key)(message.data);
    listeners.delete(key);
  } else {
    waitingLine.set(key, {
      time: Date.now(),
      data: message.data
    });
    // put in waiting line
    waitingLine.forEach(({ time }, jobId) => {
      if (Date.now() - time > TTL) {
        // cleanup
        waitingLine.delete(jobId);
      }
    });
  }
});

export function waitForJob<Type>(jobId: string, eventName = 'job'): Promise<Type> {
  const key = `${eventName}:${jobId}`;
  // wait for job
  return new Promise((resolve) => {
    if (waitingLine.has(key)) {
      // get from waiting line
      resolve(waitingLine.get(key).data);
      waitingLine.delete(key);
    } else {
      // put listener
      listeners.set(key, resolve);
    }
  });
}
