// TODO: заиспользовать энумы в конструкторе, там их есть

export enum WIDGET_TYPE { // значение переименовывать опасно! т.к. в опросниках в базу сохраняются названия виджетов
  STRING = 'TextWidget',
  INTEGER = 'TextWidget',
  PARTIAL_DICTIONARY = 'PartialDictionaryWidget',
  SELECT = 'SelectWidget',
  HTML = 'HtmlWidget',
  TEXT = 'TextareaWidget',
  DATE = 'DateWidget',
  HIDDEN = 'HiddenWidget',
  SUBMIT = 'SubmitWidget',
  CHECKBOX = 'CheckboxesWidget',
  RADIO = 'RadioWidget'
}

export enum JSON_SCHEMA_TYPE {
  OBJECT = 'object',
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ARRAY = 'array'
}

export type VjsfData = {
  schema: Record<string, any>;
  uiSchema: Record<string, any>;
  formData: Record<string, any>;
};
