<template>
  <base-dropdown
    ref="dropdown"
    :disabled="disabled"
    :shown="focused"
    :auto-boundary-max-size="false"
    @hidden="handleHide"
    @shown="$emit('show')"
    @focus="focused = true"
    @blur="focused = false"
  >
    <template #default="{ show }">
      <base-input
        :id="id"
        :value="displayedValue"
        data-qa="datepicker-trigger"
        :name="name"
        :placeholder="placeholder"
        :required="required"
        :readonly="readonly"
        :disabled="disabled"
        :invalid="invalid"
        :clearable="!required"
        :class="inputClass"
        autocomplete="off"
        @input="
          displayedValue = $event;
          show();
          handleInput($event);
        "
        @click="
          show();
          handleClick();
        "
      >
        <template #prepend="scopedProps">
          <slot name="prepend" v-bind="scopedProps" :disabled="disabled" />
        </template>
        <template #append="scopedProps">
          <slot name="append" v-bind="scopedProps" :disabled="disabled" />
        </template>
      </base-input>
    </template>
    <template #content>
      <dropdown-menu-layout size="large">
        <template #content>
          <div :class="$style.calendar" data-qa="datepicker-menu">
            <base-arrow
              v-model="currentDate"
              data-qa="previous-page"
              :disabled="parsedDisabled"
              v-bind="viewSettings.arrow"
            />
            <button
              type="button"
              data-qa="up-page"
              :disabled="!nextView"
              :class="$style.headerTitle"
              @click="currentView = nextView"
            >
              {{ title }}
            </button>
            <base-arrow
              v-model="currentDate"
              data-qa="next-page"
              :disabled="parsedDisabled"
              increment
              v-bind="viewSettings.arrow"
            />

            <!-- Day View -->
            <template v-if="currentView === $options.VIEWS[0]">
              <base-weekday v-for="weekday in weekdays" :key="weekday">
                {{ weekday }}
              </base-weekday>
              <base-day
                v-for="date in visibleDaysFromPreviousMonth"
                :key="date"
                v-model="parsedValue"
                :today="now"
                :date="date"
                :disabled="parsedDisabled"
                blur
              >
                <template #default="scopedProps">
                  <slot name="day" v-bind="scopedProps" />
                </template>
              </base-day>
              <base-day
                v-for="date in days"
                :key="date"
                v-model="parsedValue"
                :today="now"
                :date="date"
                :disabled="parsedDisabled"
                data-qa="day"
              >
                <template #default="scopedProps">
                  <slot name="day" v-bind="scopedProps" />
                </template>
              </base-day>
              <base-day
                v-for="date in visibleDaysFromNextMonth"
                :key="date"
                v-model="parsedValue"
                :today="now"
                :date="date"
                :disabled="parsedDisabled"
                blur
              >
                <template #default="scopedProps">
                  <slot name="day" v-bind="scopedProps" />
                </template>
              </base-day>
            </template>

            <!-- Month View -->
            <template v-if="currentView === $options.VIEWS[1]">
              <base-month
                v-for="date in months"
                :key="date"
                :selected="parsedValue"
                :date="date"
                :disabled="parsedDisabled"
                data-qa="month"
                @change="handleChangeMonth"
              >
                <template #default="scopedProps">
                  <slot name="month" v-bind="scopedProps" />
                </template>
              </base-month>
            </template>

            <!-- Year View -->
            <template v-if="currentView === $options.VIEWS[2]">
              <base-year
                v-for="date in years"
                :key="date"
                :selected="parsedValue"
                :date="date"
                :disabled="parsedDisabled"
                data-qa="year"
                @change="handleChangeYear"
              >
                <template #default="scopedProps">
                  <slot name="year" v-bind="scopedProps" />
                </template>
              </base-year>
            </template>
          </div>
        </template>
      </dropdown-menu-layout>
    </template>
  </base-dropdown>
</template>

<script>
import { nanoid } from 'nanoid';

import { DateTimeHelper } from '@/util/date-time-helper';
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown.vue';
import BaseInput from '@/components/ui/base-input/base-input.vue';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';
import BaseDay from './base-day.vue';
import BaseMonth from './base-month.vue';
import BaseYear from './base-year.vue';
import BaseWeekday from './base-weekday.vue';
import BaseArrow from './base-arrow.vue';
import { DatePickerUtils } from './utils';

const VIEWS = ['day', 'month', 'year'];
const VIEW_SETTINGS = {
  day: {
    arrow: {
      unit: 'month'
    }
  },
  month: {
    arrow: {
      unit: 'year'
    }
  },
  year: {
    arrow: {
      unit: 'year',
      incrementUnit: 10
    }
  }
};

export default {
  name: 'BaseDatepicker',
  components: {
    DropdownMenuLayout,
    BaseDropdown,
    BaseInput,
    BaseArrow,
    BaseWeekday,
    BaseDay,
    BaseMonth,
    BaseYear
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  VIEWS,
  props: {
    value: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default() {
        return nanoid();
      }
    },
    min: {
      type: String,
      default: '1900-01-01'
    },
    max: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    required: Boolean,
    readonly: {
      type: Boolean,
      default: true
    },
    invalid: Boolean,
    inputClass: {
      type: [String, Array, Object],
      default: null
    }
  },
  emits: ['change', 'click', 'show', 'hide'],
  data() {
    return {
      now: DateTimeHelper.now(),
      currentDate: DateTimeHelper.now(),
      currentView: VIEWS[0],
      displayedValue: '',
      focused: false
    };
  },
  computed: {
    parsedValue: {
      get() {
        return this.value
          ? DateTimeHelper.parse({ date: this.value, timeZone: DateTimeHelper.userTimezone })
          : null;
      },
      set(value) {
        this.handleChange(value);
      }
    },
    parsedDisabled() {
      const disabled = {};
      if (this.max) {
        disabled.max = DateTimeHelper.parse({
          date: this.max
        }).floor({
          unit: 'day'
        });
      }
      if (this.min) {
        disabled.min = DateTimeHelper.parse({
          date: this.min
        }).floor({
          unit: 'day'
        });
      }
      return disabled;
    },
    viewSettings() {
      return VIEW_SETTINGS[this.currentView];
    },
    nextView() {
      const index = VIEWS.indexOf(this.currentView);
      return VIEWS[index + 1];
    },
    prevView() {
      const index = VIEWS.indexOf(this.currentView);
      return VIEWS[index - 1];
    },
    startOfCurrentMonth() {
      return this.currentDate.floor({
        unit: 'month'
      });
    },
    startOfCurrentYear() {
      return this.currentDate.floor({
        unit: 'year'
      });
    },
    currentInterval() {
      const startMonthDate = this.startOfCurrentMonth;
      return {
        start: startMonthDate.floor({
          unit: 'week'
        }),
        end: startMonthDate.add({
          days: startMonthDate.daysInMonth - 1
        })
      };
    },
    title() {
      switch (this.currentView) {
        case 'day':
          return this.currentDate.toMonthYearFormat();
        case 'month':
          return this.currentDate.year;
        case 'year':
          return `${Math.floor(this.currentDate.year / 10) * 10}'s`;
      }
      throw new Error(`unknown view ${this.currentView}`);
    },
    weekdays() {
      const start = this.currentInterval.start;
      return [...Array(7).keys()].map((day) => {
        return start
          .add({
            days: day
          })
          .toCustomFormat({
            weekday: 'short'
          });
      });
    },
    visibleDaysFromPreviousMonth() {
      const start = this.currentInterval.start;
      const count =
        DateTimeHelper.countOf(start, this.startOfCurrentMonth, {
          unit: 'day'
        }) - 1;

      return this.getRangeISODate({
        start,
        count,
        unit: 'days'
      });
    },
    days() {
      const startOfMonth = this.startOfCurrentMonth;
      const daysInMonth = startOfMonth.daysInMonth;

      return this.getRangeISODate({
        start: startOfMonth,
        count: daysInMonth,
        unit: 'days'
      });
    },
    visibleDaysFromNextMonth() {
      const start = this.currentInterval.end.add({
        days: 1
      });
      const count = 7 - this.currentInterval.end.dayOfWeek;

      return this.getRangeISODate({
        start,
        count,
        unit: 'days'
      });
    },
    months() {
      const startOfYear = this.startOfCurrentYear;
      return this.getRangeISODate({
        start: startOfYear,
        count: 12,
        unit: 'months'
      });
    },
    years() {
      const startOfDecade = this.startOfCurrentYear.floor({
        unit: 'year',
        roundingIncrement: 10
      });
      return this.getRangeISODate({
        start: startOfDecade,
        count: 10,
        unit: 'years'
      });
    }
  },
  watch: {
    parsedValue: {
      handler: 'handleReset',
      immediate: true
    }
  },
  methods: {
    getRangeISODate({ start, count, unit }) {
      const dates = [];
      for (let i = 0; i < count; i++) {
        dates.push(
          start
            .add({
              [unit]: i
            })
            .toServerFormat({
              includeTime: false,
              timeZone: DateTimeHelper.userTimezone
            })
        );
      }
      return dates;
    },
    handleReset() {
      this.currentDate = this.parsedValue || DateTimeHelper.now();
      this.displayedValue = this.parsedValue ? this.parsedValue.toShortFormat() : '';
    },
    handleHide() {
      this.currentView = VIEWS[0];
      this.handleReset();
      this.$emit('hide');
    },
    handleInput(dateString) {
      if (!dateString && !this.required) {
        this.$emit('change', null);
      }

      try {
        const date = DateTimeHelper.fromShortFormat(dateString);
        if (
          DatePickerUtils.isDisabled(date, this.parsedDisabled, {
            unit: 'day'
          })
        ) {
          return;
        }
        this.$emit(
          'change',
          date.toServerFormat({
            includeTime: false
          })
        );
      } catch (e) {}
    },
    handleClick() {
      this.$emit('click', {
        disabled: this.disabled
      });
    },
    handleChange(value) {
      this.$refs.dropdown.hide();
      this.$emit(
        'change',
        value.toServerFormat({
          includeTime: false
        })
      );
    },
    handleChangeMonth(month) {
      this.currentDate = month;
      this.currentView = this.prevView;
    },
    handleChangeYear(year) {
      this.currentDate = year;
      this.currentView = this.prevView;
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables';

.calendar {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
  grid-auto-rows: 30px;
  padding: 4px;
  border-radius: 3px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
}

.blank {
  grid-column: span 3;
}

.headerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 15;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 500;
}
.headerTitle:not(:disabled) {
  cursor: pointer;
}
.headerTitle:not(:disabled):hover {
  background: #eee;
}
</style>

<i18n lang="json">{}</i18n>
