import { createApp, App, Component } from 'vue';
import { Store } from 'vuex';
import PortalVue from 'portal-vue';

import { appConfig } from '@/services/config/app-config';
import { purifyHtml } from '@/directives/purify-html';
import { transitionTrigger } from '@/directives/transition-trigger';
import VueI18n, { I18NOptions } from '@/plugins/l10n';
import { SentryOptions } from '@/types/sentry';

const lang = appConfig.get('lang');

type CreateOptions = {
  props?: Record<string, unknown>;
  store?: Store<any>;
  i18n?: I18NOptions;
  beforeMount?: (app: App) => void;
  selector: string | Element;
} & SentryOptions;

export const AppHelper = {
  createApp<C extends Component>(
    component: C,
    {
      props,
      store,
      i18n = {
        messages: { [lang]: window.Translations },
        lang
      },
      beforeMount,
      selector,
      ...routerOptions
    }: CreateOptions
  ) {
    const app = createApp(component, props);

    if (routerOptions.router) {
      app.use(routerOptions.router);
    }
    if (store) {
      app.use(store);
    }
    app.directive('purify-html', purifyHtml);
    app.directive('transition-trigger', transitionTrigger);
    app.use(VueI18n, i18n);
    app.use(PortalVue, {
      portalName: false,
      portalTargetName: false
    });

    window.Sentry?.init(app, routerOptions);

    beforeMount?.(app);

    return app.mount(selector);
  }
};
