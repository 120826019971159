<script>
import { normalizeChildren } from '@/util/component-helpers';

export default {
  name: 'ToggleText',
  props: {
    multiple: Boolean,
    required: Boolean,
    count: {
      type: Number,
      required: true
    },
    value: {
      type: [Array, String, Number, Boolean],
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    showText() {
      return !this.count;
    },
    text() {
      if (!this.showText) {
        return '';
      }
      if (this.required) {
        return this.placeholder;
      }
      return this.placeholder || this.$trlMessage('not-selected');
    }
  },
  render() {
    const children = normalizeChildren(this, {
      text: this.text,
      showText: this.showText
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
