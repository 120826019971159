<template>
  <input class="g-hidden" type="submit" value="submit" />
</template>

<script>
export default {
  name: 'FormFieldSubmit',
  inheritAttrs: false
};
</script>

<i18n lang="json">{}</i18n>
