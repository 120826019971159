<template>
  <form ref="form" novalidate @submit.prevent.stop="submit">
    <popup-layout :title="$trlMessage('vacancy.type-dialog.title')">
      <template #main>
        <div :class="$style.main">
          <base-select
            name="type"
            data-qa="new_or_existing_select"
            :value="form.type"
            @change-value="form.type = $event"
          >
            <base-option v-for="type in typeValues" :key="type.id" :value="type.id">
              {{ type.name }}
            </base-option>
          </base-select>
          <div v-if="form.type === 'add'">
            <vacancy-dropdown-autocomplete
              id="vacancy-add-type-dialog-vacancies-autocomplete"
              v-model="form.vacancyId"
              data-qa="vacancy-dropdown"
              :parent="false"
              @change:vacancies="handleVacanciesChange"
            />
          </div>
        </div>
      </template>
      <template #error>
        <div v-if="showError" :class="$style.error" v-html="$i18n('multivacancy_disabled_error')" />
      </template>
      <template #footer>
        <base-button data-qa="submit" :disabled="submitDisabled" type="submit" color="black">
          {{ $trlMessage('button.continue') }}
        </base-button>
        <base-button data-qa="cancel" @click="cancel">
          {{ $trlMessage('button.cancel') }}
        </base-button>
      </template>
    </popup-layout>
  </form>
</template>

<script>
import BaseButton from '@/components/button/button';
import BaseSelect from '@/components/select/select';
import PopupLayout from '@/components/popup-layout/popup-layout';
import VacancyDropdownAutocomplete from '@/components/hf/autocomplete/vacancy-dropdown-autocomplete.vue';
import BaseOption from '@/components/select/base-option.vue';
import { config } from '@/services/config/config';

const TYPE_VALUES = [
  { id: 'default', name: 'vacancy.type-dialog.select.default' },
  { id: 'add', name: 'vacancy.type-dialog.select.add' }
];

export default {
  name: 'VacancyAddTypeDialog',
  components: {
    BaseOption,
    BaseButton,
    BaseSelect,
    PopupLayout,
    VacancyDropdownAutocomplete
  },
  props: {
    props: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['submit', 'cancel'],
  data() {
    return {
      form: {
        type: 'default',
        vacancyId: undefined
      },
      vacancies: null,
      showError: false
    };
  },
  computed: {
    typeValues() {
      return TYPE_VALUES.map((option) => ({
        ...option,
        // eslint-disable-next-line no-restricted-syntax
        name: this.$trlMessage(option.name)
      }));
    },
    submitDisabled() {
      if (this.form.type !== 'add') {
        return false;
      }

      return !this.form.vacancyId;
    }
  },
  watch: {
    'form.type': 'resetError',
    'form.vacancyId': 'resetError'
  },
  methods: {
    resetError() {
      this.showError = false;
    },
    findVacancyById(vacancies, vacancyId) {
      return vacancies.find((vacancy) => vacancy.id === vacancyId);
    },
    handleVacanciesChange(vacancies) {
      this.vacancies = vacancies.flat();
    },
    submit() {
      const isVacancyMultiple =
        this.form.type === 'add'
          ? this.findVacancyById(this.vacancies, this.form.vacancyId)?.multiple
          : undefined;
      this.showError =
        config.settings.get('use-multivacancy')?.enabled === false && isVacancyMultiple;

      if (this.showError) {
        return false;
      }
      const vacancyId = this.form.type === 'add' ? this.form.vacancyId : undefined;
      this.$emit('submit', vacancyId);
      if (typeof this.props?.handleSubmit === 'function') {
        const dialog = {
          type: this.form.type,
          vacancyId
        };
        this.props.handleSubmit(dialog);
      }
    },
    cancel() {
      this.$emit('cancel');
      if (typeof this.props?.handleCancel === 'function') {
        this.props.handleCancel();
      }
    }
  }
};
</script>

<style module>
.main > * {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.error {
  margin: -6px 0 12px;
  font-size: 14px;
  line-height: 18px;
  color: $red-400;
}
</style>

<i18n lang="json">
{
  "multivacancy_disabled_error": {
    "ru_RU": "В&nbsp;вашем тарифе отключена мультивакансия. Недоступно их&nbsp;добавление, редактирование и&nbsp;переоткрытие",
    "en_US": "Multivacancies are disabled in&nbsp;your tariff plan. You cannot add, edit or&nbsp;reopen them"
  }
}
</i18n>
