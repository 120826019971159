import { getCurrentInstance } from 'vue';

export function useI18N() {
  const instance = getCurrentInstance();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const i18n = instance?.type._i18n;
  if (!i18n) {
    throw new Error('i18n не найден');
  }
  return i18n;
}
