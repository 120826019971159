import { ref } from 'vue';

export const useModalNext = () => {
  const isOpened = ref(false);
  let showPromiseResolver;

  const hide = (value) => {
    isOpened.value = false;
    showPromiseResolver?.(value);
  };

  const show = () => {
    isOpened.value = true;
    return new Promise((resolve) => {
      showPromiseResolver = resolve;
    });
  };

  return {
    show,
    hide,
    isOpened
  };
};
