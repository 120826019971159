<template>
  <ul :class="$style.tabs">
    <base-tab
      v-for="item in routes"
      :key="item.name"
      :class="$style.tab"
      :item="item"
      :is-side-bar="true"
    />
  </ul>
</template>

<script>
import BaseTab from './base-tab';

export default {
  name: 'BaseSidebar',
  components: { BaseTab },
  props: {
    routes: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="less" module>
.tabs {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
</style>

<i18n lang="json">{}</i18n>
