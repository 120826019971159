import { unref, ref, Ref, ComputedRef, computed } from 'vue';

import difference from 'lodash/difference';

import { HierarchyMap } from '@/components/hierarchy/hierarchy-map';
import { useListValue } from './use-list-value';

type Value = string | number;
type ValueVariant = Value | null | undefined | Array<Value>;

type Options = {
  multiple: Ref<boolean>;
  maxValues: Ref<number>;
  onlyLeaf: Ref<boolean>;
  disableAutoCompress: Ref<boolean>;
  onChange: (value: ValueVariant) => void;
  hierarchyMap: Ref<HierarchyMap>;
};
interface Result<Item> {
  toggle: (item: Item) => void;
  reset: () => void;
  selection: ComputedRef<ValueVariant>;
  selectionMap: ComputedRef<Record<Value, boolean>>;
}

export function useListSelection<Item extends Record<string, string | number | boolean>>(
  value: Ref<ValueVariant>,
  {
    multiple = ref(false),
    maxValues = ref(Infinity),
    onChange,
    hierarchyMap,
    onlyLeaf = ref(false),
    disableAutoCompress = ref(false)
  }: Options
): Result<Item> {
  const { value: normalizedValue, deepValue: allSelectedValue } = useListValue(value, {
    multiple,
    onlyLeaf,
    hierarchyMap
  });

  const selectionMap = computed(() => {
    const values = allSelectedValue.value;
    if (Array.isArray(values)) {
      return values.reduce<Record<Value, boolean>>((acc, value) => {
        acc[value] = true;
        return acc;
      }, {});
    }

    return {
      [String(values)]: true
    };
  });

  function availableId(id: Value) {
    if (!unref(onlyLeaf)) {
      return true;
    }

    return !hierarchyMap.value.directChildrenMap[id]?.length;
  }

  function deselect(id: Value) {
    if (!availableId(id)) {
      return normalizedValue.value;
    }
    if (!unref(multiple)) {
      return id;
    }

    if (unref(onlyLeaf)) {
      return (normalizedValue.value as Array<Value>).filter((v) => v !== id);
    }

    const allSelected = allSelectedValue.value as Array<Value>;
    const selected = [...(normalizedValue.value as Array<Value>)];
    const deselected = [...(hierarchyMap.value.deepChildrenMap[id] || []), id];
    let parentId = hierarchyMap.value.parentChildMap[id];
    while (parentId && allSelected.includes(parentId)) {
      deselected.push(parentId);
      selected.push(...hierarchyMap.value.directChildrenMap[parentId]);
      parentId = hierarchyMap.value.parentChildMap[parentId];
    }
    return difference(selected, deselected);
  }

  function select(id: Value) {
    if (!availableId(id)) {
      return normalizedValue.value;
    }
    if (!unref(multiple)) {
      return id;
    }

    const selected = [...(normalizedValue.value as Array<Value>)];
    if (unref(onlyLeaf)) {
      const result = selected.concat(id);
      return result.length < unref(maxValues) ? result : normalizedValue.value;
    }

    const allSelected = allSelectedValue.value as Array<Value>;
    let parentId = hierarchyMap.value.parentChildMap[id];
    if (!disableAutoCompress.value) {
      while (
        parentId &&
        !hierarchyMap.value.directChildrenMap[parentId].some(
          (child) => id !== child && !allSelected.includes(child)
        )
      ) {
        id = parentId;
        parentId = hierarchyMap.value.parentChildMap[parentId];
      }
    }

    const deselected = hierarchyMap.value.deepChildrenMap[id];
    selected.push(id);
    const result = difference(selected, deselected);
    return allSelected.concat(deselected).length <= unref(maxValues)
      ? result
      : normalizedValue.value;
  }

  function itemToValue(item: Item) {
    return item.id as number | string;
  }

  function toggle(item: Item) {
    if (item.disabled || (item.available as boolean) === false) {
      return;
    }

    const value = itemToValue(item);
    if (selectionMap.value[value]) {
      onChange(deselect(value));
      return;
    }
    onChange(select(value));
  }

  function reset() {
    onChange(unref(multiple) ? [] : null);
  }

  return {
    toggle,
    reset,
    selection: allSelectedValue,
    selectionMap
  };
}
