
    import { makeI18n } from '@/services/i18n';
    const baseTranslation = undefined
    
    
    if(module.hot) {
      const bucket = require("/app/build/i18n/browser-bucket.js");
      bucket.add('src/modals/vacancy-add-type-dialog.vue', {"multivacancy_disabled_error":{"ru_RU":"В&nbsp;вашем тарифе отключена мультивакансия. Недоступно их&nbsp;добавление, редактирование и&nbsp;переоткрытие","en_US":"Multivacancies are disabled in&nbsp;your tariff plan. You cannot add, edit or&nbsp;reopen them"}}) 
    }
   
    
    export default function (Component) {
      function beforeCreateI18n() {
        this.$i18n = this.$options._i18n;
      }

      function addHook(current) {
        if (Array.isArray(current)) {
          return [beforeCreateI18n].concat(current);
        } 
        if (current) {
          return [beforeCreateI18n, current];
        }
        return [beforeCreateI18n];
      }
    
      Component._i18n = makeI18n("src/modals/vacancy-add-type-dialog.vue", baseTranslation);
      Component.beforeCreate = addHook(Component.beforeCreate);
      delete Component._Ctor;
    }
  