import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient } from '@/api/utils/client/axios';
import { MemberType } from '@/types/member-type';
import { CursorList, Job } from '@/api/utils/types';
import { Delete } from '@/api/utils/default-responses';
import { EditableUserFields, Invite, FullInvite } from '@/types/user';
import { waitForJob } from '@/services/wait-for-job/wait-for-job';
import { enrichedColleague } from '@/api/colleague';

interface ListParams {
  member_type: MemberType;
  next_page_cursor?: string | null;
  count: number;
}

interface CountParams {
  member_type: MemberType;
}

class InviteLayer extends ApiLayer<AxiosClient> {
  fetchList(params: ListParams) {
    return this.methods
      .get<CursorList<Invite>>('/settings/invite', {
        params
      })
      .then((res) => {
        res.items = res.items.map(enrichedColleague);
        return res;
      });
  }

  fetchCount(params: CountParams) {
    return this.methods
      .get<{ total: number }>(`/settings/invites/count`, {
        params
      })
      .then(({ total }) => total);
  }

  fetchById(inviteId: number) {
    return this.methods.get<FullInvite>(`/settings/invite/${inviteId}`);
  }

  create(invite: EditableUserFields) {
    return this.methods
      .post<Job>('/settings/invite', invite)
      .then(({ job }): Promise<FullInvite> => waitForJob(job))
      .then(enrichedColleague);
  }

  update(id: number, invite: EditableUserFields) {
    return this.methods
      .put<Job>(`/settings/invite/${id}`, invite)
      .then(({ job }): Promise<FullInvite> => waitForJob(job))
      .then(enrichedColleague);
  }

  remove(id: number) {
    return this.methods.delete<Delete>(`/settings/invite/${id}`);
  }
}

export const InviteAPI = new InviteLayer(axios);
