<template>
  <div v-if="items.length === 0" :class="$style.empty">
    <span :class="$style.noTagsMessage">
      {{ $trlMessage('tag.add_first.message') }}
    </span>
    <base-button v-if="canEdit" @click="$emit('edit')">
      {{ $trlMessage('tag.add_first.button_message') }}
    </base-button>
  </div>
  <local-items-filter v-else highlight :items="items" :fields="{ name: 'name' }">
    <template #default="{ items: filteredItems, query, setQuery }">
      <base-combobox
        max-height="320px"
        data-qa="dropdown-autocomplete-content"
        :items="filteredItems"
        :value="selected"
        :query="query"
        :loading="loading"
        focus
        multiple
        @search="setQuery"
        @change="handleChange"
      >
        <template #item-title="{ item, captionField }">
          <div :class="$style.text" v-html="item[captionField]"></div>
          <span :class="$style.color" :style="{ backgroundColor: `#${item.color}` }"></span>
        </template>
        <template v-if="canEdit" #after>
          <button type="button" :class="$style.editButton" data-qa="edit" @click="$emit('edit')">
            <base-item>
              {{ $trlMessage('labels.edit') }}
            </base-item>
          </button>
        </template>
      </base-combobox>
    </template>
  </local-items-filter>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import BaseCombobox from '@/components/base-combobox/base-combobox';
import BaseItem from '@/components/list-item/base-item';
import LocalItemsFilter from '@/components/local-items-filter/local-items-filter';

export default {
  name: 'ViewMode',
  components: {
    LocalItemsFilter,
    BaseItem,
    BaseCombobox,
    BaseButton
  },
  props: {
    canEdit: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: Boolean
  },
  emits: ['change', 'edit'],
  methods: {
    handleChange(values) {
      this.$emit('change', values);
    }
  }
};
</script>

<style lang="less" module>
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.noTagsMessage {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 22px;
  color: #cccccc;
}
.text {
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.color {
  flex: 1 0 0;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.editButton {
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  border-top: 1px solid #ebebeb;
  outline: none;

  &:focus,
  &:hover {
    background: #f6f6f6;
    outline: none;
  }
}
</style>

<i18n lang="json">{}</i18n>
