import differenceBy from 'lodash/differenceBy';
import { computed, ref, Ref, watch } from 'vue';

import { useFlatList, Options, Item } from '@/components/base-list/composables/use-flat-list';
import {
  useNormalizedValue,
  ValueVariant
} from '@/components/base-list/composables/use-normalized-value';

interface FullOptions<T extends Item[]> extends Options<T> {
  query: Ref<string>;
  value: Ref<ValueVariant>;
  multiple: Ref<boolean>;
}

export function useSelectedItems<I extends Ref<Item[]>>(
  items: I,
  {
    showInactive = ref(false),
    parentChildMap,
    postprocessing = ref((items: I['value']) => items),
    multiple,
    value
  }: FullOptions<I['value']>
) {
  const innerItems = ref<Item[]>([]); // это нужно для хранения выбранных значений, даже при поиске

  const { normalizedValue } = useNormalizedValue(value, { multiple });

  const { items: flatItems } = useFlatList(items, {
    showInactive,
    parentChildMap,
    postprocessing
  });

  watch(
    () => [normalizedValue.value, flatItems.value],
    () => {
      if (!normalizedValue.value) {
        const nonExistingItems = getNonExistingItems(flatItems.value, innerItems.value);
        nonExistingItems && innerItems.value.push(...nonExistingItems);
        return;
      }
      const item = findItem(normalizedValue.value, flatItems.value);
      const items = Array.isArray(item) ? item : item && [item];
      const nonExistingItems = getNonExistingItems(items, innerItems.value);
      if (!nonExistingItems?.length) {
        return;
      }
      innerItems.value.push(...nonExistingItems);
    },
    { immediate: true }
  );

  const selectedItems = computed(() => {
    const currentValue = normalizedValue.value;
    return findItem(currentValue, innerItems.value);
  });

  return {
    selectedItems,
    normalizedValue
  };
}

function getNonExistingItems(items: Item[] | undefined, acc: Item[]) {
  if (!items) {
    return;
  }
  return differenceBy(items, acc, 'id');
}

function findItem(value: ValueVariant, items: Item[]) {
  if (Array.isArray(value)) {
    return items.filter(({ id }) => value.includes(id));
  }
  return items.find(({ id }) => id === value);
}
