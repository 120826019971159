import upperFirst from 'lodash/upperFirst';

type Listeners = Record<string, (...args: unknown[]) => unknown>;

export const migrateHelper = {
  convertListeners(listeners: Listeners): Listeners {
    const convertedListeners: Listeners = {};
    Object.entries(listeners).forEach(([key, fn]) => {
      convertedListeners[`on${upperFirst(key)}`] = fn;
    });
    return convertedListeners;
  }
};
