import { nanoid } from 'nanoid';

const elementId = `layer-target-${nanoid()}`;

const createMountPoint = (className = ''): HTMLElement => {
  let element = document.getElementById(elementId);
  if (!element) {
    element = document.createElement('div');
    element.setAttribute('id', elementId);
    document.body.appendChild(element);
  }
  element.className = className;
  return element;
};

export const mountPoint = {
  id: elementId,
  create: createMountPoint
};
