import { nanoid } from 'nanoid';

import { ImAPI } from '@/api/im';
import { notification } from '@/services/notification/notification';
import { trlMessage } from '@/services/i18n';
import { compareByOrder } from '../../libs/compators/compators';

const initialState = {
  ims: window.AccountIms || [],
  templates: window.ImTemplates ?? []
};

const gettersConfig = {
  ims: (state) => state.ims,
  getImByType: (state, getters) => (type) =>
    getters.ims.find(({ social_type }) => social_type === type),
  templates: (state) => state.templates.sort(compareByOrder),
  variables: (_, __, ___, rootGetters) => rootGetters['templateVariables/im']
};

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  removeTemplate(state, id) {
    const index = state.templates?.findIndex((template) => template.id === id);
    if (index >= 0) {
      state.templates.splice(index, 1);
    }
  }
};

const actions = {
  fetchTemplates({ commit }) {
    const promise = ImAPI.fetchTemplateList();
    promise
      .then((templates) => {
        commit('setTemplates', templates);
      })
      .catch((err) => {
        showError(err);
      });
    return promise;
  },
  createTemplate({ dispatch }, template) {
    const promise = ImAPI.createTemplate(template);
    promise
      .then(() => {
        showSuccess(trlMessage('settings.im_templates.success.create_template'));
      })
      .catch((err) => {
        showError(err, trlMessage('settings.im_templates.error.create_template'));
      });
    return promise.then(() => dispatch('fetchTemplates'));
  },
  updateTemplate({ dispatch }, { id, template }) {
    const promise = ImAPI.updateTemplate(id, template);
    promise
      .then(() => {
        showSuccess(trlMessage('settings.im_templates.success.update_template'));
      })
      .catch((err) => {
        showError(err, trlMessage('settings.im_templates.error.update_template'));
      });
    return promise.then(() => dispatch('fetchTemplates'));
  },
  deleteTemplate({ commit }, id) {
    const promise = ImAPI.deleteTemplate(id);
    promise.then(() => commit('removeTemplate', id));
    promise
      .then(() => {
        showSuccess(trlMessage('settings.im_templates.success.remove_template'));
      })
      .catch((err) => {
        showError(err, trlMessage('settings.im_templates.error.remove_template'));
      });
    return promise;
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersConfig
};

const notifyId = nanoid();

function showError(err, message = trlMessage('error.unknown')) {
  notification.notify({
    id: notifyId,
    isError: true,
    content: message
  });
}

function showSuccess(message) {
  notification.notify({
    id: notifyId,
    isError: false,
    content: message
  });
}
