<script>
import { normalizeChildren } from '@/util/component-helpers';

export default {
  name: 'CollapsedItemWrapper',
  props: {
    collapsible: Boolean,
    collapsedAbility: Boolean,
    collapsed: Boolean,
    previousCollapsedAbility: Boolean,
    previousCollapsed: Boolean,
    nextCollapsedAbility: Boolean,
    nextCollapsed: Boolean
  },
  emits: ['toggle'],
  computed: {
    canCollapsed() {
      return this.collapsible ? this.collapsedAbility : false;
    },
    previousCanCollapsed() {
      return this.collapsible ? this.previousCollapsedAbility : false;
    },
    nextCanCollapsed() {
      return this.collapsible ? this.nextCollapsedAbility : false;
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    }
  },
  render() {
    const children = normalizeChildren(this, {
      canCollapsed: this.canCollapsed,
      collapsed: this.collapsed,
      previousCanCollapsed: this.previousCanCollapsed,
      previousCollapsed: this.previousCollapsed,
      nextCanCollapsed: this.nextCanCollapsed,
      nextCollapsed: this.nextCollapsed,
      toggle: this.toggle
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
