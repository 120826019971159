import { h } from 'vue';
import { WIDGET_TYPE } from '@/components/vjsf/vjsf';
import { widgets as defaultWidgets } from '@/components/vjsf/view-widgets';
import TextareaWidget from './field-textarea';
import VerticalClamp from './vertical-clamp';

function clampHoc(widget) {
  return {
    name: 'VerticalClampHoc',
    inheritAttrs: false,
    render() {
      return h(VerticalClamp, {
        ...this.$attrs,
        component: widget
      });
    }
  };
}

const computedWidgets = Object.fromEntries(
  Object.entries(defaultWidgets).map(([name, widget]) => [name, clampHoc(widget)])
);
computedWidgets[WIDGET_TYPE.HTML] = clampHoc(TextareaWidget);
computedWidgets[WIDGET_TYPE.TEXT] = clampHoc(TextareaWidget);

export const widgets = computedWidgets;
