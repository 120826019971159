import uniqBy from 'lodash/uniqBy';
import { ref } from 'vue';

export function useSelectionHook({ dataKey = 'id', multiple = false } = {}) {
  const selectionValue = ref([]);

  const setSelectionValue = (value) => {
    selectionValue.value = value; /* ахахаха */
    return value;
  };

  const check = (item) => {
    return setSelectionValue(multiple ? uniqBy([...selectionValue.value, item], dataKey) : [item]);
  };

  const uncheck = (item) => {
    const idPropValue = item[dataKey];
    return setSelectionValue(
      selectionValue.value.filter((selectedItem) => selectedItem[dataKey] !== idPropValue)
    );
  };

  const isChecked = (item) => {
    const idPropValue = item[dataKey];
    return (
      selectionValue.value.find((selectedItem) => selectedItem[dataKey] === idPropValue) !==
      undefined
    );
  };

  const toggleCheck = (item) => {
    if (isChecked(item)) {
      return uncheck(item);
    } else {
      return check(item);
    }
  };

  return {
    selectionValue,
    toggleCheck,
    setSelectionValue
  };
}
