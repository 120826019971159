<template>
  <div v-if="isOpen">
    <document-type-modal v-model="isDocumentTypeModalOpened" @next-step="handleGoToFormStep" />
    <modal-wrapper v-model="isDocumentFormModalOpened">
      <template #default="{ dismiss, setDirty }">
        <validation-provider
          :default-values="defaultValues"
          :resolver="resolver"
          @submit="handleFormSubmit"
        >
          <template #default="{ values, handleSubmit, errors }">
            <form ref="form" novalidate @submit.prevent="handleSubmit" @change="setDirty">
              <popup-layout :title="stepFormTitle">
                <template #main>
                  <strong :class="$style.stepFormHeading">{{
                    $trlMessage('documents_request.document_type_modal.step.2')
                  }}</strong>
                  <component :is="formComponent" :errors="errors" />
                  <base-delimiter :top="10" />
                  <contact-form />
                  <base-delimiter />
                  <delivery-type-form :form-values="values" />
                  <div :class="$style.bottomSpacer" />
                  <mount-trigger @mounted="handleFormMount" />
                </template>
                <template #footer>
                  <base-button
                    type="submit"
                    color="black"
                    :loading="submitting"
                    data-qa="send_request"
                  >
                    {{ $trlMessage('document_request.form.send_request') }}
                  </base-button>
                  <base-button data-qa="close" @click="dismiss">
                    {{ $trlMessage('button.cancel') }}
                  </base-button>
                </template>
              </popup-layout>
            </form>
          </template>
        </validation-provider>
      </template>
    </modal-wrapper>
    <confirmation-modal v-model="isConfirmationModalOpened" :title="stepConfirmationTitle" />
  </div>
</template>

<script>
import pick from 'lodash/pick';
import { object } from 'yup';
import { appConfig } from '@/services/config/app-config';
import { userPermissions } from '@/services/config/user-permissions';
import tariffs from '@/services/tariffs';
import { OrganizationAPI } from '@/api/organization';
import { DateTimeHelper } from '@/util/date-time-helper';
import { StorageHelper } from '@/util/storage-helper';
import { ValidationProvider, yupResolver } from '@/components/ui/validate-form';
import BaseButton from '@/components/button/button';
import BaseDelimiter from '@/components/delimiter/delimiter';
import ModalWrapper from '@/components/modal-next/modal-wrapper';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';
import DocumentTypeModal from './document-type-modal';
import ConfirmationModal from './confirmation-modal';
import ReconciliationForm from './reconciliation-form';
import LicenseForm from './license-form';
import OtherForm from './other-form';
import WorkContractForm from './work-contract-form';
import ContactForm from './contact-form';
import DeliveryTypeForm from './delivery-type-form';
import MountTrigger from './mount-trigger';
import { DocumentType, ClosingDocumentType } from './document-type';

const STORAGE_KEY = `accounting_documents_${userPermissions.get('nick')}_${userPermissions.get(
  'account_member_id'
)}`;

export default {
  name: 'DocumentRequestFlow',
  components: {
    BaseButton,
    BaseDelimiter,
    DocumentTypeModal,
    ConfirmationModal,
    ModalWrapper,
    PopupLayout,
    ValidationProvider,
    ReconciliationForm,
    LicenseForm,
    OtherForm,
    WorkContractForm,
    ContactForm,
    DeliveryTypeForm,
    MountTrigger
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    return {
      documentType: null,
      submitting: false,
      organizations: null,
      isDocumentTypeModalOpened: false,
      isDocumentFormModalOpened: false,
      isConfirmationModalOpened: false,
      defaultValues: this.getDefaultValues()
    };
  },
  computed: {
    organization() {
      const nick = userPermissions.get('nick');
      return this.organizations?.find((org) => org.nick === nick);
    },
    formComponent() {
      if (this.documentType === DocumentType.RECONCILIATION) {
        return ReconciliationForm;
      }
      if (this.documentType === ClosingDocumentType.LICENSE_AGREEMENT) {
        return LicenseForm;
      }
      if (this.documentType === ClosingDocumentType.CONTRACT) {
        return WorkContractForm;
      }
      return OtherForm;
    },
    fullSchema() {
      return {
        ...(this.formComponent.schema ?? {}),
        ...ContactForm.schema,
        ...DeliveryTypeForm.schema
      };
    },
    resolver() {
      return yupResolver(object(this.fullSchema));
    },
    primaryDocumentName() {
      const primaryDocumentType = Object.values(ClosingDocumentType).includes(this.documentType)
        ? DocumentType.CLOSING
        : this.documentType;
      if (primaryDocumentType === DocumentType.RECONCILIATION) {
        return this.$trlMessage('documents_request.document_type_modal.type.reconciliation');
      } else if (primaryDocumentType === DocumentType.CLOSING) {
        return this.$trlMessage('documents_request.document_type_modal.type.closing_documents');
      } else if (primaryDocumentType === DocumentType.OTHER) {
        return this.$trlMessage('documents_request.document_type_modal.type.other');
      }
      return '';
    },
    stepFormTitle() {
      return this.$trlMessage('documents_request.form_modal.title', {
        name: this.primaryDocumentName.toLocaleLowerCase(),
        organization: this.organization?.name ?? ''
      });
    },
    stepConfirmationTitle() {
      return this.$trlMessage('documents_request.confirmation_modal.title', {
        name: this.primaryDocumentName.toLocaleLowerCase(),
        organization: this.organization?.name ?? ''
      });
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isDocumentTypeModalOpened = true;
        } else {
          this.isDocumentTypeModalOpened = false;
          this.isDocumentFormModalOpened = false;
          this.isConfirmationModalOpened = false;
        }
      }
    },
    isDocumentTypeModalOpened(value) {
      if (!value && !this.isConfirmationModalOpened) {
        this.$emit('close');
      }
    },
    isConfirmationModalOpened(value) {
      if (!value) {
        this.$emit('close');
      }
    }
  },
  mounted() {
    OrganizationAPI.getList().then((resp) => {
      this.organizations = resp;
    });
  },
  methods: {
    getDefaultValues() {
      const currentLicense = tariffs.licenseInfo.current_license;

      const reconciliationStart = DateTimeHelper.parse({
        date: currentLicense.begin_at,
        isPlainDate: true
      }).toServerFormat({
        includeTime: false
      });

      const reconciliationEnd = DateTimeHelper.now({
        isPlainDate: true
      }).toServerFormat({
        includeTime: false
      });

      const result = {
        reconciliation_start: reconciliationStart,
        reconciliation_end: reconciliationEnd,
        delivery_preference: 'contact_person',
        ...loadFormData(STORAGE_KEY)
      };

      const taxpayerId = currentLicense.customer_account.inn;
      if (taxpayerId) {
        result.taxpayer_id = taxpayerId;
      }
      return result;
    },
    handleFormMount() {
      const fields = Object.keys(this.fullSchema);
      const emptyFields = fields.filter((key) => !this.getDefaultValues()[key]);
      for (let i = 0; i < emptyFields.length; i++) {
        const el = this.$refs.form.querySelector(`[name="${emptyFields[i]}"]`);
        if (el) {
          el.focus();
          break;
        }
      }
    },
    handleGoToFormStep(documentType) {
      this.documentType = documentType;
      this.$nextTick(() => {
        this.isDocumentFormModalOpened = true;
      });
    },
    handleFormSubmit(values) {
      const payload = {
        ...values,
        organization_id: this.organization?.id,
        form_type: this.documentType
      };

      this.submitting = true;
      return OrganizationAPI.requestAccountingDocuments(payload)
        .then(() => {
          saveFormData(values, STORAGE_KEY);
          this.defaultValues = this.getDefaultValues();
          this.isConfirmationModalOpened = true;
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};

function saveFormData(values, storageKey) {
  const fieldsToPersist = ['taxpayer_id', 'contact_person', 'contact_phone', 'contact_email'];
  const obj = pick(values, fieldsToPersist);
  return StorageHelper.setJSON(storageKey, obj);
}

function loadFormData(storageKey) {
  const contact = appConfig.get('account');
  const result = StorageHelper.getJSON(storageKey) || {};
  if (!result.contact_person) {
    result.contact_person = contact.name;
  }
  if (!result.contact_phone) {
    result.contact_phone = contact.phone;
  }
  if (!result.contact_email) {
    result.contact_email = contact.email;
  }
  return result;
}
</script>

<style lang="less" module>
.stepFormHeading + * {
  margin-top: 10px;
}

.bottomSpacer {
  height: 10px;
}
</style>

<i18n lang="json">{}</i18n>
