<template>
  <fields-layout
    :tag="tag"
    :gap="gap"
    :gap-before="gapBefore"
    :gap-after="gapAfter"
    :autocomplete="autocomplete ? 'on' : 'off'"
    novalidate
    @keydown.native.meta.enter="handleSubmit"
    @keydown.native.ctrl.enter="handleSubmit"
    @submit.native.prevent="handleSubmit"
  >
    <slot />
  </fields-layout>
</template>

<script>
import FieldsLayout from '@/components/ui/fields-layout/fields-layout.vue';

export default {
  name: 'FormLayout',
  components: { FieldsLayout },
  props: {
    tag: {
      type: String,
      default: 'form'
    },
    gap: FieldsLayout.props.gap,
    gapBefore: FieldsLayout.props.gapBefore,
    gapAfter: FieldsLayout.props.gapAfter,
    disabled: Boolean,
    autocomplete: Boolean
  },
  emits: ['submit'],
  methods: {
    handleSubmit() {
      if (!this.disabled) {
        this.$emit('submit');
      }
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
