<template>
  <teleport :to="mountTo">
    <div :style="style" :class="wrapperClass">
      <div
        ref="content"
        v-bind="$attrs"
        :class="contentClass"
        :style="contentStyle"
        v-on="$listeners"
      >
        <template v-if="show">
          <slot />
        </template>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mountPoint } from './mount-point';

let zIndex = 1050;

export default {
  name: 'LayerPortal',
  inheritAttrs: false,
  props: {
    wrapperClass: {
      type: [String, Array, Object],
      default: ''
    },
    contentClass: {
      type: [String, Array, Object],
      default: ''
    },
    contentStyle: {
      type: [String, Object],
      default: ''
    },
    zIndexIncrement: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    mountTo() {
      return `#${mountPoint.id}`;
    },
    style() {
      return {
        zIndex,
        position: 'relative'
      };
    }
  },
  beforeMount() {
    zIndex += this.zIndexIncrement;
    mountPoint.create(this.$style.container);
  },
  mounted() {
    // фиксим поведение с прыгающим экраном при автофокусе при монтировании компонента, получается сначала позиционируем обёртку, а потом уже монтируем контент
    // пример https://huntflow.atlassian.net/browse/DEV-11350
    this.show = true;
  },
  beforeDestroy() {
    zIndex -= this.zIndexIncrement;
  },
  methods: {
    getContent() {
      return this.$refs.content;
    }
  }
};
</script>

<style module>
.container {
  position: absolute;
  top: 0;
}
</style>

<i18n lang="json">{}</i18n>
