<template>
  <base-promo :size="size" :active-campaign-tag="activeCampaignTag">
    <tariff-badge :tariff-group="unlimitedTariffGroup" />
    <h2 v-html="title" />
    <slot name="description">
      <p v-html="descriptionMessage" />
    </slot>
    <template #footer>
      <apply-button v-if="!isDemo" :reason="reason" />
      <price-link :service-kind-code="serviceKindCode" />
    </template>
  </base-promo>
</template>

<script>
import { BasePromo } from '@/components';
import TariffBadge from '@/components/tariff-badge/tariff-badge.vue';
import ApplyButton from '@/components/promo/apply-button.vue';
import PriceLink from '@/components/promo/price-link.vue';
import tariffs, { ServiceKindCodes } from '@/services/tariffs';

export default {
  name: 'DropdownPromo',
  components: { BasePromo, PriceLink, ApplyButton, TariffBadge },
  props: {
    title: {
      type: String,
      default: ''
    },
    descriptionTemplate: {
      type: String,
      default: ''
    },
    reason: {
      type: String,
      default: ''
    },
    serviceKindCode: {
      type: String,
      required: true,
      validator: (val) => Object.keys(ServiceKindCodes).includes(val)
    },
    size: {
      type: BasePromo.props.size.type,
      default: 'dropdown',
      validator: BasePromo.props.size.validator()
    },
    theme: BasePromo.props.theme,
    activeCampaignTag: BasePromo.props.activeCampaignTag
  },
  computed: {
    isDemo() {
      return tariffs.isDemo;
    },
    unlimitedTariffGroup() {
      return tariffs.unlimitedTariffGroup(this.serviceKindCode);
    },
    descriptionMessage() {
      const tariffName = this.unlimitedTariffGroup?.name;
      // eslint-disable-next-line no-restricted-syntax
      return tariffName && this.$trlMessage(this.descriptionTemplate, { tariffName });
    }
  }
};
</script>

<style lang="less" module></style>

<i18n lang="json">{}</i18n>
