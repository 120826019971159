import * as LegacySchemaUtil from './legacy-schema-utils';
import { makeUISchema } from './helpers/make-ui-schema';
import { WIDGET_TYPE } from '../vjsf';
import { getSubmitButtonName } from '@/components/vjsf/schema-converter/utils';

export function deriveUISchema(schema, getObjectFieldTemplate) {
  const root = {};
  const uiSchema = processUISchema(schema.schema, root, getObjectFieldTemplate);
  return addSubmit(uiSchema);
}

function processUISchema(group, parent, getObjectFieldTemplate) {
  const entries = Object.entries(group);
  const order = LegacySchemaUtil.getSortedFieldNames(entries);

  const mappedEntries = entries.map(([key, field]) => {
    field.name = field.name || key;
    const fieldSchema = makeUISchema(field, getObjectFieldTemplate);
    return LegacySchemaUtil.isComplexLike(field)
      ? [key, processUISchema(field.fields, fieldSchema, getObjectFieldTemplate)]
      : [key, fieldSchema];
  });

  return {
    ...parent,
    'ui:order': order,
    ...Object.fromEntries(mappedEntries)
  };
}

function addSubmit(uiSchema) {
  const name = getSubmitButtonName();
  uiSchema['ui:order'].unshift(name);
  uiSchema[name] = {
    'ui:widget': WIDGET_TYPE.SUBMIT
  };
  return uiSchema;
}
