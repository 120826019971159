<template>
  <div>
    <vacancy-request-view
      v-model="vacancyRequestId"
      :quota="quota"
      @request-removed="$emit('request-removed')"
    />
    <quota-without-request-modal v-model="quotaWithoutRequest" />
  </div>
</template>

<script>
import VacancyRequestView from '@/modules/vacancy-request/modals/view/request-view';
import QuotaWithoutRequestModal from './quota-without-request-modal';

export default {
  name: 'QuotaModal',
  components: {
    VacancyRequestView,
    QuotaWithoutRequestModal
  },
  model: {
    prop: 'quota',
    event: 'change'
  },
  props: {
    quota: {
      type: Object,
      default: null
    }
  },
  emits: ['change', 'request-removed'],
  computed: {
    vacancyRequestId: {
      get() {
        // в логах жзб у квот (hired_in_fill_quota) id лежит в свойстве "vacancy_request"
        if (typeof this.quota?.vacancy_request === 'number') {
          return this.quota?.vacancy_request;
        }
        // Для списка заявок на согласовании
        if (typeof this.quota?.id === 'number' && this.quota.next_decision_link) {
          return this.quota?.id;
        }
        return this.quota?.vacancy_request?.id;
      },
      set(value) {
        if (!value) {
          this.$emit('change', null);
        }
      }
    },
    quotaWithoutRequest: {
      get() {
        if (this.vacancyRequestId) {
          return null;
        }
        return this.quota;
      },
      set(value) {
        if (!value) {
          this.$emit('change', null);
        }
      }
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
