import { WIDGET_TYPE } from '../vjsf';
import InputWidget from './form-field-input';
import HiddenWidget from './form-field-hidden';
import PartialDictionaryWidget from './form-field-partial-dictionary';
import HtmlWidget from './form-field-html';
import TextareaWidget from './form-field-textarea';
import SelectWidget from './form-field-select';
import DateWidget from './form-field-date';
import SubmitWidget from './form-field-submit';
import CheckboxWidget from './form-field-checkbox';
import RadioWidget from './form-field-radio';

export const widgets = {
  [WIDGET_TYPE.STRING]: InputWidget,
  [WIDGET_TYPE.INTEGER]: InputWidget,
  [WIDGET_TYPE.PARTIAL_DICTIONARY]: PartialDictionaryWidget,
  [WIDGET_TYPE.HTML]: HtmlWidget,
  [WIDGET_TYPE.TEXT]: TextareaWidget,
  [WIDGET_TYPE.SELECT]: SelectWidget,
  [WIDGET_TYPE.DATE]: DateWidget,
  [WIDGET_TYPE.HIDDEN]: HiddenWidget,
  [WIDGET_TYPE.SUBMIT]: SubmitWidget,
  [WIDGET_TYPE.CHECKBOX]: CheckboxWidget,
  [WIDGET_TYPE.RADIO]: RadioWidget
};
