export const SEARCH_ROUTE_NAME = 'search';
export const DOUBLE_ROUTE_NAME = 'doubles';
export const SEARCH_APPLICANTS_ROUTE_NAME = 'search-applicants';
export const SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME = 'search-applicants-double';
export const SEARCH_VACANCIES_ROUTE_NAME = 'search-vacancies';
export const VACANCY_ON_STATUS_ROUTE_NAME = 'vacancy-on-status';
export const VACANCY_APPLICANT_ROUTE_NAME = 'vacancy-applicant';
export const VACANCY_APPLICANT_DOUBLE_ROUTE_NAME = 'vacancy-applicant-double';
export const APPLICANTS_WITH_DASHBOARD_ROUTE_NAME = 'applicants-with-dashboard';
export const APPLICANTS_ON_STATUS_ROUTE_NAME = 'applicants-on-status';
export const APPLICANTS_ON_STATUS_DOUBLE_ROUTE_NAME = 'applicants-on-status-double';
