<template>
  <view-field
    :schema="schema"
    :is-visible="options.isVisible"
    :label="label"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
  >
    <widget-value v-if="selectedRadioOption" :value="selectedRadioOption.label" />
  </view-field>
</template>

<script>
import ViewField from './view-field';
import WidgetValue from './base-widget/widget-value';

export default {
  name: 'ViewFieldRadio',
  components: {
    ViewField,
    WidgetValue
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: ViewField.props.schema
  },
  computed: {
    allRadioOptions() {
      const options = this.schema.enum.filter((value) => value || value === 0 || value === false);
      return options.map((value) => {
        const label = this.options.trl?.[value] ?? String(value);
        return { value, label };
      });
    },
    selectedRadioOption() {
      return this.allRadioOptions.find((option) => this.value === option.value);
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
