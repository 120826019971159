import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient } from '@/api/utils/client/axios';

class DictionaryLayer extends ApiLayer<AxiosClient> {
  getDictionary(name: string, dictHash: string) {
    return this.methods.get(`/dictionary/${name}${dictHash ? `/${dictHash}` : ''}`);
  }
}

export const DictionaryAPI = new DictionaryLayer(axios);
