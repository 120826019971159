<template>
  <div :class="$style.root">
    <div>
      <slot name="default" />
    </div>
    <field-visiblity-settings
      v-if="schemas.length"
      :schemas="schemas"
      :schemas-fields="fields"
      :selected-schemas-fields="selectedFields"
      @change="$emit('set:fields', $event)"
    />
  </div>
</template>

<script>
import FieldVisiblitySettings from './field-visibility-settings';

export default {
  name: 'RequestListHeader',
  components: {
    FieldVisiblitySettings
  },
  props: {
    schemas: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    selectedFields: {
      type: Object,
      required: true
    }
  },
  emits: ['set:fields']
};
</script>

<style module>
.root {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}
</style>

<i18n lang="json">{}</i18n>
