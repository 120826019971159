<template>
  <input
    :id="id"
    ref="input"
    :aria-invalid="invalid ? 'true' : 'false'"
    :value="value"
    v-bind="$attrs"
    :class="className"
    v-on="listeners"
  />
</template>

<script>
import widthStyles from '../../shared-css-modules/widths.module.css';

export default {
  name: 'BaseInput',
  model: {
    prop: 'value',
    event: 'inputValue'
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    invalid: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    big: Boolean,
    size: {
      type: String,
      default: 'full',
      validator(value) {
        return Boolean(widthStyles + '.' + value);
      }
    },
    arrowDisable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['inputValue', 'input'],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit('inputValue', event.target.value);
          this.$emit('input', event);
        }
      };
    },
    className() {
      const sizeClassName = widthStyles[this.size];
      return {
        [this.$style.formControl]: true,
        [sizeClassName]: Boolean(sizeClassName),
        [this.$style.big]: this.big,
        [this.$style.arrow]: this.arrowDisable,
        [this.$style.invalid]: this.invalid
      };
    }
  },
  mounted() {
    if (this.focus) {
      this.setFocus();
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('keydown', this.checkInputFocus);
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });

      document.body.addEventListener('keydown', this.checkInputFocus);
    },
    checkInputFocus() {
      // Исправляет ошибку в Safari, при которой после закрытия
      // модального окна через Esc, содержащее поле ввода с фокусом
      // (причём фокус при монтировании, после setFocus()),
      // может срабатывать непонятный скролл
      if (event.keyCode === 27 && document.activeElement === this.$refs.input) {
        this.$refs.input.blur();
      }
    }
  }
};
</script>

<style module>
.formControl {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: var(--content-primary, #050505);
  min-height: 40px;
  padding: 0 var(--Space-400, 16px);
  margin: 0;
  outline: 0 none;
  border-radius: var(--Radius-x2, 8px);
  border: 1px solid var(--border-neutral-M, rgba(0, 0, 0, 0.16));
  background-color: $white;
  align-items: center;
  width: 100%;
  display: inline-block;

  &:focus {
    border-color: var(--content-brand-blue, #25cfe8);
  }

  &[disabled] {
    opacity: 0.3;
    cursor: default;
    /* <3 Safari */
    -webkit-text-fill-color: var(--content-primary, #050505);
  }

  &.invalid {
    border-color: var(--content-danger, #f00);
  }
}

.big {
  padding-top: 9px;
  padding-bottom: 10px;
}
.arrows input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrows input[type='number'] {
  -moz-appearance: textfield;
}
</style>

<i18n lang="json">{}</i18n>
