export function traverseUISchema(obj, fn) {
  const recursion = (obj, fn, parentPointer = '', parent) => {
    const order = obj['ui:order'];
    if (!order) {
      return;
    }
    for (let i = 0; i < order.length; i++) {
      const name = order[i];
      const pointer = parentPointer + '/' + name;
      fn(obj[name], pointer, parent);
      recursion(obj[name], fn, pointer, obj[name]);
    }
  };

  recursion(obj, fn);
}
