<template>
  <div :class="$style.root" v-html="html" />
</template>

<script lang="ts">
export default {
  name: 'HtmlContent',
  props: {
    html: {
      type: String,
      default: ''
    }
  }
};
</script>

<style module>
.root {
  & p,
  & ul,
  & ol {
    margin: 10px 0;
  }
  & ul:first-child,
  & ol:first-child,
  & p:first-child {
    margin-top: 0;
  }
  & ul:last-child,
  & ol:last-child,
  & p:last-child {
    margin-bottom: 0;
  }

  a {
    color: #2cc8df;
    text-decoration: none;
    &:hover {
      color: $linkHoverColor;
    }
  }

  li {
    & > ol,
    & > p,
    & > ul {
      margin: 0;
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
