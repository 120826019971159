<template>
  <validation-field :name="name" :label="label" hide-errors>
    <template #default="{ id, modelValue, invalid, name, onChange }">
      <base-datepicker
        :id="id"
        :name="name"
        :value="modelValue"
        :invalid="invalid"
        required
        @change="onChange"
      />
    </template>
  </validation-field>
</template>

<script>
import { ValidationField } from '@/components/ui/validate-form';
import BaseDatepicker from '@/components/base-datepicker';

export default {
  name: 'ValidationFormDatepicker',
  components: {
    ValidationField,
    BaseDatepicker
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: undefined
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
