<template>
  <div :class="$style.value" data-qa="value">
    <div v-if="raw" :class="$style.html" v-html="computedValue"></div>
    <template v-else>{{ computedValue }}</template>
  </div>
</template>

<script>
export default {
  name: 'WidgetValue',
  props: {
    value: {
      type: [String, Number],
      default: '—'
    },
    raw: Boolean
  },
  computed: {
    computedValue() {
      return [null, undefined, ''].includes(this.value) ? '—' : this.value;
    }
  }
};
</script>

<style module>
.value {
  margin-left: var(--value-margin-left, 20px);
  display: var(--value-display, block);
  white-space: pre-wrap;
  word-break: break-word;
  font-weight: var(--value-font-weight, bold);
}

.value + .value {
  margin-top: 10px;
}

.html {
  p,
  ul,
  ol {
    margin: 10px 0;
  }
  ul:first-child,
  ol:first-child,
  p:first-child {
    margin-top: 0;
  }
  a {
    color: #2cc8df;
    text-decoration: none;
    &:hover {
      color: $linkHoverColor;
    }
  }

  li {
    & > ol,
    & > p,
    & > ul {
      margin: 0;
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
