<script>
import { SkeletonHelper, SkeletonZones } from '@/util/skeleton-helper';
import { userPermissions } from '@/services/config/user-permissions';
import tariffs, { ServiceKindCodes } from '@/services/tariffs';
import { ApplicantAPI } from '@/api/applicant';
import { MessageEvent } from '@/types/poller-message';
import { poller } from '@/poller-instance';
import { normalizeChildren } from '@/util/component-helpers';

export default {
  name: 'ApplicantLimitDataProvider',
  data() {
    return {
      ready: userPermissions.isWatcher,
      count: 0
    };
  },
  computed: {
    limit() {
      return tariffs.getTariffLimit(ServiceKindCodes.applicant_limit) || Infinity;
    },
    unlimitedTariffGroup() {
      return tariffs.getUnlimitedGroup(ServiceKindCodes.applicant_limit);
    },
    isLimitExceed() {
      return this.limit - this.count <= 0;
    },
    shouldWarn() {
      if (this.limit === Infinity) {
        return false;
      }

      const countToLimit = this.limit - this.count;
      return countToLimit > 0 && countToLimit / this.limit <= 0.02;
    }
  },
  created() {
    if (userPermissions.isWatcher) {
      SkeletonHelper.patchPromise(SkeletonZones.HEADER, Promise.resolve());
      return;
    }
    SkeletonHelper.patchPromise(
      SkeletonZones.HEADER,
      ApplicantAPI.fetchAllCount()
        .then((count) => {
          this.count = count;
        })
        .finally(() => {
          this.ready = true;
        })
    );

    this.unsubscribe = poller.onMessage((message) => {
      // корректируем локальный счётчик в целях оптимизации
      // (экономия на запросе за статистикой)
      if (message.event === MessageEvent.applicantRemove) {
        this.count = Math.max(0, this.count - 1);
      } else if (message.event === MessageEvent.applicantAdd) {
        this.count = this.count + 1;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe?.();
  },
  render() {
    const children = normalizeChildren(this, {
      ready: this.ready,
      count: this.count,
      limit: this.limit,
      isLimitExceed: this.isLimitExceed,
      shouldWarn: this.shouldWarn,
      unlimitedTariffGroup: this.unlimitedTariffGroup
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
