<template>
  <component
    :is="tagName"
    v-bind="$attrs"
    :type="type"
    :class="className"
    @click="!loading ? $emit('click', $event) : null"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <slot name="before" :size="iconSize" :classes="$style.icon">
      <base-icon
        v-if="icon"
        :type="icon"
        :width="iconSize"
        :height="iconSize"
        :class="{
          [$style.icon]: true,
          [$style.lonelyIcon]: iconOnly
        }"
      />
    </slot>
    <span v-if="!iconOnly" :class="$style.content"><slot /></span>
    <base-icon
      v-if="dropdown"
      type="chevron-down"
      :width="chevronSize"
      :height="chevronSize"
      :class="$style.chevron"
    />
    <span v-if="loading" :class="$style.loader">
      <base-loader :size="loaderSize" :color="loaderColor" />
    </span>
  </component>
</template>

<script>
import BaseIcon from '@/components/icon';
import BaseLoader from '@/components/loader/loader.vue';
import { SIZES, COLORS, ICON_SIZE, LOADER_SIZE, LOADER_COLOR } from './constants';

export default {
  name: 'BaseButton',
  components: {
    BaseIcon,
    BaseLoader
  },
  props: {
    tagName: {
      type: String,
      default: 'button'
    },
    color: {
      type: String,
      default: 'gray',
      validator(value) {
        return COLORS.hasOwnProperty(value);
      }
    },
    size: {
      type: String,
      default: 's',
      validator(value) {
        return SIZES.hasOwnProperty(value);
      }
    },
    type: {
      type: String,
      default: 'button'
    },
    active: Boolean,
    fakeDisabled: {
      type: Boolean,
      default: false
    },
    // сомнительное решение, кажется тут нужен прям отдельный компонент для только иконки,
    // т.к. там прилично различий да и дизайн хотел это разделять у себя
    iconOnly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    dropdown: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'mouseenter', 'mouseleave'],
  computed: {
    className() {
      return {
        [this.$style.button]: true,
        [this.$style.active]: this.active,
        [this.$style.disabled]: this.fakeDisabled,
        [this.$style[this.color]]: true,
        [this.$style[this.size]]: true,
        [this.$style.loading]: this.loading
      };
    },
    iconSize() {
      return ICON_SIZE[this.size];
    },
    chevronSize() {
      return this.size === 'xs' ? '18px' : '20px';
    },
    loaderSize() {
      return LOADER_SIZE[this.size];
    },
    loaderColor() {
      return LOADER_COLOR[this.color];
    }
  }
};
</script>

<style module src="./button.module.css" />

<i18n lang="json">{}</i18n>
