import { Extension } from '@tiptap/core';

export interface SubmitOptions {
  onSubmit: () => void | undefined;
}

export const Submit = Extension.create<SubmitOptions>({
  addOptions() {
    return {
      onSubmit: () => {}
    };
  },

  addKeyboardShortcuts() {
    return {
      'Cmd-Enter': ({ editor }) => {
        editor.view.dom.closest('form')?.requestSubmit();
        this.options.onSubmit();
        return true;
      },
      'Ctrl-Enter': ({ editor }) => {
        editor.view.dom.closest('form')?.requestSubmit();
        this.options.onSubmit();
        return true;
      }
    };
  }
});
