import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient } from '@/api/utils/client/axios';

type Offer = {
  id: number;
  account: number;
  name: string;
  template: string;
  active: boolean;
};

type Items<T> = {
  items: T[];
};

class OffersLayer extends ApiLayer<AxiosClient> {
  constructor(client: AxiosClient) {
    super(client);

    this.getOfferTemplates = this.memoizeMethod(this.getOfferTemplates);
  }

  getOfferTemplates() {
    return this.methods.get<Items<Offer>>('/account/offer').then(({ items }) => items);
  }
}
// TODO: переделать на именованный экспорт, в соответствии с README
export default new OffersLayer(axios);
