<template>
  <div :class="className">
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'LayoutRow',
  props: {
    borderBefore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      return {
        [this.$style.row]: true,
        [this.$style.borderBefore]: this.borderBefore
      };
    }
  }
};
</script>

<style module>
$padding: 24px;

.row {
  position: relative;
  background-color: $white;
}

.row > *:first-child {
  padding-inline-start: $padding;
}

.row > *:last-child {
  padding-inline-end: $padding;
}

.row.borderBefore {
  border-top: 1px solid var(--border-neutral-S, rgba(0, 0, 0, 0.08));
  border-radius: 0 0 var(--Radius-x4, 16px) var(--Radius-x4, 16px);
  position: sticky;
  bottom: 0;
}
</style>

<i18n lang="json">{}</i18n>
