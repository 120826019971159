<template>
  <button
    :id="id"
    type="button"
    :class="classes"
    tabindex="0"
    :name="name"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span :class="$style.text">
      <slot>
        {{ text }}
      </slot>
    </span>
    <span :class="$style.iconWrapper">
      <slot name="icon">
        <base-icon type="chevron-down-24" />
      </slot>
    </span>
  </button>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'ToggleButton',
  components: { BaseIcon },
  props: {
    text: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: undefined
    },
    invalid: Boolean,
    disabled: Boolean
  },
  emits: ['click'],
  computed: {
    classes() {
      return {
        [this.$style.button]: true,
        [this.$style.invalid]: this.invalid
      };
    }
  }
};
</script>

<style module>
.button {
  border-radius: var(--Radius-x2, 8px);
  border: 1px solid var(--border-neutral-M, rgba(0, 0, 0, 0.16));
  background: var(--bg-primary-default, #fff);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  outline: 0 none;
  padding: 0 8px 0 16px;
  vertical-align: top;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  user-select: none;
  overflow: hidden;
}
.text {
  @mixin ellipsis;
}
.iconWrapper {
  flex: 0 0 auto;
  display: inline-flex;
}
.button:focus:not(.invalid) {
  border-color: #2cc8df;
}
.button[disabled]:not(.invalid) {
  color: #cccccc;
  cursor: default;
}
.invalid {
  border-color: $errorColor;
}
</style>

<i18n lang="json">{}</i18n>
