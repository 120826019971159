<template>
  <div>
    <validation-form-input
      name="contact_person"
      :label="$trlMessage('document_request.form.contact.label')"
      :class="commonStyles.field"
    />
    <validation-form-input
      name="contact_phone"
      :label="$trlMessage('document_request.form.phone.label')"
      :class="commonStyles.field"
    />
    <validation-form-input
      name="contact_email"
      :label="$trlMessage('document_request.form.email.label')"
      :class="commonStyles.field"
    />
  </div>
</template>

<script>
import { string } from 'yup';
import { isEmailValid } from '@/util/validators/email';
import { trlMessage } from '@/services/i18n';
import ValidationFormInput from './partials/input';
import commonStyles from './common-styles.module.css';

export default {
  name: 'ContactForm',
  components: {
    ValidationFormInput
  },
  schema: {
    contact_person: string().required(),
    contact_phone: string().required(),
    contact_email: string()
      .required()
      .test('email', trlMessage('error.invalid_email'), (v) => (v ? isEmailValid(v) : true))
  },
  computed: {
    commonStyles: () => commonStyles
  }
};
</script>

<i18n lang="json">{}</i18n>
