<template>
  <view-field :label="label" :is-visible="isVisible">
    <widget-value :value="value" />
  </view-field>
</template>

<script>
import ViewField from '@/components/vjsf/view-widgets/view-field';
import WidgetValue from '@/components/vjsf/view-widgets/base-widget/widget-value';

export default {
  name: 'CompensationTemplate',
  components: {
    ViewField,
    WidgetValue
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    schema: {
      type: Object,
      required: true
    },
    uiSchema: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isVisible() {
      return this.uiSchema['ui:options']?.isVisible;
    },
    value() {
      const order = this.uiSchema['ui:order'];
      const values = order.map((name) => this.formData[name]).filter(Boolean);
      return values.join(' — ');
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
