import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

import { computed, inject, useCssModule } from 'vue';
import BaseIcon from '@/components/icon.vue';
import { removeLayerSymbol } from '@/components/ui/base-layer/constants';


export default /*@__PURE__*/_defineComponent({
  ...{
  name: 'PopupHeading'
},
  __name: 'heading',
  setup(__props) {



const css = useCssModule();

const removeLayer = inject(removeLayerSymbol);

const classes = computed(() => {
  return {
    [css.heading]: true
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(classes.value),
    "data-qa": "popup-layout-heading"
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _cache[0] || (_cache[0] = _createTextVNode()),
    (_unref(removeLayer))
      ? (_openBlock(), _createBlock(BaseIcon, {
          key: 0,
          class: _normalizeClass(_ctx.$style.icon),
          type: "x",
          onClick: _unref(removeLayer)
        }, null, 8, ["class", "onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})