<template>
  <email-list
    :id="id"
    :title="title"
    :items="selectedItems || []"
    :invalid-value-indices="invalidValueIndices"
    @remove="handleRemove"
  >
    <template #itemPrepend="props">
      <slot name="listItemPrepend" v-bind="props"></slot>
    </template>
    <template #append="{ emails }">
      <email-autocomplete
        v-if="coworkers"
        :id="id"
        ref="autocomplete"
        data-qa="to_whom_send"
        :coworkers="coworkers"
        :excluded-emails="emails"
        :input-value="inputValue"
        :include-resources="includeResources"
        :invalid="invalid"
        v-bind="$attrs"
        @select="handleSelect"
        @input="handleInput"
      />
      <base-input
        v-else
        :id="id"
        :placeholder="$trlMessage('email.placeholder')"
        disabled
        v-bind="$attrs"
      />
    </template>
  </email-list>
</template>

<script>
import { ref, watch } from 'vue';
import BaseInput from '@/components/input/input';
import EmailAutocomplete from '@/components/email-autocomplete/email-autocomplete';
import EmailList from '@/components/email-list/email-list';
import { CoworkerAPI } from '@/api/coworker';

export default {
  name: 'EmailAutocompleteList',
  components: {
    BaseInput,
    EmailAutocomplete,
    EmailList
  },
  inheritAttrs: false,
  model: {
    prop: 'selectedItems',
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    includeResources: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    invalidValueIndices: EmailList.props.invalidValueIndices
  },
  emits: ['input', 'change'],
  setup(props, { emit }) {
    const autocomplete = ref(null);
    const inputValue = ref('');
    const coworkers = ref(undefined);
    CoworkerAPI.getList({ show_watchers: true }).then((response) => {
      coworkers.value = response.items;
    });
    watch(
      () => props.selectedItems,
      () => {
        // очищаем инпут, если изменился список выбранных email
        inputValue.value = '';
        emit('input', undefined);
      }
    );

    return {
      autocomplete,
      inputValue,
      coworkers,
      commit: () => autocomplete.value?.commit(),
      focus: () => autocomplete.value?.focus(),
      handleInput(value) {
        inputValue.value = value?.email || '';
        emit('input', value);
      },
      handleSelect(selectedItem) {
        emit('change', [...(props.selectedItems ?? []), selectedItem]);
      },
      handleRemove(item) {
        emit(
          'change',
          (props.selectedItems ?? []).filter((selectedItem) => selectedItem !== item)
        );
      }
    };
  }
};
</script>

<i18n lang="json">{}</i18n>
