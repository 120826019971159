import { trlMessage } from '@/services/i18n';
import { DateTimeHelper } from './date-time-helper';

function age(date: string): number {
  const parsedDate = DateTimeHelper.parse({ date });
  const nowDate = DateTimeHelper.now();

  const duration = nowDate.since(parsedDate).round({
    smallestUnit: 'year',
    relativeTo: parsedDate,
    roundingMode: 'floor'
  });
  return duration.years;
}

type WorkPeriodDateFull = { year: number; month: number; day: number; precision: 'day' };
type WorkPeriodDateYearMonth = { year: number; month: number; precision: 'month' };
type WorkPeriodDateYearOnly = { year: number; precision: 'year' };
type WorkPeriodDate = WorkPeriodDateYearOnly | WorkPeriodDateYearMonth | WorkPeriodDateFull;

function workPeriod(
  from?: WorkPeriodDate,
  to?: WorkPeriodDate,
  continueMessage: string = trlMessage('till_today')
): string {
  if (from?.precision === 'year' || to?.precision === 'year') {
    const result = [];
    if (from) {
      result.push(from.year);
    }
    result.push(to ? to.year : continueMessage);
    return result.join(' — ');
  }
  if (!from) {
    return '';
  }
  const actualFrom = from.precision === 'day' ? from : { ...from, day: 1 };
  const actualTo = !to || to.precision === 'day' ? to : { ...to, day: 1 };
  const fromDate = DateTimeHelper.parse(actualFrom);
  const toDate = actualTo ? DateTimeHelper.parse(actualTo) : null;
  const period = workDate(fromDate.epochMilliseconds, toDate?.epochMilliseconds);
  const monthYearFrom = DateTimeHelper.toMonthYearFormat(fromDate);
  const monthYearTo = toDate ? DateTimeHelper.toMonthYearFormat(toDate) : trlMessage('till_today');
  return `${monthYearFrom} —&nbsp;${monthYearTo} ${period ? `(${period})` : ''}`;
}

function workDate(from: number, to?: number): string {
  // к сожалению ещё нет полифила для Intl.DurationFormat, на данный момент нашёл драфт https://github.com/formatjs/formatjs/pull/3892
  const fromDate = new Date(from);
  const toDate = to ? new Date(to) : new Date();

  let months = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
  months -= fromDate.getMonth(); // previous months (not inclusive)
  months += toDate.getMonth() + 1; // to months (inclusive)

  months = months <= 0 ? 0 : months;
  const years = parseInt(String(months / 12), 10);
  months = months - years * 12;

  const result = [];
  if (years) {
    result.push(trlMessage('age-icu', { age: years }));
  }
  if (months) {
    result.push(trlMessage('month-icu', { count: months }));
  }
  return result.join(' ');
}

function name(
  applicant?: { first_name?: string; middle_name?: string; last_name?: string },
  { short = false }: { short?: boolean } = {}
): string {
  if (!applicant) {
    return '';
  }
  const { first_name, middle_name, last_name } = applicant;

  if (!first_name && !last_name) {
    return trlMessage('applicant.name.unknown');
  }

  let parts = [last_name, first_name, middle_name].filter(Boolean) as string[];
  if (short) {
    parts = parts.map((part, index) => {
      if (!index) {
        return part;
      }
      return `${part[0]}.`;
    });
  }
  return parts.join(' ');
}

export const ApplicantHelper = {
  age,
  workPeriod,
  name
};
