<template>
  <base-dropdown placement="top-end">
    <template #default="{ toggle }">
      <base-button @click="toggle">
        {{ $i18n('feedback') }}
      </base-button>
    </template>
    <template #content>
      <dropdown-menu-layout size="bigger">
        <template #main>
          <div :class="$style.feedback">
            <div>
              <div v-html="helpUrlText" />
              <hr v-if="isRuLang" :class="$style.line" />
            </div>
            <template v-if="isRuLang">
              <div v-html="supportEmailText" />
              <div v-html="salesEmailText" />
            </template>
            <div :class="$style.version">
              {{ version }}
            </div>
          </div>
        </template>
      </dropdown-menu-layout>
    </template>
  </base-dropdown>
</template>

<script>
import { appConfig } from '@/services/config/app-config';
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';
import BaseButton from '@/components/button/button.vue';

export default {
  name: 'HelpInfo',
  components: { BaseButton, BaseDropdown, DropdownMenuLayout },
  computed: {
    supportEmail: () => appConfig.get('supportEmail'),
    salesEmail: () => appConfig.get('salesEmail'),
    version: () => appConfig.get('version'),
    isRuLang() {
      return appConfig.get('lang') === 'ru';
    },
    helpUrl() {
      if (!this.isRuLang) {
        return 'support@huntflow.ai';
      }
      const region = (
        appConfig.get('instanceRegion') ||
        appConfig.get('defaultRegion') ||
        ''
      ).toLocaleLowerCase();
      return `huntflow.${region}/help`;
    },
    fullHelpUrl() {
      return this.isRuLang ? `https://${this.helpUrl}` : 'mailto:support@huntflow.ai';
    },
    helpUrlText() {
      return this.$i18n('helpUrlText', {
        linkText: this.helpUrl,
        br: () => '<br />',
        a: (chunks) => `<a href="${this.fullHelpUrl}" class="${this.$style.bigLink}">${chunks}</a>`
      });
    },
    supportEmailText() {
      return this.$i18n('supportEmailText', {
        linkText: this.supportEmail,
        br: () => '<br />',
        a: (chunks) =>
          `<a href="${'mailto:' + this.supportEmail}" class="${this.$style.link}">${chunks}</a>`
      });
    },
    salesEmailText() {
      return this.$i18n('salesEmailText', {
        linkText: this.salesEmail,
        br: () => '<br />',
        a: (chunks) =>
          `<a href="${'mailto:' + this.salesEmail}" class="${this.$style.link}">${chunks}</a>`
      });
    }
  }
};
</script>

<i18n lang="json">
{
  "feedback": {
    "ru_RU": "Помощь",
    "en_US": "Support"
  },
  "helpUrlText": {
    "ru_RU": "Подробное иллюстрированное руководство по&nbsp;использованию Хантфлоу:<br /><a>{linkText}</a>",
    "en_US": "We are here to help:<br /><a>{linkText}</a>"
  },
  "supportEmailText": {
    "ru_RU": "Вопросы по&nbsp;работе с&nbsp;программой:<br /><a>{linkText}</a>",
    "en_US": "Questions about working with the program:<br /><a>{linkText}</a>"
  },
  "salesEmailText": {
    "ru_RU": "Коммерческие вопросы:<br /><a>{linkText}</a>",
    "en_US": "Commercial questions:<br /><a>{linkText}</a>"
  }
}
</i18n>

<style module lang="less">
@import '~@less/common/variables';

.feedback {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.line {
  margin-top: 9px;
  border-color: @borderColor;
  border-width: 1px;
  border-bottom: none;
}
.link {
  color: @linkColor;
  text-decoration: none;
}
.link:hover {
  color: @linkHoverColor;
}
.bigLink {
  composes: link;
  color: @blueColor;
  font-weight: 500;
  font-size: 25px;
  line-height: @headerLineHeight;
}
.version {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #cccccc;
  font-size: @secondaryFontSize;
  line-height: @baseLineHeight;
}
</style>
