import { find, keyBy } from 'lodash';
import { AccountAPI } from '@/api/account';
import { JsonHelper } from '@/util/json-helper';
import tariffs, { ServiceKindCodes } from '@/services/tariffs';
import { ResumeMode } from '@/types/resume-mode';

// initial state
// shape: [{ id, quantity }]
const config = window.Config || {};
const productionCalendar = window.ProductionCalendar || [];

const state = {
  config: {
    isSudo: config.is_sudo,
    timeDiff: config.timeDiff,
    lang: config.lang,
    staticUrl: config.staticUrl,
    uploaderUrl: config.uploaderUrl,
    notifierUrl: config.notifierUrl,
    account: config.account || null,
    permissions: window.UserPermissions || null,
    version: config.version,
    supportEmail: config.supportEmail,
    salesEmail: config.salesEmail,
    accountingEmail: config.accountingEmail,
    projectsEmail: config.projectsEmail,
    importEmail: config.importEmail,
    instanceRegion: config.instanceRegion,
    defaultRegion: config.defaultRegion,
    theme: config.theme,
    currencyCode: window.DefaultCurrencyCode
  },
  organizations: window.Organizations || [],
  organization: window.Organization || null,
  settings: (window.AccountSettings || []).map((item) => {
    // конвертируем сериализованое значение
    const data = JsonHelper.safeJsonParse(item.data);
    return { ...item, data };
  }),
  productionCalendar
};

function settingGetter(settings, settingName, predicate = (data) => data) {
  return settings.some((s) => s.type === settingName && predicate(s.data));
}

export const getters = {
  config: (state) => state.config,
  permissions: (state) => state.config.permissions,
  meMemberId: (state) => state.config.permissions?.account_member_id,
  orgNick: (_, getters) => getters.organization.nick,
  divisionPermissions: (_, getters) =>
    keyBy(
      getters.permissions.permissions.filter(({ permission }) => permission === 'division'),
      'value'
    ),
  statusPermissions: (_, getters) =>
    getters.permissions.permissions.filter(({ permission }) => permission === 'status'),
  hideSalaryPermission: (_, getters) =>
    Boolean(getters.permissions.permissions.find(({ permission }) => permission === 'hide-salary')),
  brandThemesLogo: (_, getters) =>
    getters.settings.find((s) => s.type === 'brand-themes-logo')?.data,
  // TODO: возможно что это следует переименовать в shouldUseRecruiterTeams
  // поскольку возвращается не сами настройки, а значение из него
  useRecruiterTeams: () => {
    return tariffs.isTariffAvailable(ServiceKindCodes.recruiter_teams);
  },
  defaultResumeMode: (_, getters) =>
    getters.settings.find((s) => s.type === 'default-resume-mode')?.data ?? ResumeMode.ORIGINAL,
  useRejectionReasons: (_, { settings }) => settingGetter(settings, 'use-rejection-reasons'),
  hideVacancyDeadline: (_, { settings }) => settingGetter(settings, 'hide-vacancy-deadline'),
  usePersonalData: (_, { settings }) => settingGetter(settings, 'use-personal-data'),
  usePersonalDataAutoremove: (_, { settings }) =>
    settingGetter(settings, 'use-personal-data', (data) => data?.autoremove),
  useOffers: (_, { settings }) => settingGetter(settings, 'use-offers'),
  useSms: (_, { settings }) => settingGetter(settings, 'use-sms'),
  workInDivisions: (_, { settings }) => settingGetter(settings, 'work-in-division'),
  useRegions: (_, { settings }) => settingGetter(settings, 'use-vacancy-regions'),
  useVacancyRequest: (_, { settings }) => settingGetter(settings, 'use-vacancy-request'),
  useMultivacancy: (_, getters) =>
    getters.settings.find((s) => s.type === 'use-multivacancy')?.data?.fields ?? null,
  useRecruitmentEvaluation: (_, getters) => {
    const config = getters.settings.find(
      (setting) => setting.type === 'use-recruitment-evaluation'
    );
    return config?.data;
  },
  useTelephony: (_, { settings }) => settingGetter(settings, 'use-telephony'),
  customVacancyNaming: (_, getters) => getters.settingByType('custom-vacancy-company')?.data,
  dictionaryDeepLevel: (_, getters) => getters.settingByType('division-level-view')?.data,
  maxGroupApplicants: (_, { settingByType }) => settingByType('max-group-applicants')?.data,
  account: (state) => state.config.account,
  organizations: (state) => state.organizations,
  organization: (state) =>
    (state.organization && state.organization.account) ||
    find(state.organizations, (o) => o.nick === state.config.permissions.nick),
  settings: (state) => state.settings,
  settingByType: (state) => (type) => state.settings.find((item) => item.type === type),
  productionCalendar: (state) => state.productionCalendar,
  isNYTheme: ({ config }) => ['Новогодняя', 'Christmas'].includes(config.theme.title)
};

function findIndex(state, { id }) {
  return state.findIndex((v) => v.id === id);
}

const mutations = {
  setSettings(state, settings) {
    state.settings = settings;
  },
  permissionsAdd(state, { permission }) {
    state.config.permissions.permissions.push(permission);
  },
  permissionsRemove(state, { permission }) {
    const index = findIndex(state.config.permissions, permission);

    if (index >= 0) {
      state.config.permissions.permissions.splice(index, 1);
    }
  },
  permissionsChange(state, { permission }) {
    const index = findIndex(state.config.permissions, permission);

    if (index >= 0) {
      state.config.permissions.permissions.splice(index, 1, permission);
    }
  },
  permissionsReset(state, { permissions }) {
    state.config.permissions.permissions = permissions.permissions;
  }
};

const actions = {
  fetchAccountSettings({ commit }) {
    const promise = AccountAPI.fetchSettings();
    promise.then((response) => {
      const settings = response.data.items.map((item) => ({
        ...item,
        data: JsonHelper.safeJsonParse(item.data)
      }));
      commit('setSettings', settings);
    });
    return promise;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
