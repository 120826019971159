<template>
  <dropdown-autocomplete
    :items="items"
    :dropdown-size="dropdownSize"
    :value="selected"
    :scroll-to="scrollTo"
    :disabled="!items.length"
    :shown="focused"
    @change="handleChange"
    @hidden="handleReset"
    @focus="focused = true"
    @blur="handleBlur"
  >
    <template #default="{ show, prev, next, enter, shown }">
      <base-input
        :id="id"
        :value="displayedValue"
        v-bind="$attrs"
        maxlength="5"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :invalid="invalid"
        :class="$style.timeInput"
        autocomplete="off"
        @input="
          displayedValue = $event;
          show();
          handleInput($event);
        "
        @click="show"
        @beforeinput="handleBeforeInput"
        @keydown.down.exact.prevent="
          show();
          next();
        "
        @keydown.up.exact.prevent="
          show();
          prev();
        "
        @keydown.enter.exact="handleEnter($event, shown, enter)"
      />
    </template>
    <template #item-hint="{ item }">
      {{ item.hint }}
    </template>
  </dropdown-autocomplete>
</template>

<script>
import { defineComponent } from 'vue';
import { nanoid } from 'nanoid';

import { TimeHelper } from '@/util/time-helper';
import BaseInput from '@/components/ui/base-input/base-input.vue';
import DropdownAutocomplete from './dropdown-autocomplete.vue';

const start = TimeHelper.parse({ time: '00:00:00' });
const dayMinutes = 24 * 60;
const timeMinutesPeriod = 30; // интервалы каждый 30 минут
const TIME_LIST = Array.from(Array(dayMinutes / timeMinutesPeriod)).map((_, index) => {
  const time = start.add({
    minutes: timeMinutesPeriod * index
  });
  return {
    id: time.toString({
      smallestUnit: 'minute'
    }),
    time,
    name: time.toString({
      smallestUnit: 'minute'
    }),
    hint: ''
  };
});

export default defineComponent({
  name: 'BaseTimepicker',
  components: { DropdownAutocomplete, BaseInput },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: null
    },
    min: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default() {
        return nanoid();
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    readonly: Boolean,
    required: Boolean,
    invalid: Boolean
  },
  emits: ['change'],
  data() {
    return {
      displayedValue: '',
      focused: false,
      inserting: false
    };
  },
  computed: {
    parsedValue() {
      return this.value ? TimeHelper.parse({ time: this.value }) : null;
    },
    minParsed() {
      return this.min ? TimeHelper.parse({ time: this.min }) : null;
    },
    items() {
      const min = this.minParsed;
      if (!min) {
        return TIME_LIST;
      }
      const index = TIME_LIST.findIndex(({ time }) => !time.isBefore(min));
      if (index === -1) {
        return [];
      }
      return TIME_LIST.slice(index).map((item) => {
        let duration = item.time.since(min);
        const isHours = !!duration.hours;
        if (isHours) {
          duration = duration.round({
            roundingMode: 'ceil',
            smallestUnit: 'minute',
            roundingIncrement: 30
          });
        }
        const count = duration.total({
          unit: isHours ? 'hour' : 'minute'
        });
        const hint = isHours
          ? this.$trlMessage('timepicker.hr', { time: count })
          : this.$trlMessage('timepicker.min', { time: count });
        return {
          ...item,
          hint: `(${hint})`
        };
      });
    },
    dropdownSize() {
      return this.min ? 'smaller' : 'date';
    },
    selected() {
      if (!this.parsedValue) {
        return undefined;
      }
      return this.parsedValue.toString({
        smallestUnit: 'minute'
      });
    },
    scrollTo() {
      if (!this.parsedValue) {
        return undefined;
      }
      const value = this.parsedValue.round({
        roundingMode: 'halfExpand',
        unit: 'minute',
        roundingIncrement: 30
      });
      return this.items.find(({ time }) => time.hasSame(value))?.id;
    }
  },
  watch: {
    parsedValue: {
      handler() {
        if (!this.inserting) {
          this.handleReset();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleReset() {
      this.inserting = false;
      this.displayedValue = this.parsedValue
        ? this.parsedValue.toString({
            smallestUnit: 'minute'
          })
        : '';
    },
    handleBeforeInput(event) {
      if (!event.data) {
        return;
      }
      if (!/[\d:]/g.test(event.data)) {
        event.preventDefault();
      }
    },
    handleInput(timeString) {
      this.inserting = true;
      try {
        const [hour = '0', minute = '0'] = timeString.includes(':')
          ? timeString.split(':')
          : (timeString.match(/\d{1,2}/g) ?? []);
        const parsedHour = Number(hour);
        const parsedMinute = Number(minute.padEnd(2, '0'));
        const time = TimeHelper.parse({
          hour: parsedHour > 23 ? 0 : parsedHour,
          minute: parsedMinute > 59 ? 0 : parsedMinute
        });
        this.$emit(
          'change',
          time.toString({
            smallestUnit: 'minute'
          })
        );
      } catch (e) {}
    },
    handleChange(id) {
      this.inserting = false;
      const item = this.items.find((item) => item.id === id);
      this.$emit(
        'change',
        item.time.toString({
          smallestUnit: 'minute'
        })
      );
    },
    handleBlur() {
      this.focused = false;
      this.handleReset();
    },
    handleEnter(event, shown, select) {
      if (!shown) {
        return;
      }
      event.preventDefault();
      select();
    }
  }
});
</script>

<style module>
.timeInput {
  width: 80px;
}
</style>

<i18n lang="json">{}</i18n>
