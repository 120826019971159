<template>
  <rect v-bind="$attrs" rx="2" ry="2" />
</template>

<script>
export default {
  name: 'SkeletonBar'
};
</script>

<i18n lang="json">{}</i18n>
