<template>
  <view-field-text v-bind="$attrs" :value="computedValue" :options="options" v-on="$listeners" />
</template>

<script>
import ViewFieldText from '@/components/vjsf/view-widgets/view-field-text';

export default {
  name: 'FieldTextarea',
  components: {
    ViewFieldText
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    computedValue() {
      const div = document.createElement('div');
      div.innerHTML = this.value;
      // если ставим 0, то сафари возвращает innerText всегда пустой
      div.style.height = '0.1px';
      div.style.overflow = 'hidden';
      document.body.appendChild(div);
      const value = div.innerText?.replaceAll('\n', ' ').trim();
      document.body.removeChild(div);
      return value;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
