<template>
  <elixir-button v-if="isElixirEnabled" />
  <help-info v-else />
</template>

<script>
import { defineComponent } from 'vue';

import { appConfig } from '@/services/config/app-config';
import ElixirButton from '@/components/elixir-button/elixir-button.vue';
import HelpInfo from '@/components/page-layout/help-info.vue';

export default defineComponent({
  name: 'FeedbackButton',
  components: { HelpInfo, ElixirButton },
  computed: {
    isElixirEnabled() {
      return Boolean(appConfig.get('elixir'));
    }
  }
});
</script>

<i18n lang="json">{}</i18n>
