import differenceBy from 'lodash/differenceBy';
import unionBy from 'lodash/unionBy';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import reject from 'lodash/reject';

const createIdLookupTable = (items) => keyBy(items, 'id');

export function createParentLookupTable(items) {
  const filteredList = filter(items, 'parent');
  return groupBy(filteredList, 'parent');
}

function collectDescendants(acc, value, lookup) {
  if (!lookup[value.id]) {
    return acc;
  }

  const children = lookup[value.id].filter((child) => {
    return typeof child.deep === 'number' && typeof value.deep === 'number'
      ? child.deep > value.deep
      : true;
  });

  return children.reduce((result, child) => {
    result.push(child);
    return collectDescendants(result, child, lookup);
  }, acc);
}

function collectAncestors(acc, value, lookup) {
  while (value && value.parent && lookup[value.parent] !== lookup[value.id]) {
    const parent = lookup[value.parent];
    if (!parent) {
      return acc;
    }

    const isSameDeep = parseInt(parent.deep, 10) === parseInt(value.deep, 10);
    if (isSameDeep) {
      value = parent;
      continue;
    }

    value = parent;
    if (value) {
      acc.push(value);
    }
  }

  return acc;
}

export function selectHierarchy(value, values, items) {
  const lookup = createParentLookupTable(items);
  const descendants = collectDescendants([value], value, lookup);

  return unionBy(values, descendants, (item) => `${item.id}_${item.deep}`);
}

export function deselectHierarchy(value, values, items) {
  const lookupId = createIdLookupTable(items);
  const lookupParent = createParentLookupTable(items);
  const ancestors = collectAncestors([], value, lookupId);
  const descendants = collectDescendants([], value, lookupParent);
  const temp = differenceBy(values, ancestors, (item) => `${item.id}_${item.deep}`);

  const result = differenceBy(
    reject(temp, (item) => item.id === value.id && item.deep === value.deep),
    descendants,
    (item) => `${item.id}_${item.deep}`
  );

  return result;
}
