import { ApiLayer } from '@/api/utils/api-layer';
import { AxiosClient, axiosApp } from '@/api/utils/client/axios';
import { CursorList, ToArray } from '@/api/utils/types';
import { MemberType } from '@/types/member-type';
import { FullUser, Invite, User } from '@/types/user';

type CountParams<MemberTypes extends MemberType[], IncludeInvites extends boolean> = {
  member_type?: MemberTypes;
  count_invites?: IncludeInvites;
};

type CountResult<MemberTypes extends MemberType[], IncludeInvites extends boolean> = {
  [Property in MemberTypes[number]]: IncludeInvites extends true
    ? {
        members: number;
        invites: number;
      }
    : {
        members: number;
      };
};

type ListParams = {
  member_type: MemberType | MemberType[];
  next_page_cursor?: string | null;
  count: number;
};

type SearchColleague = Omit<User, 'user_id'> & {
  type: 'member';
  name_highlight: string;
  email_highlight: string;
};

type SearchInvite = Invite & {
  type: 'invite';
  name_highlight: string;
  email_highlight: string;
};

type DownloadExcel = {
  query: string;
  member_type: MemberType | MemberType[];
};

// Те же коворкеры, но не легаси
class ColleagueLayer extends ApiLayer<AxiosClient> {
  count<
    MemberTypes extends MemberType | MemberType[] = [
      MemberType.OWNER,
      MemberType.MANAGER,
      MemberType.WATCHER
    ],
    IncludeInvites extends boolean = false
  >(params: CountParams<ToArray<MemberTypes>, IncludeInvites> = {}) {
    return this.methods.get<CountResult<ToArray<MemberTypes>, IncludeInvites>>('/colleague/count', {
      params
    });
  }

  fetchList(params: ListParams) {
    return this.methods
      .get<CursorList<User>>('/colleague', {
        params
      })
      .then((res) => {
        res.items = res.items.map(enrichedColleague);
        return res;
      });
  }

  getById(id: number) {
    return this.methods.get<FullUser>(`/colleague/${id}`);
  }

  searchList(params: ListParams & { query: string }) {
    return this.methods
      .get<CursorList<SearchColleague | SearchInvite>>('/colleague/search', {
        params
      })
      .then((res) => {
        res.items = res.items.map(enrichedColleague);
        return res;
      });
  }

  downloadExcel(params: DownloadExcel) {
    return this.methods.post(`/colleagues/xlsx`, {}, { params });
  }
}

export function enrichedColleague<Colleague extends User | SearchColleague | Invite | SearchInvite>(
  colleague: Colleague
): Colleague & { id: number } {
  return {
    ...colleague,
    id: 'account_member_id' in colleague ? colleague.account_member_id : colleague.invite_id
  };
}
export const ColleagueAPI = new ColleagueLayer(axiosApp);
