<script>
export default {
  name: 'VueVoid',
  emits: ['mounted'],
  mounted() {
    this.$emit('mounted');
  },
  render() {
    return null;
  }
};
</script>

<i18n lang="json">{}</i18n>
