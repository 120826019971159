<template>
  <svg
    :class="[$style.icon, classname]"
    :aria-label="type"
    :viewBox="icon.viewBox"
    :width="size.width"
    :height="size.height"
    :style="style"
    role="img"
    @click="$emit('click', $event)"
  >
    <use :xlink:href="url" />
  </svg>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    type: { type: String, required: true },
    width: { type: [String, Number], default: undefined },
    height: { type: [String, Number], default: undefined },
    color: {
      type: String,
      default: ''
    },
    block: {
      type: Boolean,
      default: false
    },
    classname: { type: String, default: '' }
  },
  emits: ['click'],
  computed: {
    icon() {
      const type = this.type;
      // eslint-disable-next-line no-restricted-syntax
      const icon = require(`@/svg-sprite/${type}.svg`).default;
      icon.id = icon.id.replace(/-usage$/, '');
      if (icon.viewBox) {
        const [, , w, h] = icon.viewBox.split(' ');
        icon.width = w;
        icon.height = h;
      }
      return icon;
    },
    url() {
      return this.icon.url ?? `#${this.icon.id}`;
    },
    size() {
      if (this.width !== undefined || this.height !== undefined) {
        return {
          width: this.width,
          height: this.height
        };
      }

      return {
        width: this.icon.width,
        height: this.icon.height
      };
    },
    style() {
      const style = {};

      if (this.color) {
        style.color = this.color;
      }

      if (this.block) {
        style.display = 'block';
      }

      return style;
    }
  }
};
</script>

<style module>
.icon {
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
}
</style>

<i18n lang="json">{}</i18n>
