import { ApiLayer } from '@/api/utils/api-layer';
import { AxiosClient, axiosApp } from '@/api/utils/client/axios';
import { WorkplaceInfo, WorkplacePurchasePayload } from '@/types/workspace';

class LicenseLayer extends ApiLayer<AxiosClient> {
  getWorkplaceInfo() {
    return this.methods.get<WorkplaceInfo>('/license/info');
  }

  purchaseWorkplace(payload: WorkplacePurchasePayload) {
    return this.methods.post('/license/request', payload);
  }
}

export const LicenseApi = new LicenseLayer(axiosApp);
