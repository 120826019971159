<script>
import { isGroupItem, isEmptyItem } from './helpers';

export default {
  name: 'AutocompleteMenuItem',
  props: {
    dataKey: {
      type: String,
      default: 'id'
    },
    source: {
      type: Object,
      required: true
    },
    activeItem: {
      type: Object,
      default: undefined
    },
    checkedItems: {
      type: Array,
      default: () => []
    }
  },
  render() {
    if (isGroupItem(this.source)) {
      return this.$slots.groupItem({
        item: this.source
      });
    }
    if (isEmptyItem(this.source)) {
      return this.$slots.emptyItem({});
    }
    return this.$slots.item({
      item: this.source,
      isActive: this.source === this.activeItem,
      isChecked:
        this.checkedItems.find((item) => item[this.dataKey] === this.source[this.dataKey]) !==
        undefined
    });
  }
};
</script>

<i18n lang="json">{}</i18n>
