<template>
  <textarea
    ref="input"
    v-bind="$attrs"
    :aria-invalid="invalid ? 'true' : 'false'"
    :class="className"
    :style="style"
    :value="value"
    v-on="listeners"
  />
</template>

<script>
import autosize from '@/util/autosize/autosize';
import { debounce } from 'lodash';

export default {
  name: 'BaseTextarea',
  model: {
    prop: 'value',
    event: 'inputValue'
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    invalid: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    autoHeight: {
      type: Boolean,
      default: true
    },
    minHeight: {
      type: String,
      default: ''
    },
    submitOnEnter: {
      type: Boolean,
      default: false
    }
  },
  emits: ['inputValue', 'input', 'submit', 'paste'],
  computed: {
    listeners() {
      const handlers = {
        ...this.$listeners,
        input: (event) => {
          this.$emit('inputValue', event.target.value);
          this.$emit('input', event);
        },
        paste: (event) => {
          setTimeout(() => {
            this.handlePaste(event);
          }, 100);
        }
      };

      if (this.submitOnEnter) {
        return {
          ...handlers,
          keydown: (event) => {
            const { key, ctrlKey, metaKey } = event;
            const shouldSubmit = key === 'Enter' && (ctrlKey || metaKey);
            if (shouldSubmit) {
              event.preventDefault();
              event.stopPropagation();
              this.$emit('submit');
              return;
            }
            return this.$listeners.keydown?.(event);
          }
        };
      }
      return handlers;
    },
    className() {
      return {
        'form-control_invalid': this.invalid,
        [this.$style.formControl]: true
      };
    },
    style() {
      return {
        minHeight: this.minHeight
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.focus) {
        this.$refs.input.focus();
      }
      if (this.autoHeight) {
        autosize(this.$refs.input);
      }
    });
    this.handlePaste = debounce(this._handlePaste, 500);
  },
  beforeDestroy() {
    autosize.destroy(this.$refs.input);
  },
  methods: {
    _handlePaste(event) {
      this.$emit('paste', event.target.value);
    },
    autosize() {
      this.$nextTick(() => {
        autosize.update(this.$refs.input);
      });
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font';

.formControl {
  border-radius: var(--Radius-x2, 8px);
  border: 1px solid rgba(0, 0, 0, 0.16);
  padding: var(--Space-400, 16px);
  margin: 0;
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  resize: vertical;
  vertical-align: top;
  outline: 0 none;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  background-image: none;

  &:focus {
    border-color: #2cc8df;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: default;
    /* <3 Safari */
    -webkit-text-fill-color: var(--content-primary, #050505);
  }
}

// O Lord, forgive me the sinner
// Это хак для safari, в котором при padding > 10px не работает ресайз
// Для этого мы добавляем border нужной ширины, скрываем иконку ресайза и добавляем «ее» фоновым изображением
@media not all and (min-resolution: 0.001dpcm) {
  .formControl[resize] {
    padding-left: 0;
    padding-right: 0;
    border-left: solid 16px;
    border-right: solid 16px;
    border-color: transparent;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.16);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none'%3E%3Cpath fill='%23666' fill-rule='evenodd' d='m1 8 7-7-.707-.707-7 7L1 8Zm4 0 3-3-.707-.707-3 3L5 8Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% + 14px) 100%;

    &:focus {
      box-shadow: 0 0 0 1px #2cc8df;
      border-color: transparent;
    }

    &::-webkit-resizer {
      display: none;
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
