import sourceAPI from '@/api/source';
import { trlMessage } from '@/services/i18n';

import { sortByName, otherReasonLast, isUser } from './sources-helpers';

export default {
  namespaced: true,
  state: {
    sources: (
      window.AccountSources ||
      // На странице настроек нет AccountSources переменной
      // TODO сделать
      window.Organization?.account_sources ||
      []
    ).concat(
      // Виртуалный источник, живущий на фронте
      {
        id: null,
        name: trlMessage('Other')
      }
    )
  },
  getters: {
    byId: (state) => (id) => state.sources.find((el) => el.id === id),
    sources(state) {
      return state.sources
        .filter((el) => !el.removed)
        .sort((a, b) => otherReasonLast(a, b) ?? sortByName(a, b));
    },
    activeSources(_, { sources }) {
      return sources.filter((source) => source.active);
    }
  },
  mutations: {
    setSources(state, sources) {
      state.sources = sources;
    },
    updateSources(state, sources) {
      // Потому что от бека на пост приходять только пользовательские источники
      // возможно это лучше перенести в action
      state.sources = state.sources.filter((el) => !isUser(el)).concat(sources);
    }
  },

  actions: {
    reload(store) {
      return sourceAPI.getList().then(({ items }) => {
        store.commit('setSources', items);
      });
    },
    update: (store, { sources, replacements }) =>
      sourceAPI
        .update({
          sources,
          replacements
        })
        .then(({ items }) => {
          store.commit('updateSources', items);
        })
  }
};
