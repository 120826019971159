<template>
  <component
    :is="component"
    v-bind="linkAttributes"
    :class="[$style.link, active ? $style.linkActive : '']"
    @click="$emit('click')"
  >
    <base-icon
      v-if="showLeftArrow"
      type="small-chevron-right-12"
      :class="[$style.svgLeft, rotateArrow ? $style.svgRotate : '']"
    />
    <base-icon v-if="icon" :type="icon" :class="$style.svg" />
    <span :class="$style.title">
      <slot />
    </span>
    <button v-if="showRightArrow" :class="$style.button">
      <base-icon type="chevron-down-20" />
    </button>
  </component>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'MenuChapter',
  components: { BaseIcon },
  props: {
    url: {
      type: [String, Object],
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    showRightArrow: {
      type: Boolean,
      default: false
    },
    showLeftArrow: {
      type: Boolean,
      default: false
    },
    rotateArrow: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  data() {
    return {};
  },
  computed: {
    isLink() {
      return typeof this.url === 'string';
    },
    component() {
      if (!this.url) {
        return 'div';
      }
      return this.isLink ? 'a' : 'router-link';
    },
    linkAttributes() {
      if (!this.url) {
        return {};
      }
      return this.isLink ? { href: this.url } : { to: this.url };
    }
  }
};
</script>

<style module>
.link {
  color: $sidebarTextColor;
  text-decoration: none;
  padding: 8px 12px;
  font-size: $baseFontSize;
  line-height: 24px;
  display: flex;
  align-items: center;
  border-radius: 12px;

  cursor: pointer;

  .svgLeft {
    opacity: 0;
  }

  &:not(.linkActive):hover {
    color: $sidebarTextColorHover;
    background-color: $sidebarTextBackgroundHover;

    .svgLeft {
      opacity: 1;
      position: relative;
      left: -2px;
    }
  }
}

.svg {
  margin-right: 12px;
  flex-shrink: 0;
}

.svgLeft {
  margin-left: -22px;
  margin-right: 12px;
  transform: rotate(90deg);
}

.svgRotate {
  transform: rotate(0deg);
}

.linkActive {
  color: $sidebarTextColorActive;
  background-color: $sidebarTextBackgroundActive;
  cursor: default;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  color: currentColor;
  border: 0;
  padding: 0;
  border-radius: 4px;
  margin-left: 8px;
  position: relative;
  overflow: hidden;
  background-color: transparent;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: currentColor;
    opacity: 0.12;
  }
}
</style>

<i18n lang="json">{}</i18n>
