<template>
  <div :class="$style.hint">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FieldHint'
};
</script>

<style module>
.hint {
  color: $gray-500;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
.hint a {
  color: inherit;
}
.hint a:hover {
  color: $linkHoverColor;
}
</style>

<i18n lang="json">{}</i18n>
