import { AppHelper } from '@/entries/helpers/app-helper';
import NpsElementWrapper from './nps-element-wrapper.vue';

export default () => {
  const mountElement = document.getElementById('huntflow-nps-mount-element');
  if (!mountElement) {
    return;
  }

  const script = document.querySelector('script[src*="nps/hunt-nps.js"]');

  // скрипт используется с defer, поэтому он может загрузится не сразу
  script.onload = function () {
    const { surveyApiPath, answerApiPath, surveyStatusApiPath, answeredId } = mountElement.dataset;
    const props = {
      surveyApiPath,
      answerApiPath,
      surveyStatusApiPath,
      initialAnsweredId: answeredId
    };

    AppHelper.createApp(NpsElementWrapper, {
      props,
      selector: mountElement,
      route: {
        name: 'nps-component',
        params: props
      }
    });
  };
};
