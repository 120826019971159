<template>
  <div>
    <template
      v-for="parent in tree.parents"
      :key="parent.multiple ? 'group' + parent.id : parent.id"
    >
      <vacancy-group
        v-if="parent.multiple"
        :children="tree.children[parent.id] || []"
        :vacancy="parent"
        :link-preset="linkPresets[parent.id]"
        :is-expanded="expandFlags[parent.id]"
        :selected-vacancy-id="autoSelectedVacancyId || selectedVacancyId"
        @toggle="handleToggle(parent)"
      >
        <template #default="{ children }">
          <vacancy-group-items :children="children" :parent="parent">
            <template #parent>
              <vacancy-item
                v-if="shouldShowMultivacancy(parent.id)"
                :vacancy="parent"
                :is-active="isSelected(parent.id)"
                :is-parent="false"
                :href="getHref(parent.id)"
              />
            </template>
            <template #item="{ item }">
              <vacancy-item
                :vacancy="item"
                :is-active="isSelected(item.id)"
                :is-parent="false"
                :href="getHref(item.id)"
                :company-name="item.childName"
                :short-company-name="item.childShortName"
              />
            </template>
          </vacancy-group-items>
        </template>
      </vacancy-group>
      <vacancy-item
        v-else
        :vacancy="parent"
        :is-active="isSelected(parent.id)"
        :href="getHref(parent.id)"
      />
    </template>
  </div>
</template>

<script>
import omit from 'lodash/omit';
import VacancyItem from './vacancies-list/item';
import VacancyGroupItems from './vacancies-list/group-items';
import VacancyGroup from './vacancies-list/group';
import { StorageHelper } from '@/util/storage-helper';

export default {
  name: 'VacanciesTree',
  components: {
    VacancyItem,
    VacancyGroupItems,
    VacancyGroup
  },
  props: {
    isWatcher: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Object,
      required: true
    },
    linkPresets: {
      type: Object,
      required: true
    },
    tree: {
      type: Object,
      required: true
    },
    selectedVacancyId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      autoSelectedVacancyId: undefined,
      expandFlags: {}
    };
  },
  watch: {
    tree: {
      handler(tree) {
        const vacancyToParentMap = Object.entries(tree.children).reduce(
          (acc, [parentId, children]) => {
            children.forEach((child) => {
              acc.set(child.id, Number(parentId));
            });
            return acc;
          },
          new Map()
        );
        this.vacancyToParentMap = vacancyToParentMap;
        this.parentsSet = new Set(tree.parents.map((vacancy) => vacancy.id));
      },
      immediate: true
    },
    selectedVacancyId: {
      handler(id) {
        if (id !== this.autoSelectedVacancyId) {
          this.autoSelectedVacancyId = undefined;
        }
        this.$nextTick(() => {
          // немного паранойи, на случай если это исполнится раньше построения this.vacancyToParentMap
          // (по-идее не должно, ибо nextTick)
          if (!this.vacancyToParentMap) {
            return;
          }
          if (this.parentsSet.has(id)) {
            // был выбран родитель - сбрасываем настройку
            removeAutoselectVacancy(id);
            return;
          }
          const parentVacancyId = this.vacancyToParentMap.get(id);
          saveAutoselectVacancy(id, parentVacancyId);
        });
      },
      immediate: true
    }
  },
  methods: {
    getHref(id) {
      const linkPreset = this.linkPresets[id] || {};
      const { status, applicant } = linkPreset;
      if (!status) {
        return `#/vacancy/${id}`;
      }
      if (applicant && status !== 'workon') {
        return `#/vacancy/${id}/filter/${status}/id/${applicant}`;
      }
      return `#/vacancy/${id}/filter/${status}`;
    },
    isSelected(id) {
      return this.autoSelectedVacancyId === id || this.selectedVacancyId === id;
    },
    handleToggle(parent) {
      const parentVacancyId = parent.id;
      const children = this.tree.children[parentVacancyId] || [];
      const newValue = !this.expandFlags[parentVacancyId];
      if (newValue) {
        // по-умолчанию при раскрытии выделяем либо родителя, либо первую дочку
        const vacancyToAutoselect = this.shouldShowMultivacancy(parentVacancyId)
          ? parent
          : children[0];

        // но если ранее выбирали конкретную дочку, то пытаемся перейти на неё
        let savedAutoSelectedVacancyId = getAutoselectVacancy(parentVacancyId);
        if (savedAutoSelectedVacancyId) {
          // проверяем на доступность вакансии
          if (
            parentVacancyId !== savedAutoSelectedVacancyId &&
            children.every((child) => child.id !== savedAutoSelectedVacancyId)
          ) {
            // вакансии нет в списке
            savedAutoSelectedVacancyId = null;
            saveAutoselectVacancy(null, parentVacancyId);
          }
        }

        this.autoSelectedVacancyId = savedAutoSelectedVacancyId || vacancyToAutoselect?.id;
        if (this.autoSelectedVacancyId) {
          // у группы нет линки, нужно программно обновить урл,
          // но в nextTick, чтобы не было моргания выделения
          this.$nextTick(() => {
            window.location.href = this.getHref(this.autoSelectedVacancyId);
          });
        }
      }
      this.expandFlags[parentVacancyId] = newValue;
    },
    hasVacancyPermission(vacancyId) {
      return Boolean(this.permissions[vacancyId]);
    },
    shouldShowMultivacancy(vacancyId) {
      if (this.isWatcher) {
        return this.hasVacancyPermission(vacancyId);
      }
      return true;
    }
  }
};

const AUTOSELECT_VACANCY_KEY = 'autoselected_vacancy';

function saveAutoselectVacancy(vacancyId, parentVacancyId) {
  if (vacancyId === parentVacancyId || !parentVacancyId) {
    return false;
  }
  const currSettings = StorageHelper.getJSON(AUTOSELECT_VACANCY_KEY, { namespaced: true }) || {};
  currSettings[parentVacancyId] = vacancyId;
  StorageHelper.setJSON(AUTOSELECT_VACANCY_KEY, currSettings, { namespaced: true });
  return true;
}

function removeAutoselectVacancy(parentVacancyId) {
  if (!parentVacancyId) {
    return false;
  }
  let currSettings = StorageHelper.getJSON(AUTOSELECT_VACANCY_KEY, { namespaced: true }) || {};
  currSettings = omit(currSettings, parentVacancyId);
  StorageHelper.setJSON(AUTOSELECT_VACANCY_KEY, currSettings, { namespaced: true });
  return true;
}

function getAutoselectVacancy(parentVacancyId) {
  const currSettings = StorageHelper.getJSON(AUTOSELECT_VACANCY_KEY, { namespaced: true }) || {};
  return currSettings[parentVacancyId];
}
</script>

<style module lang="less"></style>

<i18n lang="json">{}</i18n>
