<template>
  <div :class="$style.itemWrapper">
    <template v-if="collapsible">
      <template
        v-if="
          itemProps.previousDeep >= itemProps.deep &&
          itemProps.previousCanCollapsed &&
          !itemProps.previousCollapsed
        "
      >
        <div :class="$style.beforeItem">
          <slot :id="itemProps.previousItem.id" name="after-level" />
        </div>
      </template>
      <template v-else-if="itemProps.previousDeep > itemProps.deep">
        <div :class="$style.beforeItem">
          <slot :id="previousParentId" name="after-level" />
        </div>
      </template>
    </template>
    <select-collapsed-item
      :highlight="itemProps.highlight"
      :selected="itemProps.selected"
      :disabled="itemProps.disabled"
      :deep="itemProps.deep"
      :active="itemProps.active"
      :can-collapsed="itemProps.canCollapsed"
      :has-selected-children="itemProps.hasSelectedChildren"
      :collapsed="itemProps.collapsed"
      :multiple="itemProps.multiple"
      :available="itemProps.available"
      :use-gap="false"
      @click="itemProps.toggleCollapse"
    >
      <template #default>
        <slot name="item-title" v-bind="itemProps" />
      </template>
      <template #hint>
        <slot name="item-hint" v-bind="itemProps" />
      </template>
      <template #subtitle>
        <slot name="item-subtitle" v-bind="itemProps" />
      </template>
      <template #after-content>
        <slot name="after-content" v-bind="itemProps" />
      </template>
    </select-collapsed-item>
    <template v-if="!itemProps.nextItem.id">
      <div :class="$style.afterItem">
        <template v-if="!collapsible">
          <slot :id="null" name="after-level" />
        </template>
        <template v-else-if="itemProps.canCollapsed && !itemProps.collapsed">
          <slot :id="itemProps.item.id" name="after-level" />
        </template>
        <template v-else>
          <slot :id="parentId" name="after-level" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import SelectCollapsedItem from '@/components/list-item/select-collapsed-item';

export default {
  name: 'CollapsedItem',
  components: { SelectCollapsedItem },
  props: {
    itemProps: {
      type: Object,
      required: true
    },
    collapsible: Boolean,
    parentId: {
      type: Number,
      default: null
    },
    previousParentId: {
      type: Number,
      default: null
    }
  }
};
</script>

<style module>
.itemWrapper {
  width: 100%;
}
.beforeItem {
  position: relative;
  top: 1px;
}
.afterItem {
  position: relative;
  top: -1px;
}
</style>

<i18n lang="json">{}</i18n>
