<template>
  <label :class="$style.container">
    <slot />
  </label>
</template>

<script>
export default {
  name: 'BaseLabel'
};
</script>

<style module>
.container {
  color: #050505;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
</style>

<i18n lang="json">{}</i18n>
