export function normalizeQueryParams(params) {
  return preserveNulls(params);
}

// see https://npm.io/package/qs
function preserveNulls(data) {
  return run(data);
  function run(currData) {
    if (currData === null) {
      return 'null';
    }
    if (Array.isArray(currData)) {
      return currData.map(run);
    }
    if (typeof currData === 'object') {
      return Object.fromEntries(Object.entries(currData).map(([key, value]) => [key, run(value)]));
    }
    return currData;
  }
}
