<template>
  <span :class="$style.error">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BaseError'
};
</script>

<style module>
.error {
  color: var(--Red-500, var(--content-danger, #f00));
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  a {
    color: var(--Red-500, var(--content-danger, #f00));
  }
}
</style>

<i18n lang="json">{}</i18n>
