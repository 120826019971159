<template>
  <span :class="className" v-html="text" />
</template>

<script>
import { VacancyRequestStatus } from '@/types/vacancy-request-status';

export default {
  name: 'RequestListItemStatus',
  props: {
    quota: {
      type: Object,
      required: true
    }
  },
  computed: {
    value() {
      return this.quota.status;
    },
    started() {
      // для заявок, взятых в работу не существует своего статуса (они "approved"),
      // но у них есть связь с вакансией
      return Boolean(this.quota.vacancy);
    },
    progress() {
      if (this.value !== VacancyRequestStatus.PENDING || !this.quota.states?.length) {
        return null;
      }

      return {
        passed: this.quota.states.filter(
          (state) => state.status?.toLocaleLowerCase?.() === VacancyRequestStatus.APPROVED
        ).length,
        total: this.quota.states.length
      };
    },
    isApproved() {
      return this.value === VacancyRequestStatus.APPROVED;
    },
    isPending() {
      return this.value === VacancyRequestStatus.PENDING;
    },
    isRejected() {
      return this.value === VacancyRequestStatus.REJECTED;
    },
    text() {
      if (this.isApproved) {
        return this.started
          ? this.$trlMessage('vacancy_request.status_badge.work_started')
          : this.$trlMessage('vacancy_request.status_badge.approved');
      } else if (this.isPending) {
        const str = this.$trlMessage('vacancy_request.status_badge.pending');
        const progress = this.progress;
        return progress ? `${str}&nbsp${progress.passed}&#47;${progress.total}` : str;
      } else if (this.isRejected) {
        return this.$trlMessage('vacancy_request.status_badge.rejected');
      }
      return undefined;
    },
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.approved]: this.isApproved && !this.started,
        [this.$style.started]: this.isApproved && this.started,
        [this.$style.rejected]: this.isRejected,
        [this.$style.pending]: this.isPending
      };
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.root {
  display: inline-flex;
  padding: 3px 8px 1px 8px;
  border-radius: 3px;
  line-height: 14px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}

.approved {
  color: @btnGreen;
  background: fade(@btnGreen, 8%);
}

.pending {
  @pendingBadgeColor: #ec7e00;
  color: @pendingBadgeColor;
  background: fade(@pendingBadgeColor, 8%);
}

.rejected {
  color: @redColor;
  background: fade(@redColor, 8%);
}

/*== not a real status ===*/
.started {
  /* какая дизайн система, такая и семантика */
  color: @linkColor;
  background: fade(@linkColor, 8%);
}
</style>

<i18n lang="json">{}</i18n>
