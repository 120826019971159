<script>
import { parentField } from '@/components/base-list/composables/constants';
import { normalizeChildren } from '@/util/component-helpers';

export default {
  name: 'HierarchyCollapsed',
  props: {
    hierarchyMap: {
      type: Object,
      required: true
    }
  },
  emits: ['collapsed'],
  data() {
    return {
      collapsedAbility: {},
      collapsedState: {}
    };
  },
  computed: {
    visibilityState() {
      const parentChildMap = this.hierarchyMap.parentChildMap;
      const directChildrenMap = this.hierarchyMap.directChildrenMap;
      const collapsedState = this.collapsedState;

      const state = {};
      const items = Object.entries(parentChildMap).filter(
        ([, parentId]) => !(parentId in parentChildMap)
      );
      while (items.length) {
        const [id, parentId] = items.pop();

        items.push(...directChildrenMap[id].map((childId) => [childId, id]));

        if (!parentId) {
          state[id] = true;
          continue;
        }
        state[id] = state[parentId] && !collapsedState[parentId];
      }
      return state;
    }
  },
  watch: {
    hierarchyMap: {
      immediate: true,
      handler(hierarchyMap) {
        const collapsedAbility = {};
        const collapsedState = {};
        Object.entries(hierarchyMap.deepChildrenMap).forEach(([id, children]) => {
          collapsedAbility[id] = !!children.length;
          if (children.length) {
            collapsedState[id] = this.collapsedState[id] ?? true;
          }
        });

        this.collapsedAbility = collapsedAbility;
        this.collapsedState = collapsedState;
      }
    }
  },
  methods: {
    toggleCollapse(id) {
      if (id in this.collapsedState) {
        this.collapsedState[id] = !this.collapsedState[id];
        this.$emit('collapsed', {
          isCollapsed: this.collapsedState[id],
          id
        });
      }
    },
    collapse(id) {
      if (id in this.collapsedState) {
        this.collapsedState[id] = true;
        this.$emit('collapsed', {
          isCollapsed: true,
          id
        });
      }
    },
    expand(id) {
      if (id in this.collapsedState) {
        this.collapsedState[id] = false;
        this.$emit('collapsed', {
          isCollapsed: false,
          id
        });
      }
    },
    filterCollapsedItems(items) {
      return items.filter((item) => {
        if (this.visibilityState[item.id]) {
          return true;
        }
        if (!item.hasOwnProperty(parentField)) {
          return false;
        }
        const parent = item[parentField];
        return parent in this.hierarchyMap.parentChildMap && !this.collapsedState[parent];
      });
    }
  },
  render() {
    const children = normalizeChildren(this, {
      toggleCollapse: this.toggleCollapse,
      collapse: this.collapse,
      expand: this.expand,
      filterCollapsedItems: this.filterCollapsedItems,
      collapsedState: this.collapsedState,
      collapsedAbility: this.collapsedAbility,
      visibilityState: this.visibilityState
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
