<template>
  <dropdown-autocomplete
    :items="$options.searchTypes"
    :value="selectedOption.id"
    dropdown-size="larger"
    data-qa="typeSelect"
    @change="onChange"
  >
    <template #before>
      <vue-void />
    </template>
    <template #toggle="{ selectedItems, toggle }">
      <button type="button" :class="$style.typeButton" data-qa="toggleButton" @click="toggle">
        {{ selectedItems?.name }}
      </button>
    </template>
  </dropdown-autocomplete>
</template>

<script>
import DropdownAutocomplete from '@/components/base-autocomplete/dropdown-autocomplete.vue';
import VueVoid from '@/components/vue-void.vue';
import { trlMessage } from '@/services/i18n';
import { SearchTypes } from '@/modules/search/types/search-types';

export default {
  name: 'TypeSelect',
  components: { DropdownAutocomplete, VueVoid },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  searchTypes: [
    {
      id: SearchTypes.APPLICANTS,
      name: trlMessage('Applicants')
    },
    {
      id: SearchTypes.VACANCIES,
      name: trlMessage('Vacancies')
    }
  ],
  computed: {
    selectedOption() {
      return (
        this.$options.searchTypes.find(({ id }) => id === this.type) || this.$options.searchTypes[0]
      );
    }
  },
  methods: {
    onChange(option) {
      this.$emit('input', option);
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font.less';
@import '~@less/common/mixins/input.less';

.typeButton {
  color: #929097;
  .font_bold();
  font-size: 11px;
  line-height: 14px;
  padding: 10px 15px 10px 9px;
  text-transform: uppercase;
  display: block;
  box-sizing: border-box;
  .select-decorator(#929097, 10px);
  user-select: none;
  cursor: default;
  background: none;
  border: none;
}
</style>

<i18n lang="json">{}</i18n>
