import { RejectionReasonsApi } from '@/api/rejection-reasons';
import { compareByName } from '@/libs/compators/compators';

export default {
  namespaced: true,
  state: {
    enabled: null,
    rejections: window.AccountRejections || []
  },
  getters: {
    enabled(state, _, __, rootGetters) {
      return state.enabled ?? rootGetters['config/useRejectionReasons'];
    },
    byId: (state) => (id) => state.rejections.find((el) => el.id === id),
    rejections(state) {
      return state.rejections.sort((a, b) => otherReasonLast(a, b) ?? compareByName(a, b));
    }
  },
  mutations: {
    updateRejections(state, rejections) {
      state.rejections = rejections.concat(
        // Потому что от бека на пост не приходит "Другие причины"
        // возможно это лучше перенести в action
        state.rejections.find(isOther)
      );
    },
    updateEnabled(state, enabled) {
      state.enabled = enabled;
    }
  },

  actions: {
    update: (store, { rejections, enabled }) =>
      RejectionReasonsApi.update({
        rejections,
        enabled
      }).then((response) => {
        store.commit('updateRejections', response.items);
        store.commit('updateEnabled', enabled);
      })
  }
};

function isOther(reason) {
  return reason.id === null;
}

function otherReasonLast(a, b) {
  if (isOther(a)) {
    return 1;
  }
  if (isOther(b)) {
    return -1;
  }
}
