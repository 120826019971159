import { Link as DefaultLink } from '@tiptap/extension-link';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customLink: {
      // Отличие от `setLink` - если ничего не выделено, то текст для ссылки указывается такой же, как и сама ссылка
      setLinkCustom: (attributes: { href: string; target?: string | null }) => ReturnType;
    };
  }
}

export const Link = DefaultLink.extend({
  addCommands() {
    const commands = this.parent?.() || {};

    return {
      ...commands,
      setLinkCustom:
        (attributes) =>
        ({ tr, chain }) => {
          if (tr.selection.empty) {
            return chain()
              .insertContent(attributes.href)
              .setTextSelection({
                from: tr.selection.from - attributes.href.length,
                to: tr.selection.to
              })
              .setLink(attributes)
              .setTextSelection(tr.selection.to)
              .run();
          }
          return chain().setLink(attributes).run();
        }
    };
  }
});
