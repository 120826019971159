<template>
  <div ref="container" :class="$style.freebusy" :style="containerStyle">
    <div ref="scrollContainer" :class="$style.scrollContainer" :style="scrollContainerStyle">
      <div :class="$style.dataContainer">
        <div :class="[$style.attendersHeader]">
          <div ref="header" :class="[$style.attendersRow]">
            <div :class="$style.timeCell"></div>
            <template v-if="attenders.length">
              <div
                v-for="j in attenders.length"
                :key="'attenderName' + j"
                :class="[$style.attenderCell, $style.attenderNameCell]"
              >
                {{ attendeesList[j - 1].name }}
              </div>
            </template>
            <div v-else :class="[$style.attenderCell, $style.attenderNameCell]"></div>
          </div>
          <div :class="[$style.attendersRow, $style.attenderCell]">
            <div
              v-for="(busySlot, index) in busySlotsAllDay"
              :key="'busySlot' + index"
              :class="$style.allDaySlot"
              :style="busySlot.style"
            >
              <span :class="$style.busySlotInterval">{{ busySlot.interval }}</span>
            </div>
          </div>
        </div>

        <div v-for="i in 24" :key="i" :class="[$style.attendersRow, $style.attendersBody]">
          <div :class="$style.timeCell" :data-hour="i - 1">{{ i - 1 }}:00</div>
        </div>
        <div
          v-for="(busySlot, index) in busySlotsData"
          :key="'busySlot' + index"
          :class="$style.busySlot"
          :style="busySlot.style"
        >
          <span :class="$style.busySlotInterval">{{ busySlot.interval }}</span>
        </div>
        <div :class="$style.time" :style="timeStyle"></div>
      </div>
    </div>
  </div>
</template>

<script>
// Теоретически можно сообразить ещё смёрживание интервалов одного участника
// при их наложении друг на друга
// но кажется это не особо жизненный кейс

// Также скипнул пересчёт скролла при ресайзе.
// Пока компонент не в резине - нинад. Потом изян добавить.

import { computed, onMounted, ref, unref } from 'vue';

import { useGlobal } from '@/composables/use-global';
import { useStore } from 'vuex';
import {
  isAllDayEvent,
  isTwoDaysEvent,
  time2y,
  calculateEventHeight,
  calculateEventTop
} from './model';
import { ROW_HEIGHT, TIMECELL_WIDTH } from './config';

export default {
  name: 'VFreebusy',
  props: {
    /**
     * Начало выбранного интервала нового события
     */
    start: {
      type: Date,
      required: true
    },
    /**
     * Конец выбранного интервала нового события
     */
    end: {
      type: Date,
      required: true
    },
    attenders: {
      // TODO: переименовать в attendees для коньсистенции
      type: Array,
      required: true
    },
    busySlots: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { $trlMessage } = useGlobal();
    const store = useStore();

    const scrollContainer = ref(null);

    const accountRef = computed(() => store.getters['config/account']);
    const account = unref(accountRef);

    // вычисление ширины скролла,
    // чтобы скрыть вертикальную прокрутку (см. "right")
    // и отобразить горизонтальную прокрутку (см. "padding-bottom")
    const getScrollSize = (node) => ({
      vertical: node.offsetWidth - node.clientWidth,
      horizontal: node.offsetHeight - node.clientHeight
    });

    const hScroll = ref(0);
    const vScroll = ref(0);
    onMounted(() => {
      const scrollSize = getScrollSize(scrollContainer.value);
      scrollContainer.value.scrollTop = time2y(props.start, ROW_HEIGHT) - 10;

      hScroll.value = scrollSize.horizontal;
      vScroll.value = scrollSize.vertical;
      /*
       * Кажется c "scrollSize.vertical" - это косяк легаси-имплементации.
       * Там сейчас так: есть горизонтальный скролл - нет вертикального, нет горизонтального скролла - есть вертикальный
       * Если эта логика должна сохраниться, то будет что-то вроде
       * vScroll.value = hScroll.value ? scrollSize.vertical : 0;
       */
    });
    const busySlotsData = computed(() => {
      return props.attenders.reduce((acc, { email }, index) => {
        const left = `calc((100% - ${TIMECELL_WIDTH}px) * ${index} / ${props.attenders.length})`;
        const width = `calc((100% - ${TIMECELL_WIDTH}px) / ${props.attenders.length} - 10px)`;
        acc.push(
          ...props.busySlots[email].map((busySlot) => {
            if (isAllDayEvent(busySlot)) {
              return {
                allDay: true,
                interval: $trlMessage('freebusy.all_day.event'),
                style: {
                  left,
                  width
                }
              };
            }
            const formatTime = (date) => {
              const h = date.getHours();
              const m = date.getMinutes();

              const dateDay = date.getDate();

              if (isTwoDaysEvent(busySlot)) {
                if (dateDay === props.start.getDate()) {
                  return `${h <= 9 ? '0' + h : h}:${m <= 9 ? '0' + m : m}`;
                }
                if (dateDay > props.start.getDate()) {
                  return `0:00`;
                }
                if (dateDay < props.start.getDate()) {
                  return `0:00`;
                }
              }
              return `${h <= 9 ? '0' + h : h}:${m <= 9 ? '0' + m : m}`;
            };
            const startInterval = formatTime(busySlot.start);
            const endInterval = formatTime(busySlot.end);

            const top = `${calculateEventTop(busySlot, ROW_HEIGHT, props.start)}px`;
            const height = `${calculateEventHeight(busySlot, ROW_HEIGHT, props.start)}px`;

            return {
              allDay: false,
              interval: `${startInterval} — ${endInterval}`,
              style: {
                left,
                top,
                width,
                height
              }
            };
          })
        );
        return acc;
      }, []);
    });

    return {
      scrollContainer,
      containerStyle: computed(() => `padding-bottom: ${hScroll.value}px`),
      scrollContainerStyle: computed(() => `right: -${vScroll.value}px`),
      attendeesList: computed(() =>
        props.attenders.map((attendee) => {
          const isMe = attendee.member === account.id;
          return {
            ...attendee,
            name: isMe ? $trlMessage('Me') : attendee.displayName || attendee.name || attendee.email
          };
        })
      ),
      timeStyle: computed(() => {
        const top = time2y(props.start, ROW_HEIGHT);
        const bottom = time2y(props.end, ROW_HEIGHT);
        const height = bottom - top > 0 ? bottom - top : 50;
        return `top: ${top}px; height: ${height}px`;
      }),
      busySlotsData: computed(() => {
        return busySlotsData.value.filter((el) => el.allDay === false);
      }),
      busySlotsAllDay: computed(() => {
        return busySlotsData.value.filter((el) => el.allDay === true);
      })
    };
  }
};
</script>

<style module>
$rowHeight: 50px;
$headerRowHeight: 27px;
$timeCellWidth: 45px;

.freebusy {
  position: relative;
  box-sizing: content-box;
  height: 312px;
  overflow: hidden;
}

.scrollContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.dataContainer {
  display: inline-block;
  position: relative;
  min-width: 100%;
}

.attendersHeader {
  position: sticky;
  top: 0;
  z-index: 3;
}

.attendersRow {
  display: flex;
}

.attenderCell,
.timeCell {
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid $borderColor;
}

.attendersHeader {
  .timeCell,
  .attenderCell {
    height: $headerRowHeight;
  }
}
.attendersRow + .attenderCell {
  width: 100%;
}
.attendersBody .timeCell {
  flex: 1;
  height: $rowHeight;
}

.attendersBody .timeCell[data-hour='23'] {
  border-bottom: none;
}

.attenderNameCell {
  @mixin ellipsis;
  padding-right: 10px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
}

.timeCell {
  flex: none;
  flex-basis: $timeCellWidth;
  width: $timeCellWidth;
  font-size: 11px;
}

.attenderCell {
  flex-shrink: 0;
  flex-grow: 1;
  width: 200px;
}

.busySlot {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 2;
  margin-top: $headerRowHeight;
  margin-left: $timeCellWidth;
  overflow: hidden;
  padding: 2px 5px;
  background: $darkViolet;
}

.allDaySlot {
  position: absolute;
  height: 27px;
  top: 27px;
  left: 0;
  z-index: 2;
  overflow: hidden;
  margin-left: $timeCellWidth;
  padding: 2px 5px;
  background: $darkViolet;
}

.busySlotInterval {
  display: block;
  font-size: 11px;
  line-height: 14px;
  color: white;
}

.time {
  position: absolute;
  left: $timeCellWidth;
  right: 0;
  margin-top: $headerRowHeight;
  z-index: 1;
  background: rgba(34, 187, 210, 0.15);
  border: 5px solid rgba(34, 187, 210, 0.25);
}
</style>

<i18n lang="json">{}</i18n>
