let _baseTitle = document.title;
document.title = _baseTitle;
const titlesStack: string[] = [_baseTitle];

function setTitle(title: string | null) {
  document.title = title ? join(title, _baseTitle) : _baseTitle;
}

function join(...parts: string[]): string {
  return parts.join(' - ');
}

export default {
  join,
  get baseTitle(): string {
    return _baseTitle;
  },
  set baseTitle(baseTitle: string) {
    if (titlesStack[0] === _baseTitle) {
      titlesStack[0] = baseTitle;
    }
    _baseTitle = baseTitle;
    if (titlesStack.length === 1) {
      document.title = titlesStack[0];
    }
  },
  get currentTitle(): string {
    return titlesStack[titlesStack.length - 1];
  },
  push(title: string): void {
    titlesStack.push(title);
    setTitle(title);
  },
  replace(title: string): void {
    titlesStack[titlesStack.length - 1] = title;
    setTitle(title);
  },
  pop(): string {
    const title = titlesStack.pop() || '';
    setTitle(titlesStack[titlesStack.length - 1]);
    return title;
  }
};
