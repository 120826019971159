<template>
  <vue-validation-field
    :name="name"
    :tag="tag"
    :is-equal="isEqual"
    @change="$emit('change', $event)"
    @should-focus="handleFocus"
  >
    <template #default="scopedProps">
      <slot name="nude" v-bind="scopedProps">
        <input
          v-if="hidden"
          type="hidden"
          :name="scopedProps.name"
          :value="scopedProps.modelValue"
          v-bind="$attrs"
        />
        <form-field
          v-else
          :label="label"
          :description="description"
          :hide-errors="hideErrors"
          :show-only-one-error="showOnlyOneError"
          :invalid="scopedProps.invalid"
          :errors="scopedProps.errors"
          v-bind="$attrs"
        >
          <template #label="labelScoped">
            <slot name="label" v-bind="{ ...scopedProps, ...labelScoped }" />
          </template>
          <template #hint>
            <slot name="hint" v-bind="{ ...scopedProps }" />
          </template>
          <template #additional-control>
            <slot name="additional-control" v-bind="{ ...scopedProps }" />
          </template>
          <template #default="{ id }">
            <slot v-bind="scopedProps" :id="id" />
          </template>
          <template #error="{ error }">
            <slot name="error" v-bind="{ error, ...scopedProps }">
              {{ error.message }}
            </slot>
          </template>
          <template #warning>
            <slot name="warning" v-bind="scopedProps" />
          </template>
          <template #info>
            <slot name="info" v-bind="scopedProps" />
          </template>
        </form-field>
      </slot>
    </template>
  </vue-validation-field>
</template>

<script>
import { ValidationField as VueValidationField } from 'vue-validate-form';

import FormField from '../form-field/form-field.vue';

export default {
  compatConfig: {
    INSTANCE_ATTRS_CLASS_STYLE: false
  },
  name: 'ValidationField',
  components: { FormField, VueValidationField },
  inheritAttrs: false,
  props: {
    name: VueValidationField.props.name,
    tag: VueValidationField.props.tag,
    isEqual: VueValidationField.props.isEqual,
    showOnlyOneError: FormField.props.showOnlyOneError,
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    hideErrors: Boolean,
    hidden: Boolean
  },
  emits: ['change'],
  methods: {
    handleFocus() {
      this.$nextTick(() => {
        this.$el.parentNode.querySelector(`[name="${this.name}"]`)?.focus();
      });
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
