import { ComponentInternalInstance } from 'vue';
import { createEmitter } from '@/services/emitter/emitter';

type ModalProps<T> = T & {
  orphanMode?: boolean;
  className?: string;
  onHide?: () => boolean;
  modalId?: string | number;
};

interface ModalEvent<T extends ComponentInternalInstance = any> {
  state: boolean | undefined;
  component: T;
  props?: ModalProps<T['props']>;
}

type Events = {
  toggle: ModalEvent;
};

const emitter = createEmitter<Events>();

/**
 * @deprecated use `@/components/modal-next/modal-wrapper.vue` instead
 */
export const modal = {
  /**
   * @deprecated use `@/components/modal-next/modal-wrapper.vue` instead
   */
  show<T extends ComponentInternalInstance>(component: T, props: ModalProps<T['props']>): void {
    emitter.emit('toggle', { state: true, component, props });
  },

  /**
   * @deprecated use `@/components/modal-next/modal-wrapper.vue` instead
   */
  hide<T extends ComponentInternalInstance>(component: T): void {
    emitter.emit('toggle', { state: false, component });
  },

  /**
   * @deprecated use `@/components/modal-next/modal-wrapper.vue` instead
   */
  toggle<T extends ComponentInternalInstance>(component: T, props: ModalProps<T['props']>): void {
    emitter.emit('toggle', { state: undefined, component, props });
  },

  subscribe<T extends ComponentInternalInstance>(callback: () => ModalEvent<T>): () => void {
    emitter.on('toggle', callback);

    return () => emitter.off('toggle', callback);
  }
};
