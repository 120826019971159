import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient } from '@/api/utils/client/axios';

interface ExceededInfo {
  count: number;
}

class ExceededUsersLayer extends ApiLayer<AxiosClient> {
  get() {
    return this.methods.get<ExceededInfo>('/exceeded_users');
  }
}

export const ExceededUsersAPI = new ExceededUsersLayer(axios);
