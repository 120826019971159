<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RenderSlot',
  props: {
    slotContent: {
      type: Array, // VNode[]
      default: null
    }
  },
  render() {
    return this.slotContent || this.$slots.default?.();
  }
});
</script>

<i18n lang="json">{}</i18n>
