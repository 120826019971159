import { userPermissions } from '@/services/config/user-permissions';
import { StorageHelper } from '@/util/storage-helper';
import { features as defaultFeatures } from './features';
import { createEmitter } from '@/services/emitter/emitter';

export const EVENT_BUS_KEY = Symbol('feature_toggle_bus');

export type FeatureTypes = keyof typeof defaultFeatures;

type FeatureSetting = { urls: Array<string>; name: string };
type FeatureSettings = Record<FeatureTypes, FeatureSetting>;

type Events = {
  enable: string;
  disable: string;
};

function mergeSettings(
  defaultSettings: FeatureSettings,
  savedSettings: Partial<FeatureSettings>
): FeatureSettings {
  const result: FeatureSettings = { ...defaultSettings };
  Object.entries(result).forEach(([key, defaultSetting]) => {
    const savedSetting = savedSettings[key as FeatureTypes];
    if (savedSetting) {
      result[key as FeatureTypes].urls = Array.from(
        new Set([...defaultSetting.urls, ...savedSetting.urls])
      );
    } else {
      result[key as FeatureTypes].urls = defaultSetting.urls;
    }
  });

  return result;
}

class FeatureToggleManager {
  private static instance: FeatureToggleManager;
  private settings: FeatureSettings = defaultFeatures;
  emitter = createEmitter<Events>();

  constructor() {
    if (FeatureToggleManager.instance) {
      return FeatureToggleManager.instance;
    }
    FeatureToggleManager.instance = this;

    this.restoreFromStorage();
  }

  // для того, чтобы была возможность включить-выключить через консоль
  expose() {
    window.FeatureToggleManager = this;
  }

  private getPermissionIndex(name: FeatureTypes): number | undefined {
    if (!this.settings.hasOwnProperty(name)) {
      return undefined;
    }
    return this.settings[name].urls.findIndex((pattern) => {
      const patternUrl = new URL(pattern);
      const url = new URL(window.location.toString());

      if (patternUrl.hostname !== url.hostname) {
        return false;
      }

      if (!patternUrl.pathname || patternUrl.pathname === '/') {
        return true;
      }

      const patternOrg = patternUrl.pathname.match(/^\/my\/([^/]+)/)?.[1];
      const orgNick = userPermissions.get('nick');
      return patternOrg === orgNick;
    });
  }

  isEnabled(name: FeatureTypes): boolean {
    const index = this.getPermissionIndex(name);
    if (typeof index !== 'number') {
      return false;
    }
    return index >= 0;
  }

  enable(name: FeatureTypes): boolean {
    if (!this.settings[name]) {
      return false;
    }
    if (this.isEnabled(name)) {
      return true;
    }
    const url = new URL(window.location.toString());
    this.settings[name].urls.push(url.origin);
    this.saveToStorage();
    this.emitter.emit('enable', name);
    return true;
  }

  disable(name: FeatureTypes): boolean {
    if (this.isEnabled(name) === false) {
      return true;
    }
    const index = this.getPermissionIndex(name);
    this.settings[name].urls.splice(index as number, 1);
    this.saveToStorage();
    this.emitter.emit('disable', name);
    return true;
  }

  private saveToStorage() {
    StorageHelper.setJSON('feature_toggles', this.settings, { namespaced: true });
  }

  private restoreFromStorage() {
    const savedSettings = StorageHelper.getJSON<Partial<FeatureSettings>>('feature_toggles', {
      namespaced: true
    });
    if (savedSettings) {
      this.settings = mergeSettings(this.settings, savedSettings);
    }

    window.addEventListener('storage', (e) => {
      if (e.key === StorageHelper.makeKey('feature_toggles', { namespaced: true }) && e.newValue) {
        const updatedSettings = JSON.parse(e.newValue);
        const oldValues = Object.fromEntries(
          this.availableFeatures.map(([key]) => [key, this.isEnabled(key)])
        );
        this.settings = updatedSettings;
        this.availableFeatures.forEach(([key]) => {
          const newValue = this.isEnabled(key);
          if (newValue !== oldValues[key]) {
            this.emitter.emit(newValue ? 'enable' : 'disable', key);
          }
        });
      }
    });
  }

  get availableFeatures() {
    return Object.entries(this.settings).map(([key, value]) => [key, value.name]) as Array<
      [FeatureTypes, FeatureSetting['name']]
    >;
  }

  dump() {
    console.log(this.settings);
  }
}

export const featureToggle = new FeatureToggleManager();
featureToggle.expose();
