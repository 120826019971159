import { ApplicantAPI } from '@/api/applicant';
import { statusSortComparator } from '../../stats/helpers/statusSortComparator';

const state = {
  statuses: (window.AccountApplicantStatusesData ?? [])
    .filter((status) => !status.removed)
    .sort(statusSortComparator),
  stats: [],
  loading: true
};

const mutations = {
  setStatus(state, status) {
    state.status = status;
  },
  setStats(state, stats) {
    state.stats = stats;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  }
};

const actions = {
  fetchStats({ commit }, payload) {
    commit('setLoading', true);
    return ApplicantAPI.fetchStatsByFilter(payload)
      .then(({ items }) => {
        commit('setStats', items);
        commit('setLoading', false);
      })
      .catch(() => {
        commit('setLoading', false);
      });
  }
};

const getters = {
  statuses: (state) => state.statuses,
  realStatuses: (state, getters) => getters.statuses.filter((status) => !status.virtual),
  hiredStatus: (state, getters) => getters.realStatuses.find((status) => status.type === 'hired'),
  loadingStats: (state) => state.loading,
  uiStats: (state) => {
    const statusReducer = (acc, status) => {
      const { id, name } = status;
      const stat = state.stats.find((item) => item.status === id);
      if (Boolean(stat) === false) {
        return acc;
      }
      const isHidden = Boolean(status.removed);
      if (isHidden) {
        return acc;
      }
      acc.push({
        ...stat,
        id,
        name,
        disabled: Boolean(stat.count) === false
      });
      return acc;
    };

    return state.statuses.reduce(statusReducer, [{ id: 'dashboard' }]);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
