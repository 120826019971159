<template>
  <div :class="className">
    <div
      :class="$style.title"
      data-qa="sidebar-vacancy-title"
      :title="vacancy.position"
      @click="toggle"
    >
      <button
        type="button"
        data-qa="sidebar-vacancy-item-handle"
        :class="$style.toggleButton"
        @click.stop="toggle"
      >
        <base-icon type="small-chevron-right-12" />
      </button>
      <div :class="$style.vacancy">
        <div :class="$style.vacancyTitle">{{ vacancy.position }}</div>
        <span :class="$style.subTitle">{{ countText }}</span>
      </div>
    </div>
    <div v-show="isExpanded" :class="$style.content">
      <slot name="default" :children="children" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'VacancyItemGroup',
  components: { BaseIcon },
  props: {
    vacancy: {
      type: Object,
      required: true
    },
    children: {
      type: Array,
      default: undefined
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    selectedVacancyId: {
      type: Number,
      default: undefined
    }
  },
  emits: ['toggle'],
  computed: {
    isActive() {
      const children = this.children ?? [];
      return (
        this.vacancy.id === this.selectedVacancyId ||
        children.some((child) => child.id === this.selectedVacancyId)
      );
    },
    count() {
      return this.children?.length;
    },
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.expanded]: this.isExpanded,
        [this.$style.active]: this.isActive
      };
    },
    countText() {
      if (this.count === 0) {
        return this.$trlMessage('left_menu.multivacancy.no_children');
      }
      return this.$trlMessage('left_menu.multivacancy.children', { count: this.count });
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    }
  }
};
</script>

<style module>
.root {
  margin-left: 16px;
  margin-right: 0;
}

.title {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'button .';

  position: relative;

  font-size: $secondaryFontSize;
  line-height: 20px;
  color: $sidebarTextColor;
  transition: color 0.1s linear;

  cursor: pointer;
  border-radius: 10px;
  margin: 0 0 0 -16px;
  padding: 8px 12px;

  &:not(.active):hover {
    color: $sidebarTextColorHover;
    background-color: $sidebarTextBackgroundHover;
  }
}

.titleWithCounter {
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'button .'
    'button .';
}

.toggleButton {
  @mixin reset-button-styles;
  position: relative;
  top: 1px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-area: button;
  color: currentColor;
  margin-right: 6px;
  margin-left: -2px;
}

.root:hover .title {
  color: $sidebarTextColorHover;
}

.expanded .title {
  .toggleButton svg {
    transform: rotate(90deg);
  }
}

.active:not(.expanded) .title {
  color: $sidebarTextColorActive;
  background-color: $sidebarTextBackgroundActive;
}

.content {
  padding-left: 8px;
  ul {
    margin-left: 4px;
  }
}

.title + .content {
  margin-top: 0;
}

.subTitle {
  opacity: 0.55;
  line-height: 20px;
}

.vacancy,
.vacancyTitle {
  @mixin ellipsis;
}

.vacancyTitle {
}
</style>

<i18n lang="json">{}</i18n>
