<template>
  <div :class="$style.container" data-qa="limit-info">
    <applicant-limit-data-provider>
      <template #default="{ limit, unlimitedTariffGroup, isLimitExceed }">
        <div
          v-if="isLimitExceed"
          data-qa="applicant-limit-exceed-banner"
          :class="annoyingBannerClasses"
        >
          <annoying-banner>
            <template #caption>
              {{ $trlMessage('applicants-limit.banner.title', { limit }) }}
            </template>
            <template #description>
              {{
                $trlMessage('applicants-limit.banner.descr', {
                  tariffName: unlimitedTariffGroup.name
                })
              }}
            </template>
            <template #action>
              <apply-button color="black-outline" />
            </template>
          </annoying-banner>
        </div>
      </template>
    </applicant-limit-data-provider>
    <recruiters-limit-data-provider>
      <template #default="{ isLimit, removeRecruitersCount, unlimitedTariffGroup }">
        <div>
          <div v-if="isLimit" :class="annoyingBannerClasses" data-qa="recruiters-limit-banner">
            <annoying-banner>
              <template #caption>
                {{ $trlMessage('recruiters-limit.caption', { count: removeRecruitersCount }) }}
              </template>
              <template #description>
                <div
                  v-html="
                    $trlMessage('recruiters-limit.description', {
                      count: removeRecruitersCount,
                      tariffName: unlimitedTariffGroup.name,
                      a: makeSettingsLink
                    })
                  "
                />
              </template>
              <template #action>
                <apply-button color="black-outline" />
              </template>
            </annoying-banner>
          </div>
          <div
            v-if="removeRecruitersCount > 0"
            data-qa="workplace-exceed-banner"
            :class="annoyingBannerClasses"
          >
            <annoying-banner>
              <template #caption>
                {{ $trlMessage('workplace-exceeded.caption', { count: removeRecruitersCount }) }}
              </template>
              <template #description>
                <div
                  v-html="
                    $trlMessage('workplace-exceeded.description', {
                      count: removeRecruitersCount,
                      a: makeSettingsLink
                    })
                  "
                ></div>
              </template>
              <template #action>
                <apply-button color="black-outline" mail-text="promo.recruiters_upgrade">
                  {{ $trlMessage('promo.contact.apply') }}
                </apply-button>
              </template>
            </annoying-banner>
          </div>
        </div>
      </template>
    </recruiters-limit-data-provider>
    <watchers-limit-data-provider>
      <template #default="{ overLimit, unlimitedTariffGroup, isOverLimitExceed }">
        <div
          v-if="isOverLimitExceed"
          data-qa="watchers-limit-exceed-banner"
          :class="annoyingBannerClasses"
        >
          <annoying-banner>
            <template #caption>
              {{ $trlMessage('watchers-limit.banner.title', { overLimit }) }}
            </template>
            <template #description>
              <div
                v-html="
                  $trlMessage('watchers-limit.banner.descr', {
                    overLimit,
                    tariffName: unlimitedTariffGroup.name,
                    a: (linkText) => `<a href='${hiringManagersLink}'>${linkText}</a>`
                  })
                "
              ></div>
            </template>
            <template #action>
              <apply-button color="black-outline" />
            </template>
          </annoying-banner>
        </div>
      </template>
    </watchers-limit-data-provider>

    <the-telephony @call-start="talking = true" @call-end="talking = false" />
  </div>
</template>

<script>
import { userPermissions } from '@/services/config/user-permissions';
import AnnoyingBanner from '@/components/annoying-banner/annoying-banner.vue';
import ApplyButton from '@/components/promo/apply-button.vue';
import TheTelephony from '@/modules/telephony/telephony';
import ApplicantLimitDataProvider from '@/components/hf/applicant-limit-data-provider/applicant-limit-data-prodiver.vue';
import WatchersLimitDataProvider from '@/components/hf/watchers-limit-data-provider/watchers-limit-data-prodiver';
import RecruitersLimitDataProvider from './recruiters-limit-data-provider.vue';

export default {
  name: 'LayoutInfo',
  components: {
    AnnoyingBanner,
    ApplyButton,
    TheTelephony,
    ApplicantLimitDataProvider,
    WatchersLimitDataProvider,
    RecruitersLimitDataProvider
  },
  data() {
    return {
      talking: false
    };
  },
  computed: {
    permissionsNick: () => userPermissions.get('nick'),
    annoyingBannerClasses() {
      return {
        [this.$style.annoyingBanner]: true,
        [this.$style.talking]: this.talking
      };
    },
    hiringManagersLink() {
      return `/settings/orgs/${this.permissionsNick}/hiring-managers`;
    }
  },
  methods: {
    makeSettingsLink(text) {
      return `<a href="/settings/orgs/${this.permissionsNick}/recruiters">${text}</a>`;
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables.less';

.container {
  flex: 0 0 auto;
}

.annoyingBanner {
  padding-bottom: 15px;
  background-color: @headerColor;
}

.annoyingBanner ~ .annoyingBanner {
  display: none;
}

.talking {
  background-image: linear-gradient(
    90deg,
    #3f0089 0%,
    #1d0051 25%,
    #3f0089 50%,
    #1d0051 75%,
    #3f0089 100%
  );
  background-size: 200% 100%;
  animation: phonechange 3s linear infinite;
}

@keyframes phonechange {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 50% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
</style>

<i18n lang="json">{}</i18n>
