<template>
  <base-template
    :id="id"
    :ordered-properties="orderedProperties"
    :schema="schema"
    :ui-schema="uiSchema"
    :label="label"
  >
    <div :class="$style.range">
      <div :class="$style.field">
        <slot name="compensation_from" />
      </div>
      <div :class="$style.dash">
        <form-field data-qa="compensation_delimiter"> &mdash; </form-field>
      </div>
      <div :class="$style.field">
        <slot name="compensation_to" />
      </div>
    </div>
  </base-template>
</template>

<script>
import FormField from '../widgets/form-field';
import BaseTemplate from './base-template';

export default {
  name: 'CompensationTemplate',
  components: { FormField, BaseTemplate },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    uiSchema: {
      type: Object,
      required: true
    },
    orderedProperties: {
      type: Array,
      required: true
    }
  }
};
</script>

<style module>
.range {
  display: flex;
  width: 100%;
}
.field {
  flex: 1 0 0;
}
.dash {
  padding: 0 10px;
  flex: 0 0 auto;
}
</style>

<i18n lang="json">{}</i18n>
