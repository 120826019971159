<template>
  <option :value="computedValue" v-bind="$attrs">
    <slot />
  </option>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseOption',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    computedValue() {
      return this.value === null ? '' : this.value;
    }
  }
});
</script>

<i18n lang="json">{}</i18n>
