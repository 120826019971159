export enum UserPermissionType {
  statusGroupsEdit = 'status-groups-edit',
  childrenInheritMultivacancyStatuses = 'children-inherit-multivacancy-statuses',
  vacancy = 'vacancy',
  hideOtherDivisionsVacancies = 'hide-other-divisions-vacancies',
  addCandidates = 'add-candidates',
  contacts = 'contacts',
  status = 'status',
  hideSalary = 'hide-salary',
  createVacancies = 'create-vacancies',
  removeVacancies = 'remove-vacancies',
  inviteManagers = 'invite-managers',
  hideVacancies = 'hide-vacancies',
  inviteWatchers = 'invite-watchers',
  removeApplicants = 'remove-applicants',
  manageDictionaries = 'manage-dictionaries',
  mailTemplates = 'mail-templates',
  manageTags = 'manage-tags',
  receiveVacancyRequests = 'receive-vacancy-requests',
  division = 'division'
}
