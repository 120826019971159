export const COLORS = {
  green: 'green',
  yellow: 'yellow',
  gray: 'gray',
  black: 'black',
  ghost: 'ghost',
  red: 'red',
  violet: 'violet',
  'ai-red': 'ai-red',
  'ai-gray': 'ai-gray'
};

export const SIZES = {
  xl: 'xl',
  l: 'l',
  m: 'm',
  s: 's',
  xs: 'xs'
};

export const ICON_SIZE = {
  xl: '20px',
  l: '18px',
  m: '20px',
  s: '16px',
  xs: '18px'
};

export const LOADER_SIZE = {
  xl: 's',
  l: 's',
  m: 's',
  s: 'xs',
  xs: 'xs'
};

export const LOADER_COLOR = {
  green: 'white',
  yellow: 'white',
  gray: 'black',
  black: 'white',
  ghost: 'black',
  red: 'white',
  violet: 'white',
  'ai-red': 'white',
  'ai-gray': 'black'
};
