const variables = window.TemplateVariables || [];

const initialState = {
  variables
};

function getVariablesByType(variables, flagKey) {
  return variables
    .filter((variable) => variable[flagKey])
    .sort((a, b) => a.order - b.order)
    .map(({ id }) => id);
}

const gettersConfig = {
  mail: (state) => getVariablesByType(state.variables, 'available'),
  sms: (state) => getVariablesByType(state.variables, 'available_sms'),
  im: (state) => getVariablesByType(state.variables, 'available_im')
};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersConfig
};
