<template>
  <form-field
    :label="label"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
    :error-schema="errorSchema"
    :files="files"
  >
    <template #default="{ id: computedId, invalid }">
      <base-textarea
        :id="computedId"
        v-model="computedModel"
        :name="computedId"
        :invalid="invalid"
        autocomplete="off"
        v-bind="fieldProps"
      />
    </template>
  </form-field>
</template>

<script>
import FormField from './form-field.vue';
import BaseTextarea from '@/components/textarea/textarea.vue';

export default {
  name: 'FormFieldTextarea',
  components: { FormField, BaseTextarea },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['change'],
  computed: {
    files() {
      return this.schema.metadata?.files ?? [];
    },
    computedModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      }
    },
    fieldProps() {
      return {
        ...this.options,
        ...this.$attrs
      };
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
