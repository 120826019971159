<template>
  <button type="button" :title="title" :class="$style.tag" :style="style" v-on="$listeners">
    <span :class="[ellipsis && $style.ellipsis]">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'TagButton',
  props: {
    color: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: undefined
    },
    ellipsis: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    style() {
      return {
        backgroundColor: this.color
      };
    }
  }
};
</script>

<style module>
.tag {
  @mixin reset-button-styles;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: $white;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 115px;
  height: 20px;
  line-height: 20px;
  padding: 0 8px;

  cursor: default;
  user-select: none;
  border-radius: 4px;
}

.ellipsis {
  @mixin ellipsis;
}
</style>

<i18n lang="json">{}</i18n>
