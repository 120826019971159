<template>
  <form-field
    :label="label"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
    :error-schema="errorSchema"
  >
    <template #default="{ id: computedId, invalid }">
      <base-datepicker
        :id="computedId"
        v-model="computedModel"
        :invalid="invalid"
        :readonly="false"
        v-bind="fieldProps"
      />
    </template>
  </form-field>
</template>

<script>
import BaseDatepicker from '@/components/base-datepicker';
import FormField from './form-field.vue';

export default {
  name: 'FormFieldDate',
  components: { FormField, BaseDatepicker },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Number, String],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['change'],
  computed: {
    computedModel: {
      get() {
        return this.value;
      },
      set(date) {
        this.$emit('change', date);
      }
    },
    fieldProps() {
      return {
        ...this.options,
        ...this.$attrs
      };
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
