// TODO: бэк принимает названия во множественном числе - minutes, hours, etc.
// на уровне компонента не хочу завязываться на формат API, но при использовании надо учесть
export const Unit = {
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week'
};

export const DEFAULT_UNIT = Unit.MINUTE;
