<template>
  <component
    :is="calculatedTag"
    :key="item.name"
    :to="path"
    :disabled="isDisable"
    :item="item"
    custom
  >
    <template #default="{ href, navigate }">
      <li :class="$style.tab" :data-qa="`${item.name}-tab`" @click="navigate">
        <a :class="linkStyles" :href="href">
          <div :class="$style.center">
            <div :class="$style.icon">
              <icon :icon="icon" :disabled="isDisable" />
            </div>
            <div :class="$style.content">
              <span :class="captionStyles">{{ title }}</span>
              <span v-if="!isSideBar" :class="$style.text">{{ text }}</span>
            </div>
          </div>
        </a>
      </li>
    </template>
  </component>
</template>

<script>
import Icon from './icon/settings-icon.vue';
import routesInfo from './constants';
import RouterLinkCustom from './router-link-custom.vue';

export default {
  name: 'BaseTabs',
  components: { Icon, RouterLinkCustom },
  props: {
    item: {
      type: Object,
      required: true
    },
    isOrgs: {
      type: Boolean,
      default: false
    },
    isSideBar: {
      type: Boolean,
      default: false
    }
  },
  emits: ['selected'],
  computed: {
    calculatedTag() {
      if (this.isOrgs) {
        return RouterLinkCustom;
      }
      return 'router-link';
    },
    captionStyles() {
      return {
        [this.$style.caption]: true,
        [this.$style.sideBar]: this.isSideBar
      };
    },
    isDisable() {
      return this.item.isLocked || this.item.meta?.isLocked;
    },
    routeInfo() {
      return routesInfo[this.item.name];
    },
    title() {
      return this.routeInfo?.tabTitle && this.isSideBar
        ? this.routeInfo.tabTitle
        : this.routeInfo.title;
    },
    text() {
      return this.routeInfo.text;
    },
    icon() {
      return this.routeInfo?.iconBar && this.isSideBar
        ? this.routeInfo.iconBar
        : this.routeInfo.icon;
    },
    linkStyles() {
      return {
        [this.$style.link]: true,
        [this.$style.linkSidebar]: this.isSideBar,
        [this.$style.linkDisabled]: this.isDisable,
        [this.$style.hoverLink]: this.item.redirect
          ? this.$route.name === this.item.redirect.name
          : this.$route.path === this.item.path
      };
    },
    path() {
      return { name: this.item.name };
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';
@import '~@less/common/mixins/font';

.caption {
  cursor: inherit;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: @textColor;
}
.sideBar {
  font-size: 16px;
}
.content {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.linkSidebar {
  align-items: center;
  padding-left: 20px;
  width: 291px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  margin-left: -20px;
  position: relative;
  @media screen and (max-width: 1040px) {
    width: 215px;
  }
  :not(.hoverLink)&:hover {
    .caption {
      color: #fa633e;
    }
  }
}
.center {
  display: flex;
  justify-content: center;
  gap: 12px;
}
.hoverLink {
  background-color: #f7f7f7;
  border-radius: 12px;
  cursor: default;
}
.icon {
  position: relative;
}
.linkDisabled {
  .icon:before {
    content: '';
    position: absolute;
    background-image: url(~@static/i/blocks/settings/settings-lock.svg);
    background-repeat: no-repeat;
    width: 11px;
    height: 13px;
    top: -3px;
    left: -4px;
    z-index: 10;
  }
}
.tab {
  list-style: none;
  width: 100%;
  max-width: 353px;
}
.link {
  border: none;
  box-shadow: none;
  text-decoration: none;
  color: @textColor;
  display: flex;
  flex-direction: row;
  gap: 12px;
  position: relative;
  padding: 8px 11px 8px 16px;
  margin-left: -16px;
  :not(.linkSidebar)&:hover {
    background-color: #f5f5f5;
    border-radius: 12px;
    height: 100%;
  }
}
.defaultLink {
  text-decoration: none;
}
.text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #999999;
  padding: 0;
  margin: 0;
}
.icon {
  min-height: 20px;
  min-width: 20px;
}
@media screen and (max-width: 1040px) {
  .linkDisabled {
    &:after {
      top: 25px;
      left: 16px;
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
