import { isTwoDaysEvent } from './is-two-days-event';
import { ALL_DAY_ROW_HEIGHT } from '../config';
import { time2y } from './time2y';

const isEventStartBeforeDayStart = (event, dayStart) => event.start.getDate() < dayStart.getDate();

const isEventEndOnDayStart = (event, dayStart) => event.end.getDate() === dayStart.getDate();

export const calculateEventTop = (event, hourHeight, dayStart) => {
  if (!event) {
    return undefined;
  }

  if (
    isTwoDaysEvent(event) &&
    (isEventStartBeforeDayStart(event, dayStart) || isEventEndOnDayStart(event, dayStart))
  ) {
    return ALL_DAY_ROW_HEIGHT;
  }

  return time2y(event.start, hourHeight);
};

export const calculateEventHeight = (event, hourHeight, dayStart) => {
  if (!event) {
    return undefined;
  }

  const startOfDay = new Date(dayStart);
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay = new Date(dayStart);
  endOfDay.setHours(23, 59, 59, 999);

  const startY = time2y(event.start, hourHeight) - ALL_DAY_ROW_HEIGHT;
  const endY = time2y(event.end, hourHeight) - ALL_DAY_ROW_HEIGHT;

  if (event.start < startOfDay && event.end > endOfDay) {
    // Event spans the entire day
    return 24 * hourHeight;
  }

  if (event.start < startOfDay) {
    // Event starts before this day
    return Math.min(endY, 24 * hourHeight);
  }

  if (event.end > endOfDay) {
    // Event ends after this day
    return 24 * hourHeight - startY;
  }

  // Event is within this day
  return Math.max(endY - startY, Math.round(hourHeight / 3));
};
