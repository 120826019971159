import { VNode, Slot, Comment, Text, ref, useSlots, watchEffect, onBeforeUpdate } from 'vue';

function useSlotContent(slotName: string, slotProps = {}) {
  const slots = useSlots();
  const slotContent = ref();
  watchEffect(() => {
    slotContent.value = getSlotContent(slots[slotName], slotProps);
  });

  onBeforeUpdate(() => {
    slotContent.value = getSlotContent(slots[slotName], slotProps);
  });

  return slotContent;
}

function getSlotContent(slot: Slot | undefined | null, slotProps = {}) {
  if (!slot) {
    return null;
  }

  const vnodes = slot(slotProps);

  return isVNodeNotEmpty(vnodes) ? vnodes : null;
}

function isSlotNotEmpty(slot: Slot | undefined | null, slotProps = {}): boolean {
  if (!slot) {
    return false;
  }

  return isVNodeNotEmpty(slot(slotProps));
}

function isVNodeNotEmpty(vnode: VNode[]): boolean {
  if (!vnode) {
    return false;
  }

  return asArray(vnode).some((vnode) => {
    if (vnode.type === Comment) {
      return false;
    }
    // чекаем компонент для рендера слотов
    if (
      typeof vnode.type === 'object' &&
      'name' in vnode.type &&
      vnode.type?.name === 'RenderSlot'
    ) {
      // оставляем если есть пропс с контентом или если передан дефолтный слот
      return (
        vnode.props?.['slot-content'] !== null ||
        (vnode.children && typeof vnode.children === 'object' && 'default' in vnode.children)
      );
    }

    if (vnode.children?.length === 0) {
      return false;
    }

    if (vnode.type === Text && typeof vnode.children === 'string' && vnode.children.trim() === '') {
      return false;
    }

    return true;
  });
}

function asArray<T>(arg: T | T[] | null) {
  if (arg === null) {
    return [];
  }
  return Array.isArray(arg) ? arg : [arg];
}

export const SlotChecker = { isSlotNotEmpty, getSlotContent, useSlotContent };
