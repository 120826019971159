<template>
  <page-layout>
    <template #header-title>
      <back-link href="/">
        {{ $trlMessage('header.applicants_and_vacancies') }}
      </back-link>
    </template>
    <template #content>
      <div :class="$style.cover">
        <div :class="$style.wrapper">
          <base-title
            v-if="!rootRoute"
            :org-name="orgName"
            :title="title"
            :sub-title="subTitle"
            :show-back="!rootRoute"
            :is-org="true"
            @back="back"
          />
          <div :class="$style.flex">
            <base-sidebar v-if="routesChildren.length > 1" :routes="routesChildren" />
            <div :class="$style.content">
              <router-view />
            </div>
          </div>
        </div>
        <router-view name="extra" />
      </div>
    </template>
  </page-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/page-layout.vue';
import BackLink from '@/components/page-layout/back-link.vue';

import BaseTitle from './base-title.vue';
import BaseSidebar from './base-sidebar.vue';
import routesInfo from './constants';

export default {
  name: 'OrganizationSettingsApp',
  components: { PageLayout, BackLink, BaseTitle, BaseSidebar },
  props: {
    pages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      scrollInProcess: false
    };
  },
  computed: {
    ...mapGetters('config', ['organization']),
    orgName() {
      return this.organization.name;
    },
    title() {
      return this.$route.name ? routesInfo[this.$route.name].routeTitle : null;
    },
    subTitle() {
      return this.$i18n('settings.subTitle', { org: this.orgName });
    },
    rootRoute() {
      return this.$route.path === '/';
    },
    routesChildren() {
      const rootRoute = this.$route.matched[0]?.name;
      if (!rootRoute) return [];
      return this.pages.find((el) => el.name === rootRoute).children;
    }
  },
  methods: {
    back() {
      window.location.href = '/settings';
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/mixins/font';
@import '~@less/common/variables';

.wrapper {
  padding: 0 15px;
  max-width: 75%;
  position: relative;

  min-height: unset;

  composes: col-xs-offset-2 col-xs-9 from global;
}
.cover {
  background-color: @white;
  border-radius: 16px;
  margin: 8px;
  flex: 1;
}
.caption {
  font-size: 22px;
  line-height: 30px;
  .font_bold();
  padding: 0;
  margin: 40px 0 30px;
}

.activeAnimation {
  transition: opacity 100ms;
}
.fadeAnimation {
  opacity: 0;
}

.organizationName {
  padding-top: 20px;
  padding-bottom: 14px;
  border-bottom: 3px solid #eaeaea;
  font-size: 25px;
  line-height: 28px;
  margin: 0;
  .font_regular();
}

.organizationId {
  font-size: 14px;
  line-height: 18px;
  color: #999;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: #ff533b;
  }
}

.mainLayout {
  padding-bottom: 100px;
}
.content {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  flex: 1;
  min-width: 0;
}
.flex {
  display: flex;
  flex-direction: row;
  gap: 78px;
  padding-bottom: 100px;
  @media screen and (max-width: 1040px) {
    gap: 45px;
  }
}
</style>

<i18n lang="json">
{
  "settings.subTitle": {
    "ru_RU": "Настройки {org}",
    "en_US": "Settings {org}"
  }
}
</i18n>
