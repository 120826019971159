<template>
  <div>
    <block-title :is-orgs="isOrgs" />
    <ul :class="$style.tabs">
      <base-tab v-for="item in pages" :key="item.name" :item="item" :is-orgs="isOrgs" />
    </ul>
  </div>
</template>

<script>
import BlockTitle from './block-title.vue';
import BaseTab from './base-tab.vue';
export default {
  name: 'BaseTabs',
  components: { BlockTitle, BaseTab },
  props: {
    pages: {
      type: Array,
      required: true
    },
    isOrgs: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px 30px;
  padding: 0;
  margin: 0;
}
</style>

<i18n lang="json">{}</i18n>
