<template>
  <base-island :class="className">
    <loader :color="color" />
  </base-island>
</template>

<script>
import BaseIsland from '@/components/ui/base-island/base-island';
import Loader from './loader.vue';

export default {
  name: 'ContentLoader',
  components: {
    Loader,
    BaseIsland
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    color: Loader.props.color
  },
  computed: {
    className() {
      return {
        [this.$style.loaderWrapper]: true,
        [this.$style.transparent]: this.transparent
      };
    }
  }
};
</script>

<style module>
.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--content-loader-height, 150px);
  margin-top: var(--content-loader-offset, 0);
}

.transparent {
  background: none;
}
</style>

<i18n lang="json">{}</i18n>
