<template>
  <div :class="$style.container">
    <base-label :class="labelClass">
      <div :class="$style.radioContainer">
        <input
          ref="input"
          v-model="model"
          type="radio"
          :class="$style.checkbox"
          v-bind="$attrs"
          :value="value"
          :name="name"
          v-on="computedListeners"
        />
      </div>
      <span
        :class="[$style.labelContent, ellipsis ? $style.labelEllipsisContent : null]"
        v-html="label"
      />
    </base-label>
  </div>
</template>

<script>
import BaseLabel from '@/components/label/label.vue';

export default {
  name: 'VRadio',
  components: {
    BaseLabel
  },
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    ellipsis: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    column: Boolean,
    value: {
      type: [String, Number],
      default: null
    },
    checked: {
      type: [String, Number],
      default: null
    },
    name: {
      type: String,
      required: true
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('change', value);
      }
    },
    labelClass() {
      return {
        [this.$style.label]: true,
        [this.$style.column]: this.column
      };
    },
    computedListeners() {
      return {
        ...this.$listeners,
        change: () => {}
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.focus) {
        this.$refs.input.focus();
      }
    });
  }
};
</script>

<style module>
.container {
  width: 100%;
}

.label {
  display: inline-flex;
  align-items: flex-start;
  font-weight: normal;
}

.radioContainer {
  flex: none;
  display: flex;
  height: 22px;
  align-items: center;
  padding-top: 1px;
}

.label > .radioContainer .checkbox {
  /* TODO: override global styles, remove later */
  position: initial;
  top: initial;
}

.labelContent {
  margin-left: 8px;
  flex: 1;
}

.labelEllipsisContent {
  @mixin ellipsis;
}

.column {
  align-items: center;
  flex-direction: column;
}
.column .labelContent {
  margin-left: 0;
}
</style>

<i18n lang="json">{}</i18n>
