<template>
  <div />
</template>

<script>
import memoize from 'lodash/memoize';
import { getOverflowAncestors } from '@floating-ui/dom';

const getObserver = memoize(
  (options) =>
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.$_intersection_handler(entry);
      });
    }, options || {})
);

export default {
  name: 'IntersectionObserver',
  props: {
    options: {
      type: Object,
      default: () => ({
        rootMargin: '0px 0px 400px'
      })
    }
  },
  emits: ['update', 'appear', 'disappear'],
  mounted() {
    this.observer = getObserver({
      root: getOverflowAncestors(this.$el)[0],
      ...this.options
    });

    this.$el.$_intersection_handler = (entry) => {
      this.$emit('update', entry);

      if (entry.isIntersecting) {
        this.$emit('appear');
      } else {
        this.$emit('disappear');
      }
    };

    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.observer.unobserve(this.$el);
  }
};
</script>

<i18n lang="json">{}</i18n>
