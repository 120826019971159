<template>
  <two-columns
    tag="header"
    data-qa="header"
    :class="$style.header"
    :additional-gap="$options.islandGap"
  >
    <div :class="$style.logoWrapper">
      <a :href="logoLink" :class="$style.logoLink" data-qa="logo-link">
        <img alt="logo" :src="logo" :class="$style.logo" />
      </a>
    </div>
    <skeleton zone="header" width="100%" height="56px" dark :class="$style.right">
      <div :class="[$style.rightSide]">
        <div v-if="!isSearchPage" :class="$style.title" data-qa="vacancy-block">
          <portal-target name="page-header">
            <slot name="title" />
          </portal-target>
        </div>

        <slot name="right">
          <template v-if="isAuthorized">
            <slot name="search">
              <search-header :class="$style.item">
                <template #toggle>
                  <header-item :class="$style.itemSearch" data-qa="open-search">
                    <a :href="url('#/search/applicants')" :class="$style.icon">
                      <base-icon type="search-20" />
                    </a>
                  </header-item>
                </template>
              </search-header>
            </slot>
            <slot name="warning">
              <transition
                v-if="untilNy"
                name="warning"
                :enter-class="$style.warningEnter"
                :enter-active-class="$style.warningEnterActive"
                :leave-to-class="$style.warningLeaveTo"
                :leave-active-class="$style.warningLeaveActive"
                @before-leave="beforeWarningLeave"
              >
                <header-item v-show="!isSearchPage" :class="[$style.item, $style.warning]">
                  <header-warnings :until-ny="untilNy" />
                </header-item>
              </transition>
            </slot>
          </template>
        </slot>

        <slot name="menu">
          <div v-if="isAuthorized" :class="[$style.item, $style.menu]">
            <user-menu :show-settings="showSettingsLink" />
          </div>
        </slot>
      </div>
    </skeleton>
  </two-columns>
</template>

<script>
import { mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';
import { islandGap } from '@/config/css-variables';
import { appConfig } from '@/services/config/app-config';

import { Skeleton } from '@/components/skeleton';
import SearchHeader from '@/modules/search/search-header/search-header.vue';
import BaseIcon from '@/components/icon.vue';
import TwoColumns from '@/components/page-layout/two-columns.vue';
import UserMenu from '@/components/user-menu/user-menu.vue';
import HeaderItem from '@/components/page-layout/header-item.vue';
import HeaderWarnings from '@/components/hf/header-warnings/header-warnings.vue';
import {
  SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME,
  SEARCH_APPLICANTS_ROUTE_NAME,
  SEARCH_VACANCIES_ROUTE_NAME
} from '@/modules/main-screen/constants';
import { userPermissions } from '@/services/config/user-permissions';

export default {
  name: 'MainHeader',
  components: {
    HeaderWarnings,
    HeaderItem,
    UserMenu,
    TwoColumns,
    BaseIcon,
    SearchHeader,
    Skeleton,
    PortalTarget
  },
  props: {
    showSettingsLink: {
      type: Boolean,
      default: true
    },
    logoLink: {
      type: String,
      default: '/'
    }
  },
  islandGap,
  data() {
    return {};
  },
  computed: {
    ...mapGetters('config', ['brandThemesLogo']),
    isAuthorized: () => appConfig.isAuthorized,
    orgNick: () => userPermissions.get('nick'),
    canGetStats: () => userPermissions.get('can_get_stats'),
    untilNy() {
      return window.UntilNY;
    },
    isRuLang() {
      return appConfig.get('lang') === 'ru';
    },
    logo() {
      if (this.brandThemesLogo) {
        return this.brandThemesLogo;
      }
      return !this.isRuLang
        ? require('../assets/images/logo-en.svg')
        : require('../assets/images/logo-ru.svg');
    },
    isSearchPage() {
      return [
        SEARCH_APPLICANTS_ROUTE_NAME,
        SEARCH_VACANCIES_ROUTE_NAME,
        SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME
      ].includes(this.$route?.name);
    }
  },
  watch: {},
  mounted() {},
  methods: {
    url(url) {
      return `/my/${this.orgNick}${url}`;
    },
    beforeWarningLeave(el) {
      if (el.style.width) {
        return;
      }
      const width = el.getBoundingClientRect().width;
      // На случай, когда блок warning скрылся раньше (isSearchPage),
      // чем загрузилась информация о демо (untilNy)
      if (!width) {
        return;
      }
      el.style.width = `${width}px`;
    }
  }
};
</script>

<style module>
.header {
  --skeleton-opacity: 0.1;
  height: var(--header-height);
  background-color: $black;
  position: relative;
  z-index: 2000;
  font-weight: 500;
}

.logoWrapper {
  padding: 0 15px;
}

.logoLink {
  display: flex;
  height: var(--header-height);
  align-items: center;
  /*
    Safari использует OCR, и в Shadow DOM вставляет распознанный текст,
    из-за чего при наведении курсор такой как при наведении на обычный текст
  */
  cursor: pointer;
}

.logo {
  object-fit: cover;
  max-height: 90%;
  max-width: 100%;
}

.item {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemSearch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item + .item {
  position: relative;
}

.item + .item::before {
  content: '';
  border-left: 1px solid $headerDelimiterColor;
  position: absolute;
  left: 0;
  height: 25px;
  top: 14px;
  z-index: 1;
}

.right,
.item.right {
  overflow: auto;
}

.rightSide,
.title {
  display: flex;
  overflow: auto;
}

.title {
  align-items: center;
  flex: 1 0 0;
}

.icon {
  color: $whiteColor;
}

.warning {
  &:hover {
    background: none;
  }
  &.warningEnterActive,
  &.warningLeaveActive {
    transition:
      opacity 200ms,
      width 200ms ease-out;
    overflow: hidden;
    min-width: 0;
  }
  &.warningEnter,
  &.warningLeaveTo {
    opacity: 0;
    width: 0 !important; /** important используется, чтобы переписать inline **/
  }
}
</style>

<i18n lang="json">{}</i18n>
