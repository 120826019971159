<template>
  <div
    :class="{
      [$style.island]: true,
      [$style.padding]: padding
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseIsland',
  props: {
    padding: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style module>
.island {
  background-color: $white;
  border-radius: var(--Radius-x4, 16px);
}

.padding {
  padding: 24px;
}
</style>

<i18n lang="json">{}</i18n>
