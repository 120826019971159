let token = '';

function getToken(): string {
  const tokenMeta = document.querySelector('meta[name="xsrf"]');
  if (tokenMeta) {
    token = tokenMeta.getAttribute('content') || '';
    tokenMeta.parentNode?.removeChild(tokenMeta);
  }

  return token;
}

export const XSRFHelper = {
  getToken
};
