import { computed, Ref, unref, ref } from 'vue';
import omit from 'lodash/omit';

import { isLabelField, deepField, keyField } from './constants';
import { ParentChildMap } from '@/components/hierarchy/hierarchy-map';

export interface Item {
  id: number | string;
  active?: boolean;
  items?: Item[];
  [deepField]?: number;
  [keyField]?: string;
}

type ExtendsItem = Omit<Item, 'items'> & {
  [keyField]: string;
  [isLabelField]?: boolean;
  [deepField]?: number;
};

function getItems(
  items: Item[],
  params: {
    showInactive: boolean;
    parentChildMap: ParentChildMap;
  }
): ExtendsItem[] {
  const { showInactive, parentChildMap } = params;
  const result: ExtendsItem[] = [];
  items.forEach((item) => {
    const active = item.active !== false;
    if (!active && !showInactive) {
      return;
    }

    if (Array.isArray(item.items)) {
      if (item.items.length === 0) {
        return;
      }
      result.push(
        Object.freeze({
          ...omit(item, 'items'),
          active,
          [keyField]: item[keyField] ?? `group-${item.id}`,
          [isLabelField]: true
        }),
        ...getItems(item.items, params)
      );
    } else {
      result.push(
        Object.freeze({
          ...item,
          [deepField]: item.hasOwnProperty(deepField)
            ? item[deepField]
            : getItemDeep(item.id, parentChildMap),
          active,
          [keyField]: item[keyField] ?? `item-${item.id}`
        })
      );
    }
  });

  return result;
}

export function getItemDeep(id: number | string | null, parentChildMap: ParentChildMap): number {
  let deep = -1;
  let idString = String(id);
  while (idString in parentChildMap) {
    deep++;
    idString = String(parentChildMap[idString]);
  }
  return deep;
}

export interface Options<I extends Array<unknown>> {
  showInactive?: Ref<boolean>;
  parentChildMap: Ref<ParentChildMap>;
  postprocessing?: Ref<(items: I) => I>;
}

export function useFlatList<I extends Ref<Item[]>>(
  items: I,
  {
    showInactive = ref(false),
    parentChildMap,
    postprocessing = ref((items: I['value']) => items)
  }: Options<I['value']>
) {
  const resultItems = computed(() => {
    return unref(postprocessing)(
      getItems(unref(items), {
        showInactive: unref(showInactive),
        parentChildMap: unref(parentChildMap)
      })
    );
  });

  return {
    items: resultItems
  };
}
