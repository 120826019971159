<template>
  <validation-field :name="name" :label="label">
    <template #default="{ id, modelValue, onChange, name, invalid }">
      <base-select
        :id="id"
        :name="name"
        :value="modelValue"
        :invalid="invalid"
        @changeValue="
          onChange($event);
          $emit('change', $event);
        "
      >
        <slot name="default" />
      </base-select>
    </template>
  </validation-field>
</template>

<script>
import { ValidationField } from '@/components/ui/validate-form';
import BaseSelect from '@/components/select/select';

export default {
  name: 'ValidationFormSelect',
  components: {
    ValidationField,
    BaseSelect
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: undefined
    }
  },
  emits: ['change']
};
</script>

<i18n lang="json">{}</i18n>
