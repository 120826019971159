<template>
  <div class="autocomplete-list-item__link" :title="item[displayField]">
    {{ item[displayField] }}
  </div>
</template>
<script>
export default {
  name: 'VHeader',
  props: {
    item: { type: Object, required: true },
    displayField: { type: String, required: true }
  }
};
</script>

<i18n lang="json">{}</i18n>
