import dompurify, { DOMPurifyI } from 'dompurify';

export const DomPurify = {
  sanitize: (source: string, config: Parameters<DOMPurifyI['sanitize']>[1] = {}): string => {
    return dompurify.sanitize(source, {
      USE_PROFILES: {
        html: true
      },
      ...config,
      RETURN_DOM_FRAGMENT: false,
      RETURN_DOM: false
    });
  }
};
