import { ApiLayer } from '@/api/utils/api-layer';
import { axiosApp, AxiosClient } from '@/api/utils/client/axios';
import { ItemList } from '@/api/utils/types';
import { AuthType } from './integration';

type RequestPayload = {
  auth_type_id: string;
  topic: string;
  start_time: string;
  end_time: string;
};

type ConnectedConferenceIntegration = {
  id: number;
  name: string;
  auth: number;
  auth_type: AuthType;
  removed: null | boolean;
};

type Conference = Record<string, unknown>; // TODO: сделать более конкретные типы

class ConferenceLayer extends ApiLayer<AxiosClient> {
  create(payload: RequestPayload) {
    return this.methods.post<Conference>(`/conference`, payload);
  }

  getById(id: number) {
    return this.methods.get<Conference>(`/conference/${id}`);
  }

  delete(id: number) {
    return this.methods.delete(`/conference/${id}`);
  }

  getConnectedIntegrations() {
    return this.methods
      .get<ItemList<ConnectedConferenceIntegration>>('/account/conference')
      .then((res) => res.items);
  }

  disconnect(id: number) {
    return this.methods.delete(`/account/conference/${id}`);
  }
}
// TODO: переделать на именованный экспорт, в соответствии с README
export default new ConferenceLayer(axiosApp);
