<template>
  <hr :class="$style.divider" />
</template>

<script>
export default {
  name: 'MenuDivider'
};
</script>

<style module>
.divider {
  border: 0;
  border-bottom: solid 1px $sidebarTextColor;
  margin: 16px 12px 13px;
  opacity: 0.16;
}
</style>

<i18n lang="json">{}</i18n>
