export enum DocumentType {
  RECONCILIATION = 'reconciliation',
  CLOSING = 'closing_documents',
  OTHER = 'other'
}

export enum ClosingDocumentType {
  LICENSE_AGREEMENT = 'license_agreement',
  CONTRACT = 'work_contract',
  INVOICE = 'offer_invoice'
}
