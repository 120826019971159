<template>
  <base-quotas-modal
    v-bind="$props"
    :fetch-method="fetchMethod"
    :paginator-key="paginatorKey"
    v-on="listeners"
  >
    <template #caption>
      <slot name="caption" />
    </template>
    <template #sub-caption>
      <slot name="sub-caption" />
    </template>
    <template #quota="scopedProps">
      <slot name="quota" v-bind="scopedProps" />
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
    <template #close-text>
      <slot name="close-text" />
    </template>
  </base-quotas-modal>
</template>

<script>
import { QuotaState } from '@/types/quota-state';
import { VacancyQuotaAPI } from '@/api/vacancy-quota';
import BaseQuotasModal from '@/components/quotas-modal/base-quotas-modal';

export default {
  name: 'QuotasModal',
  components: {
    BaseQuotasModal
  },
  model: BaseQuotasModal.model,
  props: {
    open: BaseQuotasModal.props.open,
    frameBegin: BaseQuotasModal.props.frameBegin,
    includeAllFrames: BaseQuotasModal.props.includeAllFrames,
    vacancyId: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      default: QuotaState.ALL,
      validator(value) {
        return Object.values(QuotaState).includes(value);
      }
    },
    // Без этого флага для мультика можно взять только первую страницу со всеми дочками
    // и пагинировать придётся каждую дочку независимо.
    // Проще выгребсти всё и на фронте уже пагинировать.
    skipPagination: {
      type: Boolean,
      default: false
    }
  },
  emits: [BaseQuotasModal.model.event, 'change'],
  data() {
    return {
      items: null
    };
  },
  computed: {
    paginatorKey() {
      return `${this.vacancyId}-${this.status}-${this.includeAllFrames}`;
    },
    listeners() {
      const events = [BaseQuotasModal.model.event, 'close'];
      return Object.fromEntries(events.map((event) => [event, () => this.$emit(event)]));
    },
    fetchMethod() {
      if (this.skipPagination && !this.items) {
        // для ожидания загрузки всех заявок.
        // все заявки (при skipPagination = true) загружаются в mounted
        return () => new Promise(() => {});
      }
      if (this.skipPagination && this.items) {
        return this.fetchLocal;
      }
      return this.fetchPage;
    }
  },
  mounted() {
    if (this.skipPagination) {
      this.fetchAll().then((items) => {
        this.items = items;
      });
    }
  },
  methods: {
    fetchLocal(page) {
      const count = 10;
      const total = Math.ceil(this.items.length / count);
      const start = (page - 1) * count;
      const end = start + count;
      const items = this.items.slice(start, end);
      const result = {
        page,
        total,
        count,
        items
      };
      return Promise.resolve(result);
    },
    fetchAll() {
      return VacancyQuotaAPI.getListByVacancy(this.vacancyId, {
        status: this.status,
        include_all_frames: this.includeAllFrames,
        skip_pagination: true
      }).then((quotasByVacancy) => {
        // совмещаем всё в одно, т.к. просмотр заявок для мультивакансии должен содержать все заявки его дочек
        return Object.values(quotasByVacancy).reduce((acc, { items }) => {
          acc.push(...items);
          return acc;
        }, []);
      });
    },
    fetchPage(page) {
      return VacancyQuotaAPI.getListByVacancy(this.vacancyId, {
        page,
        count: 10,
        status: this.status,
        include_all_frames: this.includeAllFrames
      }).then((quotasByVacancy) => {
        // совмещаем всё в одно, т.к. просмотр заявок для мультивакансии должен содержать все заявки его дочек
        return Object.values(quotasByVacancy).reduce(
          (res, { page, total, count, items }) => {
            res.page = page;
            res.total += total;
            res.count += count;
            res.items.push(...items);
            return res;
          },
          {
            page,
            total: 0,
            count: 0,
            items: []
          }
        );
      });
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
