<script>
import { defineComponent } from 'vue';

import { AnalyticsAPI } from '@/api/analytics';
import { normalizeChildren } from '@/util/component-helpers';

export default defineComponent({
  name: 'AnalyticsSender',
  props: {
    analyticsFeature: {
      type: String,
      required: true
    }
  },
  methods: {
    send() {
      AnalyticsAPI.send('tariffbased_feature_click', {
        feature_type: this.analyticsFeature
      });
    }
  },
  render() {
    const children = normalizeChildren(this, {
      send: this.send
    });
    return children[0];
  }
});
</script>

<i18n lang="json">{}</i18n>
