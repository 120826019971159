<template>
  <div>
    <validation-form-input
      name="taxpayer_id"
      :label="$trlMessage('document_request.form.taxpayer_id.label')"
      :class="commonStyles.field"
    />
    <div :class="commonStyles.field">
      <span :class="$style.label">{{
        $trlMessage('document_request.form.reconciliation.label')
      }}</span>
      <div :class="$style.daterange">
        <validation-form-datepicker name="reconciliation_start" />
        <span>&mdash;</span>
        <validation-form-datepicker name="reconciliation_end" />
      </div>
    </div>
    <base-error v-if="reconciliationRangeError">
      {{ reconciliationRangeError.message }}
    </base-error>
    <base-delimiter />
    <validation-form-textarea
      name="comment"
      :label="$trlMessage('document_request.form.comment.label')"
      :placeholder="$trlMessage('document_request.form.comment.placeholder')"
      :class="[commonStyles.textarea, commonStyles.field]"
    />
    <file-upload
      name="file"
      :label="$trlMessage('document_request.form.attach.label')"
      :class="commonStyles.file"
    />
  </div>
</template>

<script>
import { string } from 'yup';
import BaseDelimiter from '@/components/delimiter/delimiter';
import BaseError from '@/components/error/error';
import { trlMessage } from '@/services/i18n';
import { DateTimeHelper } from '@/util/date-time-helper';
import ValidationFormInput from './partials/input';
import ValidationFormDatepicker from './partials/datepicker';
import ValidationFormTextarea from './partials/textarea';
import FileUpload from './partials/file-upload';
import commonStyles from './common-styles.module.css';

const DATE_RANGE_RULE_NAME = 'is-end-after-start';

const dateRangeValidator = (start, end) => {
  if (start === end) {
    return true;
  }
  const startDate = DateTimeHelper.parse({ date: start });
  const endDate = DateTimeHelper.parse({ date: end });
  return startDate.isBefore(endDate);
};

export default {
  name: 'ReconciliationForm',
  components: {
    BaseDelimiter,
    BaseError,
    ValidationFormInput,
    ValidationFormDatepicker,
    ValidationFormTextarea,
    FileUpload
  },
  schema: {
    taxpayer_id: string().required(),
    reconciliation_start: string()
      .required()
      .test(
        DATE_RANGE_RULE_NAME,
        trlMessage('document_request.form.error.date_range'),
        function (reconciliation_start) {
          const { reconciliation_end } = this.parent;
          return dateRangeValidator(reconciliation_start, reconciliation_end);
        }
      ),
    reconciliation_end: string()
      .required()
      .test(
        DATE_RANGE_RULE_NAME,
        trlMessage('document_request.form.error.date_range'),
        function (reconciliation_end) {
          const { reconciliation_start } = this.parent;
          return dateRangeValidator(reconciliation_start, reconciliation_end);
        }
      ),
    comment: string()
  },
  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    commonStyles: () => commonStyles,
    reconciliationRangeError() {
      const reconciliationErrors =
        (this.errors.reconciliation_start || this.errors.reconciliation_end) ?? [];
      return reconciliationErrors.find(({ type }) => type === DATE_RANGE_RULE_NAME);
    }
  }
};
</script>

<style lang="less" module>
.daterange {
  display: flex;
  gap: 4px;
  width: 350px;
}

.label + .daterange {
  margin-top: 3px;
}
</style>

<i18n lang="json">{}</i18n>
