<script>
import { mapGetters } from 'vuex';
import { normalizeChildren } from '@/util/component-helpers';

export default {
  name: 'RouterLinkCustom',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('config', ['organization'])
  },
  render() {
    const children = normalizeChildren(this, {
      href: `/settings/orgs/${this.organization.nick}/${this.item.to.name}`,
      navigate: () =>
        (window.location.href = `/settings/orgs/${this.organization.nick}/${this.item.to.name}`)
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
