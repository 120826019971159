export type ParentChildMap = Record<string, number | string | null>;
export type ChildrenMap = Record<string, (number | string)[]>;

export type HierarchyMap = {
  parentChildMap: ParentChildMap;
  directChildrenMap: ChildrenMap;
  deepChildrenMap: ChildrenMap;
};

export function makeHierarchyMap(parentChildMap: ParentChildMap): HierarchyMap {
  const directChildrenMap: ChildrenMap = {};
  const deepChildrenMap: ChildrenMap = {};

  function setInMap(map: ChildrenMap, parentId: string | null, id?: string | number) {
    parentId = String(parentId);
    if (!(parentId in parentChildMap)) {
      throw new Error(`parentChildMap не содержит ${parentId}`);
    }
    if (!map[parentId]) {
      map[parentId] = [];
    }
    const numberId = Number(id);
    if (id !== undefined) {
      map[parentId].push(Number.isNaN(numberId) ? id : numberId);
    }
  }

  Object.entries(parentChildMap).forEach(([id, parentId]) => {
    setInMap(deepChildrenMap, id);
    setInMap(directChildrenMap, id);
    let parentIdString = String(parentId);
    if (parentIdString in parentChildMap) {
      setInMap(directChildrenMap, parentIdString, id);
    }

    while (parentIdString in parentChildMap) {
      setInMap(deepChildrenMap, parentIdString, id);
      parentIdString = String(parentChildMap[parentIdString]);
    }
  });

  return {
    parentChildMap: Object.freeze(parentChildMap),
    directChildrenMap: Object.freeze(directChildrenMap),
    deepChildrenMap: Object.freeze(deepChildrenMap)
  };
}

type BaseItem = {
  id: string | number;
  parent: string | number | null;
};

type BaseGroup<Item> = {
  items: Item[];
};

export function makeHierarchyMapFromItems<Item extends BaseItem, Group extends BaseGroup<Item>>(
  items: Array<Item | Group>
): HierarchyMap {
  const sourceItems: Array<Item> = [];
  items.forEach((item) => {
    if ('items' in item) {
      sourceItems.push(...item.items);
    } else {
      sourceItems.push(item);
    }
  });

  return makeHierarchyMap(Object.fromEntries(sourceItems.map((item) => [item.id, item.parent])));
}
