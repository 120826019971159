<template>
  <div :class="rootClassName">
    <div :class="$style.content">
      <slot />
    </div>

    <div v-if="hasFooter" :class="$style.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import ActiveCampaignAPI from '@/api/active-campaign';
import { SlotChecker } from '@/util/slot-checker';

export default {
  name: 'VPromo',
  props: {
    size: {
      type: String,
      default: 'page',
      validator(value) {
        return ['page', 'page-small', 'popup', 'dropdown', 'dropdown-content'].includes(value);
      }
    },
    theme: {
      type: String,
      default: null,
      validator(value) {
        return [null, 'dark'].includes(value);
      }
    },
    activeCampaignTag: {
      type: String,
      default: undefined
    },
    analyticsTag: {
      type: String,
      default: undefined
    }
  },
  computed: {
    rootClassName() {
      return {
        [this.$style.root]: true,
        [this.$style[`size-${this.size}`]]: true,
        [this.$style[`theme-${this.theme}`]]: !!this.theme
      };
    },
    hasFooter() {
      return SlotChecker.isSlotNotEmpty(this.$slots.footer);
    }
  },
  mounted() {
    if (this.activeCampaignTag) {
      ActiveCampaignAPI.send(this.activeCampaignTag);
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';
@import '~@less/common/mixins/font.less';

.root {
  background-color: #ffffff;
  .font_regular();
}

.theme-dark {
  color: #fff;
  background-color: #38343f;
}

.size-page,
.size-page-small {
  width: 100%;
  max-width: 1000px;
  border-radius: 50px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
}

.content {
  position: relative;

  ul {
    max-width: 480px;
    list-style: none;
    padding: 0;
  }

  a {
    color: @linkColor;
    text-decoration: none;

    &:hover {
      color: @linkHoverColor;
    }
  }
}

.theme-dark .content {
  a {
    color: #ffea32;
  }
}

.footer {
  display: flex;

  &:empty {
    display: none;
  }

  & > * + * {
    margin-left: 5px;
  }
}

.size-page,
.size-page-small,
.size-popup {
  h2 {
    font-weight: 500;

    margin: 25px 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    margin: 0;
  }
  ul {
    margin: 0;
  }

  h2 + p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.size-page,
.size-popup {
  .content {
    font-size: 15px;
    line-height: 24px;

    h2 {
      font-size: 28px;
      line-height: 36px;
    }

    p + p {
      margin-top: 10px;
    }

    h2 + p {
      font-size: 18px;
      line-height: 28px;

      margin-top: 10px;
      margin-bottom: 15px;
    }

    p + ul,
    ul + p {
      margin-top: 10px;
    }

    ul li {
      padding-left: 34px;
      position: relative;

      &::before,
      &::after {
        position: absolute;
        background-color: currentColor;
        content: '';
        transform-origin: 50% 0;
        width: 2px;
      }

      &::before {
        left: 5px;
        top: 11px;
        height: 12px;
        transform: rotate(-40deg);
      }

      &::after {
        left: 25px;
        top: 4px;
        height: 20px;
        transform: rotate(40deg);
      }
    }

    li {
      margin-top: 10px;
    }
  }

  .footer {
    padding: 30px 0;

    border-top: 1px solid #eaeaea;
  }
}

.size-page-small {
  .content {
    font-size: 16px;
    line-height: 22px;

    h2 {
      font-size: 25px;
      line-height: 28px;
    }

    h2 + p {
      font-size: 16px;
      line-height: 22px;
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
}

.theme-dark.size-page,
.theme-dark.size-popup {
  .footer {
    border-top-color: #55505f;
  }
}

.size-page {
  margin-bottom: 30px;
  .content {
    padding: 40px 60px;
  }

  .footer {
    margin: -10px 60px 0;
  }
}

.size-page-small {
  margin-bottom: 30px;
  .content {
    padding: 30px 60px;
  }
}

.size-popup {
  border-radius: var(--Radius-x4, 16px) var(--Radius-x4, 16px);
  .content {
    padding: 30px 150px;
  }

  .footer {
    margin: 0 150px 0;
  }
}

.size-dropdown,
.size-dropdown-content {
  min-width: 400px;

  .content {
    font-size: 16px;
    line-height: 22px;
    padding: 0 0 15px;

    h2 {
      font-size: 20px;
      line-height: 24px;

      margin: 15px 0 0;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      margin: 5px 0 0;
    }
  }

  .footer {
    padding: 0 0 15px;
  }
}

.size-dropdown {
  .content {
    padding: 15px 25px;
  }

  .footer {
    margin: 0 25px;
  }
}
</style>

<i18n lang="json">{}</i18n>
