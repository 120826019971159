export function traverseTree(treeList, callback, depth = 0, parent = null) {
  treeList.forEach((node) => {
    callback(node, parent, depth);
    traverseTree(node.children, callback, depth + 1, node);
  });
}

export function filter(treeList, filterPredicate) {
  return treeList.filter(filterPredicate).map((node) => ({
    ...node,
    children: filter(node.children, filterPredicate)
  }));
}

export function filterPostorder(treeList, filterPredicate) {
  return treeList
    .map((node) => ({
      ...node,
      children: filterPostorder(node.children, filterPredicate)
    }))
    .filter(filterPredicate);
}

export function map(treeList, mapFn) {
  return treeList.map(mapFn).map((node) => ({
    ...node,
    children: map(node.children, mapFn)
  }));
}

export function mapPostorder(treeList, mapFn) {
  return treeList
    .map((node) => ({
      ...node,
      children: mapPostorder(node.children, mapFn)
    }))
    .map(mapFn);
}

export function sort(treeList, comparator) {
  return treeList
    .slice()
    .sort(comparator)
    .map((node) => ({
      ...node,
      children: sort(node.children, comparator)
    }));
}

export function flatten(treeList, parent = null) {
  return treeList
    .map((node) =>
      [
        { ...node.value, parent: parent?.value.id, ...node.meta },
        ...flatten(node.children, node)
      ].filter(Boolean)
    )
    .reduce((t1, t2) => [...t1, ...t2], []);
}
