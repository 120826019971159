<script>
import { SkeletonHelper, SkeletonZones } from '@/util/skeleton-helper';
import tariffs, { ServiceKindCodes } from '@/services/tariffs';
import { userPermissions } from '@/services/config/user-permissions';
import { ExceededUsersAPI } from '@/api/exceeded-users';
import { LicenseApi } from '@/api/license';
import { normalizeChildren } from '@/util/component-helpers';

export default {
  name: 'RecruitersLimitDataProvider',
  data() {
    return {
      // Превышен ли лимит по доступным рекрутерам
      isLimit: false,
      // количество «лишних» рекрутеров, превышающих лимит
      removeRecruitersCount: 0
    };
  },
  computed: {
    unlimitedTariffGroup() {
      return tariffs.getUnlimitedGroup(ServiceKindCodes.recruiter_limit);
    },
    limitRecruiters() {
      return tariffs.getTariffLimit(ServiceKindCodes.recruiter_limit) || Infinity;
    }
  },
  created() {
    const [isManager, isOwner] = [userPermissions.isManager, userPermissions.isOwner];

    if (isManager || isOwner) {
      SkeletonHelper.patchPromise(
        SkeletonZones.HEADER,
        Promise.all([ExceededUsersAPI.get(), LicenseApi.getWorkplaceInfo()])
          .then(([exceededUsers, coworker]) => {
            this.removeRecruitersCount = exceededUsers.count;
            const totalRecruiters = coworker.workplace_limit - coworker.available_workplaces;
            this.isLimit = this.removeRecruitersCount
              ? totalRecruiters >= this.limitRecruiters
              : false;
          })
          .catch(() => undefined)
      );
    } else {
      SkeletonHelper.patchPromise(SkeletonZones.HEADER, Promise.resolve());
    }
  },
  render() {
    const children = normalizeChildren(this, {
      isLimit: this.isLimit,
      removeRecruitersCount: this.removeRecruitersCount,
      unlimitedTariffGroup: this.unlimitedTariffGroup
    });
    return children[0];
  }
};
</script>

<i18n lang="json">{}</i18n>
