<template>
  <div :class="$style.item">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HeaderItem'
};
</script>

<style module>
.item:not(:empty) {
  flex: 0 0 auto;
  min-width: 64px;
  transition: background-color 0.1s linear;
}

.item > a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: $white;

  &:hover {
    background-color: $gray-900;
  }

  &:active {
    background-color: $gray-800;
  }
}
</style>

<i18n lang="json">{}</i18n>
