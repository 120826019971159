export enum CallState {
  PROGRESSING = 'PROGRESSING',
  CONNECTED = 'CONNECTED',
  FAILED = 'FAILED',
  ENDED = 'ENDED'
}

export type CallCallback = (state: CallState) => void;

export abstract class BaseCall {
  abstract hangup(): void;
  abstract get duration(): number;
}
