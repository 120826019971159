<template>
  <schemas-resource :schema="vacancyRequestSchema">
    <template #default="{ schemas, schemasFields, selectedSchemasFields, setFields }">
      <form @submit.prevent.stop="submit">
        <popup-layout single-column sticky-footer>
          <template #heading>
            <popup-heading>
              <request-list-header
                :schemas="schemas"
                :fields="schemasFields"
                :selected-fields="selectedSchemasFields"
                @set:fields="setFields"
              >
                {{ $trlMessage('vacancy_request.select') }}
              </request-list-header>
            </popup-heading>
          </template>

          <template #prepend>
            <request-list-container>
              <resource-paginator paginate-by-cursor :action="fetchRequests">
                <template #default="{ items: requests, allLoaded, loading, next }">
                  <content-loader v-if="!requests.length && loading" />
                  <recent-request-item
                    v-for="request in requests"
                    :key="request.id"
                    :vacancy-request-schema="schemas[0]"
                    :fields="selectedSchemasFields[vacancyRequestSchema.id]"
                    :item="request"
                    :checked="selectedRequest && selectedRequest.id === request.id"
                    @check="selectedRequest = request"
                  />
                  <div v-if="requests.length && !allLoaded" :class="$style.moreButtonContainer">
                    <more-button :loading="loading" @click="next">
                      {{ $trlMessage('button.show_more') }}
                    </more-button>
                  </div>
                </template>
              </resource-paginator>
            </request-list-container>
          </template>

          <template #footer>
            <base-button type="submit" color="black" :disabled="!selectedRequest">
              {{ $trlMessage('button.continue') }}
            </base-button>
            <base-button @click="$emit('cancel')">
              {{ $trlMessage('button.cancel') }}
            </base-button>
          </template>
        </popup-layout>
      </form>
    </template>
  </schemas-resource>
</template>

<script>
import BaseButton from '@/components/button/button';
import ContentLoader from '@/components/loader/content-loader';
import MoreButton from '@/components/more-button/more-button.vue';
import PopupHeading from '@/components/popup-layout/heading';
import PopupLayout from '@/components/popup-layout/popup-layout';
import RequestListContainer from '@/components/vjsf/inline-container.vue';
import RequestListHeader from '@/components/hf/request-list/header';
import ResourcePaginator from '@/components/resource-paginator/resource-paginator';
import SchemasResource from '@/components/quotas-modal/schemas-resource';
import VacancyRequestAPI from '@/api/vacancy-request';
import RecentRequestItem from './recent-request-item';

export default {
  name: 'RecentRequestsList',
  components: {
    BaseButton,
    ContentLoader,
    MoreButton,
    PopupHeading,
    PopupLayout,
    RecentRequestItem,
    RequestListContainer,
    RequestListHeader,
    ResourcePaginator,
    SchemasResource
  },
  props: {
    vacancyRequestSchema: {
      type: Object,
      required: true
    }
  },
  emits: ['cancel', 'submit'],
  data() {
    return {
      selectedRequest: null
    };
  },
  methods: {
    fetchRequests(cursor) {
      return VacancyRequestAPI.fetchRecentRequests(this.vacancyRequestSchema.id, {
        next_page_cursor: cursor
      }).then((response) => {
        if (!response.next_page_cursor && response.items.length === 1) {
          this.selectedRequest = response.items[0];
        }
        return response;
      });
    },
    submit() {
      if (this.selectedRequest) {
        this.$emit('submit', this.selectedRequest);
      }
    }
  }
};
</script>

<style lang="less" module>
.moreButtonContainer {
  padding-bottom: 20px;
}
</style>

<i18n lang="json">{}</i18n>
