<template>
  <span :class="[$style.root]">
    <base-icon v-if="icon" :type="icon" />
    <slot :tariff-group="tariffGroup">{{ $trlMessage('tariffs.badge-label', tariffGroup) }}</slot>
  </span>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'TariffBadge',
  components: { BaseIcon },
  props: {
    tariffGroup: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="less" module>
.root {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  height: 36px;
  padding: 0 20px;

  display: inline-block;
  white-space: nowrap;

  color: #ff8a00;
  border: 3px solid currentColor;
  border-radius: 18px;
}
</style>

<i18n lang="json">{}</i18n>
