import { ApiLayer } from '@/api/utils/api-layer';
import { AxiosClient, axiosApp } from '@/api/utils/client/axios';

class OrganizationLayer extends ApiLayer<AxiosClient> {
  getList() {
    return Promise.resolve(window.Organizations); // TODO: https://huntflow.atlassian.net/browse/DEV-5730
  }

  requestAccountingDocuments(values: Record<string, string | Blob>) {
    const url = '/accounting/docs';
    const form = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined) {
        form.append(key, value);
      }
    });
    return this.methods.post(url, form);
  }
}

export const OrganizationAPI = new OrganizationLayer(axiosApp);
