<template>
  <span :class="rootClassName">
    <img
      v-if="shouldShowImage"
      :src="src"
      data-qa="userpic"
      @load="handleLoad"
      @error="handleError"
    />
    <slot name="default"></slot>
  </span>
</template>

<script>
export default {
  name: 'BaseUserpic',
  props: {
    src: {
      type: String,
      default: null
    },
    squared: {
      type: Boolean,
      default: false
    },
    hiddenLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['load', 'error'],
  data() {
    return {
      isLoaded: !this.src,
      isBroken: false
    };
  },
  computed: {
    shouldShowImage() {
      return this.src && !this.isBroken;
    },
    rootClassName() {
      const isVisible = this.isLoaded || this.isBroken || !this.hiddenLoading;
      const isDefault = !this.isLoaded || this.isBroken || !this.src;
      return {
        [this.$style.root]: true,
        [this.$style.squared]: this.squared,
        [this.$style.hidden]: isVisible === false,
        [this.$style.default]: isDefault
      };
    }
  },
  watch: {
    isLoaded: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit('load');
        }
      }
    },
    isBroken: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit('error');
        }
      }
    },
    src(value) {
      // Сбрасываем на дефолтное состояние
      this.isLoaded = !value;
      this.isBroken = false;
    }
  },
  methods: {
    handleLoad() {
      this.isLoaded = true;
    },
    handleError() {
      this.isBroken = true;
    }
  }
};
</script>

<style lang="less" module>
.root {
  aspect-ratio: 1/1;
  display: block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.root.hidden {
  visibility: hidden;
}

.squared {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--userpic-border-radius, 16px);
}

.default {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(./default-userpic.svg);
  background-color: #f5f5f5;
  background-size: cover;

  img {
    display: none;
  }
}
</style>

<i18n lang="json">{}</i18n>
