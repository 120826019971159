import { WIDGET_TYPE } from '@/components/vjsf/vjsf';
import CheckboxesWidget from './view-field-checkboxes';
import RadioWidget from './view-field-radio';
import SelectWidget from './view-field-select';
import TextWidget from './view-field-text';
import DateWidget from './view-field-date';
import TextareaWidget from './view-field-textarea';
import HtmlWidget from './view-field-html';
import PartialDictionaryWidget from './view-field-partial-dictionary';
import VueVoid from '@/components/vue-void';

export const widgets = {
  [WIDGET_TYPE.STRING]: TextWidget,
  [WIDGET_TYPE.INTEGER]: TextWidget,
  [WIDGET_TYPE.PARTIAL_DICTIONARY]: PartialDictionaryWidget,
  [WIDGET_TYPE.HTML]: HtmlWidget,
  [WIDGET_TYPE.TEXT]: TextareaWidget,
  [WIDGET_TYPE.SELECT]: SelectWidget,
  [WIDGET_TYPE.DATE]: DateWidget,
  [WIDGET_TYPE.HIDDEN]: VueVoid,
  [WIDGET_TYPE.SUBMIT]: VueVoid,
  [WIDGET_TYPE.CHECKBOX]: CheckboxesWidget,
  [WIDGET_TYPE.RADIO]: RadioWidget
};
