import { WIDGET_TYPE } from '../../vjsf.ts';
import { always, cond } from '../utils';
import * as LegacySchemaUtil from '../legacy-schema-utils';

const mapping = [
  [LegacySchemaUtil.isDictionaryLike, always(WIDGET_TYPE.PARTIAL_DICTIONARY)],
  [LegacySchemaUtil.isComplexLike, always(undefined)], // у комплексных полей нет своего виджета
  [LegacySchemaUtil.isHtml, always(WIDGET_TYPE.HTML)],
  [LegacySchemaUtil.isInteger, always(WIDGET_TYPE.STRING)],
  [LegacySchemaUtil.isSelect, always(WIDGET_TYPE.SELECT)],
  [LegacySchemaUtil.isString, always(WIDGET_TYPE.STRING)],
  [LegacySchemaUtil.isText, always(WIDGET_TYPE.TEXT)],
  [LegacySchemaUtil.isDate, always(WIDGET_TYPE.DATE)],
  [LegacySchemaUtil.isHidden, always(WIDGET_TYPE.HIDDEN)],
  [always(true), always(WIDGET_TYPE.STRING)]
];

export function getWidget(field) {
  const ctor = cond(mapping)(field);
  return ctor();
}
