<template>
  <base-modal
    :is-opened="isOpened"
    :hide="handleHide"
    :modal-class="modalClass"
    :auto-dismiss="!dirty"
    :on-top="onTop"
  >
    <template #before>
      <slot name="before" />
    </template>
    <template #default="{ hide, dismiss, isDismissing, resolveDismissing }">
      <div :class="[$style.dialog, $style['size_' + size], contentClass]" role="document">
        <slot
          name="confirm"
          :hide="hide"
          :dismiss="dismiss"
          :is-dismissing="isDismissing"
          :resolve-dismissing="resolveDismissing"
        >
          <native-close-confirmation
            v-if="isDismissing"
            :confimation-text="confirmationText"
            @resolve="resolveDismissing"
          />
        </slot>
        <slot :hide="hide" :dismiss="dismiss" :set-dirty="setDirty" />
      </div>
    </template>
  </base-modal>
</template>

<script>
import { toRefs, watch } from 'vue';

import { trlMessage } from '@/services/i18n';
import { useModalNext } from '@/components/modal-next/use-modal-next';
import BaseModal from './modal-next';
import NativeCloseConfirmation from './native-close-confirmation';

export default {
  name: 'ModalWrapper',
  components: { BaseModal, NativeCloseConfirmation },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: Boolean,
    confirmationText: {
      type: String,
      default: trlMessage('modal.close.confirm')
    },
    size: {
      type: String,
      default: 'default' /* 775px */
    },
    onTop: BaseModal.props.onTop,
    modalClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    }
  },
  emits: ['change', 'show', 'hide'],
  setup(props, { emit }) {
    const { open } = toRefs(props);
    const { show, hide, isOpened } = useModalNext();
    watch(
      open,
      (open) => {
        open ? show() : hide();
        emit(open ? 'show' : 'hide');
      },
      { immediate: true }
    );
    return {
      isOpened
    };
  },
  data() {
    return {
      dirty: false
    };
  },
  watch: {
    open(open) {
      if (!open) {
        this.dirty = false;
      }
    }
  },
  methods: {
    handleHide() {
      this.$emit('change', false);
    },
    setDirty(flag) {
      this.dirty = flag;
    }
  }
};
</script>

<style module>
.dialog {
  position: relative;
  margin: 75px auto;
}

.size_default {
  width: 764px;
}

.size_wysiwyg {
  width: 1025px;
}

.size_maxcontent {
  width: max-content;
}
</style>

<i18n lang="json">{}</i18n>
