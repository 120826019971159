<template>
  <span />
</template>

<script>
export default {
  name: 'MountTrigger',
  emits: ['mounted'],
  mounted() {
    this.$nextTick(() => {
      this.$emit('mounted');
    });
  }
};
</script>

<i18n lang="json">{}</i18n>
