<template>
  <modal-autocomplete
    :id="id"
    :show="show"
    :items="items"
    :loading="loading"
    :page-mode="pageMode"
    :multiple="multiple"
    :value="value"
    :hierarchy-map="hierarchyMap"
    :hierarchy-map-with-inactive="hierarchyMapWithInactive"
    :show-inactive="showInactive"
    :only-leaf="onlyLeaf"
    :max-values="maxValues"
    :activity-toggle="activityToggle"
    :query="query"
    :name="name"
    :placeholder="placeholder"
    :search-placeholder="searchPlaceholder"
    :required="required"
    :clearable="!required"
    :disabled="disabled"
    :collapsible="collapsible"
    :title="title"
    :loop-pointer="false"
    :disable-auto-compress="disableAutoCompress"
    @search="$emit('search', $event)"
    @reset="$emit('reset')"
    @toggle-activity-toggle="$emit('toggle-activity-toggle', $event)"
    @change="$emit('change', $event)"
    @collapsed="$emit('collapsed', $event)"
    @visibility-state="$emit('visibility-state', $event)"
  >
    <template #content>
      <slot name="content" />
    </template>
    <template #toggle="scopedProps">
      <slot name="toggle" v-bind="scopedProps" />
    </template>
    <template #toggle-text="scopedProps">
      <slot name="toggle-text" v-bind="scopedProps" />
    </template>
    <template #before-search>
      <slot name="before-search" />
    </template>
    <template #after-search>
      <slot name="after-search" />
    </template>
    <template #not-found>
      <slot name="not-found" />
    </template>
    <template #loading>
      <slot name="loading" />
    </template>
    <template #item-header="props">
      <spinner-item :deep="props.deep" />
    </template>
    <template #item-header-title="props">
      <slot name="item-header-title" v-bind="props" />
    </template>
    <template #item="props">
      <collapsed-item
        :item-props="props"
        :collapsible="collapsible"
        :parent-id="hierarchyMap.parentChildMap[props.item.id]"
        :previous-parent-id="hierarchyMap.parentChildMap[props.previousItem.id]"
      >
        <template #after-level="afterLevelProps">
          <slot v-bind="afterLevelProps" name="after-level" />
        </template>
        <template #item-title>
          <slot name="item-title" v-bind="props" />
        </template>
        <template #item-hint>
          <slot name="item-hint" v-bind="props" />
        </template>
        <template #item-subtitle>
          <slot name="item-subtitle" v-bind="props" />
        </template>
      </collapsed-item>
    </template>
    <template #after="props">
      <slot name="after" v-bind="props" />
    </template>
    <template #after-list="props">
      <slot name="after-list" v-bind="props" />
    </template>
  </modal-autocomplete>
</template>

<script>
import { ModalAutocomplete } from '@/components/base-autocomplete';
import CollapsedItem from '@/components/partial-dictionary-autocomplete/collapsed-item';
import SpinnerItem from '@/components/list-item/spinner-item';

export default {
  name: 'PartialModalAutocomplete',
  components: { ModalAutocomplete, CollapsedItem, SpinnerItem },
  props: {
    loading: ModalAutocomplete.props.loading,
    pageMode: ModalAutocomplete.props.pageMode,
    items: ModalAutocomplete.props.items,
    itemSize: ModalAutocomplete.props.itemSize,
    showInactive: ModalAutocomplete.props.showInactive,
    hierarchyMap: ModalAutocomplete.props.hierarchyMap,
    hierarchyMapWithInactive: ModalAutocomplete.props.hierarchyMapWithInactive,
    multiple: ModalAutocomplete.props.multiple,
    value: ModalAutocomplete.props.value,
    maxValues: ModalAutocomplete.props.maxValues,
    onlyLeaf: ModalAutocomplete.props.onlyLeaf,
    searchPlaceholder: ModalAutocomplete.props.searchPlaceholder,
    clearable: ModalAutocomplete.props.clearable,
    focus: ModalAutocomplete.props.focus,
    query: ModalAutocomplete.props.query,
    activityToggle: ModalAutocomplete.props.activityToggle,
    placeholder: ModalAutocomplete.props.placeholder,
    title: ModalAutocomplete.props.title,
    name: ModalAutocomplete.props.name,
    disabled: ModalAutocomplete.props.disabled,
    required: ModalAutocomplete.props.required,
    invalid: ModalAutocomplete.props.invalid,
    collapsible: ModalAutocomplete.props.collapsible,
    disableAutoCompress: ModalAutocomplete.props.disableAutoCompress,
    id: ModalAutocomplete.props.id,
    loadingByLevel: {
      type: Object,
      default: () => ({})
    },
    show: Boolean
  },
  emits: ['change', 'search', 'reset', 'toggle-activity-toggle', 'collapsed', 'visibility-state']
};
</script>

<i18n lang="json">{}</i18n>
