<template>
  <div :class="$style.wrapper">
    <h2 :class="$style.title" v-html="title" />
    <div v-if="subTitle" :class="$style.subTitle">
      <button type="button" :class="$style.id" @click="handleCopyId(orgId)">
        ID {{ orgId }}
      </button>
      <span v-html="subTitle" />
    </div>
  </div>
</template>

<script>
import { appConfig } from '@/services/config/app-config';
import { notification } from '@/services/notification/notification';
import { mapGetters } from 'vuex';
import { userPermissions } from '@/services/config/user-permissions';
import tariffs from '@/services/tariffs';
import { DateTimeHelper } from '@/util/date-time-helper';

export default {
  name: 'SettingTitle',
  props: {
    isOrgs: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('config', ['organization']),
    title() {
      if (!this.isOrgs) {
        return this.$i18n('settings.title.main-block');
      }
      return this.organization.name;
    },
    permission() {
      const { isManager, isOwner } = userPermissions;
      return isOwner ? 'user.owner' : isManager ? 'user.recruiter' : 'user.customer';
    },
    subTitle() {
      if (!this.isOrgs) {
        return null;
      }
      if (userPermissions.isWatcher) {
        return this.$i18n('settings.no-tariff', { role: this.$i18n(this.permission) });
      }
      const currTariff = tariffs.licenseInfo.current_license.tariff_group.translations.name;
      const tariffName = appConfig.get('lang') === 'ru' ? currTariff.ru_RU : currTariff.en_US;
      const end = DateTimeHelper.toShortFormat({
        date: tariffs.licenseInfo.current_license.scheduled_end_at
      });
      return this.$i18n('settings.tariff', {
        tariff: tariffName,
        end,
        role: this.$i18n(this.permission)
      });
    },
    orgId() {
      return this.organization.id;
    }
  },
  methods: {
    handleCopyId(id) {
      const promise = navigator.clipboard.writeText(id);
      promise.then(() => {
        notification.notify({
          content: this.$i18n('id.copied', {
            id: id,
            orgName: this.title
          }),
          isError: false
        });
      });
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font';

.title {
  font-size: 18px;
  line-height: 28px;
  .font_bold();
  padding: 0;
  margin: 0;
}
.subTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #7c7c7c;
  padding: 0;
  margin: 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 16px;
  margin: 0;
}
.id {
  @mixin reset-button-styles;
  cursor: pointer;
  &:hover {
    color: #ff533b;
  }
}
</style>

<i18n lang="json">
{
  "id.copied": {
    "ru_RU": "ID {id} скопирован ({orgName})",
    "en_US": "ID {id} copied ({orgName})"
  },
  "settings.no-tariff": {
    "ru_RU": "&nbsp· &nbsp{role}",
    "en_US": "&nbsp· &nbsp{role}"
  },
  "settings.tariff": {
    "ru_RU": "&nbsp· &nbspТариф «{tariff}» до {end}&nbsp · &nbsp{role}",
    "en_US": "&nbsp· &nbspPlan \"{tariff}\" until {end}&nbsp · &nbsp{role}"
  },
  "user.recruiter": {
    "ru_RU": "Рекрутер",
    "en_US": "Recruiter"
  },
  "user.owner": {
    "ru_RU": "Управляющий рекрутер",
    "en_US": "Owner"
  },
  "user.customer": {
    "ru_RU": "Заказчик",
    "en_US": "Hiring manager"
  },
  "settings.title.main-block": {
    "ru_RU": "Мой Хантфлоу",
    "en_US": "My Huntflow"
  }
}
</i18n>
