import { DOMWindow } from 'jsdom';

type Trigger = Element | null;

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type Layer = {
  element: Element;
  trigger?: Trigger;
  id: string;
  hide: (a: Event | void) => void | boolean | PromiseLike<void | boolean>;
};

type RawLayer = PartialBy<Omit<Layer, 'type'>, 'trigger'>;

export class Layers {
  constructor(window: DOMWindow, root: Element | void) {
    this.$document = window.document.documentElement;
    this.window = window;
    this.root = root || this.$document;

    this.addHandleEvents(window.document);
  }

  private lastClickedElement: Element | undefined = undefined;
  private $document: Element;
  private root: Element;
  private window: DOMWindow;
  private stack: Array<Layer> = [];

  private get length() {
    return this.stack.length;
  }

  get lastLayer(): Layer | undefined {
    return this.stack[this.length - 1];
  }

  private addHandleEvents(document: Document) {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        const lastLayer = this.lastLayer;
        if (!lastLayer?.element.getAttribute('data-ignore-escape')) {
          this.remove(lastLayer);
        }
      }
    });
  }

  private removeFromStack(item: Layer) {
    const index = this.stack.indexOf(item);
    if (item && index > -1) {
      this.stack.splice(index, 1);
    }
  }

  private validateLastClicked() {
    if (!this.lastClickedElement) {
      return;
    }
    if (!this.root.contains(this.lastClickedElement)) {
      this.lastClickedElement = undefined;
    }
  }

  private returnTrigger(rawTrigger: Trigger | undefined): Trigger {
    if (rawTrigger) {
      return rawTrigger;
    }
    this.validateLastClicked();

    if (this.lastClickedElement) {
      return this.lastClickedElement;
    }

    return this.lastLayer?.element ?? null;
  }

  private makeLayer(config: RawLayer): Layer {
    const { trigger: rawTrigger } = config;

    const trigger = this.returnTrigger(rawTrigger);

    return {
      ...config,
      trigger
    };
  }

  private add(item: Layer): Layer | false {
    this.lastClickedElement = undefined;

    if (this.has(item.id)) {
      return false;
    }

    this.stack.push(item);

    return item;
  }

  private async remove(item: Layer | void, event?: Event) {
    if (!item) {
      return true;
    }
    this.lastClickedElement = undefined;

    const closed = await item.hide(event);
    if (closed === false) {
      return false;
    }

    if (event) {
      event.stopPropagation();
    }

    this.removeFromStack(item);

    return true;
  }

  private getById(id: string): Layer | undefined {
    return this.stack.find((layer) => layer.id === id);
  }

  public removeLast(event?: Event): Promise<boolean> {
    return this.remove(this.lastLayer, event);
  }

  public async removeById(id: string, event?: Event): Promise<boolean> {
    const index = this.stack.findIndex((el) => el.id === id);
    if (index === -1) {
      return Promise.resolve(false);
    }

    const layer = this.stack[index];

    if (this.lastLayer === layer) {
      return this.remove(layer, event);
    }

    const removeStack = this.stack.slice(index).reverse();

    let i = 0;
    while (i !== removeStack.length && (await this.removeById(removeStack[i].id))) {
      i += 1;
    }

    return Promise.resolve(i === removeStack.length);
  }

  public has(id: string): boolean {
    return Boolean(this.getById(id));
  }

  public addLayer(config: RawLayer): false | Layer {
    return this.add(this.makeLayer(config));
  }

  /**
   * Event для backbone, не будет backbone, не нужен будет
   * @deprecated
   */
  public dismiss(event?: Event): Promise<boolean> {
    return this.removeLast(event);
  }

  public async clear(): Promise<boolean> {
    while ((await this.removeLast()) && this.length !== 0) {
      // условия хватает :-)
    }
    return this.length === 0;
  }

  public changeRoot(root: Element): void {
    this.root = root;
  }
}
