import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient } from '@/api/utils/client/axios';
import type { LegacySchema } from '@/types/legacy-schema';
import { mapSchemaFields } from './map-schema-fields';
import { CancelablePromise } from '@/api/utils/cancelable-promise';

class VacancyLegacySchemaLayer extends ApiLayer<AxiosClient> {
  constructor(client: AxiosClient) {
    super(client);
    this.get = this.memoizeMethod(this.get);
  }

  get(): CancelablePromise<LegacySchema> {
    return this.methods
      .get<LegacySchema<{ name: string }>>('/vacancy/fields')
      .then(mapSchemaFields);
  }
}

export const VacancyLegacySchemaApi = new VacancyLegacySchemaLayer(axios);
