<template>
  <li :class="$style.item" :data-qa="orgActive ? 'org' : ''">
    <list-entry-button
      :class="[$style.button, { [$style.active]: orgActive }]"
      :caption="organization.name"
      :kind="orgActive ? 'default' : 'blue'"
      icon="circle-logo-menu"
      data-qa="organization-name"
      @click="changeOrg"
    />
  </li>
</template>

<script>
import ListEntryButton from '@/components/button/list-entry-button.vue';

export default {
  name: 'OrganisationItem',
  components: { ListEntryButton },
  props: {
    organization: {
      type: Object,
      required: true
    },
    orgActive: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    changeOrg() {
      window.location.href = '/my/' + this.organization.nick;
    }
  }
};
</script>

<style module>
.item {
  padding-left: 8px;
  padding-right: 8px;
  &:not(:last-child) {
    /* border-bottom: 1px solid $delimiterColor; */
  }
}

.button {
  width: 100%;
  cursor: pointer;
  font-weight: 500;
  padding: 8px 0 8px 17px;
  border-radius: 8px;
  &:hover {
    background: $black-7;
  }
}

.active {
  cursor: default;
  &:hover {
    background: transparent;
  }
}
</style>

<i18n lang="json">{}</i18n>
