<script>
import { getMethodsById } from '@/api/dictionary-partial';
import { VacancyNamingHelper } from '@/util/vacancy-naming';
import { defineComponent } from 'vue';
import { normalizeChildren } from '@/util/component-helpers';

const MIN_LOADING_TIME_MS = 400;

export default defineComponent({
  name: 'ToggleText',
  props: {
    text: {
      type: String,
      default: ''
    },
    showText: Boolean,
    multiple: Boolean,
    loading: Boolean,
    count: {
      type: Number,
      required: true
    },
    value: {
      type: [Array, String, Number],
      default: undefined
    },
    multipleText: {
      type: String,
      required: true
    },
    dictionaryId: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    hierarchyMap: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loaderCanHidden: true,
      resultFullName: '',
      nameLoading: false
    };
  },
  computed: {
    partialMethods() {
      return getMethodsById(this.dictionaryId);
    },
    computedText() {
      if (this.multiple) {
        return this.multipleText;
      }
      if (this.showText) {
        return this.text;
      }
      if (!this.value) {
        return '';
      }
      if (this.loading || this.nameLoading || !this.loaderCanHidden) {
        return this.$trlMessage('Loading');
      }
      return this.resultFullName;
    },
    fullName() {
      if (this.multiple) {
        return null;
      }

      const names = [];
      let value = this.value;
      while (value) {
        const name = this.findName(value);
        if (name === undefined) {
          return null;
        }
        names.push(name);
        value = this.hierarchyMap.parentChildMap[value];
      }

      return names.join(VacancyNamingHelper.DIVIDER);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.multiple || !value) {
          return;
        }

        if (this.fullName) {
          this.resultFullName = this.fullName;
          return;
        }

        clearTimeout(this.loadingTimeout);
        this.loaderCanHidden = false;
        this.loadingTimeout = setTimeout(() => {
          this.loaderCanHidden = true;
        }, MIN_LOADING_TIME_MS);
        this.getResultName(value);
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.loadingTimeout);
  },
  methods: {
    getResultName(value) {
      this.nameLoading = true;
      this.partialMethods
        .getFullName(value)
        .then((res) => {
          this.handleName(res);
        })
        .finally(() => {
          this.nameLoading = false;
        });
    },
    findName(value) {
      return this.items.find(({ id }) => id === value)?.name;
    },
    handleName(names = []) {
      this.resultFullName = names.join(VacancyNamingHelper.DIVIDER);
    }
  },
  render() {
    const children = normalizeChildren(this, {
      text: this.computedText,
      count: this.count
    });
    return children[0];
  }
});
</script>

<i18n lang="json">{}</i18n>
