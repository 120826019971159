import debounce from 'lodash/debounce';
import { createEmitter } from '@/services/emitter/emitter';

type UrlSetting = string | (() => string);

interface Settings {
  url: UrlSetting;
  pingInterval?: number;
}

type Events = {
  open: Event;
  message: MessageEvent;
  close: Event;
  error: Event;
};

const RECONNECT_ATTEMPT_COUNT = 3;
const RECONNECT_TIMEOUT = 5000;

export default class WebsocketChannel {
  private active = true;
  private reconnectCounter = RECONNECT_ATTEMPT_COUNT;
  private socket: WebSocket;
  private pingTimeout?: ReturnType<typeof setTimeout>;
  private pingIntervalMs?: number;
  private _url: UrlSetting;
  private debouncedReconnect!: (a: Event) => WebSocket | ErrorEvent | undefined;
  private timeoutHandler: null | ReturnType<typeof setTimeout> = null;
  emitter = createEmitter<Events>();

  private get url(): string {
    if (typeof this._url === 'function') {
      return this._url();
    }

    return this._url;
  }

  constructor(settings: Settings) {
    this.debouncedReconnect = debounce(this.reconnect, RECONNECT_TIMEOUT);
    this._url = settings.url;
    this.pingIntervalMs = settings.pingInterval;
    this.socket = this.createSocket(this.url);
    this.handleOffline = this.handleOffline.bind(this);
    this.handleOnline = this.handleOnline.bind(this);

    // В Chrome и Firefox соединение ведёт себя по-разному в случае потери сети:
    // в Firefox - ошибка, Chrome - продолжает висеть в открытом статусе.
    // Для приведения к одному поведению сделана обработка событий offline/online
    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);
  }

  private handleOffline() {
    this.socket?.close();
  }

  private handleOnline(event: Event) {
    this.reconnectCounter = RECONNECT_ATTEMPT_COUNT;
    console.log(
      `[WebsocketChannel] ${new Date().toLocaleString()} - You are now connected to the network.`
    );
    this.debouncedReconnect(event);
  }

  close(): void {
    this.active = false;
    this.socket?.close();
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('online', this.handleOnline);
  }

  private createSocket(url: string): WebSocket {
    const socket = new WebSocket(url);
    socket.addEventListener('open', (event) => {
      this.reconnectCounter = RECONNECT_ATTEMPT_COUNT;
      this.emitter.emit('open', event);
      console.log(`[WebsocketChannel] ${new Date().toLocaleString()} - open`);
      this.ping();
    });
    socket.addEventListener('message', (event) => {
      this.emitter.emit('message', event);
    });
    socket.addEventListener('close', (event) => {
      this.emitter.emit('close', event);
      if (!navigator.onLine || !this.active) {
        // Обработано в отдельном хендлере
        return;
      }
      this.debouncedReconnect(event);
    });
    socket.addEventListener('error', (event) => {
      this.emitter.emit('error', event);
      this.debouncedReconnect(event);
    });
    return socket;
  }

  private ping() {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }

    if (!this.pingIntervalMs) {
      return undefined;
    }

    this.pingTimeout = setTimeout(() => {
      if (this.socket && navigator.onLine && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send('ping');
        this.ping();
      }
    }, this.pingIntervalMs);
  }

  private reconnect(event: Event) {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }

    if (!this.socket || !navigator.onLine) {
      return undefined;
    }

    if (this.socket.readyState < WebSocket.CLOSING) {
      return this.socket;
    }

    if (this.reconnectCounter <= 0) {
      const message = 'WS reconnect failure';
      const errorInitEvent: ErrorEventInit = {
        error: event,
        message
      };
      const error = new ErrorEvent('WSReconnectFailure', errorInitEvent);
      this.emitter.emit('error', error);
      console.error(`[WebsocketChannel] ${new Date().toLocaleString()} - ${message}`);
      return error;
    }

    this.timeoutHandler = setTimeout(
      () => {
        console.warn(
          `[WebsocketChannel] ${new Date().toLocaleString()} - reconnect ${this.reconnectCounter}`
        );
        this.reconnectCounter = this.reconnectCounter - 1;
        this.socket = this.createSocket(this.url);
      },
      (RECONNECT_ATTEMPT_COUNT - this.reconnectCounter) * RECONNECT_TIMEOUT
    );
  }
}
