import { VacancyApplicantAPI } from '@/api/vacancy-applicant';
import { VacancyAPI } from '@/api/vacancy';
import { userPermissions } from '@/services/config/user-permissions';
import { UserPermissionType } from '@/types/user-permission-type';
import vuexStore from '@/store';

type Url = string;

interface Vacancy {
  id: number;
  blocks?: Vacancy[];
}

function onVacancyAdd(vacancy: Vacancy): Promise<Url> {
  userPermissions.addPermission({
    vacancy: vacancy.id,
    permission: UserPermissionType.vacancy
  });

  vuexStore.commit('vacancies/add', { vacancy });
  (vacancy.blocks ?? []).forEach((child) => {
    userPermissions.addPermission({
      vacancy: child.id,
      permission: UserPermissionType.vacancy
    });
    vuexStore.commit('vacancies/add', { vacancy: child });
  });

  return Promise.resolve(`/vacancy/${vacancy.id}`);
}

// пока нет у нас описанного интерфейса
interface Applicant {
  [key: string]: unknown;
}

function onApplicantAdd(applicant: Applicant, vacancyId?: number): Promise<Url> {
  return new Promise((resolve) => {
    if (!vacancyId) {
      resolve(`/applicants/filter/all/id/${applicant.id}`);
      return;
    }

    VacancyAPI.fetchById(vacancyId)
      .then((vacancyInfo) => {
        const targetVacancyId = vacancyInfo.parent || vacancyId;
        const payload = {
          applicant: applicant.id,
          vacancy_id: targetVacancyId
        };
        VacancyApplicantAPI.create(payload as any).then((response) => {
          resolve(`/vacancy/${targetVacancyId}/filter/${response.status}/id/${applicant.id}`);
        });
      })
      .catch(() => {
        resolve(`/applicants/filter/all/id/${applicant.id}`);
      });
  });
}

function navigate(url: Url): void {
  window.location.hash = url;
}

export const EntityAdd = {
  onVacancyAdd,
  onApplicantAdd,
  navigate
};
