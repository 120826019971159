<template>
  <div class="autocomplete-list-item__link" :title="htmlTitle">
    <span v-if="isHold" class="autocomplete-list-item__icon autocomplete-list-item__icon_hold">
      <Icon type="pause-2" /> </span
    ><span v-if="item.hidden" class="autocomplete-list-item__icon"> <Icon type="eye-slash" /> </span
    ><span
      v-if="item.priority"
      class="autocomplete-list-item__icon autocomplete-list-item__icon_flag"
    >
      <Icon type="flag" /> </span
    >{{ item[displayField] }}
    <div v-if="hasSubtitle" class="autocomplete-list-item__sub">
      <template v-if="item.multiple">
        {{ $trlMessage('autocomplete.multivacancy') }}
      </template>
      <template v-else>
        {{ item[subDisplayField] }}
      </template>
    </div>
  </div>
</template>

<script>
import Icon from '../icon.vue';

export default {
  name: 'VVacancy',
  components: { Icon },
  props: {
    item: { type: Object, required: true },
    displayField: { type: String, required: true },
    subDisplayField: { type: String, required: true }
  },
  computed: {
    isHold() {
      return this.item.state === 'HOLD';
    },
    hasSubtitle() {
      return Boolean(
        (this.subDisplayField && this.item[this.subDisplayField]) || this.item.multiple
      );
    },
    htmlTitle() {
      const title = this.item[this.displayField];
      const subtitle =
        !this.item.multiple && this.subDisplayField && this.item[this.subDisplayField];
      return subtitle ? `${title} – ${subtitle}` : title;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
