import Axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { XSRFHelper } from '@/util/xsrf-helper';
import { ApiClient, CancelTokenSource } from './index';
import { CancelablePromise, isCancel as CancelablePromiseIsCancel } from '../cancelable-promise';
import { normalizeQueryParams } from '../normalize-query-params';
import { IUserPermissions } from '@/types/user-permissions';
import { userPermissions } from '@/services/config/user-permissions';
export type { AxiosError } from 'axios';

export { AxiosRequestConfig };
export type AxiosMethods = {
  get<T = unknown>(url: string, config?: AxiosRequestConfig): CancelablePromise<T>;
  delete<T = unknown>(url: string, config?: AxiosRequestConfig): CancelablePromise<T>;
  post<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): CancelablePromise<T>;
  put<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): CancelablePromise<T>;
  patch<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): CancelablePromise<T>;
};

export interface AxiosClient extends ApiClient {
  getMethods(tokenSource: CancelTokenSource): AxiosMethods;
}

declare global {
  interface Window {
    DefaultCurrencyCode?: string;
    UserPermissions: IUserPermissions;
  }
}

function createCancelTokenSource(): CancelTokenSource {
  return Axios.CancelToken.source();
}

export function isCancel(exception: unknown): boolean {
  return Axios.isCancel(exception) || CancelablePromiseIsCancel(exception);
}

const createAxiosInstance = (options: AxiosRequestConfig): AxiosClient => {
  const axios = Axios.create(options);
  axios.defaults.headers.common['X-XSRFToken'] = XSRFHelper.getToken();
  axios.defaults.headers.common.Accept = 'application/json';

  axios.interceptors.response.use((response) => response.data);

  return {
    baseURL: options.baseURL || '',
    isCancel,
    createCancelTokenSource,
    getMethods(tokenSource: CancelTokenSource) {
      return {
        get: <T = unknown>(url: string, config: AxiosRequestConfig = {}) => {
          config.cancelToken = tokenSource.token;
          return CancelablePromise.resolve<T>(axios.get(url, config), tokenSource.cancel).finally(
            () => {}
          );
        },
        delete: <T = unknown>(url: string, config: AxiosRequestConfig = {}) => {
          config.cancelToken = tokenSource.token;
          return CancelablePromise.resolve<T>(axios.delete(url, config), tokenSource.cancel);
        },
        put: <T = unknown>(url: string, data?: unknown, config: AxiosRequestConfig = {}) => {
          config.cancelToken = tokenSource.token;
          return CancelablePromise.resolve<T>(axios.put(url, data, config), tokenSource.cancel);
        },
        patch: <T = unknown>(url: string, data?: unknown, config: AxiosRequestConfig = {}) => {
          config.cancelToken = tokenSource.token;
          return CancelablePromise.resolve<T>(axios.patch(url, data, config), tokenSource.cancel);
        },
        post: <T = unknown>(url: string, data?: unknown, config: AxiosRequestConfig = {}) => {
          config.cancelToken = tokenSource.token;
          return CancelablePromise.resolve<T>(axios.post(url, data, config), tokenSource.cancel);
        }
      };
    }
  };
};

function paramsSerializer(params: Record<any, any>) {
  return qs.stringify(normalizeQueryParams(params), {
    arrayFormat: 'repeat',
    encodeValuesOnly: true
  });
}

export default createAxiosInstance({
  baseURL: `/my/${userPermissions.nick}`,
  paramsSerializer
});

export const axiosApp = createAxiosInstance({
  baseURL: `/app/api/my/${userPermissions.nick}`,
  paramsSerializer
});
