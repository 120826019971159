import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import template from 'lodash/template';

import { DateTimeHelper } from '@/util/date-time-helper';
import { isComplexLike, isNumberLike, isDate, isSelect } from './legacy-schema-utils';

export function deriveFormData(schema, data = {}, formData = {}) {
  const root = {};
  return processFormData(schema.schema, root, data, formData);
}

const filterOutUndefineds = (obj) => pickBy(obj, (prop) => prop !== undefined);

function prepareValueFrom(value, field) {
  if (isNumberLike(field)) {
    const numValue = parseInt(value, 10);
    return isNaN(numValue) ? value : numValue;
  }

  return value;
}

function processFormData(group, parent, data, formData) {
  const entries = Object.entries(group);

  const mappedEntries = entries.map(([key, field]) => {
    const disableAutofill = field.disableAutofill === true;
    if (isComplexLike(field)) {
      return [key, processFormData(field.fields, {}, data, formData[key] || {})];
    }
    if (formData[key] !== undefined) {
      return [key, formData[key]];
    }
    if (field.value_from_template) {
      const compiled = template(field.value_from_template);
      const value = compiled(data);
      return value === '' ? undefined : [key, prepareValueFrom(value, field)];
    }
    if (field.value_from) {
      const valueFrom = Array.isArray(field.value_from) ? field.value_from : [field.value_from];
      const value = valueFrom.map((vf) => get(data, vf)).join(' ');
      return value === '' ? undefined : [key, prepareValueFrom(value, field)];
    }
    if (field.initialValue) {
      return [key, field.initialValue];
    }

    if (disableAutofill) {
      return undefined;
    }

    if (isDate(field)) {
      return [key, DateTimeHelper.now().toServerFormat({ includeTime: false })];
    }
    if (isSelect(field) && Array.isArray(field.values)) {
      return field.values[0] ? [key, field.values[0]] : undefined;
    }
    return undefined;
  });

  const result = {
    ...parent,
    ...Object.fromEntries(mappedEntries.filter(Boolean))
  };

  return Object.keys(result).length ? filterOutUndefineds(result) : undefined;
}
