<template>
  <button type="button" :class="[$style.itemBtn, $style[`kind-${kind}`]]" v-on="$listeners">
    <lock-icon-wrapper
      v-if="isShowIcon"
      :locked="locked"
      :height="12"
      :width="12"
      color="white"
      v-bind="lockIconPosition"
    >
      <template #default>
        <span v-if="$slots.photo" :class="$style.photo">
          <slot name="photo">
            <base-icon :type="icon" />
          </slot>
        </span>
        <slot v-else name="icon">
          <base-icon :width="iconSize" :class="$style.icon" :type="icon" />
        </slot>
      </template>
    </lock-icon-wrapper>
    <span data-qa="name" :class="$style.name">{{ caption }}</span>
  </button>
</template>

<script>
import { SlotChecker } from '@/util/slot-checker';
import { BaseIcon } from '@/components';
import LockIconWrapper from '@/components/ui/base-icon/lock-icon-wrapper';

export default {
  name: 'ListEntryButton',
  components: {
    BaseIcon,
    LockIconWrapper
  },
  props: {
    icon: {
      type: String,
      default: undefined
    },
    caption: {
      type: String,
      required: true
    },
    kind: {
      type: String,
      default: 'default'
    },
    locked: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: String,
      default: '30'
    },
    lockIconPosition: {
      type: Object,
      default: () => ({
        top: 9,
        left: 14
      })
    }
  },
  computed: {
    isShowIcon() {
      return this.icon || SlotChecker.isSlotNotEmpty(this.$slots.icon);
    }
  }
};
</script>

<style module>
.itemBtn {
  @mixin reset-button-styles;
  display: flex;
  align-items: center;
  max-width: 100%;
  text-align: left;
  cursor: pointer;
}

.name {
  @mixin ellipsis;
  max-width: 600px;
}

.kind-blue.itemBtn {
  color: $blueColor;
}

.icon {
  margin-right: 4px;
}

.photo {
  margin-right: 8px;
}

.photo {
  width: 24px;
  height: 24px;
}
</style>

<i18n lang="json">{}</i18n>
