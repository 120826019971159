<template>
  <span :class="$style.weekday">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BaseWeekday'
};
</script>

<style module lang="less">
.weekday {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 500;
  text-transform: lowercase;
  grid-column: span 3;
}
</style>

<i18n lang="json">{}</i18n>
