<template>
  <div :class="[$style.buttons, $style[alignment]]">
    <slot name="default" :push="$style.push" />
  </div>
</template>

<script>
export default {
  name: 'LayoutButtonsLine',
  props: {
    alignment: {
      type: String,
      required: false,
      default: 'start',
      validator: (value) => ['start', 'center', 'between', 'end'].includes(value)
    }
  }
};
</script>

<style lang="less" module>
.buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  & > * {
    flex: none;
  }
}
.start {
  justify-content: flex-start;
}
.center {
  justify-content: center;
}
.between {
  justify-content: space-between;
}
.end {
  justify-content: flex-end;
}
.push {
  margin-inline-start: auto;
}
</style>

<i18n lang="json">{}</i18n>
