import qs from 'qs';
import axios from 'axios';
import { normalizeQueryParams } from '@/api/utils/normalize-query-params';
import { XSRFHelper } from '@/util/xsrf-helper';
import { userPermissions } from '@/services/config/user-permissions';

export const basePath = (path) => `/my/${userPermissions.nick}${path}`;

// Superagent dictionary
const types = {
  html: 'text/html',
  json: 'application/json',
  xml: 'text/xml',
  urlencoded: 'application/x-www-form-urlencoded',
  form: 'application/x-www-form-urlencoded',
  'form-data': 'application/x-www-form-urlencoded'
};

export const promiseRequest = (request) => {
  const {
    method = 'get',
    path,
    body,
    query = {},
    type = 'json',
    withCredentials = true,
    timeout = 0,
    cancelToken
  } = request;

  const headers = {
    'Content-Type': types[type] || type,
    Accept: types.json
  };

  if (['post', 'put', 'delete', 'patch'].includes(method)) {
    headers['X-XSRFToken'] = XSRFHelper.getToken();
  }

  return (
    axios({
      method,
      url: path || request,
      withCredentials,
      headers,
      params: query,
      data: body,
      timeout,
      cancelToken,
      paramsSerializer: (params) => {
        return qs.stringify(normalizeQueryParams(params), {
          arrayFormat: 'repeat',
          encodeValuesOnly: true
        });
      }
    })
      // body для обратной совместимости, так как раньше был superagent, а там body
      .then((resp) => ({ ...resp, body: resp.data }))
  );
};
export default promiseRequest;
