import { ref } from 'vue';
import { EmailUserAPI } from '@/api/email-user';
import { Email, EmailType } from '@/types/email-user';

class UserEmails {
  emails = ref<Email[]>([]);

  constructor() {
    EmailUserAPI.fetchList().then((emails) => {
      this.emails.value = emails;
    });
  }

  set(emails: Email[]) {
    this.emails.value = emails;
  }

  getList() {
    return this.emails.value;
  }

  getByType(type: EmailType) {
    return this.emails.value.find((item) => item.type === type);
  }
}

export const userEmails = new UserEmails();
