<template>
  <div>
    <base-label :class="$style.label" :for="id">
      <span v-if="label">{{ label }}</span>
    </base-label>
    <base-textarea :id="id" v-bind="$attrs" v-on="$listeners" />
  </div>
</template>

<script>
import BaseLabel from '@/components/label/label.vue';
import BaseTextarea from '@/components/textarea/textarea.vue';

export default {
  name: 'TextareaFormField',
  components: {
    BaseLabel,
    BaseTextarea
  },
  model: {
    prop: 'value',
    event: 'inputValue'
  },
  props: {
    id: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    }
  }
};
</script>

<style lang="less" module>
.label {
  display: inline-block;
  margin-bottom: 4px;
}
</style>

<i18n lang="json">{}</i18n>
