import get from 'lodash/get';

export function compare(a, b, prop) {
  return propCompare(a, b, { prop, comparator: strCompare });
}

export function compareByName(a, b) {
  return compare(a, b, 'name');
}

export function compareByCompany(a, b) {
  return compare(a, b, 'company');
}

export function compareByEmail(a, b) {
  return compare(a, b, 'email');
}

function boolCompare(a, b) {
  if (a === b) {
    return 0;
  }
  if (!a) {
    return -1;
  }
  return 1;
}

function strCompare(a, b) {
  return a.localeCompare(b, 'en', { caseFirst: 'upper' });
}

function plainCompare(a, b) {
  if (a === b) {
    return 0;
  }
  if (a && b === undefined) {
    return 1;
  }
  if (a === undefined && b) {
    return -1;
  }
  return a < b ? -1 : 1;
}

export function propCompare(aObj, bObj, { prop, reverse = false, comparator = plainCompare } = {}) {
  const comp = reverse ? reverseComparator(comparator) : comparator;
  const a = get(aObj, prop);
  const b = get(bObj, prop);
  return comp(a, b);
}

function reverseComparator(comparator) {
  return (a, b) => comparator(b, a);
}

export const ComparatorHelpers = {
  propCompare,
  boolCompare,
  plainCompare,
  strCompare,
  compareByName
};

export function compareByOrder(a, b) {
  return a.order - b.order;
}
