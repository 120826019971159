<template>
  <div :class="[$style.buttonWrapper, wrapperClasses]">
    <div v-if="loading" :class="[$style.loader, loadingClass]">
      <base-loader size="s" />
    </div>
    <button v-else type="button" :class="classes" v-bind="$attrs" @click="handleClick">
      <span>
        <slot />
        <base-icon v-if="icon" :type="icon" />
      </span>
    </button>
  </div>
</template>

<script>
import BaseLoader from '../loader/loader.vue';
import BaseIcon from '@/components/icon';

export default {
  name: 'MoreButton',
  components: {
    BaseLoader,
    BaseIcon
  },
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'm',
      validator(value) {
        return ['m', 'l', 'full'].includes(value);
      }
    },
    loadingClass: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'down',
      validator(value) {
        return ['up', 'down', 'none'].includes(value);
      }
    },
    position: {
      type: String,
      default: 'left',
      validator(value) {
        return ['left', 'center', 'right'].includes(value);
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    icon() {
      const icons = {
        up: 'chevron-up-20',
        down: 'chevron-down-20'
      };
      return icons[this.iconType];
    },
    classes() {
      const classes = [this.$style.button];
      switch (this.size) {
        case 'l':
          classes.push(this.$style.large);
          break;
        case 'full':
          classes.push(this.$style.full);
          break;
      }
      return classes;
    },
    wrapperClasses() {
      return [this.buttonClass, this.$style[this.position]];
    }
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      this.state = !this.state;
      this.$emit('click');
    }
  }
};
</script>

<style module>
.buttonWrapper {
  padding-top: 8px;
}

.button {
  @mixin reset-button-styles;

  font-size: 14px;
  white-space: nowrap;

  display: inline-flex;
  vertical-align: middle;
  padding: 0;
  outline: none;
  align-items: center;

  cursor: pointer;
  color: var(--more-button-text-color, #9c9c9c);
  border: 0;
  background-color: transparent;

  max-width: 100%;

  &.large {
    font-size: 15px;
    line-height: 24px;
    text-transform: none;
    letter-spacing: 0;
  }

  &.full {
    width: 100%;
  }

  span {
    margin: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    color: $black;
  }

  svg {
    margin-left: -4px;
    margin-top: -1px;
  }
}

.center {
  text-align: center;
}

.center .button {
  justify-content: center;
}

.loader {
  display: inline-flex;
}
</style>

<i18n lang="json">{}</i18n>
