<template>
  <div class="form-container">
    <div class="form-controls">
      <slot name="controls" />
    </div>
    <div class="form-footer">
      <slot name="footer" />
    </div>
    <div class="password-errors-container">
      <slot name="password-errors" />
    </div>
  </div>
</template>

<style lang="less" scoped>
@import url('../../../../../less/common/variables.less');
.form-controls {
  margin-right: 50px;
  grid-area: form-controls;
  @media (max-width: @screen-sm-max) {
    margin-right: 0;
  }
}
.form-container {
  display: grid;
  grid-template-columns: 1fr 230px;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'form-controls password-errors'
    'form-footer password-errors';
  width: 100%;
  @media (max-width: @screen-sm-max) {
    grid-template-areas:
      'form-controls'
      'password-errors'
      'form-footer';
    grid-template-columns: 1fr;
  }
}
// По идее должно автоматом делаться, но видать старый autoprefixer
// TODO выпилить после обновления
.form-controls {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.form-footer {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  margin-top: 15px;
}
.password-errors-container {
  grid-area: password-errors;
  width: 230px;
  @media (max-width: @screen-sm-max) {
    width: 100%;
    &:not(:empty) {
      margin-top: 10px;
    }
  }
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
}
@media (max-width: @screen-sm-max) {
  .form-controls {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .form-footer {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .password-errors-container {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
  }
}

.form-footer {
  grid-area: form-footer;
}
</style>

<i18n lang="json">{}</i18n>
