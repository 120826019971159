<template>
  <base-file v-bind="$attrs" v-on="computedListeners">
    <template #default="props">
      <slot v-bind="props" :loading="uploading" />
    </template>
  </base-file>
</template>

<script>
import { notification } from '@/services/notification/notification';
import { FileHelper } from '@/util/file-helper';
import { FileState } from '@/types/file-state';
import BaseFile from '@/components/ui/base-file/base-file';

export default {
  name: 'BaseUpload',
  components: { BaseFile },
  inheritAttrs: false,
  props: {
    preset: {
      type: String,
      default: ''
    },
    parse: Boolean,
    baseUrl: String
  },
  emits: ['state', 'files'],
  data() {
    return {
      uploading: false
    };
  },
  computed: {
    computedListeners() {
      return {
        ...this.$listeners,
        change: this.onAttached
      };
    }
  },
  methods: {
    onAttached(files) {
      this.uploading = true;

      const filesInfo = files.map((file) => {
        const info = FileHelper.fileInfo(file);
        this.$emit('state', {
          state: FileState.uploading,
          file: { ...info }
        });
        return info;
      });

      this.$emit(
        'files',
        filesInfo.map((info) => ({ ...info }))
      );
      Promise.all(
        files.map((file, index) => {
          const info = filesInfo[index];
          return FileHelper.upload(file, {
            preset: this.preset,
            parse: this.parse,
            baseURL: this.baseUrl
          })
            .then((file) => {
              Object.assign(filesInfo[index], file);
              this.$emit('state', {
                state: FileState.uploaded,
                file: { ...info }
              });
              this.$emit(
                'files',
                filesInfo.map((info) => ({ ...info }))
              );
            })
            .catch((error) => {
              Object.assign(filesInfo[index], { error: error.response.data });
              this.$emit('state', {
                state: FileState.failed,
                file: { ...info }
              });
              this.$emit(
                'files',
                filesInfo.map((info) => ({ ...info }))
              );
              notification.notify({
                content: this.$trlMessage('file.error.attach'),
                isError: true
              });
            });
        })
      ).finally(() => {
        this.$emit(
          'files',
          filesInfo.map((info) => ({ ...info }))
        );
        this.uploading = false;
      });
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
