import { ApiLayer } from '@/api/utils/api-layer';
import { AxiosClient, axiosApp } from '@/api/utils/client/axios';
import { RejectionList } from '@/types/rejection';

class RejectionReasonsLayer extends ApiLayer<AxiosClient> {
  /**
   * Обновление списка причин отказа (/settings/orgs/{orgId}/rejection-reasons)
   * @param rejections
   * @param enabled
   */
  update({ rejections, enabled }: { rejections: RejectionList; enabled: boolean }) {
    const params = enabled ? { useRejectionReasons: true } : null;

    return this.methods.post<RejectionList>(`/rejection`, rejections, { params });
  }
}

export const RejectionReasonsApi = new RejectionReasonsLayer(axiosApp);
