<template>
  <div
    class="autocomplete-list-item"
    data-qa="list-item"
    :class="classNames"
    @click.stop="$emit('click', item)"
    @mousemove="$emit('mousemove', item)"
  >
    <div class="autocomplete-list-item__inner">
      <div v-if="picked" class="autocomplete-list-item__check">
        <base-icon type="checked-item-mark-new" />
      </div>
      <component
        :is="itemComponentName"
        :item="item"
        :display-field="displayField"
        :sub-display-field="subDisplayField"
      />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';
import Item from './item.vue';
import vacancyItem from './vacancy.vue';
import accountItem from './account.vue';
import header from './header.vue';

const HEADER_COMPONENT_NAME = 'header-item';

const components = {
  BaseIcon,
  item: Item,
  [HEADER_COMPONENT_NAME]: header,
  'vacancy-item': vacancyItem,
  'account-item': accountItem
};

export default {
  name: 'SelectListItem',
  components,
  props: {
    item: { type: Object, required: true },
    itemComponent: { type: String, required: true },
    hovered: { type: Boolean, default: false },
    picked: { type: Boolean, default: false },
    displayField: { type: String, required: true },
    subDisplayField: { type: String, default: '' }
  },
  emits: ['click', 'mousemove'],
  computed: {
    itemComponentName() {
      return this.isHeader ? HEADER_COMPONENT_NAME : this.itemComponent;
    },
    classNames() {
      const item = this.item;

      // Потому что header создается нами на фронте
      // он не может быть отмеченным и прочих свойств кроме текста у него нет
      if (this.isHeader) {
        return 'autocomplete-list-item_type_header';
      }

      return {
        'autocomplete-list-item_disabled': item._disabled,
        'autocomplete-list-item_inactive': item.active === false,
        'autocomplete-list-item_hover': this.hovered && !item._disabled,
        [this.$style.accountItem]: this.itemComponent === 'account-item',
        [`autocomplete-list-item_deep_${item.deep}`]: !!item.deep,
        'autocomplete-list-item_picked': this.picked,
        'autocomplete-list-item_sub': this.hasSubtitle
      };
    },
    isHeader() {
      return isHeader(this.item);
    },
    hasSubtitle() {
      return hasSubtitle({
        itemComponent: this.itemComponent,
        displayField: this.displayField,
        subDisplayField: this.subDisplayField,
        item: this.item
      });
    }
  }
};

// Так как обычной проверки на subTitle не достаточно -
// где то он inline (account.vue), где то вообще нет
// решил сделать некий патерн матчинг по computed полю
// hasSubtitle. Возможно можно это сделать лаконичнее или вообще без такого
// изврата. Но на данный момент лучшее что пришло в голову
function hasSubtitle(data) {
  const component = components[data.itemComponent];

  if (component.computed && component.computed.hasSubtitle) {
    return component.computed.hasSubtitle.call(data);
  }

  return false;
}

export function itemHeight(data) {
  const { item } = data;
  if (isHeader(item)) {
    return 39;
  }
  if (hasSubtitle(data)) {
    return 60;
  }
  return 42;
}

function isHeader(item) {
  return item.type === 'header';
}
</script>

<style lang="less" module>
:global(.autocomplete-list-item_disabled).accountItem :global(.autocomplete-list-item__link) {
  color: initial;
}
</style>

<i18n lang="json">{}</i18n>
