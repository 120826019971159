<template>
  <div :class="layoutClasses" data-qa="page-layout">
    <main-header :logo-link="logoLink" :show-settings-link="showSettingsLink">
      <template #title>
        <slot name="header-title" />
      </template>
      <template #right>
        <slot name="right-side" />
      </template>
    </main-header>

    <layout-info v-if="isAuthorized" />

    <section :class="$style.section">
      <slot name="content">
        <component :is="contentComponent" :class="$style.content">
          <sidebar-layout v-if="isShowSidebar" :is-loaded="isSidebarLoaded" :show="!isSearchPage" />
          <main :class="mainClasses">
            <slot />
          </main>
        </component>
      </slot>
      <portal-target name="after-page-content" />
    </section>

    <div :class="$style.button">
      <div :class="$style.buttonGroup">
        <slot name="left-buttons" />
      </div>
      <div :class="$style.buttonGroup">
        <slot name="right-buttons" :is-elixir-enabled="isElixirEnabled">
          <huntflow-news v-if="isAuthorized" />
          <feedback-button />
        </slot>
      </div>
    </div>
    <modal-view />
    <app-version-info />
    <system-notifications />
    <email-verification-checker />
  </div>
</template>

<script>
import { PortalTarget } from 'portal-vue';
import { appConfig } from '@/services/config/app-config';
import { islandGap } from '@/config/css-variables';
import { SkeletonEvents, SkeletonHelper, SkeletonZones } from '@/util/skeleton-helper';
import BaseIcon from '@/components/icon.vue';
import UserMenu from '@/components/user-menu/user-menu.vue';
import HeaderWarnings from '@/components/hf/header-warnings/header-warnings.vue';
import AppVersionInfo from '@/components/app-version/app-version-info.vue';
import SystemNotifications from '@/components/system-notifications/component.vue';
import ModalView from '@/components/modal-view.vue';
import EmailVerificationChecker from '@/components/email-verification/main/email-verification-checker.vue';
import { Skeleton, SkeletonBar } from '@/components/skeleton';
import HuntflowNews from '@/modules/news/news.vue';
import MainHeader from '@/modules/header/main/main-header.vue';
import SearchHeader from '@/modules/search/search-header/search-header.vue';
import SidebarLayout from '@/modules/sidebar/main/sidebar-layout.vue';
import SidebarDefaultContent from '@/modules/sidebar/default-content.vue';
import TwoColumns from './two-columns.vue';
import HeaderItem from './header-item.vue';
import LayoutInfo from './layout-info.vue';
import FeedbackButton from './feedback-button.vue';
import {
  SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME,
  SEARCH_APPLICANTS_ROUTE_NAME,
  SEARCH_VACANCIES_ROUTE_NAME
} from '@/modules/main-screen/constants';

export default {
  name: 'PageLayout',
  components: {
    Skeleton,
    SkeletonBar,
    FeedbackButton,
    TwoColumns,
    MainHeader,
    SidebarLayout,
    HuntflowNews,
    LayoutInfo,
    HeaderItem,
    UserMenu,
    BaseIcon,
    AppVersionInfo,
    SystemNotifications,
    HeaderWarnings,
    ModalView,
    SearchHeader,
    SidebarDefaultContent,
    EmailVerificationChecker,
    PortalTarget
  },
  islandGap,
  props: {
    showSettingsLink: {
      type: Boolean,
      default: true
    },
    logoLink: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      isSidebarLoaded: false
    };
  },
  computed: {
    isAuthorized: () => appConfig.isAuthorized,
    isElixirEnabled() {
      return Boolean(appConfig.get('elixir'));
    },
    isSearchPage() {
      return [
        SEARCH_APPLICANTS_ROUTE_NAME,
        SEARCH_VACANCIES_ROUTE_NAME,
        SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME
      ].includes(this.$route?.name);
    },
    isShowSidebar() {
      return this.$route?.matched[0]?.meta?.layout === 'sidebar';
    },
    contentComponent() {
      if (this.isShowSidebar) {
        return TwoColumns;
      }
      return 'div';
    },
    layoutClasses() {
      return {
        [this.$style.layout]: true,
        // Этот класс описан и в index.html - он используется для определения,
        // когда скрывать дефолтный скелетон сайдбара (layout-placeholder).
        // Здесь он указан потому что в момент инициализации страницы
        // роутер ещё не знает текущий route и isShowSidebar=false.
        // Что происходит: сайдбар ещё скрыт, скелетон уже скрыт,
        // и только после инициализации route'а сайдбар отображается.
        // Визуально происходит "моргание" сайдбара
        'page-not-loaded': this.$route ? !this.$route.name : false
      };
    },
    mainClasses() {
      const routeClass = this.$style[this.$route?.name];
      return {
        [this.$style.main]: true,
        [routeClass]: Boolean(routeClass)
      };
    }
  },
  mounted() {
    SkeletonHelper.on(SkeletonEvents.CONTENT_APPEARING, this.handleContentAppearing);
  },
  beforeDestroy() {
    SkeletonHelper.off(SkeletonEvents.CONTENT_APPEARING, this.handleContentAppearing);
  },
  methods: {
    handleContentAppearing(zone) {
      if (zone === SkeletonZones.SIDEBAR_GENERAL) {
        SkeletonHelper.off(SkeletonEvents.CONTENT_APPEARING, this.handleContentAppearing);
        this.isSidebarLoaded = true;
      }
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables';
@import '~@less/common/mixins/font';

.layout {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  min-width: 960px;
}

@media only screen and (max-width: 600px) {
  .layout {
    min-width: 100px;
  }
}

.content {
  flex: 1;
  display: flex;
  height: 100%;
  padding: @islandGap;
  gap: @islandGap;
}
.main {
  display: flex;
  overflow: auto;
  flex: 1;
  &.calendar {
    --content-loader-offset: 55px;
  }
}
.section {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
  position: relative;
}

.button {
  position: fixed;
  bottom: 18px;
  left: 8px;
  z-index: 10;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 25px 0 15px;
  width: 100%;
  transition: opacity 200ms ease-out;
}
.buttonGroup {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.buttonGroup > * {
  pointer-events: auto;
}
</style>

<i18n lang="json">{}</i18n>
