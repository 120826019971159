<template>
  <base-item-layout
    :disabled="disabled"
    :bordered="bordered"
    :deep="deep"
    :active="active"
    :highlight="highlight"
    :use-gap="useGap"
    :icon="false"
  >
    <template #before-content>
      <div :class="$style.wrap">
        <div v-if="collapsible" :class="$style.caretWrapper">
          <div v-if="canCollapsed" :class="caretClasses" @click.stop="$emit('click')">
            <base-icon type="caret-down" />
          </div>
        </div>
        <div :class="toggleClasses"></div>
      </div>
    </template>
    <template #default>
      <slot />
    </template>
    <template #hint>
      <slot name="hint" />
    </template>
    <template #subtitle>
      <slot name="subtitle" />
    </template>
    <template #after-content>
      <slot name="after-content" />
    </template>
  </base-item-layout>
</template>

<script>
import BaseIcon from '@/components/icon';
import BaseItemLayout from './base-item-layout';

export default {
  name: 'SelectCollapsedItem',
  components: { BaseItemLayout, BaseIcon },
  props: {
    disabled: BaseItemLayout.props.disabled,
    active: BaseItemLayout.props.active,
    bordered: BaseItemLayout.props.bordered,
    deep: BaseItemLayout.props.deep,
    highlight: BaseItemLayout.props.highlight,
    useGap: BaseItemLayout.props.useGap,
    collapsible: {
      type: Boolean,
      default: true
    },
    canCollapsed: Boolean,
    collapsed: {
      type: Boolean,
      default: true
    },
    available: {
      type: Boolean,
      default: true
    },
    hasSelectedChildren: {
      type: Boolean,
      default: false
    },
    multiple: Boolean,
    selected: Boolean
  },
  emits: ['click'],
  computed: {
    caretClasses() {
      return {
        [this.$style.caret]: true,
        [this.$style.collapsed]: this.collapsed
      };
    },
    toggleClasses() {
      const classNames = {
        [this.$style.toggle]: true,
        [this.multiple ? this.$style.checkbox : this.$style.radio]: true,
        [this.$style.disabled]: !this.canToggle
      };

      if (this.selected) {
        classNames[this.$style.selected] = true;
      } else if (this.hasSelectedChildren) {
        classNames[this.$style.partiallySelected] = true;
      }

      return classNames;
    },
    canToggle() {
      return !this.disabled && this.available;
    }
  }
};
</script>

<style module>
.wrap {
  color: #bfbfbf;
  padding: 0 8px 0 5px;
  display: flex;
  align-items: center;
}
.caretWrapper {
  width: 16px;
}
.caret {
  width: calc(24px * v-bind(deep) + 24px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.caret svg {
  transition: transform 300ms;
}
.collapsed svg {
  transform: rotate(-90deg);
}
.toggle {
  width: 16px;
  height: 16px;
  border: 1px solid #bfbfbf;
  position: relative;
  margin-left: 8px;
}
.toggle::before {
  content: '';
  display: flex;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  bottom: 50%;
  margin-bottom: -8px;
  width: 16px;
  height: 16px;
  transform: scale(0);
  background: linear-gradient(0deg, #0374ff, #0374ff), #ffffff;
}
.checkbox {
  border-radius: 2px;
}
.radio {
  border-radius: 50%;
}
.radio::before {
  border-radius: 50%;
}
.selected::before,
.partiallySelected::before {
  transform: scale(0.5);
}
.partiallySelected::after {
  content: '';
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;

  background-color: #fff;
}
.checkbox.partiallySelected::after {
  width: 4px;
  height: 2px;
  margin-left: -2px;
  margin-top: -1px;
}
.radio.partiallySelected::after {
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  margin-top: -2px;
}
.disabled {
  background-color: #eaeaea;
  border-color: #cccccc;
}

.checkbox.selected.disabled::before {
  background: #bfbfbf;
}

.checkbox.partiallySelected.disabled::before {
  display: none;
}
.checkbox.partiallySelected.disabled::after {
  width: 6px;
  margin-left: -3px;
  background-color: #bfbfbf;
}

.radio.partiallySelected.disabled::before {
  background: #bfbfbf;
}
.radio.partiallySelected.disabled::after {
  background-color: #eaeaea;
}
</style>

<i18n lang="json">{}</i18n>
