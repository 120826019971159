<template>
  <loader v-if="loading" :class="$style.loader" />
  <div v-else :class="$style.layout">
    <slot />
  </div>
</template>

<script>
import Loader from './loader.vue';

export default {
  name: 'PageLoader',
  components: {
    Loader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style module>
.loader {
  margin: 100px auto;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.layout {
  opacity: 0;
  animation: appear 100ms forwards;
  overflow: auto;
  height: 100%;
}
</style>

<i18n lang="json">{}</i18n>
