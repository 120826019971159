<script>
import omit from 'lodash/omit';
import { notifier } from '@/poller-instance';
import { poller } from '@/poller-instance';
import { NotifierMessageType } from './notifier-message-type';
import { MessageEvent } from '@/types/poller-message';

const notifierMessages = Object.values(NotifierMessageType);

export function getNotifierEventName(message) {
  return `${message.event}:${message.data.event_type}`;
}

export default {
  name: 'NotifierMessage',
  emits: [...Object.values(MessageEvent), ...notifierMessages],
  mounted() {
    this.unsubscribeNotifier = notifier.onMessage((message) => {
      const eventName = getNotifierEventName(message);
      if (!notifierMessages.includes(eventName)) {
        return;
      }
      const payload = omit(message.data, 'event_type');
      this.$emit(eventName, payload, message);
    });
    this.unsubscribePoller = poller.onMessage((message) => {
      if (notifierMessages.includes(message.event)) {
        return;
      }
      this.$emit(message.event, message.data, message);
    });
  },
  beforeDestroy() {
    this.unsubscribeNotifier();
    this.unsubscribePoller();
  },
  render: () => null
};
</script>

<i18n lang="json">{}</i18n>
