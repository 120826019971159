<template>
  <validation-field :name="name" :label="label">
    <template #default="{ id, modelValue, invalid, name, onChange }">
      <base-textarea
        :id="id"
        :name="name"
        :invalid="invalid"
        :value="modelValue"
        :placeholder="placeholder"
        :maxlength="maxlength"
        @inputValue="onChange"
      />
    </template>
  </validation-field>
</template>

<script>
import { ValidationField } from '@/components/ui/validate-form';
import BaseTextarea from '@/components/textarea/textarea';

export default {
  name: 'ValidationFormTextarea',
  components: {
    ValidationField,
    BaseTextarea
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    maxlength: {
      type: Number,
      default: undefined
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
