<template>
  <base-dropdown :content-class="$style.dropdownContent" placement="bottom-end">
    <template #default="{ toggle }">
      <button
        type="button"
        :disabled="!editor.isActive('table')"
        :class="$style.arrowBtn"
        @click="toggle"
      >
        <base-icon type="chevron-down-20" width="20px" />
      </button>
    </template>
    <template #content="{ hide }">
      <item-button
        type="table-del"
        :class="$style.btn"
        @click="
          hide();
          deleteTable();
        "
      >
        {{ $i18n('action.table-del') }}
      </item-button>

      <item-button
        type="table-cell-border"
        :class="$style.btn"
        :active="hasBorder"
        @click="
          hide();
          toggleCellBorder();
        "
      >
        {{ $i18n('action.table-cell-border') }}
      </item-button>

      <item-button
        type="table-col-after"
        :class="$style.btn"
        @click="
          hide();
          addColumnAfter();
        "
      >
        {{ $i18n('action.table-col-after') }}
      </item-button>
      <item-button
        type="table-col-before"
        :class="$style.btn"
        @click="
          hide();
          addColumnBefore();
        "
      >
        {{ $i18n('action.table-col-before') }}
      </item-button>
      <item-button
        type="table-del-col"
        :class="$style.btn"
        @click="
          hide();
          deleteColumn();
        "
      >
        {{ $i18n('action.table-del-col') }}
      </item-button>
      <item-button
        type="table-row-before"
        :class="$style.btn"
        @click="
          hide();
          addRowBefore();
        "
      >
        {{ $i18n('action.table-row-before') }}
      </item-button>
      <item-button
        type="table-row-after"
        :class="$style.btn"
        @click="
          hide();
          addRowAfter();
        "
      >
        {{ $i18n('action.table-row-after') }}
      </item-button>
      <item-button
        type="table-del-row"
        :class="$style.btn"
        @click="
          hide();
          deleteRow();
        "
      >
        {{ $i18n('action.table-del-row') }}
      </item-button>
      <item-button
        type="table-join-cells"
        :class="$style.btn"
        @click="
          hide();
          mergeOrSplit();
        "
      >
        {{ $i18n('action.table-join-cells') }}
      </item-button>
    </template>
  </base-dropdown>
</template>

<script>
import { Editor } from '@tiptap/vue-3';
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown';
import ItemButton from './item-button.vue';
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'TableItems',
  components: {
    BaseIcon,
    BaseDropdown,
    ItemButton
  },
  props: {
    editor: {
      type: Editor,
      default: null
    }
  },
  emits: ['merge'],
  computed: {
    hasBorder() {
      return this.editor.isActive('tableCell', { border: true });
    }
  },
  methods: {
    deleteTable() {
      this.editor.chain().focus().deleteTable().run();
    },
    toggleCellBorder() {
      this.editor.chain().focus().setCellAttribute('border', !this.hasBorder).run();
    },
    addColumnAfter() {
      this.editor.chain().focus().addColumnAfter().run();
    },
    addColumnBefore() {
      this.editor.chain().focus().addColumnBefore().run();
    },
    deleteColumn() {
      this.editor.chain().focus().deleteColumn().run();
    },
    addRowBefore() {
      this.editor.chain().focus().addRowBefore().run();
    },
    addRowAfter() {
      this.editor.chain().focus().addRowAfter().run();
    },
    deleteRow() {
      this.editor.chain().focus().deleteRow().run();
    },
    mergeOrSplit() {
      this.editor.chain().focus().mergeOrSplit().run();
      this.$emit('merge');
    }
  }
};
</script>

<style module>
.arrowBtn {
  @mixin reset-button-styles;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  padding: 4px;
  border-radius: var(--radius-x-15, 6px);

  &:disabled {
    color: #ebebeb;
  }

  &:not(:disabled) {
    background-color: var(--Background-white-white-hover, rgba(5, 5, 5, 0.04));
  }
}

.dropdownContent {
  overflow: visible;
  display: flex;
  white-space: nowrap;
  line-height: 0;
  padding: 4px;
}

.btn:not(:last-child) {
  margin-right: 7px;
}
</style>

<i18n lang="json" base="../../wysiwyg.lang.json">
{}
</i18n>
