import { Temporal } from '@js-temporal/polyfill';

import { DateTime } from '@/util/date-time-helper';

function toTwoDigits(value: number | string) {
  return String(value).padStart(2, '0');
}

type RoundTo = (
  | {
      /**
       * The unit to round to. For example, to round to the nearest
       * minute, use `smallestUnit: 'minute'`. This property is normally
       * required, but is optional if `largestUnit` is provided and not
       * undefined.
       */
      smallestUnit: Temporal.SmallestUnit<Temporal.DateTimeUnit>;

      /**
       * The largest unit to allow in the resulting `Temporal.Duration`
       * object.
       *
       * Larger units will be "balanced" into smaller units. For example,
       * if `largestUnit` is `'minute'` then a two-hour duration will be
       * output as a 120-minute duration.
       *
       * Valid values include `'year'`, `'month'`, `'week'`, `'day'`,
       * `'hour'`, `'minute'`, `'second'`, `'millisecond'`,
       * `'microsecond'`, `'nanosecond'` and `'auto'`.
       *
       * The default is `'auto'`, which means "the largest nonzero unit in
       * the input duration". This default prevents expanding durations to
       * larger units unless the caller opts into this behavior.
       *
       * If `smallestUnit` is larger, then `smallestUnit` will be used as
       * `largestUnit`, superseding a caller-supplied or default value.
       */
      largestUnit?: Temporal.LargestUnit<Temporal.DateTimeUnit>;
    }
  | {
      /**
       * The unit to round to. For example, to round to the nearest
       * minute, use `smallestUnit: 'minute'`. This property is normally
       * required, but is optional if `largestUnit` is provided and not
       * undefined.
       */
      smallestUnit?: Temporal.SmallestUnit<Temporal.DateTimeUnit>;

      /**
       * The largest unit to allow in the resulting `Temporal.Duration`
       * object.
       *
       * Larger units will be "balanced" into smaller units. For example,
       * if `largestUnit` is `'minute'` then a two-hour duration will be
       * output as a 120-minute duration.
       *
       * Valid values include `'year'`, `'month'`, `'week'`, `'day'`,
       * `'hour'`, `'minute'`, `'second'`, `'millisecond'`,
       * `'microsecond'`, `'nanosecond'` and `'auto'`.
       *
       * The default is `'auto'`, which means "the largest nonzero unit in
       * the input duration". This default prevents expanding durations to
       * larger units unless the caller opts into this behavior.
       *
       * If `smallestUnit` is larger, then `smallestUnit` will be used as
       * `largestUnit`, superseding a caller-supplied or default value.
       */
      largestUnit: Temporal.LargestUnit<Temporal.DateTimeUnit>;
    }
) & {
  /**
   * Allows rounding to an integer number of units. For example, to round
   * to increments of a half hour, use `{ smallestUnit: 'minute',
   * roundingIncrement: 30 }`.
   */
  roundingIncrement?: number;

  /**
   * Controls how rounding is performed:
   * - `halfExpand`: Round to the nearest of the values allowed by
   *   `roundingIncrement` and `smallestUnit`. When there is a tie, round
   *   away from zero like `ceil` for positive durations and like `floor`
   *   for negative durations. This mode is the default.
   * - `ceil`: Always round towards positive infinity. For negative
   *   durations this option will decrease the absolute value of the
   *   duration which may be unexpected. To round away from zero, use
   *   `ceil` for positive durations and `floor` for negative durations.
   * - `trunc`: Always round down towards zero.
   * - `floor`: Always round towards negative infinity. This mode acts the
   *   same as `trunc` for positive durations but for negative durations
   *   it will increase the absolute value of the result which may be
   *   unexpected. For this reason, `trunc` is recommended for most "round
   *   down" use cases.
   */
  roundingMode?: Temporal.RoundingMode;

  /**
   * The starting point to use for rounding and conversions when
   * variable-length units (years, months, weeks depending on the
   * calendar) are involved. This option is required if any of the
   * following are true:
   * - `unit` is `'week'` or larger units
   * - `this` has a nonzero value for `weeks` or larger units
   *
   * This value must be either a `Temporal.PlainDateTime`, a
   * `Temporal.ZonedDateTime`, or a string or object value that can be
   * passed to `from()` of those types. Examples:
   * - `'2020-01'01T00:00-08:00[America/Los_Angeles]'`
   * - `'2020-01'01'`
   * - `Temporal.PlainDate.from('2020-01-01')`
   *
   * `Temporal.ZonedDateTime` will be tried first because it's more
   * specific, with `Temporal.PlainDateTime` as a fallback.
   *
   * If the value resolves to a `Temporal.ZonedDateTime`, then operation
   * will adjust for DST and other time zone transitions. Otherwise
   * (including if this option is omitted), then the operation will ignore
   * time zone transitions and all days will be assumed to be 24 hours
   * long.
   */
  relativeTo?: DateTime;
};

export class DurationHelper {
  private readonly duration: Temporal.Duration;
  private _sign?: 1 | 0 | -1;
  constructor(duration: Temporal.Duration) {
    this.duration = duration;
  }

  get temporal(): Temporal.Duration {
    return this.duration;
  }

  get sign(): 1 | 0 | -1 {
    if (this._sign === undefined) {
      this._sign = this.duration.sign;
    }

    return this._sign;
  }

  get years(): number {
    return this.duration.years;
  }

  get months(): number {
    return this.duration.months;
  }

  get weeks(): number {
    return this.duration.weeks;
  }

  get days(): number {
    return this.duration.days;
  }

  get hours(): number {
    return this.duration.hours;
  }

  get minutes(): number {
    return this.duration.minutes;
  }

  get seconds(): number {
    return this.duration.seconds;
  }

  toString(options?: Temporal.ToStringPrecisionOptions): string {
    return this.duration.toString(options);
  }

  round({ relativeTo, ...roundTo }: RoundTo): DurationHelper {
    const options = relativeTo ? { ...roundTo, relativeTo: relativeTo.temporal } : roundTo;
    return new DurationHelper(this.duration.round(options));
  }

  total(totalOf: Temporal.DurationTotalOf): number {
    return this.duration.total(totalOf);
  }

  static secondsToFormat(seconds: number): string {
    const duration = new DurationHelper(Temporal.Duration.from({ seconds })).round({
      largestUnit: 'minute'
    });

    return `${toTwoDigits(duration.minutes)}:${toTwoDigits(duration.seconds)}`;
  }
}
