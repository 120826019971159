<template>
  <component :is="component" :params="params" @close="handleClose" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { emitter } from '@/services/emitter/emitter';
import { telephony } from '@/services/telephony/telephony';

export default {
  name: 'TheTelephony',
  inheritAttrs: false,
  emits: ['call-start', 'call-end'],
  data() {
    return {
      component: null,
      params: null
    };
  },
  mounted() {
    if (telephony.isAvailable) {
      emitter.on('call-start', this.onCallStart);
    }
  },
  beforeDestroy() {
    if (telephony.isAvailable) {
      emitter.off('call-start', this.onCallStart);
    }
  },
  methods: {
    onCallStart(params) {
      this.params = params;
      this.$emit('call-start');
      if (telephony.isIntegrationExists) {
        this.component = defineAsyncComponent(() => import('./telephony-call'));
        return;
      }
      this.component = defineAsyncComponent(() => import('./telephony-demo'));
    },
    handleClose() {
      this.component = null;
      this.params = null;
      this.$emit('call-end');
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
