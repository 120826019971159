import { WIDGET_TYPE } from '../types';
import RatingRadioWidget from './radio-rating';
import RatingWidget from './rating.vue'; // используется райфайзеном
import AttachFileWidget from './attach-file';

export const widgets = {
  [WIDGET_TYPE.RATING_RADIO]: RatingRadioWidget,
  [WIDGET_TYPE.RATING]: RatingWidget,
  [WIDGET_TYPE.ATTACH_FILE]: AttachFileWidget
};
