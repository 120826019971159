<template>
  <div :class="{ [$style.item]: true, [$style.activeItem]: isActive }" v-on="$listeners">
    <div :class="$style.checkmarkPlaceholder">
      <icon v-if="isChecked" type="checked-item-mark" />
    </div>
    <div :class="$style.content">
      <span data-qa="item-title" :class="[$style.itemTitle, $style.itemPrimaryTitle]">{{
        item.title
      }}</span>
      <span v-if="item.secondaryTitle" :class="[$style.itemTitle, $style.itemSecondaryTitle]">
        {{ item.secondaryTitle }}
      </span>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon.vue';

export default {
  name: 'VItem',
  components: {
    Icon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isChecked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style module>
.item {
  user-select: none;
  border-radius: var(--Radius-x2, 8px);
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 8px;

  &.activeItem {
    background: #f5f5f5;
  }
}

.checkmarkPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 35px;
  color: #38343f;
}

.content {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 8px 8px 8px 0;
  @mixin ellipsis;
}

.itemPrimaryTitle,
.itemSecondaryTitle {
  @mixin ellipsis;
  display: block;
  flex: none;
}

.itemPrimaryTitle {
  color: var(--content-primary, #050505);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.itemSecondaryTitle {
  color: var(--content-primary, #050505);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
</style>

<i18n lang="json">{}</i18n>
