<template>
  <base-attachment
    icon="clip-18"
    data-qa="files-name"
    :loading="loading"
    v-bind="attachmentProps"
    :title="caption"
    :has-error="hasError"
    :can-remove="!viewMode"
    @remove="$emit('remove', file.id)"
  />
</template>

<script>
import BaseAttachment from '@/components/hf/base-attachment/base-attachment';

export default {
  name: 'FileAttachment',
  components: { BaseAttachment },
  props: {
    file: {
      type: Object,
      required: true
    },
    viewMode: Boolean,
    loading: Boolean
  },
  emits: ['remove'],
  computed: {
    attachmentProps() {
      if (!this.viewMode) {
        return {};
      }
      return {
        target: '_blank',
        href: this.file.url
      };
    },
    hasError() {
      return Boolean(this.file.error);
    },
    caption() {
      if (this.file.error) {
        return this.file.error.message?.match('Bad content type')
          ? this.$trlMessage('file.error.attach.type')
          : this.$trlMessage('file.error.attach');
      }
      return this.file.name;
    }
  }
};
</script>

<style module>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: rotate 1s infinite linear;
}

.link {
  text-decoration: none;
  color: var(--file-attachment-link-color, $linkColor);
  max-width: 100%;
}

.link:hover {
  color: $linkHoverColor;
}

.link .icon {
  margin: -3px -1px 0 0;
}

.icon {
  height: 32px;
  width: 32px;
  color: $vantaBlackColor;
}

.iconWithCaption {
  display: flex;
  align-items: center;
  min-width: 0;

  .icon {
    flex: none;
  }

  .caption {
    @mixin ellipsis;
  }
}
</style>

<i18n lang="json">{}</i18n>
