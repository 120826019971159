export const COLORS = {
  'settings-calendar': '#28BAD3',
  'settings-jobsites': '#E743D6',
  'settings-profile': '#F0B10E',
  'settings-magic-button': '#F55932',
  'settings-integrations': '#6C63EB',
  'settings-api': '#4B83EF',
  'settings-business': '#00B84E',
  'settings-funnel': '#E743D6',
  'settings-organization': '#6C63EB',
  'settings-response': '#F0B10E',
  'settings-templates': '#28BAD3',
  'settings-themes': '#A4BB13',
  'settings-users': '#F55932',
  'business-applicant': '#00B84E',
  'business-folder': '#4B83EF',
  'business-request': '#28BAD3',
  'business-tags': '#E26EE4',
  'business-book': '#F0B10E',
  'business-thumbs-up': '#A4BB13',
  'business-vacancy': '#FA633E',
  'funnel-clock': '#28BAD3',
  'funnel-funnels': '#E743D6',
  'funnel-refusal': '#F55932',
  'funnel-stages': '#6C63EB',
  'org-afety': '#F0B10E',
  'org-info': '#E743D6',
  'org-notifications': '#F55932',
  'org-phone': '#28BAD3',
  'org-sms': '#6C63EB',
  'org-structure': '#A4BB13',
  'response-career-site': '#E743D6',
  'response-jobposting': '#4B83EF',
  'template-feedback': '#00B84E',
  'template-letter': '#F55932',
  'template-messengers': '#6C63EB',
  'template-rating': '#F0B10E',
  'template-sms': '#E743D6',
  'template-test': '#4B83EF',
  'user-hiring-managers': '#FA633E',
  'user-recruiters': '#00B84E',
  blocked: '#BFBFBF'
};
