<template>
  <svg :height="height" :width="width" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 37">
    <path
      d="M21.228 15.9h-6.55l6.09-14.824c.307-.776-.65-1.436-1.264-.854L.236 19.742a.766.766 0 0 0 .536 1.32h6.55l-6.09 14.862c-.307.776.65 1.436 1.264.854l19.268-19.52c.498-.504.154-1.358-.536-1.358z"
      :fill="color"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  name: 'LightningIcon',
  props: {
    height: { type: [String, Number], required: true },
    width: { type: [String, Number], required: true },
    color: { type: String, default: '#FFF' }
  }
};
</script>

<i18n lang="json">{}</i18n>
