function normalizeColor(hexColor) {
  hexColor = hexColor.slice(1);
  return hexColor.length === 3 ? hexColor.repeat(2) : hexColor;
}

export function colorRelativeLightness(hexColor) {
  const hexInt = parseInt(normalizeColor(hexColor), 16);
  const r = (hexInt >> 16) & 255;
  const g = (hexInt >> 8) & 255;
  const b = hexInt & 255;
  return (r * 0.2126 + g * 0.7152 + b * 0.0722) / 255;
}
