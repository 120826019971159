<template>
  <div :class="$style.item" @click="handleSelect">
    <slot
      :select="handleSelect"
      :has-selected-children="hasSelectedChildren"
      :has-children="hasChildren"
      :selected="selected"
    />
  </div>
</template>

<script>
export default {
  name: 'SelectItemWrapper',
  props: {
    selected: Boolean,
    multiple: Boolean,
    deepChildren: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Array, String, Number, Boolean],
      default: undefined
    }
  },
  emits: ['click'],
  computed: {
    childIdMap() {
      const childIds = this.deepChildren || [];
      return childIds.reduce((map, id) => {
        map[id] = true;
        return map;
      }, {});
    },
    hasSelectedChildren() {
      if (this.selected) {
        return true;
      }
      if (!this.multiple) {
        return this.value in this.childIdMap;
      }
      return this.value.some((id) => id in this.childIdMap);
    },
    hasChildren() {
      return this.deepChildren.length > 0;
    }
  },
  methods: {
    handleSelect() {
      this.$emit('click');
    }
  }
};
</script>

<style module>
.item {
  display: flex;
  width: 100%;
}
</style>

<i18n lang="json">{}</i18n>
