import { PipelinesAPI } from '@/api/pipelines';
import { StatusType } from '@/services/status/status';
import { trlMessage } from '@/services/i18n';

const MAX_DAYS_ON_PIPELINE = 30;
const initialState = {
  pipelines: [],
  statuses: [],
  daysLimit: MAX_DAYS_ON_PIPELINE
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    daysLimit: (state) => state.daysLimit,
    statuses(state) {
      return (
        state.statuses
          .filter((el) => !el.removed)
          // Предмет для обсуждения
          // мотивация этого map, чтобы не прокидывать строки типов за пределы
          .map((el) => {
            const isTrash = el.type === StatusType.TRASH;
            // для системного этапа берём название из переводов
            const name = isTrash ? trlMessage('status.trash') : el.name;
            return {
              ...el,
              name,
              isTrash,
              isUser: el.type === StatusType.USER
            };
          })
          .sort((a, b) => a.order - b.order)
      );
    },
    hiredStatus: (_, { statuses }) => statuses.find(({ type }) => type === StatusType.HIRED),
    pipelines(state) {
      return state.pipelines.filter((el) => !el.removed);
    },
    systemStatuseIds(_, getters) {
      return getters.statuses.filter((el) => !el.isUser).map((el) => el.id);
    },
    pipelineById: (state) => (id) => state.pipelines.find((el) => el.id === id)
  },
  mutations: {
    updateStatuses(state, statuses) {
      state.statuses = statuses.concat(
        state.statuses.find((status) => status.type === 'trash') ?? []
      );
    },

    updateTimeOnStatus(state, { pipeline: pipelineId, status: statusId, days: rawDays, toAll }) {
      // Код ниже подталкивает на мысль о нормализации этого модуля
      // но это уже в другом PR'е
      // PS: возможно стоит облегчить мутацию, вынеся логику перебора в action
      const days = rawDays === 0 ? null : rawDays;

      if (pipelineId !== -1) {
        state.pipelines
          .find((el) => pipelineId === el.id)
          .statuses.find((el) => el.id === statusId).stay_duration = days;
        return;
      }

      const index = state.statuses.findIndex((el) => el.id === statusId);
      if (index !== -1) {
        state.statuses[index].stay_duration = days;
      }

      if (!toAll) {
        return;
      }

      state.pipelines.forEach((el) => {
        const status = el.statuses.find(
          (pipelineStatus) => pipelineStatus.account_vacancy_status === statusId
        );
        if (status) {
          status.stay_duration = days;
        }
      });
    },

    updatePipelines(state, pipelines) {
      state.pipelines = pipelines;
    },
    createPipeline(state, pipeline) {
      state.pipelines.push(pipeline);
    },
    updatePipeline(state, pipeline) {
      const index = state.pipelines.findIndex((el) => el.id === pipeline.id);

      if (!state.pipelines[index]) {
        return;
      }

      state.pipelines[index] = pipeline;
    },
    removePipeline(state, id) {
      state.pipelines = state.pipelines.filter((el) => el.id !== id);
    }
  },

  actions: {
    updateTimeOnStatus: (store, { pipeline, status, days, toAll }) =>
      PipelinesAPI.updateTimeOnStatus({
        pipeline,
        status,
        days,
        toAll
      }).then(() => {
        store.commit('updateTimeOnStatus', {
          pipeline,
          status,
          days,
          toAll
        });
      }),
    createPipeline: (store, pipeline) => {
      return PipelinesAPI.createPipeline(pipeline).then((pipeline) => {
        store.commit('createPipeline', pipeline);
      });
    },
    updatePipeline: (store, pipeline) => {
      return PipelinesAPI.updatePipeline(pipeline).then((pipeline) => {
        store.commit('updatePipeline', pipeline);
      });
    },
    removePipeline: (store, id) => {
      return PipelinesAPI.removePipeline(id).then(() => {
        store.commit('removePipeline', id);
      });
    },
    updateStatuses: (store, payload) =>
      PipelinesAPI.updateStatuses(payload).then((statuses) => {
        store.commit('updateStatuses', statuses);
      })
  }
};
