<template>
  <div :class="$style.item">
    {{ item.title }}
  </div>
</template>

<script>
export default {
  name: 'VGroup',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style module>
.item {
  @mixin ellipsis;
  height: 39px;
  padding-top: 15px;
  padding-left: 34px;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 0;
  color: #929097;
  user-select: none;
}
</style>

<i18n lang="json">{}</i18n>
