import { Temporal } from '@js-temporal/polyfill';
// удалить после имплементации в браузерах https://github.com/tc39/proposal-intl-locale-info
import '@formatjs/intl-locale/polyfill-force';
import memoize from 'lodash/memoize';
import stringify from 'fast-json-stable-stringify';

import { appConfig } from '@/services/config/app-config';
import { serverTimezone, userTimezone } from './constant';
import { trlMessage } from '@/services/i18n';
import { DurationHelper } from '@/util/duration-helper';

type DateObject = { date: string; timeZone?: string };

type DateVariant = DateTime | DateObject;

function getBrowserTimezone(): string | undefined {
  try {
    return Temporal.Now.timeZoneId();
  } catch {}
}

const browserTimezone = getBrowserTimezone();

type ZonedDateTimeLike = Omit<Temporal.ZonedDateTimeLike, 'timeZone'> & { timeZone?: string };

export type CompareOptions = {
  unit?: Temporal.DateTimeUnit;
  timeZone?: string;
};

export class DateTime {
  private readonly date: Temporal.ZonedDateTime;
  private _dayOfWeek?: number;
  private _epochMilliseconds?: number;
  private _offset?: string;

  readonly isPlainDate: boolean;

  constructor(date: Temporal.ZonedDateTime, isPlainDate = false) {
    this.date = date;
    this.isPlainDate = isPlainDate;

    return new Proxy(this, {
      get: HandlerGet
    });
  }

  static get serverTimezone(): string {
    return serverTimezone;
  }

  static get userTimezone(): string {
    return userTimezone;
  }

  static get browserTimezone(): string | undefined {
    return browserTimezone;
  }

  static get firstDayOfWeek(): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // TODO: пока что всегда начинаем с понедельника, далее планируется настройка
    return new Intl.Locale('ru').weekInfo.firstDay;
  }

  get temporal(): Temporal.ZonedDateTime {
    return this.date;
  }

  get timeZone(): string | undefined {
    return this.date.timeZoneId;
  }

  get dayOfWeek(): number {
    // высчитывается каждый раз заново внутри полифила, сделано ради оптимизации
    if (this._dayOfWeek === undefined) {
      this._dayOfWeek = this.date.dayOfWeek;
    }

    return this._dayOfWeek;
  }

  get daysInMonth(): number {
    return this.date.daysInMonth;
  }

  get hour(): number {
    return this.date.hour;
  }

  get minute(): number {
    return this.date.minute;
  }

  get second(): number {
    return this.date.second;
  }

  get day(): number {
    return this.date.day;
  }

  get month(): number {
    return this.date.month;
  }

  get year(): number {
    return this.date.year;
  }

  get epochMilliseconds(): number {
    // высчитывается каждый раз заново внутри полифила, сделано ради оптимизации
    if (this._epochMilliseconds === undefined) {
      this._epochMilliseconds = this.date.epochMilliseconds;
    }

    return this._epochMilliseconds;
  }

  get offset(): string {
    // высчитывается каждый раз заново внутри полифила, сделано ради оптимизации
    if (this._offset === undefined) {
      this._offset = this.date.offset;
    }

    return this._offset;
  }

  toString(options?: Temporal.ZonedDateTimeToStringOptions): string {
    return this.date.toString(options);
  }

  withTimeZone(timeZone: string): DateTime {
    if (this.isPlainDate) {
      return new CachedDateTime(
        this.date.toPlainDateTime().toZonedDateTime(timeZone),
        this.isPlainDate
      );
    }
    return new CachedDateTime(this.date.withTimeZone(timeZone), this.isPlainDate);
  }

  /**
   * К сожалению встроенный метод round не умеет округлять единицы больше day, поэтому написано своё
   */
  floor({
    unit,
    roundingIncrement = 1
  }: {
    unit: Temporal.DateTimeUnit;
    roundingIncrement?: number;
  }): DateTime {
    const timeUnits: Array<Temporal.DateTimeUnit> = [
      'hour',
      'minute',
      'second',
      'millisecond',
      'microsecond',
      'nanosecond'
    ];
    const dateUnits: Array<Temporal.DateTimeUnit> = ['year', 'month', 'week', 'day'];
    const roundUnits: Array<Temporal.DateTimeUnit> = dateUnits.concat(timeUnits);
    const unitIndex = roundUnits.indexOf(unit);
    const unitsToChange = roundUnits.slice(unitIndex + 1);
    const params = unitsToChange.reduce<ZonedDateTimeLike>(
      (acc, unit) => {
        if (unit === 'week') {
          return acc;
        }
        acc[unit] = dateUnits.includes(unit) ? 1 : 0;
        return acc;
      },
      unit === 'week'
        ? {}
        : {
            [unit]: Math.floor(this.date[unit] / roundingIncrement) * roundingIncrement
          }
    );
    if (unit !== 'week') {
      return this.with(params);
    }
    delete params.day;
    const resultDate = this.with(params);
    if (unit === 'week') {
      return resultDate.add({
        days: -((resultDate.dayOfWeek - DateTime.firstDayOfWeek + 7) % 7)
      });
    }
    return resultDate;
  }

  since(
    date: DateTime,
    options?: Temporal.DifferenceOptions<Temporal.DateTimeUnit>
  ): DurationHelper {
    return new DurationHelper(this.date.since(date.date, options));
  }

  with(zonedDateTimeLike: ZonedDateTimeLike | DateTime): DateTime {
    const date = zonedDateTimeLike.timeZone
      ? this.date.withTimeZone(zonedDateTimeLike.timeZone)
      : this.date;
    return new CachedDateTime(date.with(zonedDateTimeLike), this.isPlainDate);
  }

  add(durationLike: Temporal.DurationLike | DurationHelper): DateTime {
    return new CachedDateTime(this.date.add(durationLike), this.isPlainDate);
  }

  /**
   * Метод считает количество календарных единиц, которые попали в период, получается не полное количество единиц
   *
   * Например:
   * Между 2022-10-10T00:00:00 и 2023-05-10T00:00:00 меньше года, но метод верёт результат 2, для единиц 'year'. Получается включит 2022 и 2023 год
   */
  countOf(
    dateToCompare: DateVariant,
    { unit, timeZone = userTimezone }: { unit: Temporal.DateTimeUnit; timeZone?: string }
  ): number {
    const dateParsed = this.withTimeZone(timeZone).floor({ unit });
    const dateToCompareParsed = normalizeZonedDate(dateToCompare)
      .withTimeZone(timeZone)
      .floor({ unit });

    const duration = dateToCompareParsed.since(dateParsed, {
      smallestUnit: unit,
      roundingMode: 'trunc'
    });

    const count =
      duration.total({
        unit,
        relativeTo: dateParsed
      }) + (duration.sign || 1);

    return Math.abs(count);
  }

  hasSame(dateToCompare: DateVariant, options: CompareOptions = {}): boolean {
    return DateTime.compare(this, dateToCompare, options) === 0;
  }

  isBefore(dateToCompare: DateVariant, options: CompareOptions = {}): boolean {
    return DateTime.compare(this, dateToCompare, options) === -1;
  }

  isAfter(dateToCompare: DateVariant, options: CompareOptions = {}): boolean {
    return DateTime.compare(this, dateToCompare, options) === 1;
  }

  startOfDay({ timeZone = userTimezone }: { timeZone?: string } = {}): DateTime {
    return this.withTimeZone(timeZone).with({
      hour: 0,
      minute: 0,
      second: 0
    });
  }

  isStartOfDay({ timeZone = userTimezone }: { timeZone?: string } = {}) {
    const date = this.withTimeZone(timeZone);
    return !date.hour && !date.minute && !date.second;
  }

  endOfDay({ timeZone = userTimezone }: { timeZone?: string } = {}): DateTime {
    return this.withTimeZone(timeZone).with({
      hour: 23,
      minute: 59,
      second: 59
    });
  }

  isEndOfDay({ timeZone = userTimezone }: { timeZone?: string } = {}) {
    const date = this.withTimeZone(timeZone);
    return date.hour === 23 && date.minute === 59 && date.second === 59;
  }

  toTimeFormat({
    timeZone = userTimezone,
    includeWeekday = false,
    includeTimeZone = false
  }: {
    timeZone?: string;
    includeWeekday?: boolean;
    includeTimeZone?: boolean;
  } = {}): string {
    return this.toCustomFormat(
      {
        weekday: includeWeekday ? 'long' : undefined,
        hour: '2-digit',
        minute: '2-digit',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        timeZoneName: includeTimeZone ? 'shortOffset' : undefined
      },
      { timeZone }
    );
  }

  /**
   * @deprecated
   */
  toLegacyFormat({ timeZone = userTimezone }: { timeZone?: string } = {}): string {
    return this.withTimeZone(timeZone).date.toLocaleString('ru', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  toShortFormat({
    timeZone = userTimezone,
    includeWeekday = false,
    useRelationFormat = false
  }: {
    timeZone?: string;
    includeWeekday?: boolean;
    useRelationFormat?: boolean;
  } = {}): string {
    const date = this.withTimeZone(timeZone);

    if (useRelationFormat) {
      const nowDateTime = DateTime.now({ timeZone });
      const days = nowDateTime.countOf(date, { unit: 'day', timeZone });
      if (days === 1) {
        return date.toCustomFormat({
          weekday: includeWeekday ? 'long' : undefined,
          hour: '2-digit',
          minute: '2-digit'
        });
      }
    }
    return date.toCustomFormat(
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        weekday: includeWeekday ? 'long' : undefined
      },
      { timeZone }
    );
  }

  toLongFormat({
    timeZone = userTimezone,
    includeWeekday = false,
    includeTime = false,
    includeTimeZone = false,
    useRelationFormat = false
  }: {
    timeZone?: string;
    includeWeekday?: boolean;
    includeTime?: boolean;
    includeTimeZone?: boolean;
    useRelationFormat?: boolean;
  } = {}): string {
    const date = this.withTimeZone(timeZone);
    const formattedDateParts: string[] = [];

    if (useRelationFormat) {
      const nowDateTime = DateTime.now({ timeZone });
      const days = nowDateTime.countOf(date, { unit: 'day', timeZone });
      if (days === 1) {
        formattedDateParts.push(trlMessage('date.today'));
      }
      if (days === 2 && nowDateTime.isAfter(date)) {
        formattedDateParts.push(trlMessage('date.yesterday'));
      }
    }

    formattedDateParts.push(
      date.toCustomFormat(
        {
          day: !formattedDateParts.length ? 'numeric' : undefined,
          month: !formattedDateParts.length ? 'long' : undefined,
          year: !formattedDateParts.length ? 'numeric' : undefined,
          weekday: includeWeekday ? 'long' : undefined,
          hour: includeTime ? '2-digit' : undefined,
          minute: includeTime ? '2-digit' : undefined,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          timeZoneName: includeTimeZone ? 'shortOffset' : undefined
        },
        { timeZone }
      )
    );
    return formattedDateParts.filter(Boolean).join(', ');
  }

  toPeriodFormat(
    dateVariant: DateVariant,
    {
      timeZone = userTimezone,
      includeWeekday = false,
      includeTime = true,
      includeTimeZone = false
    }: {
      timeZone?: string;
      includeWeekday?: boolean;
      includeTime?: boolean;
      includeTimeZone?: boolean;
    } = {}
  ): string {
    const start = this.withTimeZone(timeZone);
    const end = normalizeZonedDate(dateVariant).withTimeZone(timeZone);

    const isSameDate = start.hasSame(end, {
      unit: 'day'
    });
    const isSameTime = start.hasSame(end, {
      unit: 'minute'
    });

    const startFormatOptions: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      weekday: includeWeekday ? 'long' : undefined,
      hour: includeTime ? '2-digit' : undefined,
      minute: includeTime ? '2-digit' : undefined
    };

    const endFormatOptions: Intl.DateTimeFormatOptions = {};
    if (!isSameDate) {
      endFormatOptions.day = 'numeric';
      endFormatOptions.month = 'long';
      endFormatOptions.year = 'numeric';
    }
    if (includeTime && !isSameTime) {
      endFormatOptions.hour = '2-digit';
      endFormatOptions.minute = '2-digit';
    }
    if ((includeTimeZone || includeWeekday) && Object.keys(endFormatOptions).length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      endFormatOptions.timeZoneName = includeTimeZone ? 'shortOffset' : undefined;
      endFormatOptions.weekday = includeWeekday ? 'long' : undefined;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      startFormatOptions.timeZoneName = includeTimeZone ? 'shortOffset' : undefined;
    }
    const formattedStart = start.toCustomFormat(startFormatOptions, { timeZone });
    const formattedEnd = end.toCustomFormat(endFormatOptions, { timeZone });

    return [formattedStart, formattedEnd].filter(Boolean).join(' − ');
  }

  toMonthYearFormat({ timeZone = userTimezone }: { timeZone?: string } = {}): string {
    return this.toCustomFormat(
      {
        month: 'long',
        year: 'numeric'
      },
      { timeZone }
    );
  }

  toServerFormat({
    timeZone = serverTimezone,
    includeTime = true,
    legacy = true,
    includeOffset = false
  }: {
    timeZone?: string;
    includeTime?: boolean;
    legacy?: boolean;
    includeOffset?: boolean;
  } = {}): string {
    const date = this.withTimeZone(timeZone).date;
    let dateString = date.toString({
      offset: includeOffset ? 'auto' : 'never',
      timeZoneName: 'never',
      fractionalSecondDigits: 0
    });

    if (legacy) {
      dateString = dateString.replace('T', ' ');
    }
    if (includeTime) {
      return dateString;
    }
    const [dateOnly] = dateString.split(/[T ]/);
    return dateOnly;
  }

  toCustomFormat(
    { timeZoneName, hour, minute, second, weekday, ...options }: Intl.DateTimeFormatOptions,
    { timeZone = userTimezone }: { timeZone?: string } = {}
  ): string {
    const date = this.withTimeZone(timeZone).date;
    const formattedDateParts: string[] = [];
    if (Object.values(options).filter(Boolean).length) {
      formattedDateParts.push(date.toLocaleString(appConfig.get('lang'), options));
    }

    if (weekday) {
      formattedDateParts.push(date.toLocaleString(appConfig.get('lang'), { weekday }));
    }

    if (hour || minute || second) {
      formattedDateParts.push(
        date.toLocaleString(appConfig.get('lang'), {
          hour,
          minute,
          second
        })
      );
    }

    let result = formattedDateParts.join(', ');
    if (timeZoneName) {
      const formattedParts = date
        .toLocaleString(appConfig.get('lang'), {
          hour: '2-digit',
          timeZoneName
        })
        .replace(' ', '')
        .split(' ');
      const formattedTimeZone = formattedParts[formattedParts.length - 1];
      result = result ? `${result} (${formattedTimeZone})` : `(${formattedTimeZone})`;
    }

    return normalizeFormattedDate(result);
  }

  static parse({
    timeZone = serverTimezone,
    isPlainDate,
    ...data
  }: (DateObject | ZonedDateTimeLike) & { isPlainDate?: boolean }): DateTime {
    let normalizedDate: string | Temporal.PlainDateTimeLike;
    const isPlain = isPlainDate ?? getIsPlainDate(data);
    if ('date' in data) {
      normalizedDate = data.date;
      if (data.date.includes(' ')) {
        console.warn(`legacy format ${data.date}, use ISO8601 format instead`);
        normalizedDate = data.date.replace(' ', 'T');
      }
      const parsedTimeZone = parseTimeZone(normalizedDate);
      if (parsedTimeZone) {
        return new CachedDateTime(
          Temporal.Instant.from(normalizedDate)
            .toZonedDateTimeISO(parsedTimeZone)
            .withTimeZone(timeZone),
          isPlain
        ).withTimeZone(DateTimeHelper.userTimezone);
      }
    } else {
      normalizedDate = data;
    }
    const dateTime = Temporal.PlainDateTime.from(normalizedDate);
    return new CachedDateTime(dateTime.toZonedDateTime(timeZone), isPlain).withTimeZone(
      DateTimeHelper.userTimezone
    );
  }

  /**
   * @deprecated use DateTimeHelper.parse instead
   * @param date формата DD.MM.YYYY
   * @param timeZone
   */
  static parseLegacyDate({ date, timeZone = serverTimezone }: DateObject): DateTime {
    console.warn(`deprecated, use DateTimeHelper.parse instead`);
    const [day, month, year] = date.split('.');
    return CachedDateTime.parse({
      day: Number(day),
      month: Number(month),
      year: Number(year),
      timeZone
    });
  }

  static isLegacyDate(date: string): boolean {
    return date.includes('.');
  }

  static now({
    timeZone = userTimezone,
    isPlainDate = false
  }: {
    timeZone?: string;
    isPlainDate?: boolean;
  } = {}): DateTime {
    return new CachedDateTime(Temporal.Now.zonedDateTimeISO(timeZone), isPlainDate);
  }

  static countOf(
    date: DateVariant,
    dateToCompare: DateVariant,
    { unit, timeZone = userTimezone }: { unit: Temporal.DateTimeUnit; timeZone?: string }
  ): number {
    return normalizeZonedDate(date).countOf(dateToCompare, { unit, timeZone });
  }

  static compare(
    date: DateVariant,
    dateToCompare: DateVariant,
    { unit, timeZone = userTimezone }: { unit?: Temporal.DateTimeUnit; timeZone?: string } = {}
  ): Temporal.ComparisonResult {
    let dateParsed = normalizeZonedDate(date).withTimeZone(timeZone);
    let dateToCompareParsed = normalizeZonedDate(dateToCompare).withTimeZone(timeZone);
    if (unit) {
      dateParsed = dateParsed.floor({ unit });
      dateToCompareParsed = dateToCompareParsed.floor({ unit });
    }
    return Temporal.ZonedDateTime.compare(dateParsed.temporal, dateToCompareParsed.temporal);
  }

  // для однострочных вызовов
  static toTimeFormat(
    dateVariant: DateVariant,
    {
      timeZone = userTimezone,
      includeWeekday = false,
      includeTimeZone = false
    }: {
      timeZone?: string;
      includeWeekday?: boolean;
      includeTimeZone?: boolean;
    } = {}
  ): string {
    return normalizeZonedDate(dateVariant).toTimeFormat({
      timeZone,
      includeWeekday,
      includeTimeZone
    });
  }

  /**
   * @deprecated
   */
  static toLegacyFormat(
    dateVariant: DateVariant,
    { timeZone = userTimezone }: { timeZone?: string } = {}
  ): string {
    return normalizeZonedDate(dateVariant).toLegacyFormat({ timeZone });
  }

  static toShortFormat(
    dateVariant: DateVariant,
    {
      timeZone = userTimezone,
      useRelationFormat = false,
      includeWeekday = false
    }: {
      timeZone?: string;
      includeWeekday?: boolean;
      useRelationFormat?: boolean;
    } = {}
  ): string {
    return normalizeZonedDate(dateVariant).toShortFormat({
      timeZone,
      includeWeekday,
      useRelationFormat
    });
  }

  static toLongFormat(
    dateVariant: DateVariant,
    {
      timeZone = userTimezone,
      includeWeekday = false,
      includeTime = false,
      includeTimeZone = false,
      useRelationFormat = false
    }: {
      timeZone?: string;
      includeWeekday?: boolean;
      includeTime?: boolean;
      includeTimeZone?: boolean;
      useRelationFormat?: boolean;
    } = {}
  ): string {
    return normalizeZonedDate(dateVariant).toLongFormat({
      timeZone,
      includeWeekday,
      includeTime,
      includeTimeZone,
      useRelationFormat
    });
  }

  static toPeriodFormat(
    startVariant: DateVariant,
    endVariant: DateVariant,
    {
      timeZone = userTimezone,
      includeWeekday = false,
      includeTime = true,
      includeTimeZone = false
    }: {
      timeZone?: string;
      includeWeekday?: boolean;
      includeTime?: boolean;
      includeTimeZone?: boolean;
    } = {}
  ): string {
    return normalizeZonedDate(startVariant).toPeriodFormat(endVariant, {
      timeZone,
      includeWeekday,
      includeTime,
      includeTimeZone
    });
  }

  static toMonthYearFormat(
    dateVariant: DateVariant,
    { timeZone = userTimezone }: { timeZone?: string } = {}
  ): string {
    return normalizeZonedDate(dateVariant).toMonthYearFormat({ timeZone });
  }

  static toServerFormat(
    dateVariant: DateVariant,
    {
      timeZone = serverTimezone,
      includeTime = true,
      legacy = true,
      includeOffset = false
    }: { timeZone?: string; includeTime?: boolean; legacy?: boolean; includeOffset?: boolean } = {}
  ): string {
    return normalizeZonedDate(dateVariant).toServerFormat({
      timeZone,
      includeTime,
      legacy,
      includeOffset
    });
  }

  static toCustomFormat(
    dateVariant: DateVariant,
    options: Intl.DateTimeFormatOptions,
    { timeZone = userTimezone }: { timeZone?: string } = {}
  ): string {
    return normalizeZonedDate(dateVariant).toCustomFormat(options, { timeZone });
  }

  static fromShortFormat(
    date: string,
    { timeZone = userTimezone }: { timeZone?: string } = {}
  ): DateTime {
    return DateTimeHelper.parse({
      ...parseString(date),
      timeZone
    });
  }
}

function parseString(date: string) {
  const lang = appConfig.get('lang');
  const parts = date.split(/[/.,]/);
  if (parts.length < 3 || parts.some((part) => !part || part.length < 2)) {
    throw new Error(`invalid date ${date}`);
  }
  return {
    day: Number(lang === 'en' ? parts[1] : parts[0]),
    month: Number(lang === 'en' ? parts[0] : parts[1]),
    year: Number(parts[2])
  };
}

function normalizeZonedDate(date: DateVariant): DateTime {
  return date instanceof DateTime ? date : CachedDateTime.parse(date);
}

function getIsPlainDate(data: DateObject | ZonedDateTimeLike): boolean {
  if ('date' in data) {
    return data.date.length === 10;
  }
  return ![data.hour, data.minute, data.second].filter((unit) => unit !== undefined).length;
}

const parseMap = new Map();
const CachedDateTime = new Proxy(DateTime, {
  get(target, prop: string | symbol, receiver: unknown) {
    const value = Reflect.get(target, prop, receiver);
    if (prop === 'parse') {
      return ({
        timeZone = serverTimezone,
        isPlainDate,
        ...data
      }: (DateObject | ZonedDateTimeLike) & { isPlainDate?: boolean }) => {
        const isPlain = isPlainDate ?? getIsPlainDate(data);
        const key =
          'date' in data
            ? `${data.date.replace(' ', 'T')}-${timeZone}-${isPlain}`
            : stringify({ ...data, timeZone, isPlain });
        if (parseMap.has(key)) {
          return parseMap.get(key);
        }
        const result = value({ ...data, timeZone, isPlainDate });
        parseMap.set(key, result);
        return result;
      };
    }

    return value;
  }
});

function baseMemoize<T extends (...args: any[]) => any>(
  resolver: (fn: T, date: DateTime, ...args: Parameters<T>) => string
) {
  return memoize((fn: T, date: DateTime, ...args: Parameters<T>): ReturnType<T> => {
    return fn(...args);
  }, resolver);
}

const withTimeZone = baseMemoize<DateTime['withTimeZone']>((fn, date, timeZone) => {
  return [
    'withTimeZone',
    appConfig.get('lang'),
    date.epochMilliseconds,
    date.offset,
    date.isPlainDate,
    timeZone
  ].join('-');
});

const floor = baseMemoize<DateTime['floor']>((fn, date, options) => {
  return [
    'floor',
    appConfig.get('lang'),
    date.epochMilliseconds,
    date.offset,
    date.isPlainDate,
    stringify(options)
  ].join('-');
});

const since = baseMemoize<DateTime['since']>((fn, date, secondDate, options) => {
  return [
    'since',
    appConfig.get('lang'),
    date.epochMilliseconds,
    date.offset,
    date.isPlainDate,
    secondDate.epochMilliseconds,
    secondDate.offset,
    secondDate.isPlainDate,
    stringify(options)
  ].join('-');
});

const toString = baseMemoize<DateTime['toString']>((fn, date, options) => {
  return [
    'toString',
    appConfig.get('lang'),
    date.epochMilliseconds,
    date.offset,
    date.isPlainDate,
    stringify(options)
  ].join('-');
});

const hasSame = baseMemoize<DateTime['hasSame']>((fn, date, secondDate, options) => {
  const zonedDate = normalizeZonedDate(secondDate);
  return [
    'hasSame',
    appConfig.get('lang'),
    date.epochMilliseconds,
    date.offset,
    date.isPlainDate,
    zonedDate.epochMilliseconds,
    zonedDate.offset,
    zonedDate.isPlainDate,
    stringify(options)
  ].join('-');
});

const toCustomFormat = baseMemoize<DateTime['toCustomFormat']>(
  (fn, date, formatOptions, options) => {
    return [
      'toCustomFormat',
      appConfig.get('lang'),
      date.epochMilliseconds,
      date.offset,
      date.isPlainDate,
      stringify(formatOptions),
      stringify(options)
    ].join('-');
  }
);

function HandlerGet(target: DateTime, prop: keyof DateTime, receiver: unknown) {
  const value = Reflect.get(target, prop, receiver);
  if (typeof value !== 'function') {
    return value;
  }

  const fn = value.bind(target);
  let resultFn = fn;

  switch (prop) {
    case 'withTimeZone': {
      // @ts-ignore - no overload matches this call
      resultFn = withTimeZone.bind(target, fn, target);
      break;
    }
    case 'floor': {
      // @ts-ignore - no overload matches this call
      resultFn = floor.bind(target, fn, target);
      break;
    }
    case 'since': {
      // @ts-ignore - no overload matches this call
      resultFn = since.bind(target, fn, target);
      break;
    }
    case 'toString': {
      // @ts-ignore - no overload matches this call
      resultFn = toString.bind(target, fn, target);
      break;
    }
    case 'toCustomFormat': {
      // @ts-ignore - no overload matches this call
      resultFn = toCustomFormat.bind(target, fn, target);
      break;
    }
    case 'hasSame': {
      // @ts-ignore - no overload matches this call
      resultFn = hasSame.bind(target, fn, target);
      break;
    }
  }
  return resultFn;
}

function parseTimeZone(date: string): Temporal.TimeZone | Temporal.TimeZoneProtocol | undefined {
  try {
    return Temporal.TimeZone.from(date);
  } catch {
    return undefined;
  }
}

function normalizeFormattedDate(date: string): string {
  return date.replace(' г.', '').replace(' г.', '').replace('&nbsp;г.', '');
}

export const DateTimeHelper = CachedDateTime;
