<template>
  <span :class="$style.root">
    <base-icon type="timer" :class="$style.icon" />
    <span :class="$style.title" data-qa="title" v-html="title" />
  </span>
</template>

<script>
import { DateTimeHelper } from '@/util/date-time-helper';
import BaseIcon from '@/components/icon';
import { VacancyRequestHelpers } from '@/components/vjsf/helpers/vacancy-request-helpers';

export default {
  name: 'RequestListItemDeadline',
  components: {
    BaseIcon
  },
  props: {
    date: {
      type: Date,
      required: true
    },
    vacancyRequestSchema: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      const dateStr = DateTimeHelper.toLongFormat(DateTimeHelper.parse({ date: this.date }), {
        useRelationFormat: true
      });
      const title = VacancyRequestHelpers.getDeadlineTitle(this.vacancyRequestSchema);
      return `${title} ${dateStr}`;
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.root {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: @lightTextColor;
  position: relative;
}

.icon,
.title {
  flex: none;
}

.icon {
  @offset: -8.5px;
  position: absolute;
  top: @offset;
  left: @offset;
}

.title {
  padding-left: 20px;
}
</style>

<i18n lang="json">{}</i18n>
