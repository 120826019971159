export enum LegacySchemaType {
  COMPLEX = 'complex',
  DICTIONARY = 'dictionary',
  DIVISION = 'division',
  HTML = 'html',
  INTEGER = 'integer',
  REGION = 'region',
  SELECT = 'select',
  STRING = 'string',
  TEXT = 'text',
  DATE = 'date',
  HIDDEN = 'hidden',
  URL = 'url',
  CONTRACT = 'contract',
  REASON = 'reason',
  INCOME = 'income',
  COMPENSATION = 'compensation',
  POSITION_STATUS = 'position_status'
}
