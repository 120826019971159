import { ref, Ref } from 'vue';

interface Pointer {
  next: () => void;
  prev: () => void;
  reset: () => void;
  select: (index: number) => void;
  pointer: Ref<number | undefined>;
}

type BaseItem = Record<string, string | number> & { id: string };

type Options = {
  selected: Ref<string | undefined>;
  loop?: Ref<boolean>;
};

/*
 * - управление выделением элемента в списке
 */
export function useListCursor<Item extends BaseItem>(
  items: Ref<Array<Item>>,
  { selected, loop = ref(true) }: Options
): Pointer {
  const pointer: Ref<number | undefined> = ref();

  function select(index: number) {
    pointer.value = index;
  }
  function next() {
    if (!items.value.length) {
      pointer.value = undefined;
      return;
    }

    const index = pointer.value;
    if (index === undefined) {
      pointer.value = 0;
      return;
    }

    const lastIndex = items.value.length - 1;
    pointer.value = index === lastIndex ? (loop.value ? 0 : index) : index + 1;
  }
  function prev() {
    if (!items.value.length) {
      pointer.value = undefined;
      return;
    }

    const index = pointer.value;
    const lastIndex = items.value.length - 1;
    if (index === undefined) {
      pointer.value = lastIndex;
      return;
    }

    pointer.value = index === 0 ? (loop.value ? lastIndex : index) : index - 1;
  }

  function reset() {
    const index = items.value.findIndex((item) => item.id === selected.value);
    pointer.value = index === -1 ? undefined : index;
  }

  return {
    next,
    prev,
    reset,
    select,
    pointer
  };
}
