import { appConfig } from '@/services/config/app-config';

export function quotaFromRequest(vacancyRequest) {
  let quota = {
    applicants_to_hire: 1,
    deadline: null,
    created: new Date().toISOString(),
    account_info: appConfig.get('account')
  };

  if (vacancyRequest) {
    const { applicants_to_hire = 1, deadline = null, vacancy_request } = vacancyRequest;

    quota = {
      applicants_to_hire,
      deadline,
      vacancy_request,
      created: new Date().toISOString()
    };
  }

  return quota;
}

export function enrichWithFillQuotas(
  vacancy,
  fillQuotas,
  { childId, multiple, reopen, vacancyRequest } = {}
) {
  if (multiple) {
    vacancy.blocks = vacancy.blocks || [];
    vacancy.blocks.forEach((block) => {
      block.fill_quotas = fillQuotas[block.id] || [];
    });
  } else {
    vacancy.fill_quotas = fillQuotas[vacancy.id] || [];
  }

  const quota = quotaFromRequest(vacancyRequest);
  if (!vacancy.id) {
    if (multiple) {
      vacancy.blocks = [{ fill_quotas: [quota] }];
    } else {
      vacancy.fill_quotas = [quota];
    }
  } else if (vacancyRequest) {
    if (multiple) {
      const currentBlock = vacancy.blocks.find((b) => b.id === childId);
      if (currentBlock) {
        currentBlock.fill_quotas = [quota, ...currentBlock.fill_quotas];
      }
    } else {
      vacancy.fill_quotas = [quota, ...vacancy.fill_quotas];
    }
  }

  if (reopen) {
    if (!multiple && vacancy.fill_quotas.length === 0) {
      vacancy.fill_quotas.push(quota);
    } else if (multiple) {
      const block = vacancy.blocks.find((b) => b.id === childId);
      if (block && block.fill_quotas.length === 0) {
        block.fill_quotas.push(quota);
      }
    }
  }

  return vacancy;
}

export function fixVisibility(vacancy) {
  const { hidden, ...rest } = vacancy;

  return {
    visibility: hidden ? 1 : 0,
    ...rest
  };
}

export function fixVacancyFieldsTypes(vacancy, vacancyFields) {
  const result = vacancyFields.reduce((acc, field) => {
    if (vacancy[field.name]) {
      if (field.multiple) {
        let value;

        if (Array.isArray(vacancy[field.name])) {
          value = vacancy[field.name];
        } else {
          try {
            value = JSON.parse(vacancy[field.name]);
          } catch (e) {} // eslint-disable-line

          if (!Array.isArray(value)) {
            value = [value];
          }
        }

        acc[field.name] = value;
      } else {
        acc[field.name] = vacancy[field.name];
      }
    }

    return acc;
  }, {});

  return { ...vacancy, ...result };
}

function transformQuotasErrors(errors) {
  if (!errors) {
    return errors;
  }

  if (errors.fill_quotas) {
    errors.fill_quotas = errors.fill_quotas[0];

    Object.keys(errors.fill_quotas).forEach((idx) => {
      if (Array.isArray(errors.fill_quotas[idx])) {
        errors.fill_quotas[idx] = errors.fill_quotas[idx][0];
      }
    });
  }

  return errors;
}

export function transformErrors(errors) {
  if (!errors) {
    return errors;
  }

  transformQuotasErrors(errors);
  if (Array.isArray(errors.blocks)) {
    errors.blocks.forEach(transformQuotasErrors);
  }

  return errors;
}
