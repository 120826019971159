import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient } from '@/api/utils/client/axios';
import { ItemList } from '@/api/utils/types';

interface NewsItem {
  id: number;
  content: string;
  subject: string;
  theme?: {
    background: string;
    textColor: string;
    iconColor: string;
  };
}

class NewsLayer extends ApiLayer<AxiosClient> {
  private getItemByNewsKey(key: string) {
    return this.methods.get<NewsItem>(`/news_items/${key}`, { baseURL: '/' });
  }

  private fakeNews() {
    const fakeNewsItems = ['0057_palantir', '0050_join'];
    return Promise.all(fakeNewsItems.map((key) => this.getItemByNewsKey(key)));
  }

  getList() {
    return this.methods
      .get<ItemList<NewsItem>>('/news_items/', { baseURL: '/' })
      .then(({ items }) => items);
  }

  markViewed(newsId: number) {
    return this.methods.put(
      `/news_items/${newsId}`,
      {
        viewed: true
      },
      { baseURL: '/' }
    );
  }
}

export const NewsAPI = new NewsLayer(axios);
