import omit from 'lodash/omit';
import * as LegacySchemaUtil from './legacy-schema-utils';
import { makeSchema } from './helpers/make-schema';
import { enrichLegacyField } from './enrich-legacy-field';
import { getSubmitButtonName } from '@/components/vjsf/schema-converter/utils';
import { LegacySchemaType } from '@/components/vjsf/schema-converter/legacy-schema-type';

export function deriveSchema(schema, { dictionaries = {} } = {}) {
  const root = makeSchema(schema, { dictionaries });
  const newSchema = processSchema(schema.schema, root, dictionaries);
  return addSubmit(newSchema);
}

function processSchema(group, parent, dictionaries) {
  const entries = Object.entries(group);
  const required = LegacySchemaUtil.getRequiredFieldNames(entries);

  const { mappedEntries, allOf, availableOnFields } = entries.reduce(
    (acc, [key, field], index, arr) => {
      field.name = field.name || key;
      field = enrichLegacyField(field);
      const fieldSchema = makeSchema(field, { dictionaries });

      if (field.availableOn) {
        acc.availableOnFields.push(field.name);
        const [dependencyFieldKey, ...dependencyFieldPath] = field.availableOn.field.split('.');

        const dependencyField = arr.find(([k]) => k === dependencyFieldKey)?.[1];
        if (!dependencyField) {
          throw new Error(`missing dependency: ${dependencyFieldKey}`);
        }
        if (LegacySchemaUtil.isComplexLike(dependencyField)) {
          throw new Error(`incorrect dependency: ${dependencyFieldKey}`);
        }

        let dependencyFieldValidation;
        if (LegacySchemaUtil.isDictionaryLike(dependencyField)) {
          const dictionaryId = LegacySchemaUtil.getDictionaryId(dependencyField);
          dependencyFieldValidation = {
            valid_against_dictionary: {
              dictionary: dictionaries[dictionaryId].fields,
              path: dependencyFieldPath,
              operator: field.availableOn.operator,
              value: field.availableOn.value
            }
          };
        } else {
          dependencyFieldValidation = {
            valid_against_value: {
              operator: field.availableOn.operator,
              value: field.availableOn.value
            }
          };
        }

        acc.allOf.push({
          if: {
            properties: {
              [dependencyFieldKey]: dependencyFieldValidation
            },
            required: [dependencyFieldKey]
          },
          then: {
            properties: {
              [key]: LegacySchemaUtil.isComplexLike(field)
                ? processSchema(field.fields, fieldSchema, dictionaries)
                : fieldSchema
            },
            ...(required.includes(field.name) ? { required: [field.name] } : {})
          }
        });

        return acc;
      }

      if (LegacySchemaUtil.isComplexLike(field)) {
        acc.mappedEntries.push([key, processSchema(field.fields, fieldSchema, dictionaries)]);
      } else {
        acc.mappedEntries.push([key, fieldSchema]);
      }
      return acc;
    },
    { mappedEntries: [], allOf: [], availableOnFields: [] }
  );

  const result = {
    ...parent,
    properties: Object.fromEntries(mappedEntries),
    required: required.filter((name) => availableOnFields.includes(name) === false),
    ...(allOf.length ? { allOf } : {})
  };

  return result.required.length ? omit(result, 'nullable') : result;
}

function addSubmit(schema) {
  schema.properties[getSubmitButtonName()] = makeSchema({
    type: LegacySchemaType.STRING
  });
  return schema;
}
