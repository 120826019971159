<template>
  <select
    ref="select"
    v-bind="$attrs"
    :class="className"
    :aria-invalid="invalid ? 'true' : 'false'"
    v-on="inputListeners"
  >
    <slot></slot>
  </select>
</template>

<script>
import widthStyles from '../../shared-css-modules/widths.module.css';

export default {
  name: 'CustomSelect',
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'full',
      validator(value) {
        return Boolean(widthStyles[value]);
      }
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changeValue', 'change'],
  computed: {
    inputListeners() {
      return {
        ...this.$listeners,
        change: (event) => {
          this.$emit('changeValue', event.target.value);
          // TODO: changeValue event (see input component)
          this.$emit('change', event.target.value, event);
        }
      };
    },
    className() {
      const widthClassName = widthStyles[this.size];
      return {
        [this.$style.select]: true,
        'custom-select': true,
        'form-control_invalid': this.invalid,
        [widthClassName]: Boolean(widthClassName)
      };
    }
  },
  mounted() {
    if (this.focus) {
      this.makeFocus();
    }
  },
  methods: {
    makeFocus() {
      this.$refs.select.focus();
    }
  }
};
</script>

<style module>
.select {
  @mixin ellipsis;
}
</style>

<i18n lang="json">{}</i18n>
