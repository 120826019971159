<template>
  <div :class="$style.wrapper" data-qa="files-attachment-list">
    <ul v-if="visibleFiles.length" :class="$style.list" data-qa="files">
      <li v-for="file in visibleFiles" :key="file.temporalId || file.id" :class="$style.item">
        <file-attachment
          :file="file"
          :loading="isFileLoading(file)"
          :view-mode="viewMode"
          @remove="handleRemove"
        />
      </li>
    </ul>
    <base-upload
      v-if="!viewMode"
      v-show="!hideAddButton"
      :base-url="baseUrl"
      :multiple="computedMultipleSelect"
      :class="$style.uploadButton"
      @files="handleFiles"
    >
      <template #default="slotProps">
        <slot name="button" v-bind="slotProps">
          <attachment-add-button
            :disabled="disabled"
            @click="slotProps.open"
            @disabled-click="$emit('click')"
          >
            <template #caption>
              <slot name="button-caption" />
            </template>
          </attachment-add-button>
        </slot>
      </template>
    </base-upload>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';

import FileAttachment from '@/components/ui/file-attachment/file-attachment';
import BaseUpload from '@/components/ui/base-upload/base-upload';
import AttachmentAddButton from '@/components/ui/file-attachment-list/attachment-add-button.vue';

export default {
  name: 'FileAttachmentList',
  components: { AttachmentAddButton, FileAttachment, BaseUpload },
  model: {
    prop: 'files',
    event: 'change'
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    viewMode: Boolean,
    hideAddButton: Boolean,
    disabled: Boolean,
    // true - можно загрузить несколько файлов
    multiple: {
      type: Boolean,
      default: true
    },
    // true - файлы добавляются по одному
    multipleSelect: {
      ...BaseUpload.props.multiple,
      default: undefined
    },
    baseUrl: BaseUpload.props.baseUrl,
    showLoadingFiles: {
      type: Boolean,
      default: true
    }
  },
  emits: ['change', 'click'],
  data() {
    return {
      innerFiles: []
    };
  },
  computed: {
    // код для согласованности значений параметров
    computedMultipleSelect() {
      if (!this.multiple) {
        return false;
      }
      return this.multipleSelect ?? this.multiple;
    },
    visibleFiles() {
      if (this.showLoadingFiles) {
        return this.files;
      }
      return this.files.filter((file) => {
        return !this.isFileLoading(file);
      });
    }
  },
  watch: {
    files: {
      immediate: true,
      handler(files) {
        this.innerFiles = [...files];
      }
    }
  },
  methods: {
    isFileLoading(file) {
      return !file.id && !file.error;
    },
    handleRemove(id) {
      const files = this.innerFiles.filter((file) => file.id !== id);
      this.$emit('change', files);
    },
    handleFiles(fileList) {
      if (!this.multiple) {
        this.innerFiles = fileList;
        this.$emit('change', fileList);
        return;
      }
      const files = [...this.innerFiles];
      fileList.forEach((file) => {
        const fileIndex = files.findIndex(({ temporalId }) => file.temporalId === temporalId);
        if (fileIndex === -1) {
          files.push(file);
        } else {
          files[fileIndex] = file;
        }
      });

      const uniqFiles = uniqBy(files, ({ id, temporalId }) => id || temporalId);
      this.$emit('change', uniqFiles);
    }
  }
};
</script>

<style module>
.wrapper {
  display: flex;
  flex-direction: column;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none inside none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.list + .uploadButton {
  margin-top: 8px;
}

.item {
  display: inline-flex;
  max-width: 100%;
}
</style>

<i18n lang="json">{}</i18n>
