<template>
  <fieldset :id="id" :class="{ [$style.delimiter]: isDelimiter }">
    <base-label>
      <slot name="label" :label="label">
        <span v-html="label" />
      </slot>
    </base-label>
    <slot>
      <slot v-for="name in orderedProperties" :name="name" />
    </slot>
  </fieldset>
</template>

<script>
import BaseLabel from '@/components/label/label.vue';

export default {
  name: 'BaseTemplate',
  components: { BaseLabel },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    schema: {
      type: Object,
      required: true
    },
    uiSchema: {
      type: Object,
      required: true
    },
    orderedProperties: {
      type: Array,
      required: true
    }
  },
  computed: {
    isDelimiter() {
      return this.uiSchema['ui:options']?.breakAfter;
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables.less';

.delimiter {
  padding-bottom: 24px;
  margin-bottom: 16px;
  border-bottom: 1px solid @borderColor;
}
</style>

<i18n lang="json">{}</i18n>
