<template>
  <email-verification-modal
    v-if="showModal"
    :email-info="emailInfo"
    :is-change="isChange"
    :initial-verify-id="verifyId"
    :initial-pin-code="pinCode"
    @close="handleClose"
  />
</template>

<script>
import EmailVerificationModal from '@/components/email-verification/main/email-verification-modal.vue';

const IS_CHANGE_PARAM = 'change';

export default {
  name: 'EmailVerificationChecker',
  components: { EmailVerificationModal },
  data() {
    return {
      showModal: false,
      verifyId: '',
      isChange: false,
      pinCode: '',
      emailInfo: {}
    };
  },
  watch: {
    $route: {
      handler(route) {
        if (!route) {
          return;
        }

        const { meta, query } = route;

        if (!meta || !meta.showVerification) {
          return;
        }
        const { id, type, email, action, code } = query;
        if (!id || !type || !email || !action) {
          return;
        }
        this.verifyId = id;
        this.isChange = action === IS_CHANGE_PARAM;
        this.pinCode = code;
        this.emailInfo = { type, email };
        this.showModal = true;
      },
      immediate: true
    }
  },
  methods: {
    handleClose() {
      this.showModal = false;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
