import { ApiLayer } from '@/api/utils/api-layer';
import { axiosApp, AxiosClient, AxiosRequestConfig } from '@/api/utils/client/axios';
import { Delete } from '@/api/utils/default-responses';
import { ItemList } from '@/api/utils/types';
import { Status } from '@/types/status';

type Pipeline = {
  id: number;
  account: number;
  name: string;
  statuses: [{ account_vacancy_status: number }];
};

declare global {
  interface Window {
    AccountPipelines: Pipeline[];
  }
}

class PipelinesLayer extends ApiLayer<AxiosClient> {
  getStatuses() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Promise.resolve(window.Organization?.vacancy_status ?? []); // TODO: https://huntflow.atlassian.net/browse/DEV-5570
  }

  updateStatuses(statuses: Status[], config?: AxiosRequestConfig) {
    return this.methods
      .put<ItemList<Status>>('/settings/statuses', statuses, config)
      .then((res) => res.items);
  }

  getPipelines() {
    return Promise.resolve(window.AccountPipelines || []); // TODO: https://huntflow.atlassian.net/browse/DEV-5573
  }

  removePipeline(id: number, config?: AxiosRequestConfig) {
    return this.methods.delete<Delete>(`/pipelines/${id}`, config);
  }

  createPipeline(pipeline: Omit<Pipeline, 'id' | 'account'>, config?: AxiosRequestConfig) {
    return this.methods.post<Pipeline>('/pipelines', pipeline, config);
  }

  updatePipeline(pipeline: Pipeline, config?: AxiosRequestConfig) {
    return this.methods.put<Pipeline>(`/pipelines/${pipeline.id}`, pipeline, config);
  }

  updateTimeOnStatus(
    {
      pipeline,
      status,
      days,
      toAll
    }: {
      pipeline: number;
      status: number;
      days: number;
      toAll: boolean;
    },
    config?: AxiosRequestConfig
  ) {
    const updateStatuses = pipeline === -1;
    const path = updateStatuses
      ? `/settings/statuses/${status}`
      : `/pipelines/${pipeline}/statuses/${status}`;

    return this.methods.patch(
      path,
      {
        stay_duration: days || null
      },
      {
        ...config,
        params: toAll
          ? {
              apply_to_all: true
            }
          : null
      }
    ); // TODO: с возвращаемыми типами тут совсем беда, тут в трёх разных случаях приходит 3 разных ответа
  }
}
export const PipelinesAPI = new PipelinesLayer(axiosApp);
