<template>
  <div :class="$style.searchInput">
    <type-select :type="$route.meta.type" @input="onTypeChange" />

    <div :class="$style.textWrapper">
      <base-input
        ref="searchInput"
        v-model="searchQuery"
        :focus="true"
        :placeholder="$trlMessage('layout.search_placeholder')"
        :class="$style.input"
        type="text"
        data-qa="search-input"
        @input="debounceSearch"
      />
    </div>

    <base-loader size="m" :class="[$style.searchLoading, { [$style.visible]: isShowLoading }]" />
  </div>
</template>

<script>
import TypeSelect from '@/modules/search/type-select/type-select';
import BaseInput from '@/components/input/input';
import BaseLoader from '@/components/loader/loader';
import debounce from 'lodash/debounce';
import pageTitle from '@/services/page-title';

export default {
  name: 'SearchInput',
  components: {
    BaseInput,
    BaseLoader,
    TypeSelect
  },
  props: {
    isShowLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: ''
    };
  },
  watch: {
    searchQuery: {
      handler() {
        this.setPageTitle();
      },
      immediate: true
    }
  },
  mounted() {
    this.searchQuery = this.$route.query.q || '';
  },
  methods: {
    debounceSearch: debounce(function () {
      this.search();
    }, 300),

    search() {
      this.setPageTitle();
      const { query } = this.$route;
      this.$router.replace({ query: { ...query, q: this.searchQuery } });
    },

    setPageTitle() {
      const parts = [];

      if (this.searchQuery.length) {
        parts.push(this.searchQuery);
      }

      parts.push(this.$trlMessage('search.page-title'));
      // $nextTick тут затем, чтобы НЕ происходило так:
      // сначала title такой: "[localSearchQuery] - Поиск - ...",
      // а потом перезаписывается стандартным: "Поиск - ..."
      // this.$nextTick(() => pageTitle.replace(pageTitle.join(...parts)));
      this.$nextTick(() => pageTitle.replace(pageTitle.join(...parts)));
    },
    focusInput() {
      this.$refs.searchInput.$el.focus();
    },
    onTypeChange(type) {
      this.focusInput();
      this.$router.push({ name: `search-${type}`, query: this.$route.query });
      this.setPageTitle();
    }
  }
};
</script>

<style module>
.searchInput {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}

.textWrapper {
  padding-left: 1px;
  position: relative;
  top: -2px;
  width: 100%;
}

.input {
  display: block;
  width: 100%;
  height: $layoutHeaderHeight;
  border: 0 none;
  background: transparent;
  color: $white;

  &:focus {
    outline: 0 none;
  }
}

.searchLoading {
  padding-left: 5px;
  transition: opacity 0.3s;
  flex-shrink: 0;

  &:not(.visible) {
    opacity: 0;
    pointer-events: none;
  }
}
</style>

<i18n lang="json">{}</i18n>
